// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   envName: 'local',
//   production: false,
//   // redirectUrl: 'https://login.microsoftonline.com/0161ef8a-0ea0-436e-a7ef-892a8018d1ef/oauth2/v2.0/authorize?p=B2C_1A_LMLD_Toyota_SignInPolicy&client_id=9c3b5ece-7214-4f77-8a5d-9e2888c3c2d3&redirect_uri=http://localhost:4200&response_type=id_token&scope=openid',
//   baseUrl: 'https://lmlqafunction.azurewebsites.net/api/',
//   functionKey: '',
//   sendMail: false,
//   azureStorage: 'https://lmlqastorage.blob.core.windows.net/docusign/',
//   pilotCheck: false,
//   checkDocusignMailId: true,
//   pcMailId:true,
//   // issuer: 'https://ep.fram.qa.idm.toyota.com/oauth2/realms/root/realms/dealerdaily',
//   issuer: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily', // given by fr, updated in july2024
//   clientId: 'LML',
//   scope: 'LML',
//   responseType: 'code',
//   redirectUri: "http://localhost:4200",
//   silentRefreshRedirectUri: 'http://localhost:4200/silent-refresh.html',
//   // redirectUri: window.location.origin,
//   // silentRefreshRedirectUrl: window.location.origin + "/silent-refresh.html"
// };

export const environment = {
  envName: 'qa',
  production: true,
  redirectUri: 'https://lmlstage.lexuslearn.com',
  // redirectUri: 'https://login.microsoftonline.com/tmnab2cqa.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_LMLD_Toyota_SignInPolicy&client_id=6accd379-d6c3-471f-88a4-edbe2b65b8fa&redirect_uri=https://lmlstage.lexuslearn.com/&response_type=id_token&scope=openid',
  baseUrl: 'https://lmlqafunction.azurewebsites.net/api/',
  functionKey: '',
  sendMail: false,
  azureStorage: 'https://lmlqastorage.blob.core.windows.net/docusign/',
  pilotCheck: false,
  checkDocusignMailId: true,
  pcMailId:true,
  // issuer: 'https://ep.fram.qa.idm.toyota.com/oauth2/realms/root/realms/dealerdaily',
  issuer: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily', // given by fr, updated in july2024
  clientId: 'LML',
  scope: 'LML',
  responseType: 'code',
  silentRefreshRedirectUri: ''
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
