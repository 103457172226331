import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ExcelService } from '../service/excel.service';
import { SwitchAccountService } from './switch-account.service';
import { CommonService } from '../service/common.service';
import { PlatformLocation, Location } from '@angular/common';
declare var $: any;
import { Router } from '@angular/router';

export interface Dealerdata {
  //radio: boolean;
  fullname: string;
  spin_id: string;
  job_description: string
}
@Component({
  selector: 'app-switch-account',
  templateUrl: './switch-account.component.html',
  styleUrls: ['./switch-account.component.css']
})
export class SwitchAccountComponent implements OnInit {

  // ------------------------------------------Table Source------------------------------------------

  dealerDataSource: MatTableDataSource<Dealerdata>;
  dealerColumns = ['radio', 'name', 'spinId', 'designation'];

  //------------------------------------- Common Variables--------------------------------------------

  dlr: string = '';
  dealername: string = '';
  spinid: string = '';
  Success: boolean = false;
  Error: boolean = false;
  ErrMsg: string = '';
  successMessage: string = '';

  noExcelData:boolean;
  tabName: string;
  dasboardTab: boolean = false;

  //------------------------------------- Paginator and Sort @ViewChild------------------------------

  @ViewChild('dealerSort', { read: MatSort }) dealerSort: MatSort;
  @ViewChild('dealerPaginator', { read: MatPaginator }) dealerPaginator: MatPaginator;

  //------------------------------------- Table Variables--------------------------------------------

  DEALER_DATA: Dealerdata[] = [];
  dealerTableLoading: boolean = false;
  dealerNoData: boolean = false;
  tableDisplay: boolean = false;
  dealerDataResponse: any = '';
  DEALERDATA: any = [];
  dealerTotalData: number = 0;

  //------------------------------------- Search Variables ------------------------------------- 

  loadSpinner: boolean;
  selectedRegion:string;
  filteredDealers: any[] = [];
  DealerDataForm: FormGroup;
  isLoading = false;
  dlrcode: any = "";
  dlrcodeClicked: Boolean = false;

  constructor(private switchService: SwitchAccountService, private fb: FormBuilder, private location: PlatformLocation,
    private appService: CommonService, private excelService: ExcelService, private _location: Location, private router: Router) {

    // if(this.selectedRegion == null || this.selectedRegion == ''){this.selectedRegion = this.appService.selectedRegion;}
    //* location onPopState function is to monitor the click of the browser back button
    location.onPopState(() => {
      var PageUrl = window.location.href;
      if (PageUrl.indexOf('/switchAccount') != -1) {
        this.navigateBack();
      }
    });

  }

  // changeAreaCode(region){
  //   this.appService.selectedRegion = region;
  //   console.log("Switch Component check :",this.appService.selectedRegion);
  //   this.navigateBack();
  // }

  ngOnInit() {
    console.log('clg admin switch acc',this.appService.role);
    if (this.appService.role != 'Lexus College' && this.appService.role != 'Lexus Associate' && this.appService.role != 'Area Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.DealerDataForm = this.fb.group({
      userInput: null
    });
  }

  // ======================================DEALER CODE SEARCH==========================================

  fadeDiv() { // make the table section is opacity to 0.5
    this.dlrcodeClicked = true;
  }

  fadeOut() { // make the table section is opacity to 1
    this.dlrcodeClicked = false;
  }

  closeSpinSearch() { // make the table section is opacity to 0.5 and close the search
    this.dlrcode = "";
    this.dlrcodeClicked = true;
    this.successMessage = '';
    this.Success = false;
    this.ErrMsg = '';
    this.Error = false;
  }

  // ------------------------------------------ autopopulate the user details -----------------------------------
  filterUser() {
    if (this.dlrcode != undefined) {this.dlrcode = this.dlrcode.replace(/[^a-zA-Z0-9 ]/g, "");}
    if (this.dlrcode != undefined && this.dlrcode.length > 1) {
      this.successMessage = '';
      this.Success = false;
      this.ErrMsg = '';
      this.Error = false;
      this.filteredDealers = [];
      this.isLoading = true;
      // console.log("Switch Func check :", this.appService.selectedRegion);
      this.switchService.getEnrolledDealerSearch(this.dlrcode,this.appService.selectedRegion).subscribe((result: any) => {
        this.filteredDealers = result.DealerDataSearch.Result;
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.filteredDealers = [];
        console.log('Error', err);
        console.log('Error while fetching the user list');
      });
    }
  }

  // ----------------------------------- get the associate details of the selected dealercode --------------------------------
  selectUser(dlrcode) {
    console.log('Selected DealerCode : ', dlrcode);
    this.successMessage = '';
    this.Success = false;
    this.ErrMsg = '';
    this.Error = false;
    this.tableDisplay = true;
    this.dlr = dlrcode.dealerCode;
    this.dealername = dlrcode.Name;
    this.associateDetail(dlrcode.dealerCode);
    if (window.location.href.indexOf("localhost") != -1) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '#/switchAccount';
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      // URL will change to switchAccount 
      this._location.go('switchAccount');
    }
  }

  navigateBack() {
    if (window.location.href.indexOf("localhost") != -1) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '#/switchAccount';
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      // URL will change to back to switchAccount
      this._location.go('switchAccount');
    }
    this.tableDisplay = false;
    this.dlrcode = '';
  }

  // -----------------------------------------Table Filter Method----------------------------------

  dealerapplyFilter(filterValue: string) {
    if (filterValue != undefined) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dealerDataSource.filter = filterValue;
    this.dealerDataSource.sort = this.dealerSort;
    this.dealerDataSource.paginator = this.dealerPaginator;
    }
  }

  associateDetail(dlrcode) {
    this.dealerTableLoading = true;
    this.dealerNoData = false;
    this.DEALER_DATA = [];
    this.dealerDataResponse = '';
    this.switchService.getDealerData(dlrcode).subscribe((response: any) => {
      this.dealerDataResponse = response.AssociateDetail.AssociateDetail;
      [].push.apply(this.DEALER_DATA, this.dealerDataResponse);
      if (this.dealerDataResponse != undefined && this.dealerDataResponse.length != 0) {
        for (let i = 0; i < this.dealerDataResponse.length; i++) {
          //this.spinid = this.dealerDataResponse[i].spinId;
          //this.DEALER_DATA[i].radio = this.dealerDataResponse[i].spinId;
          this.DEALER_DATA[i].fullname = this.dealerDataResponse[i].fullname;
          this.DEALER_DATA[i].spin_id = this.dealerDataResponse[i].spin_id;
          if (this.dealerDataResponse[i].job_description.toUpperCase() != 'GENERAL MANAGER' && this.dealerDataResponse[i].job_description.toUpperCase() != 'DEALER PRINCIPAL'
           && this.dealerDataResponse[i].job_description.toUpperCase() != 'CONTROLLER') {
            this.DEALER_DATA[i].job_description = 'PROGRAM CONSULTANT';
          } else {
            this.DEALER_DATA[i].job_description = this.dealerDataResponse[i].job_description;
          }
        };
        //console.log(this.dealerDataResponse);
        //console.log(this.DEALER_DATA);
      } else {
        this.DEALER_DATA = [];
        this.dealerNoData = true;
      };
      this.dealerDataSource = new MatTableDataSource(this.DEALER_DATA);
      this.dealerDataSource.sort = this.dealerSort;
      this.dealerDataSource.paginator = this.dealerPaginator;
      this.dealerTableLoading = false;
      this.dlrcode = this.dealername;
    }, error => {
      this.dealerNoData = true;
      this.dealerTableLoading = false;
      console.log('Error', error);
      console.log('Error While getting data for the Dealer Details Table');
      this.dlrcode = this.dealername;
    });
  }

  //------------------------------------ Switching operation -----------------------------------------------

  saveToSwitch(name, spinid) {
    this.successMessage = '';
    this.Success = false;
    this.ErrMsg = '';
    this.Error = false;
    this.switchService.updateTestUser(name, this.dlr, spinid).subscribe((result: any) => {
      this.successMessage = 'User Info Updated as ' + name + ', Kindly login using TESTUMT1 account.';
      this.Success = true;
      this.ErrMsg = '';
      this.Error = false;
      setTimeout(function () {
        this.Success = false;
      }.bind(this), 15000);
      console.log('Updated Test User Data');
    }, err => {
      this.successMessage = '';
      this.Success = false;
      this.ErrMsg = 'Error in Updating User Info';
      this.Error = true;
      console.log('Error while updating test user data');
    });
  }

  // ==============================EXPORT EXCEL==================================

  exportSummary() {
    this.noExcelData = false;
    if (this.tabName == 'Associate') {
      if (!this.dealerNoData) {
        this.excelService.exportAsExcelFile(this.dealerDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    }
  }
}
