import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ase-lexus',
  templateUrl: './ase-lexus.component.html',
  styleUrls: ['./ase-lexus.component.css']
})
export class AseLexusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
