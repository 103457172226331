import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });


@Injectable()
export class WatchlistService {

  public getWatchlistRecordsURL = "GetWatchlistRecords";
  public getWatchlistCommentsURL = "GetWatchlistComments";
  public insertWatchlistRecordURL = "InsertWatchlistRecord";
  public updateWatchlistRecordURL = "UpdateWatchlist";

  constructor(private http: Http, public appService : CommonService) { }

  getWatchlistRecords(region, isOnWatch){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getWatchlistRecordsURL + this.appService.functionKey;
    const body = {
      'region': region,
      'isOnWatch': isOnWatch
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getWatchlistComments(spinId) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getWatchlistCommentsURL + this.appService.functionKey;
    const body = {
      'spin_id': spinId,
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  insertWatchlistRecord(spinId, name, dealerCode, comments, addedBy){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertWatchlistRecordURL + this.appService.functionKey;
    const body = {
      'spinId': spinId,
      'name': name,
      'dealerCode': dealerCode,
      'comments': comments,
      'addedBy': addedBy
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  updateWatchlistRecord(spinId, comment, isOnWatch, isDeleted, By, type){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.updateWatchlistRecordURL + this.appService.functionKey;
    const body = {
      'spinId': spinId,
      'comment': comment,
      'isOnWatch': isOnWatch,
      'isDeleted': isDeleted,
      'by': By,
      'type': type
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //--------------------ERROR HANDLING--------------------

  private handleError(error: Response | any) {
    if (error.status === 500) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 400) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 409) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 406) {
      return Observable.throw(new Error(error.status));
    }
  }

}