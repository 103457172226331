import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatIconModule } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AseLexusService } from '../ase-lexus/ase-lexus.service';
import { ExcelService } from '../service/excel.service';
function reportValidator(fg: FormGroup) {
  const spinId = fg.get('spinId').value;
  const district00 = fg.get('district00').value;
  const district01 = fg.get('district01').value;
  const district02 = fg.get('district02').value;
  const district03 = fg.get('district03').value;
  const district04 = fg.get('district04').value;
  const district05 = fg.get('district05').value;
  const district06 = fg.get('district06').value;
  const areaCentral = fg.get('areaCentral').value;
  const areaEastern = fg.get('areaEastern').value;
  const areaSouthern = fg.get('areaSouthern').value;
  const areaWestern = fg.get('areaWestern').value;
  const areaAll = fg.get('areaAll').value;
  const codeN = fg.get('codeN').value;
  const codeNR = fg.get('codeNR').value;
  const codeSX = fg.get('codeSX').value;
  const dealer = fg.get('dealer').value;
  return spinId || district00 ||district01 ||district02 ||district03 ||district04 ||district05 ||district06 || codeN|| codeNR|| codeSX || areaCentral || areaEastern ||areaSouthern ||areaWestern ||areaAll || dealer
    ? null
    : { range: true };
}
@Component({
  selector: 'app-ase-rejection-report',
  templateUrl: './ase-rejection-report.component.html',
  styleUrls: ['./ase-rejection-report.component.css']
})
export class AseRejectionReportComponent implements OnInit {
  areaList = [
    { name: 'Central', formName: 'areaCentral' },
    { name: 'Eastern', formName: 'areaEastern' },
    { name: 'Southern', formName: 'areaSouthern' },
    { name: 'Western', formName: 'areaWestern' },
    { name: 'All', formName: 'areaAll' }
  ]
  districtList = [
    { name: '00', formName: 'district00' },
    { name: '01', formName: 'district01' },
    { name: '02', formName: 'district02' },
    { name: '03', formName: 'district03' },
    { name: '04', formName: 'district04' },
    { name: '05', formName: 'district05' },
    { name: '06', formName: 'district06' }
  ]
  codeList = [
    { name: 'N', formName: 'codeN' },
    { name: 'NR', formName: 'codeNR' },
    { name: 'SX', formName: 'codeSX' }
  ]
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('ReportPaginator', { read: MatPaginator }) ReportPaginator: MatPaginator;
  searchForm: FormGroup;
  reportArray: any = [];
  reportDataSource: MatTableDataSource<any>;
  reportDataColumns: string[] = [ "spinId","dealerCode", "firstName", "lastName", "region", "district", "code", "courses"];
  tableLoading = true;
  showSpinner = false;
  exportData: any[];
  filterSelected: boolean;
  lastUpdatedOn: any;
  constructor(private fb: FormBuilder,private aseLexusService: AseLexusService,private excelService: ExcelService) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      spinId: '',
      areaWestern: false,
      areaCentral: false,
      areaEastern: false,
      areaSouthern: false,
      areaAll: false,
      district01: false,
      district02: false,
      district03: false,
      district04: false,
      district05: false,
      district06: false,
      district00: false,
      codeN: false,
      codeNR: false,
      codeSX: false,
      dealer: ''
    }, { validator: reportValidator });
  }
  search() {
    this.tableLoading = true;
    this.showSpinner = true;
    this.reportArray = [];
    this.exportData = [];
    this.aseLexusService.getRejectionReport(this.searchForm.value).subscribe((response:any)=>{
      let responseArr = response.Reports.Result;
      for (let i = 0; i < responseArr.length; i++) {
        this.reportArray.push(responseArr[i]);
        this.exportData.push(responseArr[i])
      }
      this.lastUpdatedOn = this.reportArray.length>0 ? this.reportArray[0].createdOn : new Date()
      this.tableLoading = false;
      this.showSpinner = false;
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    })
  }
  reset() {
    this.searchForm.reset({
      spinId: '',
      areaWestern: false,
      areaCentral: false,
      areaEastern: false,
      areaSouthern: false,
      areaAll: false,
      district01: false,
      district02: false,
      district03: false,
      district04: false,
      district05: false,
      district06: false,
      district00: false,
      codeN: false,
      codeNR: false,
      codeSX: false,
      dealer: ''
    });
    this.reportArray = [];
    this.exportData = []
    this.tableLoading = true;
    this.showSpinner = false;
    this.reportDataSource.sort = this.sort;
    this.reportDataSource = new MatTableDataSource(this.reportArray);
    this.reportDataSource.paginator = this.ReportPaginator;
  }
  download() {
    let reportExportData = [];
    this.exportData.forEach(data => {
      let obj = {
        "SPIN ID": data.spinId,
        "DEALER CODE": data.dealerCode,
        "FIRST NAME": data.firstName,
        'LAST NAME': data.lastName,
        "AREA": data.region,
        "DISTRICT": data.district,
        "CODE": data.code,
        "COURSES": data.courses
      }
      reportExportData.push(obj);
    });
    this.excelService.exportAsExcelFile(reportExportData, 'ASE_Certifcation_Status_Report');
  }
  //  ================================= TABLE SEARCH FILTER METHOD ======================================
  applyFilter(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.reportDataSource.filter = filterValue.trim().toLowerCase();
    this.reportDataSource.filteredData.forEach(element => {
      this.exportData.push(element);
    })
    // if (filterValue != '' || this.reportDataSource.filteredData.length < 10) {
    //   $("#holdHistorTable").removeClass('tableOverflow');
    // } else {
    //   $("#holdHistorTable").addClass('tableOverflow');
    // }
  }
}
