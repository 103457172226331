import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const authConfig: AuthConfig = {
    issuer: environment.issuer,
    clientId: environment.clientId, // The "Auth Code + PKCE" client
    responseType: environment.responseType,
    redirectUri: environment.redirectUri,
    // scope: 'ADAS',
    // scope: 'openid ADAS',
    scope: environment.scope,
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    showDebugInformation: true,
    dummyClientSecret: 'secret',
    requireHttps: false,
    requestAccessToken: true,
    useHttpBasicAuth: false,  // Need to get the Token with Base 64 Encoded
    oidc: true,
    silentRefreshRedirectUri: environment.silentRefreshRedirectUri,
    timeoutFactor: 1,
    sessionChecksEnabled: true,
    clearHashAfterLogin: false,
    nonceStateSeparator: 'semicolon',
    useIdTokenHintForSilentRefresh: false
};
