import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Chart } from 'angular-highcharts';
import { StockChart } from 'angular-highcharts';
import * as  Highcharts from 'highcharts';
import { AdminReportService } from './admin-report.service';
import { ExcelService } from '../service/excel.service';
import { Router } from '@angular/router';

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

@Component({
  selector: 'app-admin-report',
  templateUrl: './admin-report.component.html',
  styleUrls: ['./admin-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminReportComponent implements OnInit {

  loadSpinner: boolean = true;
  notrendData: boolean = false;

  //Trend Chart Variable
  adminTrendchart: any = {};
  trendCategory: any = [];
  PAYMENT_HISTORY_COUNT: any = [];
  PAYMENT_LIST_COUNT: any = [];
  STAFF_MASTER_COUNT: any = [];
  LFS_MASTER_COUNT: any = [];
  ASSOCIATE_ENROLLMENT_COUNT: any = [];
  DEALER_ENROLLMENT_COUNT: any = [];
  HOLD_PAYMENT_COUNT: any = [];
  xaxisCategory: any = ["PAYMENT_HISTORY_COUNT", "PAYMENT_LIST_COUNT", "STAFF_MASTER_COUNT", "LFS_MASTER_COUNT",
    "ASSOCIATE_ENROLLMENT_COUNT", "DEALER_ENROLLMENT_COUNT", "HOLD_PAYMENT_COUNT"]
  trendChartLoading: boolean = false;
  trendResponse: any = '';
  trendFilterData: any = '';

  constructor(private reportService: AdminReportService, private excelService: ExcelService,private appService: CommonService, private router: Router) {
    this.loadSpinner = true;
    this.changefilterPeriod('ALL');
  }

  ngOnInit() {
    console.log('admin',this.appService.role);
    if (this.appService.role != 'Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
  }

  CheckChartLoading() {
    if (!this.trendChartLoading) {
      this.loadSpinner = false;
      this.trendChart();
    }
  }

  // filter function
  changefilterPeriod(filterValue) {
    this.trendFilterData = filterValue;
    var filterPeriod = '';
    var currentdate = new Date();
    if (filterValue == 'ALL') {
      filterPeriod = 'ALL';
      this.getTrendData(filterPeriod);
    } else if (filterValue == '3') {
      var Period = currentdate.setMonth(currentdate.getMonth() - 3);
      filterPeriod = new Date(Period).toISOString();
      this.getTrendData(filterPeriod);
    } else if (filterValue == '6') {
      var Period = currentdate.setMonth(currentdate.getMonth() - 6);
      filterPeriod = new Date(Period).toISOString();
      this.getTrendData(filterPeriod);
    } else if (filterValue == '365') {
      var Period = currentdate.setFullYear(currentdate.getFullYear() - 1);
      filterPeriod = new Date(Period).toISOString();
      this.getTrendData(filterPeriod);
    }
  }

  // Service call to get the trend details
  getTrendData(filterPeriod) {
    this.trendChartLoading = true;
    this.trendResponse = "";
    this.trendCategory = [];
    this.PAYMENT_HISTORY_COUNT = [];
    this.PAYMENT_LIST_COUNT = [];
    this.STAFF_MASTER_COUNT = [];
    this.LFS_MASTER_COUNT = [];
    this.ASSOCIATE_ENROLLMENT_COUNT = [];
    this.DEALER_ENROLLMENT_COUNT = [];
    this.HOLD_PAYMENT_COUNT = [];

    this.reportService.getTrendData(filterPeriod).subscribe((response: any) => {
      this.trendResponse = response.AdminTrend.Result;
      if (this.trendResponse.length != 0) {
        for (let i = 0; i < this.trendResponse.length; i++) {
          this.trendCategory.push(this.dateFormatter(this.trendResponse[i].LST_UPDT_DT));

          if (this.trendResponse[i].PAYMENT_HISTORY_COUNT != 0) {
            this.PAYMENT_HISTORY_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), this.trendResponse[i].PAYMENT_HISTORY_COUNT]);
          } else {
            this.PAYMENT_HISTORY_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), 0]);
          }
          if (this.trendResponse[i].PAYMENT_LIST_COUNT != 0) {
            this.PAYMENT_LIST_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), this.trendResponse[i].PAYMENT_LIST_COUNT]);
          } else {
            this.PAYMENT_LIST_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), 0]);
          }
          if (this.trendResponse[i].STAFF_MASTER_COUNT != 0) {
            this.STAFF_MASTER_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), this.trendResponse[i].STAFF_MASTER_COUNT]);
          } else {
            this.STAFF_MASTER_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), 0]);
          }
          if (this.trendResponse[i].LFS_MASTER_COUNT != 0) {
            this.LFS_MASTER_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), this.trendResponse[i].LFS_MASTER_COUNT]);
          } else {
            this.LFS_MASTER_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), 0]);
          }
          if (this.trendResponse[i].ASSOCIATE_ENROLLMENT_COUNT != 0) {
            this.ASSOCIATE_ENROLLMENT_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), this.trendResponse[i].ASSOCIATE_ENROLLMENT_COUNT]);
          } else {
            this.ASSOCIATE_ENROLLMENT_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), 0]);
          }
          if (this.trendResponse[i].DEALER_ENROLLMENT_COUNT != 0) {
            this.DEALER_ENROLLMENT_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), this.trendResponse[i].DEALER_ENROLLMENT_COUNT]);
          } else {
            this.DEALER_ENROLLMENT_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), 0]);
          }
          if (this.trendResponse[i].HOLD_PAYMENT_COUNT != 0) {
            this.HOLD_PAYMENT_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), this.trendResponse[i].HOLD_PAYMENT_COUNT]);
          } else {
            this.HOLD_PAYMENT_COUNT.push([this.dateFormatter(this.trendResponse[i].LST_UPDT_DT), 0]);
          }
        }
        this.notrendData = false;
      } else {
        this.notrendData = true;
      }
      this.trendChartLoading = false;
      this.CheckChartLoading();
      this.trendChart();
    }, error => {
      this.trendChartLoading = false;
      this.CheckChartLoading();
      this.trendResponse = "";
      this.trendCategory = [];
      this.PAYMENT_HISTORY_COUNT = [];
      this.PAYMENT_LIST_COUNT = [];
      this.STAFF_MASTER_COUNT = [];
      this.LFS_MASTER_COUNT = [];
      this.ASSOCIATE_ENROLLMENT_COUNT = [];
      this.DEALER_ENROLLMENT_COUNT = [];
      this.HOLD_PAYMENT_COUNT = [];
      this.trendCategory = [];
      this.notrendData = true;
      this.trendChart();
      console.log('Error While fetching Trend Chart Data');
      console.log('Error', error);
    });
  }

  trendChart() {
    this.adminTrendchart = new StockChart({
      chart: {
        type: 'spline',
      },
      rangeSelector: {
        inputEnabled: true,
        buttonTheme: {
          visibility: 'hidden',
          labelStyle: 'white'
        },
        // labelStyle: {
        //   color: 'white',
        // }
      },
      title: {
        align: 'left',
        text: 'TREND',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        },
        margin: 30,
      },
      subtitle: {
        align: 'left',
        text: 'Snapshot of data in summary table',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
      },
      legend: {
        enabled: true,
        borderColor: 'black',
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: true
          }
        }
      },
      series: [
        {
          name: 'PAYMENT_HISTORY ',
          color: '#C19237',
          data: this.PAYMENT_HISTORY_COUNT
        },
        {
          name: 'PAYMENT_LIST',
          data: this.PAYMENT_LIST_COUNT
        },
        {
          name: 'STAFF_MASTER',
          data: this.STAFF_MASTER_COUNT
        },
        {
          name: 'LFS_MASTER',
          data: this.LFS_MASTER_COUNT
        },
        {
          name: 'ASSOCIATE_ENROLLMENT',
          data: this.ASSOCIATE_ENROLLMENT_COUNT
        },
        {
          name: 'HOLD_PAYMENT',
          data: this.HOLD_PAYMENT_COUNT
        }
      ]
    });
  }

  // UTC date formatter function
  dateFormatter(date) {
    let givenDate = new Date(date);
    let month = givenDate.getMonth();
    let dat = givenDate.getDate();
    let yr = givenDate.getFullYear();
    return Date.UTC(yr, month, dat);
  }
}
