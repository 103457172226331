import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class DebitPaymentService {

  public getDebitPaymentsURL = 'GetDebitPayments';
  public SaveCheckedDebitPaymentsURL = 'SaveCheckedDebitPayments';
  public SaveUncheckedDebitPaymentsURL = 'SaveUncheckedDebitPayments';
  public bulkDebitUpdateStatusPayURL='BulkDebitUpdateStatus';
  public bulkDebitUpdateCheckURL='BulkDebitUpdateCheck';
  public approveDebitURL = 'DebitApproveEmailTrig';
  public PaymentHistorySearchUrl = "PaymentHistorySearch";
  public insertDebitPaymentUrl = "InsertDebitPayment";
  public updateDebitPaymentUrl = "UpdateDebitPayment";
  public exportDebitPaymentUrl = "DebitExportEmailTrig";
  public exportDebitSendMailUrl ="LMSCSODCertStatusMail";
 
  constructor(private http: Http, public appService : CommonService) {}

  // ======================EMAIL TRIGGERING AFTER CLICKING APPROVE===============================
  approveDebit(excelApprove) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.approveDebitURL + this.appService.functionKey;
    const body = {
      'excel': excelApprove,
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  // ============================ INPROGRESS DEBITS DATA=========================================
  getDebitPayments(uid){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getDebitPaymentsURL + this.appService.functionKey;
    const body = {
      'uid': uid,
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================SAVE CHECKED DEBITS DATA=========================================
  saveDebitsCheck(checkDebitId: string) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.SaveCheckedDebitPaymentsURL + this.appService.functionKey;
    const body = {
      'checkDebitId': checkDebitId,
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================SAVE UNCHECKED DEBITS DATA=========================================
  SaveDebitsUnCheck(uncheckDebitId: string) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.SaveUncheckedDebitPaymentsURL + this.appService.functionKey;
    const body = {
      'uncheckDebitId': uncheckDebitId,
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  //  ==========================================APPROVE DEBIT PAYMENTS===================================
  bulkDebitUpdateStatus(debit_history_id) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkDebitUpdateStatusPayURL + this.appService.functionKey;
    const body = {
      "debit_history_id": debit_history_id
    };
    return this.http.post(url, body , options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  bulkDebitUpdateChecked(debit_history_id) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkDebitUpdateCheckURL + this.appService.functionKey;
    const body = {
      "debit_history_id": debit_history_id
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  searchPaymentHistory(searchParam) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      'searchParam': searchParam,
    }
    const url = this.appService.baseUrl + this.PaymentHistorySearchUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  insertDebitPayment(body) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    
    const url = this.appService.baseUrl + this.insertDebitPaymentUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  UpdateDebitPayment(body) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    
    const url = this.appService.baseUrl + this.updateDebitPaymentUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  exportDebitPayment(body) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    
    const url = this.appService.baseUrl + this.exportDebitPaymentUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  sendDebitPaymentMail(body){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    
    const url = this.appService.baseUrl + this.exportDebitSendMailUrl + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================EXCEPTION HANDLER=========================================
  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }
}
