import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { FormBuilder, FormGroup } from '@angular/forms';
import * as  Highcharts from 'highcharts';
import { BillingDashboardService } from '../billing-dashboard/billing-dashboard.service';
import { ExcelService } from '../service/excel.service';
declare var $: any;


Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

function reportValidator(fg: FormGroup) {
  const spinId = fg.get('spinId').value;
  const jobRole = fg.get('jobRole').value;
  const areaOrDistrict = fg.get('areaOrDistrict').value;
  const dealerNumber = fg.get('dealerNumber').value;
  const attendance = fg.get('attendance').value;
  const year = fg.get('year').value;
  return spinId  || jobRole || areaOrDistrict || dealerNumber || attendance || year
    ? null
    : { range: true };
}

@Component({
  selector: 'app-elevate-billing',
  templateUrl: './elevate-billing.component.html',
  styleUrls: ['./elevate-billing.component.css']
})
export class ElevateBillingComponent implements OnInit {
  dash = true;
  reportArray: any = [];
  coursesArr: any = [];
  reportDataSource: MatTableDataSource<any>;
  reportDataColumns: string[] = ["event","name", "spinId", "status", "dealer", "dealerArea", "jobRole", "amount"];
  tableLoading = true;
  coursesTableLoading = true;
  showSpinner = false;
  searchForm: FormGroup;
  firstTotalAmount = 0;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('ReportPaginator', { read: MatPaginator }) ReportPaginator: MatPaginator;
  exportData = [];
  filterSelected = false;
  exportChartName = '';
  expandState = true;
  jobRoleList = [];
  eventList = [];
  secondChartMonthFilter = '';
  firstChartMonthFilter = '';
  thirdChartMonthFilter = '';
  fourthChartMonthFilter = '';
  fifthChartMonthFilter = '';
  sixthChartJobRoleFilter ='';
  sixthChartMonthFilter = '';

  //first chart vars
  chart8: any = {};
  FirstChart: any = {};
  firstChartLoading: boolean = true;
  firstChartData: any = [];
  firstChartGraph: any = {};
  firstArea: any = [];
  firstAmount: any = [];
  noFirstData: boolean = false;
  firstDataDrill: any = [];
  firstDataDrill2: any = [];
  firstDrill: any[];
  areaDealerTrendMonArr = [];

   //second chart vars
  chart2: any = {};
   SecondChart: any = {};
   secondChartLoading: boolean = true;
   secondChartData: any = [];
   XAx4: any = [];
   secondChartGraph: any = {};
   secondDealers: any = [];
   secondDealerCosts: any = [];
   secondAmounts: any = [];
   secondAmounts2: any = [];
   secondAmounts2yt: any = [];
   noSecondData: boolean = false;
   secondTotalAmnt = 0;

   //third chart vars
   chart3: any = {};
  ThirdChart: any = {};
   thirdChartLoading: boolean = true;
   thirdChartData: any = [];
   XAx3: any = [];
   thirdChartGraph: any = {};
   thirdDealers: any = [];
   thirdDealerCosts: any = [];
   thirdAmounts: any = [];
   thirdAmounts2: any = [];
   thirdAmounts2yt: any = [];
   noThirdData: boolean = false;
   thirdTotalAmnt = 0;
  thirdDataDrill: any = [];
  thirdDataDrill2: any = [];
  thirdDrill: any[];
  jobRoleDealerTrendArr = [];
  //fourth chart vars
  chart4: any = {};
  FourthChart: any = {};
  fourthChartLoading: boolean = true;
  fourthChartData: any = [];
  XAx6: any = [];
  fourthChartGraph: any = {};
  fourthPresent: any = [];
  fourthPercents: any = [];
  noFourthData: boolean = false;
  fourthDataDrillComp: any = [];
  fourthDataDrillNoshow: any = [];
  fourthNewDrill: any = [];
  fourthNewDrill2: any = [];
  fourthTotalCount = 0;
  //fifth chart vars
  chart5: any = {};
  FifthChart: any = {};
  fifthChartLoading: boolean = true;
  fifthChartData: any = [];
  XAx5: any = [];
  fifthChartGraph: any = {};
  fifthPresent: any = [];
  fifthPercents: any = [];
  noFifthData: boolean = false;
  fifthDataDrillComp: any = [];
  fifthDataDrillNoshow: any = [];
  fifthNewDrill: any = [];
  fifthNewDrill2: any = [];
  fifthTotalCount = 0;
   //sixth chart vars
   chart6: any = {};
   SixthChart: any = {};
   sixthChartLoading: boolean = true;
   sixthChartData: any = [];
   XAx9: any = [];
   sixthChartGraph: any = {};
   sixthDealers: any = [];
   sixthDealerCosts: any = [];
   sixthAmounts: any = [];
   sixthAmounts2: any = [];
   sixthAmounts2yt: any = [];
   noSixthData: boolean = false;
   sixthTotalAmnt = 0;
  constructor(private fb: FormBuilder,private readonly billingDashboardService: BillingDashboardService,private excelService: ExcelService) { 
    this.searchForm = this.fb.group({
      spinId: '',
      jobRole: '',
      areaOrDistrict: '',
      dealerNumber: '',
      attendance: '',
      year: '',
      jobRoleSearch: 'No'
    }, { validator: reportValidator });
  }

  ngOnInit() {
    const body = {
      "jobRoleSearch": "Yes"
    }
    this.billingDashboardService.getReportSearchDetails(body).subscribe((response: any) => {
      this.jobRoleList = response.jobRoles.Result;
      this.eventList = response.event.Result1;
      this.sixthChartJobRoleFilter = this.jobRoleList[0].jobRole
      this.secondChartMonthFilter = this.eventList[0].event;
      this.firstChartMonthFilter = this.eventList[0].event;
      this.thirdChartMonthFilter = this.eventList[0].event;
      this.fourthChartMonthFilter = this.eventList[0].event;
      this.fifthChartMonthFilter = this.eventList[0].event;
      this.sixthChartMonthFilter = this.eventList[0].event
      console.log(this.sixthChartJobRoleFilter)
      this.firstChart();
      this.secondChart();
      this.thirdChart();
      this.fourthChart();
      this.fifthChart();
      this.sixthChart();
    })
  }
  dashCharts() {
    this.firstChart();
    this.secondChart();
    this.thirdChart();
    this.fourthChart();
    this.fifthChart();
    this.sixthChart();
    localStorage.setItem('chartAreaDrillVal', '')
    localStorage.setItem('chartAreaMonDrillVal', '')
  }
  firstChart() {
    this.firstChartLoading = true; this.noFirstData = false;
    this.firstArea = [];
    this.firstAmount = [];
    this.firstDrill = [];
    this.firstTotalAmount = 0;
    this.areaDealerTrendMonArr = [];
    let body = {
      "event": this.firstChartMonthFilter
    }
    this.billingDashboardService.getAreaTrendChart(body).subscribe((res: any) => {
      this.firstChartData = res.AreaTrend.Result;
      // this.firstChartData = null; //null check
      if (this.firstChartData != undefined && this.firstChartData != null) {
        if (this.firstChartData.length != 0) {
          for (let a = 0; a < this.firstChartData.length; a++) {
            this.firstArea.push(this.firstChartData[a].area);
            this.firstAmount.push(this.firstChartData[a].amount);
            this.firstTotalAmount += this.firstChartData[a].amount
          }
          this.firstDataDrill = []; this.firstDataDrill2 = [];
          let firstDrillDealers = []; let firstDrillAmounts = []; let firstDrillArea = []; let firstDrillDist = []; let firstDrillDlrName = [];
          const firstNewDrill = Array.from({ length: this.firstChartData.length }, (_v, _i) => []);
          this.billingDashboardService.getAreaDealerTrendChart(body).subscribe((res: any) => {
            let firstDrillData = res.AreaDealersTrend.Result;
            this.areaDealerTrendMonArr = res.AreaDealersTrend.Result;
            // firstDrillData = null; //null check drill
            if (firstDrillData != undefined && firstDrillData != null) {
              if (firstDrillData.length != 0) {
                this.noFirstData = false;
                for (let c = 0; c < firstDrillData.length; c++) {
                  for (let d = 0; d < this.firstArea.length; d++) {
                    if (firstDrillData[c].area == this.firstArea[d]) {
                      firstNewDrill[d].push(firstDrillData[c]);
                    }
                  }
                }
                for (let b = 0; b < firstNewDrill.length; b++) {
                  for (let z = 0; z < firstNewDrill[b].length; z++) {
                    firstDrillDealers.push(firstNewDrill[b][z].dealer_code);
                    firstDrillAmounts.push(firstNewDrill[b][z].amount);
                    firstDrillArea.push(firstNewDrill[b][z].area);
                    firstDrillDist.push(firstNewDrill[b][z].dealerDistrict);
                    firstDrillDlrName.push(firstNewDrill[b][z].dealerName);
                  }
                  let dumdum = []; let dum = [];
                  for (let d = 0; d < firstDrillDealers.length; d++) {
                    dumdum.push([firstDrillDealers[d], firstDrillAmounts[d]]);
                    dum.push({ y: firstDrillAmounts[d], extraInfo: [firstDrillDlrName[d], firstDrillArea[d], firstDrillDist[d]] })
                  }
                  this.firstDataDrill.push({ name: this.firstArea[b], id: this.firstArea[b], data: dumdum, type: 'column' });
                  this.firstDataDrill2.push({ name: this.firstArea[b], id: this.firstArea[b], data: dum, type: 'column' });
                  firstDrillDealers = []; firstDrillAmounts = []; firstDrillArea = []; firstDrillDist = []; firstDrillDlrName = [];
                  if (this.firstChartData.length == this.firstDataDrill.length) {
                    this.firstChartLoading = false;
                    this.firstChartGr(this.firstDataDrill, this.firstDataDrill2);
                  }
                }

              }
              else {
                this.noFirstData = true;
                this.firstChartLoading = false;
                this.firstChartGr('', '');
              }
            } else {
              this.noFirstData = true;
              this.firstChartLoading = false;
              this.firstChartGr('', '');
            }
          }, err => {
            this.noFirstData = true;
            this.firstChartLoading = false;
            this.firstChartGr('', '');
          })
        } else {
          this.noFirstData = true;
          this.firstChartLoading = false;
          this.firstChartGr('', '');
        }
      } else {
        this.noFirstData = true;
        this.firstChartLoading = false;
        this.firstChartGr('', '');
      }
    }, err => {
      this.noFirstData = true;
      this.firstChartLoading = false;
      this.firstChartGr('', '');
    })

  }
  firstChartGr(smth, smth2) {
    let mainTitle = 'Area billing trend for '+this.firstChartMonthFilter;
    let drillTitle = 'Area billing trend for '+this.firstChartMonthFilter;
    var firstArea = this.firstArea; 
    var firstAmount = this.firstAmount;
    let centD = []; let eastD = []; let westD = []; let southD = []; let otherD = [];
    for (let a = 0; a < smth2.length; a++) {
      if (smth2[a].id == 'CENTRAL AREA') { centD = smth2[a].data }
      else if (smth2[a].id == 'EASTERN AREA') { eastD = smth2[a].data }
      else if (smth2[a].id == 'WESTERN AREA') { westD = smth2[a].data }
      else if (smth2[a].id == 'SOUTHERN AREA') { southD = smth2[a].data }
      else if (smth2[a].id == 'PUERTO RICO') { otherD = smth2[a].data }
    }
    var UNDF;
    var newCat = [];
    var firstChartGraph = new Highcharts.Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        renderTo: 'firstChartGr',
        events: {
          load: function () {
            if (smth.length == 4) {
              this.addSeries({
                name: 'Area Trend', data: [{ name: firstArea[0], y: firstAmount[0], drilldown: firstArea[0] },
                { name: firstArea[1], y: firstAmount[1], drilldown: firstArea[1] },
                { name: firstArea[2], y: firstAmount[2], drilldown: firstArea[2] },
                { name: firstArea[3], y: firstAmount[3], drilldown: firstArea[3] }]
              })
            } else if (smth.length == 5) {
              this.addSeries({
                name: 'Area Trend', data: [{ name: firstArea[0], y: firstAmount[0], drilldown: firstArea[0] },
                { name: firstArea[1], y: firstAmount[1], drilldown: firstArea[1] },
                { name: firstArea[2], y: firstAmount[2], drilldown: firstArea[2] },
                { name: firstArea[3], y: firstAmount[3], drilldown: firstArea[3] },
                { name: firstArea[4], y: firstAmount[4], drilldown: firstArea[4] }]
              })
            }
            else if (smth.length == 6) {
              this.addSeries({
                name: 'Area Trend', data: [{ name: firstArea[0], y: firstAmount[0], drilldown: firstArea[0] },
                { name: firstArea[1], y: firstAmount[1], drilldown: firstArea[1] },
                { name: firstArea[2], y: firstAmount[2], drilldown: firstArea[2] },
                { name: firstArea[3], y: firstAmount[3], drilldown: firstArea[3] },
                { name: firstArea[4], y: firstAmount[4], drilldown: firstArea[4] },
                { name: firstArea[5], y: firstAmount[5], drilldown: firstArea[5] },]
              })
            }
          },
          drilldown: function (e) {
            let drillName = e.point.name;
            newCat = [];
            localStorage.setItem('chartAreaMonDrillVal', e.point.name)
            for (let i = 0; i < smth.length; i++) {
              if (smth[i].name == e.point.name) {
                for (let j = 0; j < smth[i].data.length; j++) {
                  newCat.push(smth[i].data[j][0])
                }
              }
            }
            if (smth.length == 4) {
              var chart = this,
                drilldowns1 = {
                  'CENTRAL AREA': { name: 'CENTRAL AREA', id: 'CENTRAL AREA', data: centD, type: 'column' },
                  'EASTERN AREA': { name: 'EASTERN AREA', id: 'EASTERN AREA', data: eastD, type: 'column' },
                  'SOUTHERN AREA': { name: 'SOUTHERN AREA', id: 'SOUTHERN AREA', data: southD, type: 'column' },
                  'WESTERN AREA': { name: 'WESTERN AREA', id: 'WESTERN AREA', data: westD, type: 'column' },
                }
              let series = drilldowns1[e.point.name];
              firstChartGraph.addSeriesAsDrilldown(e.point, series);
            } else if (smth.length == 5) {
              var chart = this,
                drilldowns2 = {
                  'CENTRAL AREA': { name: 'CENTRAL AREA', id: 'CENTRAL AREA', data: centD, type: 'column' },
                  'EASTERN AREA': { name: 'EASTERN AREA', id: 'EASTERN AREA', data: eastD, type: 'column' },
                  'SOUTHERN AREA': { name: 'SOUTHERN AREA', id: 'SOUTHERN AREA', data: southD, type: 'column' },
                  'WESTERN AREA': { name: 'WESTERN AREA', id: 'WESTERN AREA', data: westD, type: 'column' },
                  'PUERTO RICO': { name: 'PUERTO RICO', id: 'PUERTO RICO', data: otherD, type: 'column' }
                }
              let series = drilldowns2[e.point.name];
              firstChartGraph.addSeriesAsDrilldown(e.point, series);
            }
            else if (smth.length == 6) {
              var chart = this,
                drilldowns3 = {
                  'CENTRAL AREA': { name: 'CENTRAL AREA', id: 'CENTRAL AREA', data: centD, type: 'column' },
                  'EASTERN AREA': { name: 'EASTERN AREA', id: 'EASTERN AREA', data: eastD, type: 'column' },
                  'SOUTHERN AREA': { name: 'SOUTHERN AREA', id: 'SOUTHERN AREA', data: southD, type: 'column' },
                  'WESTERN AREA': { name: 'WESTERN AREA', id: 'WESTERN AREA', data: westD, type: 'column' },
                  'PUERTO RICO': { name: 'PUERTO RICO', id: 'PUERTO RICO', data: otherD, type: 'column' },
                  'OTHER': { name: 'OTHERS', id: 'OTHERS', data: otherD, type: 'column' },
                }
              let series = drilldowns3[e.point.name];
              firstChartGraph.addSeriesAsDrilldown(e.point, series);
            }
            firstChartGraph.xAxis[0].setCategories(newCat);
            firstChartGraph.xAxis[0].update({ title: { text: 'Dealers' } });
            firstChartGraph.yAxis[0].update({ title: { text: 'Cost ($)' } });
            firstChartGraph.xAxis[0].update({ lineWidth: 1 });
            firstChartGraph.setTitle({ text: 'Billing trend of ' + drillName + ' for ' + drillTitle });
            firstChartGraph.redraw();
          },
          drillup: function (e) {
            localStorage.setItem('chartAreaMonDrillVal', '')
            firstChartGraph.xAxis[0].update({ title: { text: '' } });
            firstChartGraph.yAxis[0].update({ title: { text: '' } });
            firstChartGraph.xAxis[0].update({ lineWidth: 0 });
            firstChartGraph.setTitle({ text: mainTitle })
          }
        },
      },
      title: {
        text: mainTitle,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      tooltip: {
        pointFormatter: function (this) {
          if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 0) {
            return 'Dealer Name: <b>' + this.extraInfo[0] + '</b> <br> Area: <b>' + this.extraInfo[1] + '</b> <br> District: <b>' + this.extraInfo[2] + '</b> <br> Amount: <b> $' + this.y + '</b>';
          } else {
            return 'Amount: <b> $' + this.y + '</b>';
          }
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<b>' + this.point.name + '</b>: $ ' + this.y;
            }
          }
        },
        column: {
          // allowPointSelect: true,
          // cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '$ ' + this.y;
            },
            style: {
              fontSize: '8px',
              fontWeight: 'bold'
            },
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Cost ($)'
        },
        labels: {
          formatter: function () {
            if (this.value > 999) {
              return Highcharts.numberFormat(this.value / 1000, 1) + "K"
            } else if (this.value <= 999) {
              return this.value
            }
          }
        }
      },
      series: [],
      drilldown: {
        animation: false,
        series: [],
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
            states: {
              hover: {
                fill: '#a4edba'
              },
              select: {
                stroke: '#039',
                fill: '#a4edba'
              }
            }
          }
        }

      }
    })
  }
  secondChart() {
    this.secondChartLoading = true; this.noSecondData = false;
    this.secondDealers = [];
    this.secondDealerCosts = [];
    this.secondTotalAmnt = 0;
    let body = {
      "event": this.secondChartMonthFilter
    }
    this.billingDashboardService.getDealerTrendChart(body).subscribe((response: any) => {
      this.secondChartData = response.AreaDealersTrend.Result;
      this.secondAmounts2 = []; this.secondAmounts2yt = [];
      if (this.secondChartData != undefined && this.secondChartData != null) {
        if (this.secondChartData.length != 0) {
          this.noSecondData = false;
          let secondDlrName = []; let secondDlrDist = []; let secondDlrArea = []; let secondDlrAreaName = [];
          let dummy = [];
          for (let a = 0; a < this.secondChartData.length; a++) {
            this.secondDealers.push(this.secondChartData[a].dealer);
            this.secondDealerCosts.push(this.secondChartData[a].amount);
            secondDlrName.push(this.secondChartData[a].dealerName);
            secondDlrArea.push(this.secondChartData[a].dealerArea);
            secondDlrDist.push(this.secondChartData[a].dealerDistrict);
            secondDlrAreaName.push(this.secondChartData[a].dealerAreaName);
            this.secondTotalAmnt += this.secondChartData[a].amount
          }
          this.secondAmounts = { y: this.secondDealerCosts, xtraInfo: [secondDlrName, secondDlrDist, secondDlrArea, secondDlrAreaName] };
          for (let a = 0; a < this.secondDealers.length; a++) {
            dummy.push({ y: this.secondDealerCosts[a], xtraInfo: [secondDlrName[a], secondDlrDist[a], secondDlrArea[a], secondDlrAreaName[a]] })
          }
          this.secondAmounts2 = { name: 'Amounts', data: dummy, type: 'column' };
          this.secondAmounts2yt = { name: 'Amounts', data: dummy, type: 'column', color: '#f7a35c' };
          this.secondChartLoading = false;
          this.secondChartGr();
        } else {
          this.secondAmounts2 = []; this.secondAmounts2yt = [];
          this.noSecondData = true;
          this.secondChartLoading = false;
          this.secondChartGr();
        }
      } else {
        this.secondAmounts2 = []; this.secondAmounts2yt = [];
        this.noSecondData = true;
        this.secondChartLoading = false;
        this.secondChartGr();
      }
    }, err => {
      this.secondAmounts2 = []; this.secondAmounts2yt = [];
      this.noSecondData = true;
      this.secondChartLoading = false;
      this.secondChartGr();
    })

  }
  secondChartGr() {
    // const yt = this.yearType;
    const secondAmounts2 = this.secondAmounts2; const secondAmounts2yt = this.secondAmounts2yt;
    this.secondChartGraph = new Highcharts.Chart({
      chart: {
        type: 'column',
        renderTo: 'secondChartGr',
        inverted: true,
        height: 575,
        events: {
          load: function () {
            this.addSeries(secondAmounts2)
          }
        }
      },
      title: {
        text: 'Billing trend across Dealers',
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: 'For '+ this.secondChartMonthFilter,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        categories: this.secondDealers,
        title: {
          text: 'Dealers'
        },
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Cost ($)'
        },
        labels: {
          formatter: function () {
            if (this.value > 999) {
              return Highcharts.numberFormat(this.value / 1000, 1) + "K"
            } else if (this.value <= 999) {
              return this.value
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormatter: function () {
          return 'Dealer Name: <b>' + this.xtraInfo[0] + '</b> <br> District: <b>' + this.xtraInfo[1] + '</b> <br> Amount: <b> $' + this.y + '</b>';
        }
      },
      plotOptions: {
        // column: {
        //   dataLabels: {
        //     enabled: true,
        //     align: 'right',
        //     inside: false,
        //     formatter: function () {
        //       return '$ ' + this.y;
        //     },
        //     style: {
        //       fontSize: '8px',
        //       fontWeight: 'bold'
        //     },
        //     overflow: 'none'
        //   }
        // }
      },
      series: []
    });

  }
  thirdChart() {
    this.thirdChartLoading = true; this.noThirdData = false;
    this.thirdDealers = [];
    this.thirdDealerCosts = [];
    this.thirdTotalAmnt = 0;
    let body = {
      "event": this.thirdChartMonthFilter
    }
    this.billingDashboardService.getJobRoleTrendChart(body).subscribe((response: any) => {
      this.thirdChartData = response.JobRoleTrend.Result;
      this.thirdAmounts2 = []; this.thirdAmounts2yt = [];
      // console.log('4th res', this.thirdChartData);
      // this.thirdChartData = null; //null check
      if (this.thirdChartData != undefined && this.thirdChartData != null) {
        if (this.thirdChartData.length != 0) {
          this.noThirdData = false;
          let thirdTotalCnt = []; let thirdDlrDist = []; let thirdDlrArea = []; let thirdDlrAreaName = [];
          let dummy = [];
          for (let a = 0; a < this.thirdChartData.length; a++) {
            this.thirdDealers.push(this.thirdChartData[a].jobRole);
            this.thirdDealerCosts.push(this.thirdChartData[a].amount);
            thirdTotalCnt.push(this.thirdChartData[a].totalCnt);
            // thirdDlrArea.push(this.thirdChartData[a].dealerArea);
            // thirdDlrDist.push(this.thirdChartData[a].dealerDistrict);
            // thirdDlrAreaName.push(this.thirdChartData[a].dealerAreaName);
            this.thirdTotalAmnt += this.thirdChartData[a].amount
          }
          this.thirdAmounts = { y: this.thirdDealerCosts, xtraInfo: [thirdTotalCnt, thirdDlrDist, thirdDlrArea, thirdDlrAreaName] };
              

          for (let a = 0; a < this.thirdDealers.length; a++) {
            dummy.push({ y: this.thirdDealerCosts[a], xtraInfo: [thirdTotalCnt[a], thirdDlrDist[a], thirdDlrArea[a], thirdDlrAreaName[a]] })
                  }
          this.thirdAmounts2 = { name: 'Amounts', data: dummy, type: 'column' };
          this.thirdAmounts2yt = { name: 'Amounts', data: dummy, type: 'column', color: '#f7a35c' };
                    this.thirdChartLoading = false;
          this.thirdChartGr();
            
        } else {
          this.thirdAmounts2 = []; this.thirdAmounts2yt = [];
          this.noThirdData = true;
          this.thirdChartLoading = false;
          this.thirdChartGr();
        }
      } else {
        this.thirdAmounts2 = []; this.thirdAmounts2yt = [];
        this.noThirdData = true;
        this.thirdChartLoading = false;
        this.thirdChartGr();
      }
    }, err => {
      this.thirdAmounts2 = []; this.thirdAmounts2yt = [];
      console.log('4th error', err);
      this.noThirdData = true;
      this.thirdChartLoading = false;
      this.thirdChartGr();
    })

  }
  thirdChartGr() {
    // const yt = this.yearType;
    const thirdAmounts2 = this.thirdAmounts2; const thirdAmounts2yt = this.thirdAmounts2yt;
    this.thirdChartGraph = new Highcharts.Chart({
      chart: {
        type: 'column',
        renderTo: 'thirdChartGr',
        height: 575,
        events: {
          load: function () {
            
            this.addSeries(thirdAmounts2)
          }
        }
      },
      title: {
        text: 'Billing trend across Job Roles',
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: 'For '+this.thirdChartMonthFilter,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        categories: this.thirdDealers,
        title: {
          text: 'Job Roles'
        },
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Cost ($)'
        },
        labels: {
          formatter: function () {
            if (this.value > 999) {
              return Highcharts.numberFormat(this.value / 1000, 1) + "K"
            } else if (this.value <= 999) {
              return this.value
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormatter: function () {
          return 'Amount: <b> $' + this.y + '</b> <br>  Total Count: <b>' + this.xtraInfo[0]+'</b>';
        }
      },
      plotOptions: {
        // column: {
        //   dataLabels: {
        //     enabled: true,
        //     align: 'right',
        //     inside: false,
        //     formatter: function () {
        //       return '$ ' + this.y;
        //     },
        //     style: {
        //       fontSize: '8px',
        //       fontWeight: 'bold'
        //     },
        //     overflow: 'none'
        //   }
        // }
              },
      series: []
    });
  }
  fourthChart() {
    this.fourthChartLoading = true; this.noFourthData = false;
    this.fourthTotalCount = 0;
    let body = {
      "event": this.fourthChartMonthFilter
    }
    this.billingDashboardService.getDealerAttendance(body).subscribe((response: any) => {
      this.fourthChartData = response.AttendanceDealers.Result;
      if (this.fourthChartData != undefined && this.fourthChartData != null) {
        if (this.fourthChartData.length != 0) {
          for (let i = 0; i < this.fourthChartData.length; i++) {
            this.fourthTotalCount = this.fourthChartData[i].totalCount;
            this.fourthPresent = ['Completed Count', 'No Show Count'];
            this.fourthPercents = [this.fourthChartData[i].completedCount, this.fourthChartData[i].noShowCount];
            let fourthDrill = [[], []]; this.fourthNewDrill = []; this.fourthNewDrill2 = [];
            let counts = []; let dlrs = []; let dlrsName = []; let dlrsAreaName = []; let dlrAreaDist = [];
            this.billingDashboardService.getDealerAttendanceCnt(body).subscribe((response: any) => {
              let fourthCountRes = response.DealerAttendanceCount.Result;
              if (fourthCountRes != undefined && fourthCountRes != null) {
                if (fourthCountRes.length != 0) {
                  this.noFourthData = false;
                  for (let a = 0; a < fourthCountRes.length; a++) {
                    if (fourthCountRes[a].status == 'Completed') {
                      fourthDrill[0].push(fourthCountRes[a]);
                    } else if (fourthCountRes[a].status == 'No Show') {
                      fourthDrill[1].push(fourthCountRes[a]);
                    }
                  }
                  for (let i = 0; i < fourthDrill.length; i++) {
                    for (let j = 0; j < fourthDrill[i].length; j++) {
                      counts.push(fourthDrill[i][j].count);
                      dlrs.push(fourthDrill[i][j].dealer);
                      dlrsName.push(fourthDrill[i][j].dealerName);
                      dlrsAreaName.push(fourthDrill[i][j].dealerAreaName);
                      dlrAreaDist.push(fourthDrill[i][j].dealerAreaDistrict);
                    }
                    let dum = []; let dumdum = [];
                    for (let e = 0; e < counts.length; e++) {
                      dumdum.push([dlrs[e], counts[e]]);
                      dum.push({ y: counts[e], extraInfo: [dlrsName[e], dlrsAreaName[e], dlrAreaDist[e]] })
                    }
                    this.fourthNewDrill.push({ name: this.fourthPresent[i], id: this.fourthPresent[i], data: dumdum, type: 'column' })
                    this.fourthNewDrill2.push({ name: this.fourthPresent[i], id: this.fourthPresent[i], data: dum, type: 'column' })
                    counts = []; dlrs = []; dlrsName = []; dlrsAreaName = []; dlrAreaDist = [];
                    if (this.fourthPresent.length == this.fourthNewDrill.length) {
                      this.fourthChartLoading = false;
                      this.fourthChartGr(this.fourthNewDrill);
                    }
                  }
                } else {
                  this.noFourthData = true;
                  this.fourthChartLoading = false;
                  this.fourthChartGr('');
                }
              } else {
                this.noFourthData = true;
                this.fourthChartLoading = false;
                this.fourthChartGr('');
              }
            }, err => {
              this.noFourthData = true;
              this.fourthChartLoading = false;
              this.fourthChartGr('');
            })
          }
        } else {
          this.noFourthData = true;
          this.fourthChartLoading = false;
          this.fourthChartGr('');
        }
      } else {
        this.noFourthData = true;
        this.fourthChartLoading = false;
        this.fourthChartGr('');
      }
    }, err => {
      this.noFourthData = true;
      this.fourthChartLoading = false;
      this.fourthChartGr('');
    })
    this.fourthChartLoading = true;
  }
  fourthChartGr(smth) {
    const fourthPresent = this.fourthPresent; const fourthPercents = this.fourthPercents;
    var UNDF;
    let month = '';

    let text = this.fourthChartMonthFilter
    let maintitle = 'Attendance count across Dealers for ' + text;
    var fourthChartGraph = new Highcharts.Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        renderTo: 'fourthChartGr',
        events: {
          load: function () {
            this.addSeries(
              {
                name: 'Dealers Attendance',
                data: [{ name: fourthPresent[0], y: fourthPercents[0], sliced: true, selected: true, drilldown: fourthPresent[0] },
                { name: fourthPresent[1], y: fourthPercents[1], drilldown: fourthPresent[1] }]
              })
          },
          drilldown: function (e) {
            let newCat = [];
            for (let a = 0; a < smth.length; a++) {
              if (e.seriesOptions.name == smth[a].name) {
                for (let j = 0; j < smth[a].data.length; j++) {
                  newCat.push(smth[a].data[j][0])
                }
              }
            }
            fourthChartGraph.xAxis[0].setCategories(newCat);
            fourthChartGraph.xAxis[0].update({ title: { text: 'Dealers' } });
            fourthChartGraph.yAxis[0].update({ title: { text: 'No. of People' } });
            fourthChartGraph.xAxis[0].update({ lineWidth: 1 });
            fourthChartGraph.setTitle({ text: e.seriesOptions.name + ' by Dealer for ' + text });
          },
          drillup: function () {
            fourthChartGraph.xAxis[0].update({ title: { text: '' } });
            fourthChartGraph.yAxis[0].update({ title: { text: '' } });
            fourthChartGraph.xAxis[0].update({ lineWidth: 0 });
            fourthChartGraph.setTitle({ text: maintitle });
          }
        }
      },
      title: {
        text: maintitle,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: month,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      tooltip: {
        pointFormatter: function () {
          if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 0) {
            return 'Dealer Name: <b>' + this.extraInfo[0] + '</b> <br> Area: <b>' + this.extraInfo[1] + '</b> <br> District: <b>' + this.extraInfo[2] + '</b> <br> Count: <b>' + this.y + '</b>';
          } else {
            return '<b>' + this.y + '</b>';
          }
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<b>' + this.point.name + '</b>: ' + this.y;
            }
          }
        },
        column: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold'
            },
            formatter: function () {
              return this.y;
            }
          }
        }
      },
      legend: { enabled: true },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: 'Dealers'
        },
        type: 'category',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'No of People'
        },
        allowDecimals: false
      },
      series: [],
      drilldown: {
        animation: false,
        series: [this.fourthNewDrill2[0], this.fourthNewDrill2[1]],
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
            states: {
              hover: {
                fill: '#a4edba'
              },
              select: {
                stroke: '#039',
                fill: '#a4edba'
              }
            }
          }
        }
      }
    });

  }
  fifthChart() {
    this.fifthChartLoading = true; this.noFifthData = false;
    this.fifthTotalCount = 0;
    let body = {
      "event": this.fifthChartMonthFilter
    }
    this.billingDashboardService.getJobRoleAttendance(body).subscribe((response: any) => {
      this.fifthChartData = response.AttendanceJobRole.Result;
      if (this.fifthChartData != undefined && this.fifthChartData != null) {
        if (this.fifthChartData.length != 0) {
          for (let i = 0; i < this.fifthChartData.length; i++) {
            this.fifthTotalCount = this.fifthChartData[i].totalCount;
            this.fifthPresent = ['Completed Count', 'No Show Count'];
            this.fifthPercents = [this.fifthChartData[i].completedCount, this.fifthChartData[i].noShowCount];
            let fifthDrill = [[], []]; this.fifthNewDrill = []; this.fifthNewDrill2 = [];
            let counts = []; let dlrs = []; let dlrsName = []; let dlrsAreaName = []; let dlrAreaDist = [];
            this.billingDashboardService.getJobRoleAttendanceCnt(body).subscribe((response: any) => {
              let fifthCountRes = response.JobRoleAttendanceCount.Result;
              if (fifthCountRes != undefined && fifthCountRes != null) {
                if (fifthCountRes.length != 0) {
                  this.noFifthData = false;
                  for (let a = 0; a < fifthCountRes.length; a++) {
                    if (fifthCountRes[a].status == 'Completed') {
                      fifthDrill[0].push(fifthCountRes[a]);
                    } else if (fifthCountRes[a].status == 'No Show') {
                      fifthDrill[1].push(fifthCountRes[a]);
                    }
                  }
                  for (let i = 0; i < fifthDrill.length; i++) {
                    for (let j = 0; j < fifthDrill[i].length; j++) {
                      counts.push(fifthDrill[i][j].count);
                      dlrs.push(fifthDrill[i][j].jobRole);
                    }
                    let dum = []; let dumdum = [];
                    for (let e = 0; e < counts.length; e++) {
                      dumdum.push([dlrs[e], counts[e]]);
                      dum.push({ y: counts[e], extraInfo: dlrs[e] })
                    }
                    this.fifthNewDrill.push({ name: this.fifthPresent[i], id: this.fifthPresent[i], data: dumdum, type: 'column' })
                    this.fifthNewDrill2.push({ name: this.fifthPresent[i], id: this.fifthPresent[i], data: dum, type: 'column' })
                    counts = []; dlrs = []; dlrsName = []; dlrsAreaName = []; dlrAreaDist = [];
                    if (this.fifthPresent.length == this.fifthNewDrill.length) {
                      this.fifthChartLoading = false;
                      this.fifthChartGr(this.fifthNewDrill);
                    }
                  }
                } else {
                  this.noFifthData = true;
                  this.fifthChartLoading = false;
                  this.fifthChartGr('');
                }
              } else {
                this.noFifthData = true;
                this.fifthChartLoading = false;
                this.fifthChartGr('');
              }
            }, err => {
              this.noFifthData = true;
              this.fifthChartLoading = false;
              this.fifthChartGr('');
            })
          }
        } else {
          this.noFifthData = true;
          this.fifthChartLoading = false;
          this.fifthChartGr('');
        }
      } else {
        this.noFifthData = true;
        this.fifthChartLoading = false;
        this.fifthChartGr('');
      }
    }, err => {
      this.noFifthData = true;
      this.fifthChartLoading = false;
      this.fifthChartGr('');
    })
    this.fifthChartLoading = true;
  }
  fifthChartGr(smth) {
    const fifthPresent = this.fifthPresent; const fifthPercents = this.fifthPercents;
    var UNDF;
    let month = '';

    let maintitle = 'Attendance count across Job Roles for ' + this.fifthChartMonthFilter;
    var fifthChartGraph = new Highcharts.Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        renderTo: 'fifthChartGr',
        events: {
          load: function () {
            this.addSeries(
              {
                name: 'Job Roles Attendance',
                data: [{ name: fifthPresent[0], y: fifthPercents[0], sliced: true, selected: true, drilldown: fifthPresent[0] },
                { name: fifthPresent[1], y: fifthPercents[1], drilldown: fifthPresent[1] }]
              })
          },
          drilldown: function (e) {
            let newCat = [];
            for (let a = 0; a < smth.length; a++) {
              if (e.seriesOptions.name == smth[a].name) {
                for (let j = 0; j < smth[a].data.length; j++) {
                  newCat.push(smth[a].data[j][0])
                }
              }
            }
            fifthChartGraph.xAxis[0].setCategories(newCat);
            fifthChartGraph.xAxis[0].update({ title: { text: 'Job Roles' } });
            fifthChartGraph.yAxis[0].update({ title: { text: 'No. of People' } });
            fifthChartGraph.xAxis[0].update({ lineWidth: 1 });
            fifthChartGraph.setTitle({ text: e.seriesOptions.name + ' by Job Role for ' + this.fifthChartMonthFilter });
          },
          drillup: function () {
            fifthChartGraph.xAxis[0].update({ title: { text: '' } });
            fifthChartGraph.yAxis[0].update({ title: { text: '' } });
            fifthChartGraph.xAxis[0].update({ lineWidth: 0 });
            fifthChartGraph.setTitle({ text: maintitle });
          }
        }
      },
      title: {
        text: maintitle,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: month,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      tooltip: {
        pointFormatter: function () {
          if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 0) {
            return 'Job Role: <b>' + this.extraInfo + '</b> <br> Count: <b>' + this.y + '</b>';
          } else {
            return '<b>' + this.y + '</b>';
          }
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<b>' + this.point.name + '</b>: ' + this.y;
            }
          }
        },
        column: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold'
            },
            formatter: function () {
              return this.y;
            }
          }
        }
      },
      legend: { enabled: true },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: 'Dealers'
        },
        type: 'category',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'No of People'
        },
        allowDecimals: false
      },
      series: [],
      drilldown: {
        animation: false,
        series: [this.fifthNewDrill2[0], this.fifthNewDrill2[1]],
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
            states: {
              hover: {
                fill: '#a4edba'
              },
              select: {
                stroke: '#039',
                fill: '#a4edba'
              }
            }
          }
        }
      }
    });
  }
  sixthChart() {
    this.sixthChartLoading = true; this.noSixthData = false;
    this.sixthDealers = [];
    this.sixthDealerCosts = [];
    this.sixthTotalAmnt = 0;
    let body = {
      "event": this.sixthChartMonthFilter,
      "jobRole": this.sixthChartJobRoleFilter
    }
    this.billingDashboardService.getJobRoleTrendDealersChart(body).subscribe((response: any) => {
      this.sixthChartData = response.DealersTrendPerJobRole.Result;
      this.sixthAmounts2 = []; this.sixthAmounts2yt = [];
      if (this.sixthChartData != undefined && this.sixthChartData != null) {
        if (this.sixthChartData.length != 0) {
          this.noSixthData = false;
          let sixthDlrName = []; let sixthDlrDist = []; let sixthDlrArea = []; let sixthDlrAreaName = [];
          let dummy = [];
          for (let a = 0; a < this.sixthChartData.length; a++) {
            this.sixthDealers.push(this.sixthChartData[a].dealer);
            this.sixthDealerCosts.push(this.sixthChartData[a].amount);
            sixthDlrName.push(this.sixthChartData[a].dealerName);
            sixthDlrDist.push(this.sixthChartData[a].dealerDistrict);
            sixthDlrAreaName.push(this.sixthChartData[a].dealerAreaName);
            this.sixthTotalAmnt += this.sixthChartData[a].amount
          }
          this.sixthAmounts = { y: this.sixthDealerCosts, xtraInfo: [sixthDlrName, sixthDlrDist, sixthDlrArea, sixthDlrAreaName] };
          for (let a = 0; a < this.sixthDealers.length; a++) {
            dummy.push({ y: this.sixthDealerCosts[a], xtraInfo: [sixthDlrName[a], sixthDlrDist[a], sixthDlrAreaName[a]] })
          }
          this.sixthAmounts2 = { name: 'Amounts', data: dummy, type: 'column' };
          this.sixthAmounts2yt = { name: 'Amounts', data: dummy, type: 'column', color: '#f7a35c' };
          this.sixthChartLoading = false;
          this.sixthChartGr();
        } else {
          this.sixthAmounts2 = []; this.sixthAmounts2yt = [];
          this.noSixthData = true;
          this.sixthChartLoading = false;
          this.sixthChartGr();
        }
      } else {
        this.sixthAmounts2 = []; this.sixthAmounts2yt = [];
        this.noSixthData = true;
        this.sixthChartLoading = false;
        this.sixthChartGr();
      }
    }, err => {
      this.sixthAmounts2 = []; this.sixthAmounts2yt = [];
      this.noSixthData = true;
      this.sixthChartLoading = false;
      this.sixthChartGr();
    })
  }
  sixthChartGr() {
    // const yt = this.yearType;
    const sixthAmounts2 = this.sixthAmounts2; const sixthAmounts2yt = this.sixthAmounts2yt;
    this.sixthChartGraph = new Highcharts.Chart({
      chart: {
        type: 'column',
        renderTo: 'sixthChartGr',
        // inverted: true,
        height: 575,
        events: {
          load: function () {
            this.addSeries(sixthAmounts2)
          }
        }
      },
      title: {
        text: 'Billing trend across Dealers for '+ this.sixthChartJobRoleFilter,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: 'For '+this.sixthChartMonthFilter,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        categories: this.sixthDealers,
        title: {
          text: 'Dealers'
        },
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Cost ($)'
        },
        labels: {
          formatter: function () {
            if (this.value > 999) {
              return Highcharts.numberFormat(this.value / 1000, 1) + "K"
            } else if (this.value <= 999) {
              return this.value
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormatter: function () {
          return 'Dealer Name: <b>' + this.xtraInfo[0] + '</b> <br> Area Name: <b>'+ this.xtraInfo[2] +'</b> <br> District: <b>' + this.xtraInfo[1] + '</b> <br> Amount: <b> $' + this.y + '</b>';
        }
      },
      plotOptions: {
        // column: {
        //   dataLabels: {
        //     enabled: true,
        //     align: 'right',
        //     inside: false,
        //     formatter: function () {
        //       return '$ ' + this.y;
        //     },
        //     style: {
        //       fontSize: '8px',
        //       fontWeight: 'bold'
        //     },
        //     overflow: 'none'
        //   }
        // }
      },
      series: []
    });
  }
  exportModelClicked(chartName) {
    this.exportChartName = chartName;
    $("#excelReportModal").show("slow");
    $("#excelReportModal").removeClass('hide_block');
    $("#excelReportModal").addClass('display_block');
  }
  async exportFile() {
    this.closeErrorModal();
    let exportChartData = [];
    if (this.exportChartName == 'thirdChart') {
      this.thirdChartData.forEach(element => {
        let obj = {
          "JOB ROLE": element.jobRole,
          "AMOUNT": element.amount
        }
        exportChartData.push(obj);
      })
      this.excelService.exportAsExcelFile(exportChartData, 'JobRole_2024' )
    }
    else if (this.exportChartName == 'secondChart') {
      this.secondChartData.forEach(element => {
        let obj = {
          "DEALER CODE": element.dealer,
          "DEALER NAME": element.dealerName,
          "DEALER AREA": element.dealerAreaName,
          "DEALER DISTRICT": element.dealerDistrict,
          "AMOUNT": element.amount
        }
        exportChartData.push(obj);
      })
      this.excelService.exportAsExcelFile(exportChartData, 'Dealer_2024' )
    }
    else if (this.exportChartName == 'areaTrendChart') {
      this.areaDealerTrendMonArr.forEach(element => {
        if (localStorage.getItem('chartAreaMonDrillVal') != '' && localStorage.getItem('chartAreaMonDrillVal') != undefined && localStorage.getItem('chartAreaMonDrillVal') != null) {
          if (element.area != 'OTHER' && element.area == localStorage.getItem('chartAreaMonDrillVal')) {
            let obj = {
              "DEALER CODE": element.dealer,
              "DEALER NAME": element.dealerName,
              "DEALER AREA": element.area,
              "DEALER DISTRICT": element.dealerDistrict,
              "AMOUNT": element.amount,
            }
            exportChartData.push(obj);
          }
        }
        else {
          if (element.area != 'OTHER') {
            let obj = {
              "DEALER CODE": element.dealer_code,
              "DEALER NAME": element.dealerName,
              "DEALER AREA": element.area,
              "DEALER DISTRICT": element.dealerDistrict,
              "AMOUNT": element.amount,
            }
            exportChartData.push(obj);
          }
        }
      })
      this.excelService.exportAsExcelFile(exportChartData, 'Area_Billing_Trend_Dealers')
    }
    else if (this.exportChartName == 'sixthChart') {
      this.areaDealerTrendMonArr.forEach(element => {
            let obj = {
              "JOB ROLE": element.jobRole,
              "DEALER CODE": element.dealer,
              "DEALER NAME": element.dealerName,
              "DEALER AREA": element.dealerAreaName,
              "DEALER DISTRICT": element.dealerDistrict,
              "AMOUNT": element.amount,
            }
            exportChartData.push(obj);
      })
      this.excelService.exportAsExcelFile(exportChartData, 'Job_Role_Trend_Across_Dealers')
    }
  }
  closeErrorModal() {
      $("#excelReportModal").removeClass('display_block');
      $("#excelReportModal").addClass('hide_block');
      $("#excelReportModal").show("slow");
  }
  search(){
    this.tableLoading = true;
    this.showSpinner = true;
    this.exportData = [];
    this.reportArray = [];
    this.billingDashboardService.getReportSearchDetails(this.searchForm.value).subscribe((response:any)=>{
      this.reportArray = response.Reports.Result;
      this.exportData = this.reportArray;
      this.tableLoading = false;
      this.showSpinner = false;
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      this.expandState = false;
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    })
  }
  reset(){
    this.searchForm.reset({
      spinId: '',
      areaOrDistrict: '',
      jobRole: '',
      dealerNumber: '',
      attendance: '',
      year: '',
      jobRoleSearch: 'No'
    });
    this.reportArray = [];
    this.exportData = [];
    this.tableLoading = true;
    this.showSpinner = false;
    this.reportDataSource.sort = this.sort;
    this.reportDataSource = new MatTableDataSource(this.reportArray);
    this.reportDataSource.paginator = this.ReportPaginator;
    this.expandState = true;
  }
  applyFilter(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.reportDataSource.filter = filterValue.trim().toLowerCase();
    this.reportDataSource.filteredData.forEach(element => {
      this.exportData.push(element);
    })
    if (filterValue != '' || this.reportDataSource.filteredData.length < 10) {
      $("#holdHistorTable").removeClass('tableOverflow');
    } else {
      $("#holdHistorTable").addClass('tableOverflow');
    }
  }
  download() {
    let reportExportData = [];
    this.exportData.forEach(data => {
      let obj = {
        "EVENT": data.event,
        "NAME": data.name,
        "SPIN ID": data.spinId,
        "JOB ROLE": data.jobRole,
        "DEALER CODE": data.dealer,
        "DEALER NAME": data.dealerName,
        "DEALER AREA": data.dealerArea,
        "DEALER DISTRICT": data.dealerDistrict,
        "DEALER ADDRESS": data.dealerAddr1,
        "DEALER CITY": data.dealerCity,
        "DEALER STATE": data.dealerState,
        "STATUS": data.status,
        "AMOUNT": data.amount
      }
      reportExportData.push(obj);
    });
    this.excelService.exportAsExcelFile(reportExportData, 'Elevate_Billing_Reports');
  }
  chartMonthChanges(chartType) {
    if (chartType == 'second') {
      this.secondChart();
    }
    if (chartType == 'first') {
      this.firstChart();
    }
    if (chartType == 'third') {
      this.thirdChart();
    }
    if (chartType == 'sixth') {
      this.sixthChart();
    }
    if (chartType == 'sixthJobRole') {
      this.sixthChart();
    }
  }
}
