import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http, Response, Headers, RequestOptions,URLSearchParams } from '@angular/http';
import {map, tap} from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from '../service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin' : '*',
  'authToken': localStorage.getItem('accessToken')
};
let  myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class AdminService {
  public triggerBatchUrl = "ManualPaymentJobExec";
  public PPjobLoggerURL = 'Job_Logger';
  public OtherJobLoggerURL = 'OtherBatchJobLogger';
  public getFileUrl = '';

  
  constructor(private _http: Http, private appService: CommonService) { }

  triggerBatch(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    var todayDate = new Date();
    let body = {
      "UserDate": todayDate.toISOString().substring(0,10)
    }
    const url = this.appService.baseUrl + this.triggerBatchUrl + this.appService.functionKey;
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //  =========================================GET THE LOGGER DETAILS========================================
  getJobLoggerDetails(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.PPjobLoggerURL + this.appService.functionKey;
    return this._http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //  =========================================GET OTH JOB LOGGER DETAILS====================================
  getAllJobLoggerDetails(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.OtherJobLoggerURL + this.appService.functionKey;
    return this._http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //  =========================================DOWNLOAD THE LOG DETAILS======================================
  downloadLogFiles(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    var todayDate = new Date();
    let body = {
      "UserDate": todayDate.toISOString().substring(0,10)
    }
    const url = this.appService.baseUrl + this.getFileUrl + this.appService.functionKey;
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //  =========================================EXCEPTION HANDLE==============================================
  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }
}
