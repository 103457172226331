import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatIconModule, MatExpansionModule } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CorpDashboardService } from './corp-dashboard.service';
import { ExcelService } from '../service/excel.service';
import { CorpHoldResumeService } from '../corp-hold-resume/corp-hold-resume.service';
import { PaymentHistoryService } from '../payment-history/payment-history.service';
import { PlatformLocation, Location } from '@angular/common';
import { CommonService } from '../service/common.service';
import { WatchlistService } from '../watchlist/watchlist.service';
import { watchlistComponent } from '../watchlist/watchlist.component';
import { defer } from 'rxjs';
import { element } from '@angular/core/src/render3/instructions';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DebitPaymentService } from '../debit-payment/debit-payment.service';
declare var $: any;

export interface CurrentPaymentData {
  dealerCode: string;
  name: string;
  spinId: string;
  paymentNo: string;
  paymentPeriod: string;
  runDate: string;
}

export interface CurrentDebitData {
  dealerCode: string;
  name: string;
  spinId: string;
  paymentNo: string;
  paymentPeriod: string;
  debitDate: string;
}

export interface PaymentData {
  payduecode: string;
  lfspaycode: string;
  paymentPeriod: string;
  paymentDate: string;
  invoice: string;
}

export interface DebitData {
  payduecode: string;
  lfspaycode: string;
  paymentPeriod: string;
  paymentDate: string;
  debitClicked: boolean;
  Comments: string;
  status: string;
}

export interface EnrollmentData {
  dealerCode: string;
  spinId: string;
  name: string;
  enrollmentType: string;
  enrolledDate: string;
}

@Component({
  selector: 'app-corp-dashboard',
  templateUrl: './corp-dashboard.component.html',
  styleUrls: ['./corp-dashboard.component.css']
})

export class CorpDashboardComponent implements OnInit {

  //Tab Variables
  selectedPayView: number;
  currentPayTab: boolean = true;
  pastPayTab: boolean = false;
  selectedDebitView: number;
  currentDebitTab: boolean = true;
  pastDebitTab: boolean = false;

  // ------------------------------------------Table Source------------------------------------------
  currentDataSource: MatTableDataSource<CurrentPaymentData>;
  pastDataSource: MatTableDataSource<CurrentPaymentData>;
  currentDebitDataSource: MatTableDataSource<CurrentDebitData>;
  pastDebitDataSource: MatTableDataSource<CurrentDebitData>;
  paymentDataSource: MatTableDataSource<any>;
  debitDataSource: MatTableDataSource<DebitData>;
  recentDataSource: MatTableDataSource<EnrollmentData>;
  allDataSource: MatTableDataSource<EnrollmentData>;
  paymentColumns: string[] = ['dealerCode', 'lfspaycode', 'paymentPeriod', 'paymentDate'];
  debitColumns = ['dealerCode', 'paymentPeriod', 'debitDate', 'reason','invoiceNumber','approvedDate'];
  currentPaymentColumns = ['name', 'spinId', 'dealerCode', 'paymentNo', 'paymentPeriod', 'runDate'];
  pastPaymentColumns = ['name', 'spinId', 'dealerCode', 'paymentNo', 'paymentPeriod'];
  currentDebitColumns = ['name', 'spinId', 'dealerCode', 'paymentNo', 'paymentPeriod', 'debitDate'];
  pastDebitColumns = ['name', 'spinId', 'dealerCode', 'paymentNo', 'paymentPeriod', 'debitDate'];
  recentColumns = ['name', 'spinId', 'dealerCode', 'enrolledDate', 'enrollmentType'];
  allColumns = ['name', 'spinId', 'dealerCode', 'enrolledDate', 'enrollmentType'];

  //------------------------------------- Paginator and Sort @ViewChild------------------------------

  @ViewChild('currentPaySort', { read: MatSort }) currentPaySort: MatSort;
  @ViewChild('pastPaySort', { read: MatSort }) pastPaySort: MatSort;
  @ViewChild('currentDebitSort', { read: MatSort }) currentDebitSort: MatSort;
  @ViewChild('pastDebitSort', { read: MatSort }) pastDebitSort: MatSort;
  @ViewChild('paymentHistorySort', { read: MatSort }) paymentHistorySort: MatSort;
  @ViewChild('debitHistorySort', { read: MatSort }) debitHistorySort: MatSort;
  @ViewChild('recentSort', { read: MatSort }) recentSort: MatSort;
  @ViewChild('allSort', { read: MatSort }) allSort: MatSort;
  @ViewChild('currentPayPaginator', { read: MatPaginator }) currentPayPaginator: MatPaginator;
  @ViewChild('pastPayPaginator', { read: MatPaginator }) pastPayPaginator: MatPaginator;
  @ViewChild('currentDebitPaginator', { read: MatPaginator }) currentDebitPaginator: MatPaginator;
  @ViewChild('pastDebitPaginator', { read: MatPaginator }) pastDebitPaginator: MatPaginator;
  @ViewChild('paymentHistoryPaginator', { read: MatPaginator }) paymentHistoryPaginator: MatPaginator;
  @ViewChild('debitHistoryPaginator', { read: MatPaginator }) debitHistoryPaginator: MatPaginator;
  @ViewChild('recentPaginator', { read: MatPaginator }) recentPaginator: MatPaginator;
  @ViewChild('allPaginator', { read: MatPaginator }) allPaginator: MatPaginator;

  //------------------------------------- Table Variables--------------------------------------------
  CURRENT_DATA: CurrentPaymentData[] = [];
  PAST_DATA: CurrentPaymentData[] = [];
  CURRENT_DEBIT_DATA: CurrentDebitData[] = [];
  PAST_DEBIT_DATA: CurrentDebitData[] = [];
  RECENT_DATA: EnrollmentData[] = [];
  ALL_DATA: EnrollmentData[] = [];

  currentPaymentTableLoading: boolean = false;
  pastPaymentTableLoading: boolean = false;
  currentDebitTableLoading: boolean = false;
  pastDebitTableLoading: boolean = false;
  recentTableLoading: boolean = false;
  allTableLoading: boolean = false;

  currentPaymentNoData: boolean = false;
  pastPaymentNoData: boolean = false;
  currentDebitNoData: boolean = false;
  pastDebitNoData: boolean = false;
  recentNoData: boolean = false;
  allNoData: boolean = false;

  pastTabClickCount: number = 0;
  pastDebitClickCount: number = 0;
  allTabClickCount: number = 0;
  timeStamp: any = '';
  tabName: string = '';
  currentPayCount: number = 0;
  previousPayCount: number = 0;
  currentDebitCount: number = 0;
  previousDebitCount: number = 0;
  recentCount: number = 0;
  allCount: number = 0;

  //Debit Variables
  debitPaymentArray = [];
  Comments: string = "";
  debitBtnDisabled: boolean = true;
  user: any = [];
  fullName: string = '';
  panelOpenState = false;
  debitTable: boolean = true;
  debitTableLoading: boolean = false;
  debitNoData: boolean = false;
  debitHistoryResponse: any = '';
  DEBIT_DATA: any = [];
  DEBITDATA: DebitData[] = [];
  debitTotalData: number = 0;

  //Search Variables
  debitStatus: boolean = false;
  loadSpinner: boolean = false;
  filteredUsers: any[] = [];
  PayHistoryForm: FormGroup;
  isLoading = false;
  spinId: any = "";
  spinIdClicked: Boolean = false;

  //Table Variables
  tableDisplay: boolean = false;
  paymentTableLoading: boolean = false;
  paymentNoData: boolean = false;
  paymentHistoryResponse: any = '';
  PAYMAINDATA: any = [];
  PAYMENTDATA: PaymentData[] = [];
  paymentTotalData: number = 0;
  //Info Variable
  dealername: string;
  spinnos: string;
  paymentDate: string;
  dealercode: string;
  nameAssocaite: string = '';

  countOfPaymentHistoryVisit: number = 0;
  noExcelData: boolean;

  //Enrollment Variable
  selectedEnrollView: number;
  recentTab: boolean = false;
  allTab: boolean = true;

  // Associate Detail View
  associateInfo: FormGroup;
  infoLoading: boolean = false;

  //watchlist vars
  watchAddStatus: boolean = false;
  watchRemoveStatus: boolean = false;
  isOnWatch: boolean;
  showDeets: boolean;
  watchComments: string;
  removeComments: string;
  addBtnDisabled: boolean = true;
  removeBtnDisabled: boolean = true;
  watchArray1 = [];
  watchArray2 = [];
  typeString: boolean;
  watchArrayReady: boolean = false;
  commErr: boolean = false;
  errMsg1: boolean = false; //remove
  errMsg2: boolean = false; //add
  errMsg3: boolean = false; //readd
  errMsg4: boolean = false; //additional

  allCommPopup: boolean = false;
  allCommAdd: boolean = false;
  AllComments: string = "";
  watchlistComments: any = [];
  commentsSplit: any = [];
  notes: string;
  allCommAddBtnDis: boolean = true;
  allCommSpin;
  addlCommAdded: boolean = false;
  addlLoading: boolean = false;
  addlPop: boolean = false;

  //past year cert data vars

  openPYCmodel: boolean = false;
  pastYearCertLoad: boolean = false;
  PYCrecords: any = [];
  pastYearCertDetails: any;
  marSpin: any;
  pastYearCertLoaded: boolean;
  PYCrecordsDex: any[];
  pastYearCertDetailsDex: any;

  constructor(private dashboardService: CorpDashboardService, private excelService: ExcelService,
    private fb: FormBuilder, private corpHoldService: CorpHoldResumeService,
    private payHistoryService: PaymentHistoryService, private location: PlatformLocation,
    private appService: CommonService, private _location: Location, private WatchlistService: WatchlistService,
    private watchlistComponent: watchlistComponent, private router: Router, private debitService: DebitPaymentService) {
    this.selectView(1);
    this.selectDebitView(1);
    this.selectEnrollView(1);

    //* this method will get the current month payment data
    this.getCurrentMonthData();

    //* this method will get the current month debits data
    this.getCurrentMonthDebitData();

    //* this method will get the recent enrollment data
    this.getRecentData();

    //* location onPopState function is to monitor the click of the browser back button
    location.onPopState(() => {
      var PageUrl = window.location.href;
      if (PageUrl.indexOf('/corpDashboard') != -1) {
        this.navigateBack();
      }
    });

    // *Associate Informatio Form Group 
    this.associateInfo = new FormGroup({
      spin_id: new FormControl(''),
      firstName: new FormControl(''),
      middleName: new FormControl(''),
      lastName: new FormControl(''),
      jobTitle: new FormControl(''),
      tenure: new FormControl(''),
      jobCode: new FormControl(''),
      employmentStatus: new FormControl(''),
      contact: new FormControl(''),
      email: new FormControl(''),
      certificationLevel: new FormControl(''),
      certifiedYear: new FormControl(''),
      certification_months: new FormControl(''),
      certStartDate: new FormControl(''),
      eligEligibility: new FormControl(''),
      eligTenureMonths: new FormControl(''),
      eligTotalCreds: new FormControl(''),
      eligLexusEssentials: new FormControl(''),
      dealerCode: new FormControl(''),
      dealerName: new FormControl(''),
      lfsNo: new FormControl(''),
      lfs_maturity_date: new FormControl(''),
      lfsActivationDate: new FormControl(''),
      lfs_extension_date: new FormControl(''),
      lfs_termination_date: new FormControl(''),
      veh_acquisition_date: new FormControl(''),
      customerType: new FormControl(''),
      vehicleBrand: new FormControl(''),
      vehicleModel: new FormControl(''),
      vin: new FormControl(''),
    });
  }

  ngOnInit() {
    console.log('corp dash',this.appService.role);
    if (this.appService.role != 'Lexus College' && this.appService.role != 'Lexus Associate' && this.appService.role != 'Area Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.PayHistoryForm = this.fb.group({
      userInput: null
    });
  }

  // -----------------------------------------Tab Navigation Method----------------------------------
  selectView(tabIndex) {
    // index = 1 is for selecting the current month payment tab
    if (tabIndex == 1) {
      this.selectedPayView = 1;
      this.currentPayTab = true;
      this.pastPayTab = false;
      // index = 2 is for selecting the past mont payment tab
    } else if (tabIndex == 2) {
      if (this.pastTabClickCount == 0) {
        this.getPastYrData();
        this.pastTabClickCount += 1;
      }
      this.selectedPayView = 2;
      this.currentPayTab = false;
      this.pastPayTab = true;
    }
  }

  selectDebitView(tabIndex) {
    // index = 1 is for selecting the current month debit tab
    if (tabIndex == 1) {
      this.selectedDebitView = 1;
      this.currentDebitTab = true;
      this.pastDebitTab = false;
      // index = 2 is for selecting the past mont debit tab
    } else if (tabIndex == 2) {
      if (this.pastDebitClickCount == 0) {
        this.getPastYrDebitData();
        this.pastDebitClickCount += 1;
      }
      this.selectedDebitView = 2;
      this.currentDebitTab = false;
      this.pastDebitTab = true;
    }
  }

  selectEnrollView(tabIndex) {
    // index = 1 is for selecting the recent enrollment tab
    if (tabIndex == 1) {
      this.selectedEnrollView = 1;
      this.recentTab = true;
      this.allTab = false;
      // index = 2 is for selecting the all enrollment tab
    } else if (tabIndex == 2) {
      if (this.allTabClickCount == 0) {
        this.getAllEnrollData();
        this.allTabClickCount += 1;
      }
      this.selectedEnrollView = 2;
      this.recentTab = false;
      this.allTab = true;
    }
  }

  // -----------------------------------------Table Filter Method----------------------------------
  pastapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.pastDataSource.filter = filterValue;
    this.pastDataSource.sort = this.pastPaySort;
    this.pastDataSource.paginator = this.pastPayPaginator;
  }

  currentapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.currentDataSource.filter = filterValue;
    this.currentDataSource.sort = this.currentPaySort;
    this.currentDataSource.paginator = this.currentPayPaginator;
  }

  pastdebitapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.pastDebitDataSource.filter = filterValue;
    this.pastDebitDataSource.sort = this.pastDebitSort;
    this.pastDebitDataSource.paginator = this.pastDebitPaginator;
  }

  currentdebitapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.currentDebitDataSource.filter = filterValue;
    this.currentDebitDataSource.sort = this.currentDebitSort;
    this.currentDebitDataSource.paginator = this.currentDebitPaginator;
  }

  allapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.allDataSource.filter = filterValue;
    this.allDataSource.sort = this.allSort;
    this.allDataSource.paginator = this.allPaginator;
  }

  recentapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.recentDataSource.filter = filterValue;
    this.recentDataSource.sort = this.recentSort;
    this.recentDataSource.paginator = this.recentPaginator;
  }

  paymentapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.paymentDataSource.filter = filterValue;
    this.paymentDataSource.sort = this.paymentHistorySort;
    this.paymentDataSource.paginator = this.paymentHistoryPaginator;
  }

  debitapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.debitDataSource.filter = filterValue;
    this.debitDataSource.sort = this.debitHistorySort;
    this.debitDataSource.paginator = this.debitHistoryPaginator;
  }

  // ======================================DEALER CODE SEARCH==========================================

  fadeDiv() { // make the table section is opacity to 0.5
    this.spinIdClicked = true;
  }

  fadeOut() { // make the table section is opacity to 1
    this.spinIdClicked = false;
  }

  closeSpinSearch() { // make the table section is opacity to 0.5 and close the search
    this.spinId = "";
    this.spinIdClicked = true;
  }

  // autopopulate the user details
  filterUser() {
    if (this.spinId != undefined) { this.spinId = this.spinId.replace(/[^a-zA-Z0-9 ]/g, ""); }
    if (this.spinId != undefined && this.spinId.length > 1) {
      this.filteredUsers = [];
      this.isLoading = true;
      this.corpHoldService.getEnrolledUserSearch(this.spinId, this.appService.selectedRegion).subscribe((result: any) => {
        this.filteredUsers = result.HoldPaymentSearch.Result;
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.filteredUsers = [];
        console.log('Error', err);
        console.log('Error while fetching the user list');
      });
    }
  }

  // get the payment history of the selected users
  selectUser(user) {
    console.log('Selected User', user);
    this.user = user;
    this.tableDisplay = true;
    this.nameAssocaite = user.firstName + ' ' + user.lastName;
    this.getPaymentHistoryDetail(user.spinId, user.dealerCode, user.dealerName);
    this.getDebitHistoryDetail(user.spinId);
    if (window.location.href.indexOf("localhost") != -1) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '#/corpDashboard/PaymentHistory';
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      // URL will change to Payment History
      this._location.go('corpDashboard/PaymentHistory');
    }
  }

  navigateBack() {
    if (window.location.href.indexOf("localhost") != -1) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '#/corpDashboard';
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      // URL will change to back to corpDashboard
      this._location.go('corpDashboard');
    }
    this.tableDisplay = false;
    this.spinId = '';
  }

  // -----------------------------------------Service Method----------------------------------

  getCurrentMonthData() { //Current Month Payment Data
    this.CURRENT_DATA = [];
    this.currentPaymentTableLoading = true;
    if (this.appService.baseUrl != '') {
      this.dashboardService.getCurrentMonthPayment(this.appService.selectedRegion).subscribe((response: any) => {
        [].push.apply(this.CURRENT_DATA, response.CurrentMonthPayment.Result);
        if (this.CURRENT_DATA.length != 0) {
          this.currentPayCount = this.CURRENT_DATA.length;
          this.timeStamp = response.CurrentMonthPayment.Result[0].lstUpdtDt;
          this.currentPaymentNoData = false;
          this.currentDataSource = new MatTableDataSource(this.CURRENT_DATA);
          this.currentDataSource.sort = this.currentPaySort;
          this.currentDataSource.paginator = this.currentPayPaginator;
        } else {
          this.currentPaymentNoData = true;
        }
        this.currentPaymentTableLoading = false;
      }, err => {
        console.log('Error', err);
        console.log('Error while fetching Current Month Payment Data');
        this.currentPaymentTableLoading = false;
        this.currentPaymentNoData = true;
        this.CURRENT_DATA = [];
      });
    }
  }

  getPastYrData() { // Past Month Payment Data
    this.PAST_DATA = [];
    this.pastPaymentTableLoading = true;
    this.dashboardService.getPastYearPayment(this.appService.selectedRegion).subscribe((response: any) => {
      [].push.apply(this.PAST_DATA, response.LastOneYearPayment.Result);
      if (this.PAST_DATA.length != 0) {
        this.previousPayCount = this.PAST_DATA.length
        this.pastPaymentNoData = false;
        this.pastDataSource = new MatTableDataSource(this.PAST_DATA);
        this.pastDataSource.sort = this.pastPaySort;
        this.pastDataSource.paginator = this.pastPayPaginator;
      } else {
        this.pastPaymentNoData = true;
      }
      this.pastPaymentTableLoading = false;
    }, err => {
      console.log('Error', err);
      console.log('Error while fetching past year payment data');
      this.pastPaymentTableLoading = false;
      this.pastPaymentNoData = true;
      this.PAST_DATA = [];
    });
  }

  getCurrentMonthDebitData() { //Current Month Debit Data
    this.CURRENT_DEBIT_DATA = [];
    this.currentDebitTableLoading = true;
    if (this.appService.baseUrl != '') {
      this.dashboardService.getCurrentMonthDebitPayment(this.appService.selectedRegion).subscribe((response: any) => {
        [].push.apply(this.CURRENT_DEBIT_DATA, response.CurrentMonthDebit.Result);
        if (this.CURRENT_DEBIT_DATA.length != 0) {
          this.currentDebitCount = this.CURRENT_DEBIT_DATA.length;
          // this.timeStamp = response.CurrentMonthDebit.Result[0].runDate;
          this.currentDebitNoData = false;
          this.currentDebitDataSource = new MatTableDataSource(this.CURRENT_DEBIT_DATA);
          this.currentDebitDataSource.sort = this.currentDebitSort;
          this.currentDebitDataSource.paginator = this.currentDebitPaginator;
        } else {
          this.currentDebitNoData = true;
        }
        this.currentDebitTableLoading = false;
      }, err => {
        console.log('Error', err);
        console.log('Error while fetching Current Month Debit Data');
        this.currentDebitTableLoading = false;
        this.currentDebitNoData = true;
        this.CURRENT_DEBIT_DATA = [];
      });
    }
  }

  getPastYrDebitData() { // Past Month Debit Data
    this.PAST_DEBIT_DATA = [];
    this.pastDebitTableLoading = true;
    this.dashboardService.getPastYearDebitPayment(this.appService.selectedRegion).subscribe((response: any) => {
      [].push.apply(this.PAST_DEBIT_DATA, response.LastOneYearDebits.Result);
      if (this.PAST_DEBIT_DATA.length != 0) {
        this.previousDebitCount = this.PAST_DEBIT_DATA.length
        this.pastDebitNoData = false;
        this.pastDebitDataSource = new MatTableDataSource(this.PAST_DEBIT_DATA);
        this.pastDebitDataSource.sort = this.pastDebitSort;
        this.pastDebitDataSource.paginator = this.pastDebitPaginator;
      } else {
        this.pastDebitNoData = true;
      }
      this.pastDebitTableLoading = false;
    }, err => {
      console.log('Error', err);
      console.log('Error while fetching past year debit data');
      this.pastDebitTableLoading = false;
      this.pastDebitNoData = true;
      this.PAST_DEBIT_DATA = [];
    });
  }

  getDebitHistoryDetail(spinId) { // Debit History table
    this.DEBITDATA = [];
    this.DEBIT_DATA = [];
    this.debitHistoryResponse = '';
    this.debitTableLoading = true;
    this.debitService.getDebitPayments(spinId).subscribe((response: any) => {
      this.debitHistoryResponse = response.DebitPayments.Result;
      this.DEBIT_DATA = [];
      [].push.apply(this.DEBIT_DATA, this.debitHistoryResponse);
      if (this.DEBIT_DATA.length != 0) {
        this.DEBITDATA = [];
        this.debitNoData = false;
        // this.spinnos = '104' + this.DEBIT_DATA[0].uid;
        let obj, month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        for (let i = 0; i < this.DEBIT_DATA.length; i++) {
          // let paydueDate = this.DEBIT_DATA[i].payDueCd;
          obj = {
            // 'payduecode': this.PAYMAINDATA[i].payDueCd ,
            'dealerCode': this.DEBIT_DATA[i].dealerCode,
            // 'lfspaycode': this.DEBIT_DATA[i].lfsPayCd,
            'paymentPeriod': this.DEBIT_DATA[i].paymentPeriod,
            'debitDate': new Date(this.DEBIT_DATA[i].debitDate.substring(0,10).replace(/-/g,'\/')).toLocaleDateString(),
            'reason': this.DEBIT_DATA[i].reasonId,
            'invoiceNumber': this.DEBIT_DATA[i].invoiceNumber,
            'approvedDate': new Date(this.DEBIT_DATA[i].approvedDate.substring(0,10).replace(/-/g,'\/')).toLocaleDateString()

          }
          this.DEBITDATA.push(obj);
        }
        this.debitTotalData = this.DEBITDATA.length;
      } else {
        this.debitNoData = true;
        this.DEBIT_DATA = [];
        this.debitTotalData = 0;
      }
      this.debitDataSource = new MatTableDataSource(this.DEBITDATA);
      this.debitDataSource.sort = this.debitHistorySort;
      this.debitDataSource.paginator = this.debitHistoryPaginator;
      this.debitDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'paymentDate': return new Date(item.paymentDate);
          default: return item[property];
        }
      };
      this.debitDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'debitDate': return new Date(item.paymentDate);
          default: return item[property];
        }
      };
      this.debitTableLoading = false;
    }, err => {
      console.log('Error', err);
      console.log('Error while fetching data from Debit history Table');
      this.debitTableLoading = false;
      this.debitNoData = true;
      this.debitTotalData = 0;
      this.DEBIT_DATA = [];
    });
  }

  getPaymentHistoryDetail(spinId, dlrcode, dlrname) { // Payment history details 
    this.paymentTableLoading = true;
    this.PAYMAINDATA = [];
    this.paymentHistoryResponse = '';
    this.spinnos = spinId;
    this.dealercode = dlrcode;
    this.dealername = dlrname;
    this.payHistoryService.getPayHistoryDataSpinId(spinId).subscribe((response: any) => {
      this.paymentHistoryResponse = response.PaymentHistory.Result;
      if (response.isOnWatch.Result1.length != 0) {
        var onWatch = response.isOnWatch.Result1[0].isOnWatch;
        if (typeof (onWatch) == 'string') {
          this.typeString = true;
          this.isOnWatch = false;
        } else { this.typeString = false; this.isOnWatch = onWatch; }

      }
      else if (response.isOnWatch.Result1.length == 0) {
        this.isOnWatch = false;
      }
      this.showDeets = true;
      this.PAYMAINDATA = [];
      [].push.apply(this.PAYMAINDATA, this.paymentHistoryResponse);
      if (this.PAYMAINDATA.length != 0) {
        // this.PAYMENTDATA = [];
        this.spinnos = '104' + this.PAYMAINDATA[0].uid;
        // let obj, month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        for (let i = 0; i < this.PAYMAINDATA.length; i++) {
          // let paydueDate = this.PAYMAINDATA[i].payDueCd;
          this.PAYMAINDATA[i].dealerCodeNo = this.PAYMAINDATA[i].dealerCode;
          this.PAYMAINDATA[i].lfspaycode = this.PAYMAINDATA[i].lfsPayCd;
          this.PAYMAINDATA[i].paymentPeriod = this.PAYMAINDATA[i].payDueCd;
          this.PAYMAINDATA[i].paymentDate = this.PAYMAINDATA[i].lfsRunDtp;
          this.PAYMAINDATA[i].invoiceNo = this.PAYMAINDATA[i].invoice;
        }
        this.paymentTotalData = this.PAYMAINDATA.length;
      } else {
        this.PAYMAINDATA = [];
        this.paymentNoData = true;
        this.paymentTotalData = 0;
      };
      this.paymentDataSource = new MatTableDataSource(this.PAYMAINDATA);
      this.paymentDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'paymentDate': return new Date(item.paymentDate);
          default: return item[property];
        }
      };
      this.paymentDataSource.sort = this.paymentHistorySort;
      this.paymentDataSource.paginator = this.paymentHistoryPaginator;
      this.paymentTableLoading = false;
      this.spinId = this.nameAssocaite;
    }, error => {
      this.paymentNoData = true;
      this.paymentTotalData = 0;
      this.paymentTableLoading = false;
      console.log('Error', error);
      console.log('Error While getting data for the payment History Table');
      this.spinId = this.nameAssocaite;
    });
  };

  getRecentData() { // recent enrollment data
    this.RECENT_DATA = [];
    this.recentTableLoading = true;
    if (this.appService.baseUrl != '') {
      this.dashboardService.getRecentData(this.appService.selectedRegion).subscribe((response: any) => {
        [].push.apply(this.RECENT_DATA, response.RecentEnrollmentSummary.Result);
        if (this.RECENT_DATA.length != 0) {
          this.recentCount = this.RECENT_DATA.length;
          this.recentNoData = false;
          this.recentDataSource = new MatTableDataSource(this.RECENT_DATA);
          this.recentDataSource.sort = this.recentSort;
          this.recentDataSource.paginator = this.recentPaginator;
        } else {
          this.recentNoData = true;
        }
        this.recentTableLoading = false;
      }, err => {
        console.log('Error', err);
        console.log('Error while fetching Recent Enrollment Data');
        this.recentTableLoading = false;
        this.recentNoData = true;
        this.RECENT_DATA = [];
      });
    }
  };

  getAllEnrollData() { // all enrollment data service call
    this.ALL_DATA = [];
    this.allTableLoading = true;
    this.dashboardService.getAllData(this.appService.selectedRegion).subscribe((response: any) => {
      [].push.apply(this.ALL_DATA, response.PastEnrollmentSummary.Result);
      if (this.ALL_DATA.length != 0) {
        this.allCount = this.ALL_DATA.length
        this.allNoData = false;
        this.allDataSource = new MatTableDataSource(this.ALL_DATA);
        this.allDataSource.sort = this.allSort;
        this.allDataSource.paginator = this.allPaginator;
      } else {
        this.allNoData = true;
      }
      this.allTableLoading = false;
    }, err => {
      console.log('Error', err);
      console.log('Error while fetching all enrollment data');
      this.allTableLoading = false;
      this.allNoData = true;
      this.ALL_DATA = [];
    });
  };

  // ==============================UPDATE LFS PAY CODE==================================

  updateLfsPayCode() {
    console.log("Update lfspaycode")
  }

  // ==============================DEBIT PAYMENT FUNCTION AND VALIDATION==================================

  debitPayment() {
    this.loadSpinner = true;
    this.debitStatus = false;
    var month = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    var spinid = this.spinnos;
    var lfspaycd = this.debitPaymentArray[0].lfspaycode;
    var dealerno = this.debitPaymentArray[0].dealerCode;
    var payduecd = this.debitPaymentArray[0].paymentPeriod;
    if (month.indexOf(payduecd.substring(0, 3)) >= 10) {
      payduecd = (payduecd.substring(4) + "-" + month.indexOf(payduecd.substring(0, 3))).toString()
    } else {
      payduecd = (payduecd.substring(4) + "-0" + month.indexOf(payduecd.substring(0, 3))).toString()
    }
    console.log("Checking date format:", payduecd)
    var comment = this.Comments;
    this.dashboardService.insertDebitPayment(spinid, lfspaycd, dealerno, payduecd, comment).subscribe((response: any) => {
      if (response.RowUpdated.rowCount >= 0) {
        this.debitStatus = true;
        this.loadSpinner = false;
        this.PAYMAINDATA = [];
        this.DEBITDATA = [];
        this.paymentDataSource = new MatTableDataSource(this.PAYMAINDATA);
        this.debitDataSource = new MatTableDataSource(this.DEBITDATA);
        this.getPaymentHistoryDetail(this.user.spinId, this.user.dealerCode, this.user.dealerName);
        this.getDebitHistoryDetail(this.user.spinId);
        this.getCurrentMonthDebitData();
        this.getPastYrDebitData();
        this.Comments = "";
      }
    }, err => {
      console.log("Error in Debiting the Selected Payment")
      this.loadSpinner = false;
      this.debitStatus = false;
    })
  }

  // Validate the comments in the debit payment function: comments should not be empty
  validateComment() {
    if (this.Comments.trim() == '') {
      this.debitBtnDisabled = true;
      $('#commentDebitInput').addClass('warningDiv');
      $('#commentDebitInput').focus();
    } else {
      this.debitBtnDisabled = false;
      $('#commentDebitInput').removeClass('warningDiv');
    }
  }

  // Assigning the row value into an array for modal display
  preDebitOperation(index, row) {
    this.loadSpinner=false; // Dont remove - highly important (madhu)
    this.debitStatus = false; // Dont remove - highly important (madhu)
    this.debitPaymentArray = [];
    this.fullName = this.user.firstName + ' ' + this.user.lastName;
    this.spinnos = this.user.spinId;
    let debitPayArray = [];
    this.PAYMAINDATA.forEach(function (payData) {
      if (payData.dealerCode == row.dealerCode && payData.lfspaycode == row.lfspaycode && payData.paymentDate == row.paymentDate && payData.paymentPeriod == row.paymentPeriod) {
        let obj = {
          'dealerCode': row.dealerCode,
          'lfspaycode': row.lfspaycode,
          'paymentPeriod': row.paymentPeriod,
          'paymentDate': row.paymentDate
        }
        debitPayArray.push(obj);
      }
    });
    this.debitPaymentArray = debitPayArray;
    console.log("DebitPaymentArray for Modal :", this.debitPaymentArray);
  }

  // ==============================EXPORT EXCEL==================================

  exportSummary() {
    this.noExcelData = false;
    if (this.tabName == 'Current Month Payment') {
      if (!this.currentPaymentNoData) {
        this.excelService.exportAsExcelFile(this.currentDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Past 1 year Payment') {
      if (!this.pastPaymentNoData) {
        this.excelService.exportAsExcelFile(this.pastDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Current Month Debit Payment') {
      if (!this.currentDebitNoData) {
        this.excelService.exportAsExcelFile(this.currentDebitDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Past 1 year Debit Payment') {
      if (!this.pastDebitNoData) {
        this.excelService.exportAsExcelFile(this.pastDebitDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Payment History') {
      if (!this.paymentNoData) {
        this.excelService.exportAsExcelFile(this.paymentDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Debit History') {
      if (!this.debitNoData) {
        this.excelService.exportAsExcelFile(this.debitDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Recent Enrollment') {
      if (!this.recentNoData) {
        this.excelService.exportAsExcelFile(this.recentDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'All Enrollment') {
      if (!this.allNoData) {
        this.excelService.exportAsExcelFile(this.allDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    }
  }

  // ======================  VIEW ENROLLMENT ASSOCIATE DETAILS IN MODAL ====================================
  async viewEnrollmentModal(row) {
    this.infoLoading = false;  /// loader till data fetchs
    $('#enrollDetails').modal('show'); // Show Modal in UI
    let associateResponse: any = [], certificationResponse: any = [], lfsResponse: any = [];
    let validResponse: any = '';
    let associateDetail: any = {};
    this.marSpin = row.spinId;
    this.pastYearCertData(this.marSpin);

    // Fetch Associate Details from staff Master
    this.dashboardService.getAssociateDetails(row.spinId, row.dealerCode).subscribe((result: any) => {
      associateResponse = result.EnrollmentDetail.EnrollDetail;
      var eligibleUser = false;
      loop1:
      for (let i = 0; i < associateResponse.length; i++) {
        loop2:
        for (let j = 0; j < this.appService.jobCodeList.length; j++) {
          if (Number(associateResponse[i].JOB_CODE) == Number(this.appService.jobCodeList[j])) {
            eligibleUser = true;
            validResponse = associateResponse[i];
            break loop1;
          }
        }
      }
      if (!eligibleUser) {
        validResponse = result.EnrollmentDetail.EnrollDetail[0];
        validResponse.employmentStatus = 'Ineligible Job Code'
      }
      if (eligibleUser) {
        validResponse.employmentStatus = validResponse.employee_status.toUpperCase();
      }
      validResponse.firstName = validResponse.first_name.toUpperCase();
      if (validResponse.firstName == null || validResponse.firstName == undefined || validResponse.firstName.toLowerCase() == 'null') {
        validResponse.firstName = '';
      }
      validResponse.lastName = validResponse.Last_Name.toUpperCase();
      if (validResponse.lastName == null || validResponse.lastName == undefined || validResponse.lastName.toLowerCase() == 'null') {
        validResponse.lastName = '';
      }
      validResponse.spinId = validResponse.spin_id.toUpperCase();
      if (validResponse.spinId == null || validResponse.spinId == undefined || validResponse.spinId.toLowerCase() == 'null') {
        validResponse.spinId = '';
      }

      if (validResponse.employmentStatus == null || validResponse.employmentStatus == undefined || validResponse.employmentStatus.toLowerCase() == 'null') {
        validResponse.employmentStatus = '';
      }
      validResponse.contact = validResponse.contact_phone_no;
      if (validResponse.contact == null || validResponse.contact == undefined || validResponse.contact.toLowerCase() == 'null') {
        validResponse.contact = '';
      }
      validResponse.email = validResponse.user_email;
      if (validResponse.email == null || validResponse.email == undefined || validResponse.email.toLowerCase() == 'null') {
        validResponse.email = '';
      }
      validResponse.jobTitle = validResponse.job_description.toUpperCase();
      if (validResponse.jobTitle == null || validResponse.jobTitle == undefined || validResponse.jobTitle.toLowerCase() == 'null') {
        validResponse.jobTitle = '';
      }
      validResponse.tenure = validResponse.tenure + ' Months';
      if (validResponse.tenure == null || validResponse.tenure == undefined || validResponse.tenure.toLowerCase() == 'null') {
        validResponse.tenure = '';
      }

      // Fetch Certification Details from Maritz Table
      this.dashboardService.getCertificateDetails(row.spinId, row.dealerCode).subscribe(async (result1: any) => {
        console.log('Certification Details', result1);
        if (result1.EnrollmentMaritzDetail.MaritzDetail != undefined && result1.EnrollmentMaritzDetail.MaritzDetail.length != 0) {
          certificationResponse = result1.EnrollmentMaritzDetail.MaritzDetail[result1.EnrollmentMaritzDetail.MaritzDetail.length - 1];
          if (certificationResponse.startDate == null || certificationResponse.startDate == '' || certificationResponse.startDate == undefined) {
            validResponse.certStartDate = ''; console.log('start date null')
          } else {
            let certStartDate = new Date(certificationResponse.startDate.substring(0, 10).replace(/-/g, '\/'));
            validResponse.certStartDate = certStartDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });
          }
          // if (certificationResponse.cert_level == 'C') {
          //   validResponse.certificationLevel = 'Certified';
          // }
          // else if (certificationResponse.cert_level == 'S') {
          //   // await this.dashboardService.seniorEligibleCheck(row.spinId).subscribe(async (seniorEligibleResponse: any) => {
          //   //   console.log('Senior Eligible', seniorEligibleResponse);
          //   //   validResponse.certificationLevel = await this.appService.getSeniorEligibileCheck(seniorEligibleResponse.SeniorEligibleCheck.Result)
          //   // })
          //   validResponse.certificationLevel = await this.seniorEligibleCheck(row);
          // }
          // else if (certificationResponse.cert_level == 'M') {
          //   validResponse.certificationLevel = 'Master';
          // }
          // else if (certificationResponse.cert_level == 'N') {
          //   validResponse.certificationLevel = 'NA';
          // }
          // else {
            validResponse.certificationLevel = certificationResponse.mar_master;
            validResponse.eligibility = certificationResponse.eligibility;
            validResponse.eligTenureMonths = certificationResponse.tenure_months;
            validResponse.eligTotalCreds = certificationResponse.total_credits;
            validResponse.eligLexusEssentials = certificationResponse.lexus_essentials;
          // }
          validResponse.result = certificationResponse.result;
          // if (validResponse.result != 'none') {
          //   this.twoTimesEnrolled = true;
          // }
          validResponse.certification_months = certificationResponse.certification_months;
          if (validResponse.certification_months == null || validResponse.certification_months == undefined || String(validResponse.certification_months).toLowerCase() == 'null') {
            validResponse.certification_months = '';
          }
          validResponse.certifiedYear = certificationResponse.certified_year;
          if (validResponse.certifiedYear == null || validResponse.certifiedYear == undefined || String(validResponse.certifiedYear).toLowerCase() == 'null') {
            validResponse.certifiedYear = '';
          }
        } else {
          validResponse.certificationLevel = 'NA';
        };

        // Fetch LFS Details
        //console.log(row.spinId.substring(3));
        this.dashboardService.getLFSDetails(row.spinId.substring(3)).subscribe(async (result2: any) => {
          console.log(result2);
          lfsResponse = result2.EnrollmentLFSDetail.LFSDetail[0]
          if (lfsResponse != undefined && lfsResponse.length != 0) {
            var dateFrom = lfsResponse.veh_acquisition_date.substring(0, 10);
            var dateTo = lfsResponse.lfs_maturity_date.substring(0, 10);
            var today: any = new Date();
            var dd: any = today.getDate();
            var mm: any = today.getMonth() + 1;
            var yyyy: any = today.getFullYear();
            if (dd < 10) {
              dd = '0' + dd
            }

            if (mm < 10) {
              mm = '0' + mm
            }

            today = yyyy + '-' + mm + '-' + dd;
            console.log(today);
            console.log(dateFrom);
            console.log(dateTo);
            var d1 = dateFrom.split("-");
            var d2 = dateTo.split("-");
            var c = today.split("-");

            var from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
            var to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
            var check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

            if (check > from && check < to) {
              validResponse.leasedVehicle = 'Yes';
            }
            else {
              validResponse.leasedVehicle = 'No';
            }

            validResponse.dealerName = lfsResponse.dlr_name;
            if (validResponse.dealerName == null || validResponse.dealerName == undefined || validResponse.dealerName.toLowerCase() == 'null') {
              validResponse.dealerName = '';
            } else {
              validResponse.dealerName = lfsResponse.dlr_name.toUpperCase();
            }

            validResponse.lfsNo = lfsResponse.lfs_no;
            if (validResponse.lfsNo == null || validResponse.lfsNo == undefined || validResponse.lfsNo.toLowerCase() == 'null') {
              validResponse.lfsNo = '';
            }

            validResponse.lfsActivationDate = this.appService.dateSplitter(lfsResponse.lfs_activation_date);
            if (validResponse.lfsActivationDate == null || validResponse.lfsActivationDate == undefined || validResponse.lfsActivationDate.toLowerCase() == 'null') {
              validResponse.lfsActivationDate = '';
            }

            validResponse.lfs_maturity_date = this.appService.dateSplitter(lfsResponse.lfs_maturity_date);
            if (validResponse.lfs_maturity_date == null || validResponse.lfs_maturity_date == undefined || validResponse.lfs_maturity_date.toLowerCase() == 'null') {
              validResponse.lfs_maturity_date = '';
            }

            validResponse.lfs_extension_date = this.appService.dateSplitter(lfsResponse.lfs_extension_date);
            if (validResponse.lfs_extension_date == null || validResponse.lfs_extension_date == undefined || validResponse.lfs_extension_date.toLowerCase() == 'null') {
              validResponse.lfs_extension_date = '';
            }
            if (validResponse.lfs_extension_date == "01/01/1990") { validResponse.lfs_extension_date = "NA" }

            validResponse.lfs_termination_date = this.appService.dateSplitter(lfsResponse.lfs_termination_date);
            if (validResponse.lfs_termination_date == null || validResponse.lfs_termination_date == undefined || validResponse.lfs_termination_date.toLowerCase() == 'null') {
              validResponse.lfs_termination_date = '';
            }
            if (validResponse.lfs_termination_date == "01/01/2100") { validResponse.lfs_termination_date = "NA" }

            validResponse.veh_acquisition_date = this.appService.dateSplitter(lfsResponse.veh_acquisition_date);
            if (validResponse.veh_acquisition_date == null || validResponse.veh_acquisition_date == undefined || validResponse.veh_acquisition_date.toLowerCase() == 'null') {
              validResponse.veh_acquisition_date = '';
            }

            validResponse.vehicleBrand = lfsResponse.Vehicle_Brand;
            if (validResponse.vehicleBrand == null || validResponse.vehicleBrand == undefined || validResponse.vehicleBrand.toLowerCase() == 'null') {
              validResponse.vehicleBrand = '';
            } else {
              validResponse.vehicleBrand = lfsResponse.Vehicle_Brand.toUpperCase();
            }

            validResponse.vehicleModel = lfsResponse.veh_model;
            if (validResponse.vehicleModel == null || validResponse.vehicleModel == undefined || validResponse.vehicleModel.toLowerCase() == 'null') {
              validResponse.vehicleModel = '';
            } else {
              validResponse.vehicleModel = lfsResponse.veh_model.toUpperCase();
            }

            validResponse.customerType = lfsResponse.customerType;
            if (validResponse.customerType == null || validResponse.customerType == undefined || validResponse.customerType.toLowerCase() == 'null') {
              validResponse.customerType = '';
            } else {
              validResponse.customerType = lfsResponse.customerType.toUpperCase();
            }

            validResponse.vin = lfsResponse.veh_vin;
            if (validResponse.vin == null || validResponse.vin == undefined || validResponse.vin.toLowerCase() == 'null') {
              validResponse.vin = '';
            }
          } else {
            validResponse.leasedVehicle = 'No';
            validResponse.lfsActivationDate = '';
            validResponse.veh_acquisition_date = '';
            validResponse.customerType = '';
            validResponse.lfs_maturity_date = '';
            validResponse.lfs_extension_date = '';
            validResponse.lfs_termination_date = '';
            //new changes
            validResponse.vehicleBrand = '';
            validResponse.vehicleModel = '';
            validResponse.vin = '';
            validResponse.dealerName = '';
            validResponse.lfsNo = '';
          };

          console.log('Before Setting Form Value', await validResponse);
          associateDetail = {
            spin_id: validResponse.spinId,
            firstName: validResponse.firstName,
            middleName: validResponse.Middle_Name,
            lastName: validResponse.lastName,
            jobTitle: validResponse.jobTitle,
            tenure: validResponse.tenure,
            jobCode: validResponse.JOB_CODE,
            employmentStatus: validResponse.employmentStatus,
            contact: validResponse.contact,
            email: validResponse.email,
            certificationLevel: await validResponse.certificationLevel,
            certifiedYear: validResponse.certifiedYear,
            certification_months: validResponse.certification_months,
            certStartDate: validResponse.certStartDate,
            eligEligibility: validResponse.eligibility,
            eligTenureMonths: validResponse.eligTenureMonths,
            eligTotalCreds: validResponse.eligTotalCreds,
            eligLexusEssentials: validResponse.eligLexusEssentials,
            dealerCode: row.dealerCode,
            dealerName: validResponse.dealerName,
            lfsNo: validResponse.lfsNo,
            lfs_maturity_date: validResponse.lfs_maturity_date,
            lfsActivationDate: validResponse.lfsActivationDate,
            lfs_extension_date: validResponse.lfs_extension_date,
            lfs_termination_date: validResponse.lfs_termination_date,
            veh_acquisition_date: validResponse.veh_acquisition_date,
            customerType: validResponse.customerType,
            vehicleBrand: validResponse.vehicleBrand,
            vehicleModel: validResponse.vehicleModel,
            vin: validResponse.vin,
          }
          this.associateInfo.setValue(await associateDetail);
          this.infoLoading = true;
        }, err => {
          validResponse.leasedVehicle = 'No';
          validResponse.lfsActivationDate = '';
          validResponse.veh_acquisition_date = '';
          validResponse.customerType = '';
          validResponse.lfs_maturity_date = '';
          validResponse.lfs_extension_date = '';
          validResponse.lfs_termination_date = '';
          validResponse.vehicleBrand = '';
          validResponse.vehicleModel = '';
          validResponse.vin = '';
          validResponse.dealerName = '';
          validResponse.lfsNo = '';
          console.log('Before Setting Form Value', validResponse);
        });
      }, err => {
        validResponse.certificationLevel = 'NA';
        validResponse.certifiedYear = '';
        validResponse.certification_months = '';
      });
    });
  }
  async seniorEligibleCheck(row) {
    return new Promise((resolve, reject) => {
      this.dashboardService.seniorEligibleCheck(row.spinId).subscribe(async (seniorEligibleResponse: any) => {

 

              console.log('Senior Eligible', seniorEligibleResponse);

              var certificationLevel = await this.appService.getSeniorEligibileCheck(seniorEligibleResponse.SeniorEligibleCheck.Result)
console.log('certificationLevel', certificationLevel);
          resolve(certificationLevel);
            })

    });
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  pastYearCertData(marSpin) {
    //this.openPYCmodel = true
    //$('#pastYearCert').modal('show');
    this.PYCrecords = []; this.PYCrecordsDex = [];
    this.pastYearCertLoaded = false;
    this.dashboardService.getPastYearCertDetails(marSpin).subscribe((res: any) => {
      this.PYCrecords = []; this.PYCrecordsDex = []; console.log('res of certs :: ', res);
      this.pastYearCertDetails = res.PastYearCertDetails.Result;
      this.pastYearCertDetailsDex = res.PastYearCertDetailsDex.Result2;
      console.log('past year cert', this.pastYearCertDetails);
      console.log('past year cert dex', this.pastYearCertDetailsDex);
      for (let a = 0; a < this.pastYearCertDetails.length; a++) {
        if (this.pastYearCertDetails[a].start_date != null && this.pastYearCertDetails[a].start_date != undefined) {
        let obj = {
          as_of_date: new Date(this.pastYearCertDetails[a].as_of_date.substring(0, 10).replace(/-/g, '\/')).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
          spin_id: this.pastYearCertDetails[a].spinId,
          dealer_code: this.pastYearCertDetails[a].dealerCode,
          cert_level: this.pastYearCertDetails[a].cert_level,
          start_date: new Date(this.pastYearCertDetails[a].start_date.substring(0, 10).replace(/-/g, '\/')).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
        }
        this.PYCrecords.push(obj);
      } else {
        let obj = {
          as_of_date: new Date(this.pastYearCertDetails[a].as_of_date.substring(0, 10).replace(/-/g, '\/')).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
          spin_id: this.pastYearCertDetails[a].spinId,
          dealer_code: this.pastYearCertDetails[a].dealerCode,
          cert_level: this.pastYearCertDetails[a].cert_level,
          start_date: ''
        }
        this.PYCrecords.push(obj);
      }
      }
      // this.pastYearCertLoaded = true;
      console.log('pyc', this.PYCrecords)
      for (let a = 0; a < this.pastYearCertDetailsDex.length; a++) {
        let obj = {
          as_of_date: new Date(this.pastYearCertDetailsDex[a].as_of_date.substring(0, 10).replace(/-/g, '\/')).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
          spin_id: this.pastYearCertDetailsDex[a].spinId,
          dealer_code: this.pastYearCertDetailsDex[a].dealerCode,
          highest_cert_level: this.pastYearCertDetailsDex[a].highest_cert_level,
          cert_credits: this.pastYearCertDetailsDex[a].cert_credits,
          training_credits: this.pastYearCertDetailsDex[a].training_credits,
          lexus_essentials: this.pastYearCertDetailsDex[a].lexus_essentials,
          elig_status: this.pastYearCertDetailsDex[a].elig_status,
        }
        this.PYCrecordsDex.push(obj);
      }
      this.pastYearCertLoaded = true;
      console.log('pyc from dex :: ', this.PYCrecordsDex);
    })
  }
  closePYC() {
    const pyc = <HTMLInputElement> document.getElementById("pastYearCert");
    console.log('close man');
    $('#pastYearCert').modal('hide');
    $('#enrollDetails').modal('focus');
  }

  AddToWatchlist() {
    this.commErr = false;
    this.loadSpinner = true;
    this.watchAddStatus = false;
    var spinid = this.spinnos;
    var dealerno = this.dealercode;
    var comment = this.watchComments;
    var addedBy = this.appService.loggedInUserName;
    console.log(addedBy, 'addedby');
    console.log('comments', comment);
    this.WatchlistService.insertWatchlistRecord(spinid, this.nameAssocaite, dealerno, comment, addedBy).subscribe((response: any) => {
      if (response.RowUpdated.rowCount >= 0) {
        this.watchAddStatus = true;
        this.loadSpinner = false;
        this.watchComments = "";
      }
    }, err => {
      this.commErr = true;
      console.log("Error in Adding to watchlist")
      this.loadSpinner = false;
      this.watchAddStatus = false;
    })
    this.selectUser(this.user);
  }
  ReAddToWatchlist() {
    this.commErr = false;
    this.loadSpinner = true;
    this.watchAddStatus = false;
    var spinid = this.spinnos;
    var name = this.nameAssocaite;
    var dealerno = this.dealercode;
    var comment = this.watchComments;
    var addedBy = this.appService.loggedInUserName;
    const type = 'readd';
    console.log('spinid-', spinid, ' name-', name, ' dealerno-', dealerno, ' comments-', comment, ' addedBy-', addedBy);
    this.WatchlistService.updateWatchlistRecord(spinid, comment, '1', '0', addedBy, type).subscribe((response: any) => {
      if (response.RowUpdated.rowCount >= 0) {
        this.watchAddStatus = true;
        this.loadSpinner = false;
        this.watchComments = "";
        this.getPaymentHistoryDetailWatch(spinid);
      }
    }, err => {
      this.commErr = true;
      console.log("Error in ReAdding to watchlist")
      this.loadSpinner = false;
      this.watchAddStatus = false;
    })
    this.watchAddStatus = false;
  }
  removeWatchlist() {
    this.commErr = false;
    this.loadSpinner = true;
    this.watchRemoveStatus = false;
    var spin = this.spinnos;
    var comment = this.removeComments;
    var deletedBy = this.appService.loggedInUserName;
    console.log('spin', spin);
    console.log(deletedBy, 'addedby');
    console.log('comments', comment);
    const type = 'removal';
    this.WatchlistService.updateWatchlistRecord(spin, comment, '0', '1', deletedBy, type).subscribe((response: any) => {
      if (response.RowUpdated.rowCount >= 0) {
        this.loadSpinner = false;
        this.removeComments = '';
        this.watchRemoveStatus = true;
        this.getPaymentHistoryDetailWatch(spin);
      }
    }, err => {
      this.commErr = true;
      console.log("Error in Removing watchlist record")
      this.loadSpinner = false;
      this.watchRemoveStatus = false;
    })
    //this.loadSpinner = false;
    //this.watchlistComponent.watchRemoveStatus = false;
  }
  validateCommentWatch() {
    this.addBtnDisabled = false;
    if (this.watchComments.trim() == '') {
      this.addBtnDisabled = true;
      $('#watchComments').addClass('warningDiv');
      $('#watchComments').focus();
    } else {
      this.addBtnDisabled = false;
      $('#watchComments').removeClass('warningDiv');
    }
  }
  validateCommentRemove() {
    if (this.removeComments.trim() == '') {
      console.log(this.removeComments);
      this.removeBtnDisabled = true;
      $('#removeComments').addClass('warningDiv');
      $('#removeComments').focus();
    } else {
      this.removeBtnDisabled = false;
      $('#removeComments').removeClass('warningDiv');
    }
  }
  validateCommentNotes() {
    if (this.notes.trim() == '') {
      this.allCommAddBtnDis = true;
      $('#notes').addClass('warningDiv');
      $('#notes').focus();
    } else {
      this.allCommAddBtnDis = false;
      $('#notes').removeClass('warningDiv');
    }
  }

  omit_characters(event, type) {
    if (event.key == ';' || event.key == '#' || event.key == '=' || event.key == '<' || event.key == '>' ||
      event.key == '!') {
      if (type == 'remove') {
        this.errMsg1 = true;
        setTimeout(() => {
          this.errMsg1 = false;
        }, 4000)
      }
      else if (type == 'add') {
        this.errMsg2 = true;
        setTimeout(() => {
          this.errMsg2 = false;
        }, 4000)
      }
      else if (type == 'readd') {
        this.errMsg3 = true;
        setTimeout(() => {
          this.errMsg3 = false;
        }, 4000)
      }
      else if (type == 'addl') {
        this.errMsg4 = true;
        setTimeout(() => {
          this.errMsg4 = false;
        }, 4000)
      }
    }
  }

  async preAdd() {
    this.commErr = false;
    this.watchAddStatus = false; //important
    this.loadSpinner = false; // Dont remove - highly important 
    this.watchArray2 = [];
    let watchAddArray = [];
    await this.watchlistComponent.getPastWatchlist();
    let pastList = this.watchlistComponent.pastWatchlist;
    console.log(pastList, pastList.length);
    for (let k = 0; k < pastList.length; k++) {
      console.log(this.spinnos, this.dealercode, 'inside');
      if (pastList[k].SpinId != this.spinnos) { k++; }
      else {
        let obj = {
          'Name': this.nameAssocaite.toUpperCase(),
          'SpinId': this.spinnos,
          'DealerCode': this.dealercode
        }
        watchAddArray.push(obj);
      }
    }
    this.watchArray2 = watchAddArray;
    console.log("watchArray2 for Modal :", this.watchArray2);
  }
  async preRemove() {
    this.commErr = false;
    this.watchArrayReady = false;
    this.watchRemoveStatus = false; // very important
    this.loadSpinner = false; // Dont remove - highly important 
    this.watchArray1 = [];
    console.log(this.spinnos, this.dealercode);
    let watchAddArray = [];
    this.WatchlistService.getWatchlistRecords(this.appService.selectedRegion, 1).subscribe((response: any) => {
      let actList = response.Watchlist.Result;
      console.log(actList, actList.length);
      for (let k = 0; k < actList.length; k++) {
      console.log(this.spinnos, this.dealercode, 'inside');
      if (actList[k].spinId != this.spinnos) { console.log(actList[k].spinId) ; continue; }
      else {
        console.log(actList[k].spinId)
        let obj = {
          'Name': this.nameAssocaite.toUpperCase(),
          'SpinId': this.spinnos,
          'DealerCode': this.dealercode,
          'Reason': actList[k].reason.toUpperCase()
        }
        watchAddArray.push(obj);
      }
    }
    this.watchArray1 = watchAddArray;
    console.log("watchArray1 for Modal :", this.watchArray1);
    this.watchArrayReady = true;
    }) 
  }

  allCommFn(row) {
    this.allCommAddBtnDis = true;
    this.addlCommAdded = false
    console.log("clicked!")
    this.commentsSplit = [];
    this.notes = '';
    this.allCommPopup = true;
    console.log("row", row);
    this.allCommAdd = true;
    this.allCommSpin = row;
    console.log('spin: ', this.allCommSpin, ' isonwatch: ', this.isOnWatch);
    this.WatchlistService.getWatchlistComments(row).subscribe((response: any) => {
      console.log(response.WatchlistComments.Result);
      this.watchlistComments = response.WatchlistComments.Result
      if (this.watchlistComments.length != 0) {
        this.allCommAdd = false;
        for (let j = 0; j < this.watchlistComments.length; j++) {
          let obj = {
            "comment": this.watchlistComments[j].comments,
            "addedBy": this.watchlistComments[j].AddedBy,
            "addedOn": new Date(this.watchlistComments[j].AddedOn).toLocaleString("en-US"),
            "spinId": this.watchlistComments[j].spinId
          }
          this.commentsSplit.push(obj);
        }
        console.log('com split', this.commentsSplit);
      }
      else {
        this.allCommAdd = false;
        let obj = {
          "comment": "No previous Watchlist Comments found",
          "addedBy": " ",
          "addedOn": " "
        }
        this.commentsSplit.push(obj);
        console.log('com split', this.commentsSplit);
      }
    })
  }

  additionalComment() {
    //this.addlPop = true;
    this.addlCommAdded = false;
    this.addlLoading = true;
    let watch, del;
    console.log('spin: ', this.spinnos, ' comm: ', this.notes, ' addedBy: ', this.appService.loggedInUserName);
    if (this.isOnWatch == true) { watch = '1'; del = '0' }
    else { watch = '0', del = '1' }
    this.WatchlistService.updateWatchlistRecord(this.spinnos, this.notes, watch, del, this.appService.loggedInUserName, 'additional').subscribe((response: any) => {
      if (response.RowUpdated.rowCount >= 0) {
        this.addlCommAdded = true;
        this.addlLoading = false;
        this.notes = "";
        //if (this.isOnWatch == true) { this.getActiveWatchlist(); }
        //else if (this.isOnWatch == false) { this.getPastWatchlist(); }

      }
    })
  }

  getPaymentHistoryDetailWatch(spinId) { // Payment history details 
    this.paymentTableLoading = true;
    this.PAYMAINDATA = [];
    this.paymentHistoryResponse = '';
    this.spinnos = spinId;
    this.payHistoryService.getPayHistoryDataSpinId(spinId).subscribe((response: any) => {
      console.log('res', response);
      this.paymentHistoryResponse = response.PaymentHistory.Result;
      console.log(response.isOnWatch.Result1.length);
      if (response.isOnWatch.Result1.length != 0) {
        const onWatch = response.isOnWatch.Result1[0].isOnWatch;
        console.log(onWatch);
        this.isOnWatch = onWatch;
        console.log(this.isOnWatch, typeof (this.isOnWatch));
      }
      else if (response.isOnWatch.Result1.length == 0) {
        this.isOnWatch = false;
        console.log(this.isOnWatch, typeof (this.isOnWatch));
      }
      console.log(this.isOnWatch);
      this.showDeets = true;
      this.PAYMAINDATA = [];
      [].push.apply(this.PAYMAINDATA, this.paymentHistoryResponse);
      if (this.PAYMAINDATA.length != 0) {
        // this.PAYMENTDATA = [];
        this.spinnos = '104' + this.PAYMAINDATA[0].uid;
        // let obj, month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        for (let i = 0; i < this.PAYMAINDATA.length; i++) {
          // let paydueDate = this.PAYMAINDATA[i].payDueCd;
          this.PAYMAINDATA[i].dealerCodeNo = this.PAYMAINDATA[i].dealerCode;
          this.PAYMAINDATA[i].lfspaycode = this.PAYMAINDATA[i].lfsPayCd;
          this.PAYMAINDATA[i].paymentPeriod = this.PAYMAINDATA[i].payDueCd;
          this.PAYMAINDATA[i].paymentDate = this.PAYMAINDATA[i].lfsRunDtp;
          this.PAYMAINDATA[i].invoiceNo = this.PAYMAINDATA[i].invoice;
        }
        this.paymentTotalData = this.PAYMAINDATA.length;
      } else {
        this.PAYMAINDATA = [];
        this.paymentNoData = true;
        this.paymentTotalData = 0;
      };
      this.paymentDataSource = new MatTableDataSource(this.PAYMAINDATA);
      console.log(this.paymentDataSource);
      this.paymentDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'paymentDate': return new Date(item.paymentDate);
          default: return item[property];
        }
      };
      this.paymentDataSource.sort = this.paymentHistorySort;
      this.paymentDataSource.paginator = this.paymentHistoryPaginator;
      this.paymentTableLoading = false;
      this.spinId = this.nameAssocaite;
    }, error => {
      this.paymentNoData = true;
      this.paymentTotalData = 0;
      this.paymentTableLoading = false;
      console.log('Error', error);
      console.log('Error While getting data for the payment History Table');
      this.spinId = this.nameAssocaite;
    });
  }
}
