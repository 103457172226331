import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { LexusJobService } from '../lexus-job/lexus-job.service';
function reportValidator(fg: FormGroup) {
  const spinId = fg.get('spinId').value;
  const firstName = fg.get('firstName').value;
  const lastName = fg.get('lastName').value;
  const dealer = fg.get('dealer').value;
  return spinId || firstName || lastName || dealer
    ? null
    : { range: true };
}
@Component({
  selector: 'app-job-enrollment-history',
  templateUrl: './job-enrollment-history.component.html',
  styleUrls: ['./job-enrollment-history.component.css']
})

export class JobEnrollmentHistoryComponent implements OnInit {
  showDetails = false;
  searchForm: FormGroup;
  associateArr = [];
  spinClicked = false;
  type = '';
  certificationDetailsLex = [];
  certificationDetailsToy = [];
  associateDetailsLex = [];
  associateDetailsToy = [];
  primaryJobDetailsToy = [];
  primaryJobDetailsLex = [];
  secondaryJobDetails = [];
  searchResults = [];
  jobDetailsLex = [];
  searchLoading = false;
  jobLoading = false;
  constructor(private fb: FormBuilder, private lexusJobService: LexusJobService) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      spinId: '',
      firstName: '',
      lastName: '',
      dealer: '',
      type: 'search'
    }, { validator: reportValidator });
  }
  search() {
    this.showDetails = true;
    this.spinClicked = false;
    this.searchLoading = true;
    this.certificationDetailsLex = [];
    this.certificationDetailsToy = [];
    this.associateDetailsToy = [];
    this.associateDetailsLex = [];
    this.primaryJobDetailsToy = [];
    this.primaryJobDetailsLex = [];
    this.secondaryJobDetails = [];
    this.searchResults = [];
    this.jobDetailsLex = [];
    this.searchForm.get('type').setValue('search');
    // if(this.searchForm.get('spinId').value == '104449123975' || this.searchForm.get('spinId').value == '104449123975'){
    //   this.associateArr = ['one'];
    //   this.type = 'Lexus'
    // }
    // else if (this.searchForm.get('firstName').value == 'CHRIS' || this.searchForm.get('firstName').value == 'CHRIS'){
    //   this.associateArr = ['one','two'];
    //   this.type = 'Both';
    // }
    // else if (this.searchForm.get('spinId').value == '104578112773' || this.searchForm.get('spinId').value == '578112773'){
    //   this.associateArr = ['one'];
    //   this.type = 'Toyota';
    // }
    // else{
    //   this.associateArr = []
    // }
    this.lexusJobService.getJobEnrollHistory(this.searchForm.value).subscribe((response: any) => {
      this.searchLoading = false;
      this.searchResults = response.Reports.Result;
    }, err => {
      this.searchLoading = false;
      this.searchResults = [];
    })
  }
  reset() {
    this.searchForm.reset({
      spinId: '',
      firstName: '',
      lastName: '',
      dealer: '',
      type: 'search'
    });
    this.showDetails = false;
    this.spinClicked = false;
    this.certificationDetailsLex = [];
    this.certificationDetailsToy = [];
    this.associateDetailsToy = [];
    this.associateDetailsLex = [];
    this.primaryJobDetailsToy = [];
    this.primaryJobDetailsLex = [];
    this.secondaryJobDetails = [];
    this.secondaryJobDetails = [];
    this.searchResults = [];
    this.jobDetailsLex = [];
  }
  getDetails(usr) {
    this.jobLoading = true;
    this.spinClicked = true;
    this.showDetails = true;
    this.searchForm.get('type').setValue('spinSearch');
    var body = {
      "spinId": usr.spinId,
      "firstName": '',
      "lastName": '',
      "dealer": usr.dealer,
      "type": 'spinSearch',
      "searchType": 'assocDetails'
    }
    this.lexusJobService.getJobEnrollHistory(body).subscribe((response: any) => {
      this.associateDetailsLex = response.LexusAssociateDetails.Result;
      this.associateDetailsToy = response.ToyAssociateDetails.Result1;
      var body1 = {
        "spinId": usr.spinId,
        "firstName": '',
        "lastName": '',
        "dealer": usr.dealer,
        "type": 'spinSearch',
        "searchType": 'jobDetails'
      }
      this.lexusJobService.getJobEnrollHistory(body1).subscribe((response: any) => {
        this.jobDetailsLex = response.lexusJobDetails.Result;
        this.primaryJobDetailsToy = response.toyotaJobDetails.Result1;
        this.jobDetailsLex.forEach(element => {
          if (element.primaryRole == 'TRUE' || element.primaryRole == 'True' || element.primaryRole == null) {
            this.primaryJobDetailsLex.push(element);
          }
          else {
            this.secondaryJobDetails.push(element);
          }
        })
        //   if (this.type == 'TOYOTA' && this.primaryJobDetails) {
        //     this.primaryJobDetails[0].jobCode = this.primaryJobDetails[0].jobDescription.split('-')[3]
        //   }
        let body2 = {
          "spinId": usr.spinId,
          "firstName": '',
          "lastName": '',
          "dealer": usr.dealer,
          "type": 'spinSearch',
          "searchType": 'certDetails'
        }
        this.lexusJobService.getJobEnrollHistory(body2).subscribe((response: any) => {
          this.jobLoading = false;
          this.type = usr.associateType;
          this.certificationDetailsLex = response.lexusCertDetails ? response.lexusCertDetails.Result : [];
          this.certificationDetailsToy = response.toyotaCertDetails ? response.toyotaCertDetails.Result1 : [];
        })
      })
    }, err => {
      this.jobLoading = false;
      this.type = usr.associateType;
      this.certificationDetailsLex = [];
      this.certificationDetailsToy = [];
      this.associateDetailsToy = [];
      this.associateDetailsLex = [];
      this.primaryJobDetailsToy = [];
      this.primaryJobDetailsLex = [];
      this.secondaryJobDetails = [];
      this.secondaryJobDetails = [];
    })
  }
}

