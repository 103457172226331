import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from '../service/common.service'

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class AccessControlService {
  public getUserListUrl = "GetAccessCtrlUserList";
  public deleteUserDetailUrl = "DeleteAccessControl";
  public insertUserDataUrl="InsertAccessControl";
  public updateUserDataUrl='UpdateAccessControl';

  constructor(private _http: Http, private appService: CommonService) {
   }

  //  ==========================================GET THE USER LIST============================================
  getUserList(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getUserListUrl + this.appService.functionKey;
    return this._http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //  ============================================DELETE THE USER ===========================================
  deleteUser(userId): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.deleteUserDetailUrl + this.appService.functionKey;
    let body = {
      "userId": userId
    }
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //  ============================================ADD NEW USER ==============================================
  addUser(userDetail): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertUserDataUrl + this.appService.functionKey;
    let body = {
      "workDayId": userDetail.workDayId,
      "firstName" : userDetail.firstName,
      "lastName" : userDetail.lastName,
      "isLexusCollegeAdmin" : userDetail.isLexusCollegeAdmin,
      "isLexusUser" : userDetail.isLexusUser,
      "isAreaAdmin": userDetail.isAreaAdmin,
      "isAdmin" : userDetail.isAdmin,
      "emailAddress" : userDetail.emailAddress,
      "region" : userDetail.region 
    }
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //  =========================================UPDATE THE USER ROLE==========================================
  updateUser(userId,isAdminValue,areaAdminValue,lexusUserValue,collegeAdminValue,region): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.updateUserDataUrl + this.appService.functionKey;
    let body = {
      "userId":userId,
      "isAdmin" : isAdminValue,
      "isAreaAdmin": areaAdminValue,
      "isLexusUser":lexusUserValue,
      "isLexusCollegeAdmin":collegeAdminValue,
      "region":region 
    }
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  //  =========================================EXCEPTION HANDLE==============================================
  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }
}

