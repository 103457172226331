import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
declare var $: any;
import { Location } from '@angular/common';
import { CommonService } from '../app/service/common.service';
import { DashboardService } from './dashboard/dashboard.service';
import { LoginService } from './login/login.service'
import { NotificationService } from './notification/notification.service'
import { environment } from '../environments/environment';
import { JoyrideService } from 'ngx-joyride';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { EnrollService } from './enroll-assosiate/enroll-assosiate.service';
import { Observable } from 'rxjs';
import { CorpDashboardComponent } from './corp-dashboard/corp-dashboard.component';
import { AuthService } from './auth-guard/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  your_date: Date;
  hideNotificationDiv: boolean = true;
  emptyArray: boolean = false;
  clearAllNotifications: boolean = true;
  displayArray: any[];
  profilecard: boolean = true;
  dasboardTab: boolean = false;
  batchJob: boolean = false;
  Report: boolean = false;
  accessControl: boolean = false;
  debitTab: boolean = false;
  enrollTab: boolean = false;
  unenrollTab: boolean = false;
  watchlistTab: boolean = false;
  billing: boolean = false;
  payHistoryTab: boolean = false;
  payReportsTab: boolean = false;
  searchAssociateTab: boolean = false;
  userName: string;
  switchRole: string;
  pcOrPrincipal: string;
  switchToPc = 'SWITCH TO 60403 - PC';
  switchToPrincipal = 'SWITCH TO 60403 - PRINCIPAL';
  adminRole: string;
  unsaved = false;
  navigateTopage = '';
  newMessages: number;
  displayMessageCount: string;
  localI = 0

  // added for qa / dev
  baseUrl = '';
  functionKey = '';
  notificationArray: any[] = [];

  // ForgeRock 
  isAuthenticated: Observable<boolean>;
  isDoneLoading: Observable<boolean>;
  canActivateProtectedRoutes: Observable<boolean>;

  // idle variables
  lastPinged: Date = null;
  sasTokenResponse: any = '';
  fileDownloadError: boolean = false;
  IE: boolean = false // Check browser is IE

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if (this.appService.idleStart) {
      this.reset();
      this.closeModalIdle();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.fitContentDiv();
  }

  @ViewChild('side_full', { read: ElementRef }) side_full: ElementRef;
  @ViewChild('Corp_sidefull', { read: ElementRef }) Corp_sidefull: ElementRef;
  @ViewChild('Admin_sidefull', { read: ElementRef }) Admin_sidefull: ElementRef;
  @ViewChild('Billing_sidefull', { read: ElementRef }) Billing_sidefull: ElementRef;
  @ViewChild('ASE_sidefull', { read: ElementRef }) ASE_sidefull: ElementRef;
  @ViewChild('Job_sidefull', { read: ElementRef }) Job_sidefull: ElementRef;


  constructor(private router: Router, public appService: CommonService, private dashboardService: DashboardService,
    private loginService: LoginService, private notificationService: NotificationService, private joyrideService: JoyrideService,
    private idle: Idle, private keepalive: Keepalive, public enrollService: EnrollService, private _location: Location,
    private authService: AuthService) {

    // ===============================ForgeRock Implementation S==================================
    this.isAuthenticated = this.authService.isAuthenticated$;
    this.isDoneLoading = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes = this.authService.canActivateProtectedRoutes$;
    this.authService.runInitialLoginSequence();

    this.idle.setIdle(1800);
    this.idle.setTimeout(100);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => { this.reset(); this.closeModalIdle() });
    this.idle.onTimeout.subscribe(() => {
      this.appService.timedOut = true; this.appService.idleState = `Timed Out`;
      this.logout();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.appService.idleState = `You have gone idle`;
      $("#idleModal").removeClass('hide_block');
      $("#idleModal").addClass('display_block');
      $("#idleModal").show("slow");
      this.appService.idleStart = true;
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.appService.idleState = `You are idle. Do something!
      You will get logged off in ${countdown} seconds`
    })
    this.keepalive.interval(5);
    this.reset();
  }

  changeAreaCode(code) {
    this.appService.setAreaCode(code);
    console.log('App Code check :', this.appService.selectedRegion);
    this.router.navigate(['/switchAccount']);
    setTimeout(() => {
      this.router.navigate(['/corpDashboard']);
    }, 50);

  }

  ngOnDestroy() {
    localStorage.clear();
  }

  reset() {
    this.idle.watch();
    this.appService.idleState = 'Started';
    this.appService.timedOut = false;
    this.appService.idleStart = false;
  }

  closeModalIdle() {
    $("#idleModal").removeClass('display_block');
    $("#idleModal").addClass('hide_block');
    $("#idleModal").show("slow");
  }

  getCategory() {
    // for separating different categories for notification.
    this.appService.category3 = [];
    this.appService.category4 = [];
    this.appService.category5 = [];
    this.appService.isCategoryFunctionCalled = true;
    this.loginService.getUserDetailList().subscribe((result: any) => {
      this.appService.accessControlDetails = result.UserDetails.Result;
      for (let i = 0; i < this.appService.accessControlDetails.length; i++) {
        if (this.appService.accessControlDetails[i].isAdmin == "Y") {
          this.appService.category5.push(this.appService.accessControlDetails[i]);
        }
        if (this.appService.accessControlDetails[i].isLexusCollegeAdmin == "Y") {
          this.appService.category4.push(this.appService.accessControlDetails[i]);
        }
        if (this.appService.accessControlDetails[i].isLexusUser == "Y") {
          this.appService.category3.push(this.appService.accessControlDetails[i]);
        }
        if (this.appService.accessControlDetails[i].isAreaAdmin == "Y") {
          this.appService.category4.push(this.appService.accessControlDetails[i]);
        }
      }
    });
  }

  getCategoryOneandTwo(dealerCode) {
    this.appService.category1 = [];
    this.appService.category2 = [];
    this.appService.category6 = [];
    this.appService.category7 = [];
    this.loginService.getUserDetailListWithSpinID(this.appService.selectedRegion).subscribe((result: any) => {
      // this.appService.staffMasterDealerDetails = result.UserDetails.Result;
      this.appService.staffMasterDealerDetails = []
      for (let i = 0; i < result.UserDetails.Result.length; i++) {
        if (result.UserDetails.Result[i].dealerCode == dealerCode) {
          this.appService.staffMasterDealerDetails.push(result.UserDetails.Result[i]);
        }
      }
      for (let i = 0; i < this.appService.staffMasterDealerDetails.length; i++) {
        if (this.appService.staffMasterDealerDetails[i].isPc.toUpperCase() == 'TRUE') {
          this.appService.category1.push(this.appService.staffMasterDealerDetails[i]);
        } else if (this.appService.staffMasterDealerDetails[i].jobDescription == 'DEALER PRINCIPAL') {
          this.appService.category2.push(this.appService.staffMasterDealerDetails[i]);
        } else if (this.appService.staffMasterDealerDetails[i].jobDescription == 'GENERAL MANAGER') {
          this.appService.category6.push(this.appService.staffMasterDealerDetails[i]);
        } else if (this.appService.staffMasterDealerDetails[i].jobDescription == 'CONTROLLER') {
          this.appService.category7.push(this.appService.staffMasterDealerDetails[i]);
        }
      }
    });
  }

  getAlluserList() {
    this.appService.category1 = [];
    this.appService.category2 = [];
    this.appService.staffMasterDealerDetails = [];
    this.loginService.getUserDetailListWithSpinID(this.appService.selectedRegion).subscribe((result: any) => {
      for (let i = 0; i < result.UserDetails.Result.length; i++) {
        this.appService.staffMasterDealerDetails.push(result.UserDetails.Result[i]);
      }
    });
  }

  getUnreadNotifications() {
    this.displayArray = [];
    var senderId = this.appService.workdayId;
    if (this.appService.userType.indexOf('Dealer') != -1) {
      senderId = this.appService.spinId;
    }
    this.notificationService.getUnreadNotifications(senderId).subscribe((result: any) => {
      this.displayArray = result.NotificationDetails.NotificationDetail;
      for (let i = 0; i < this.displayArray.length; i++) {
        this.displayArray[i].Notification = this.displayArray[i].MSG_SUMMARY;
        this.displayArray[i].action = this.displayArray[i].MSG_TYPE;
        this.displayArray[i].status = this.displayArray[i].IS_READ;
        this.displayArray[i].date = this.displayArray[i].LST_UPDT_DT;
        if (this.displayArray[i].MSG_TYPE == "Info") {
          this.displayArray[i].action = "View"
        }
      }

      this.displayArray.sort(function (a, b) {
        var sortA = new Date(a.date);
        var sortB = new Date(b.date);
        var compA: any; var compB: any; compA = sortA; compB = sortB;
        return compB - compA
      });
      // this.displayArray = this.notificationArray;
      // { Notification: "RON GREENE sent you a email,ASSOCIATE ENROLLMENT FORM", action: "view", status: "unRead", date: "11/22/2018" },
      this.newMessages = this.displayArray.length;
      if (this.newMessages > 99) {
        this.displayMessageCount = '...';
      } else {
        this.displayMessageCount = '' + this.newMessages + '';
      }
    });
  }

  ngOnInit() {
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident/.test(ua);

    if (isIE) {
      this.IE = true;
    }
    this.appService.showSpinnerAuth = true;
    this.appService.corpUser = false;
    this.appService.adminAccount = false;
    this.appService.unauthorizedUser = false;
    this.appService.hideSideFull = true;
    this.appService.mainContent = true;
    this.appService.baseUrl = environment.baseUrl;
    this.appService.functionKey = environment.functionKey;
    this.appService.sendMail = environment.sendMail;
    this.appService.idleState = `Not Started`;
    this.appService.timedOut = false;
    this.appService.idleStart = false;
    this.displayArray = [];
    this.displayArray = this.notificationArray;
    this.newMessages = this.displayArray.length;
    if (this.newMessages > 99) {
      this.displayMessageCount = '...';
    } else {
      this.displayMessageCount = '' + this.newMessages + '';
    }
    this.unsaved = this.appService.unsavedChanges;
    if (window.location.pathname == '/unauthorized') {
      this.appService.unauthorizedUser = true;
    } else {
      this.appService.unauthorizedUser = false;
    }
  }

  fitContentDiv() {
    if (window.innerWidth >= 768) {
      this.appService.hideSideFull = true;
      if (!this.appService.corpUser && !this.appService.adminAccount) {
        //this.side_full != undefined ? this.side_full.nativeElement.style.minHeight = (window.innerHeight - 580) + 'px' : '';
        if (this.side_full != undefined) {
          this.side_full.nativeElement.style.minHeight = (window.innerHeight - 580) + 'px';
        }
      } else if (this.appService.corpUser && !this.appService.adminAccount) {
        //this.Corp_sidefull != undefined ? this.Corp_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px' : '';
        if (this.Corp_sidefull != undefined) {
          this.Corp_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px';
        }
      } else if (this.appService.adminAccount) {
        //this.Admin_sidefull != undefined ? this.Admin_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px' : '';
        if (this.Admin_sidefull != undefined) {
          this.Admin_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px';
        }
      } else if (this.appService.billing) {
        //this.Admin_sidefull != undefined ? this.Admin_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px' : '';
        if (this.Billing_sidefull != undefined) {
          this.Billing_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px';
        }
      } else if (this.appService.ASE) {
        //this.Admin_sidefull != undefined ? this.Admin_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px' : '';
        if (this.ASE_sidefull != undefined) {
          this.ASE_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px';
        }
      } else if (this.appService.JobUser) {
        //this.Admin_sidefull != undefined ? this.Admin_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px' : '';
        if (this.Job_sidefull != undefined) {
          this.Job_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px';
        }
      }
    } else {
      this.appService.hideSideFull = false;
    }
  }

  // capitalize(s) {
  //   return s
  //     .toLowerCase()
  //     .split(' ')
  //     .map(function (word) {
  //       return word[0].toUpperCase() + word.substr(1);
  //     })
  //     .join(' ');
  // }

  loginHandler(event) {
    this.userName = '';
    this.appService.role = '';
    // console.log('from Login', event);
    this.appService.LoggedInUser = event;
    this.userName = this.appService.LoggedInUser.userName;
    this.appService.role = this.appService.LoggedInUser.role;
    //this.appService.dealerCode = this.appService.LoggedInUser.dealerCode;
    this.appService.workdayId = this.appService.LoggedInUser.workdayId;
    this.appService.idOfLoggedUser(this.appService.LoggedInUser.spinId);
    // console.log("spinId", this.appService.LoggedInUser.spinId);
    if (this.appService.role == 'Lexus College') {
      this.appService.setLexusCollegeAdminUserFromLogin();
    }
    if (this.appService.role == 'Area Admin') {
      this.appService.setAreaAdminUserFromLogin();
    }
    if (this.appService.role == 'Lexus College' || this.appService.role == 'Lexus Associate' || this.appService.role == 'Area Admin') {
      this.appService.corpUser = true;
      this.appService.adminAccount = false;
      this.router.navigate(['/corpDashboard']);
    } else if (this.appService.role == 'Dealer Principal' || this.appService.LoggedInUser.PcUser == true || this.appService.role.toUpperCase() == 'GENERAL MANAGER' || this.appService.role.toUpperCase() == 'CONTROLLER') {
      this.appService.corpUser = false;
      this.appService.adminAccount = false;
      this.appService.dc = this.appService.dealerCode;
      this.router.navigate(['/dashboard']);
    } else if (this.appService.role == 'Admin') {
      this.appService.adminAccount = true;
      this.router.navigate(['/adminReport']);
    }
    if (this.appService.LoggedInUser.isPrincipal == true) {
      this.appService.isPrincipal = true;
    }
    this.appService.mainContent = false;
    // this.getAlluserList();
    //this.getUnreadNotifications();(Need to check)
    //this.loginComponent.showUserList();
    // this.startTour();
    setInterval(
      function () {
        //   this.getUnreadNotifications();(Need to check)
        console.log(" loginHandler  Every 5 minutes ")
      }.bind(this),
      300000
    );
  }

  startTour() {
    // update last login time here only if dealer is enrolled. if dealer is un enrolled and clicks on help, should not update.
    if (this.appService.userType.indexOf('Dealer') != -1) {
      if (this.appService.showHelp == '1' && !this.appService.dealershipEnrollment) {
        this.loginService.updateShowFeatureWTSM(this.appService.loginId, '0').subscribe((res: any) => {
          console.log('updated show feature to false staff master')
        })
      }
    }
    else if (this.appService.userType.indexOf('Dealer') == -1) {
      if (this.appService.showHelp == '1') {
        this.loginService.updateShowFeatureWTAC(this.appService.loginId, '0').subscribe((res: any) => {
          console.log('updated show feature to false access ctrl')
        })
      }
    }

    $('html,body').animate({ scrollTop: 0 }, "smooth");
    if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('corpDashboard') != -1) {
      this.joyrideService.startTour(
        {
          steps: ['title', 'firstStep', 'secondStep', 'thirdStep', 'fourthStep', 'fifthStep', 'sixthStep@corpDashboard',
            'seventhStep@corpDashboard', 'eighthStep@corpDashboard', 'ninethStep@corpDashboard', 'tenthStep@corpDashboard', 'thirteenthStep']
        }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('dashboard') != -1 && !this.appService.dealershipEnrollment) {
      this.joyrideService.startTour(
        {
          steps: ['title', 'secondStep', 'thirdStep', 'fourthStep', 'fifthStep', 'sixthStep',
            'seventhStep@dashboard', 'eighthStep@dashboard', 'ninethStep@dashboard', 'twelvethStep@dashboard', 'tenthStep@dashboard',
            'eleventhStep@dashboard', 'thirteenthStep']
        }
      )
    }

    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('dashboard') != -1 && this.appService.dealershipEnrollment) {
      this.joyrideService.startTour(
        {
          steps: ['dealerUnenrolledStep']
        }
      )
    }

    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('holdResumePayments') != -1 && this.appService.pcHoldPage == '' && !this.appService.isPcHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['firstHoldStep@holdResumePayments', 'secondHoldStep@holdResumePayments', 'thirdHoldStep@holdResumePayments'] }
      )
    }
    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('corpHoldResume') != -1 && this.appService.corpHoldPage == '' && !this.appService.isPcHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['firstHoldStep@corpHoldResume', 'secondHoldStep@corpHoldResume', 'thirdHoldStep@corpHoldResume'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('holdResumePayments') != -1 && this.appService.pcHoldPage != '' && !this.appService.isPcHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['fourthHoldStep@holdResumePayments', 'fifthHoldStep@holdResumePayments'] }
      )
    }
    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('corpHoldResume') != -1 && this.appService.corpHoldPage != '' && !this.appService.isCorpHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['fourthHoldStep@corpHoldResume', 'fifthHoldStep@corpHoldResume'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('holdResumePayments') != -1 && this.appService.pcHoldPage != '' && this.appService.isPcHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['sixthStep@holdResumePayments', 'seventhStep@holdResumePayments', 'eighthStep@holdResumePayments'] }
      )
    }

    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('corpHoldResume') != -1 && this.appService.corpHoldPage != '' && this.appService.isCorpHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['sixthStep@corpHoldResume', 'seventhStep@corpHoldResume', 'eighthStep@corpHoldResume'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('enroll') != -1 && this.appService.enrollmentCurrentStatus == undefined) {
      this.joyrideService.startTour(
        { steps: ['firstEnrollStep@enroll'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('enroll') != -1 && this.appService.enrollmentCurrentStatus == 'REVIEW INFO') {
      this.appService.statusDetails = 'Review the details of the associate before creating an enrollment.';
      this.joyrideService.startTour(
        { steps: ['secondEnrollStep@enroll', 'thirdEnrollStep@enroll'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('enroll') != -1 && (this.appService.enrollmentCurrentStatus == 'PENDING WITH ASSOCIATE' || this.appService.enrollmentCurrentStatus == 'PENDING WITH PRINCIPAL' || this.appService.enrollmentCurrentStatus == 'ENROLLED')) {
      if (this.appService.enrollmentCurrentStatus == 'ENROLLED') {
        this.joyrideService.startTour(
          { steps: ['secondEnrollStep@enroll', 'fourthEnrollStep@enroll', 'seventhStep@enroll'] }
        )
      }
      else {
        this.joyrideService.startTour(
          { steps: ['secondEnrollStep@enroll', 'fourthEnrollStep@enroll', 'fifthEnrollStep@enroll'] }
        )
      }
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('enroll') != -1 && this.appService.enrollmentCurrentStatus == 'DRAFT') {
      this.appService.statusDetails = 'Draft';
      this.joyrideService.startTour(
        { steps: ['secondEnrollStep@enroll', 'fourthEnrollStep@enroll', 'fifthEnrollStep@enroll', 'sixthStep@enroll'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('paymentHistory') != -1) {
      this.joyrideService.startTour(
        { steps: ['stepOne@paymentHistory'] }
      )
    }

    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('reviewPayment') != -1) {
      this.joyrideService.startTour(
        { steps: ['firstReviewStep@reviewPayment', 'secondReviewStep@reviewPayment', 'thirdReviewStep@reviewPayment', 'fourthReviewStep@reviewPayment', 'fifthReviewStep@reviewPayment'] }
      )
    }

    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('sendNotification') != -1) {
      this.joyrideService.startTour(
        { steps: ['sendNotifOne@sendNotification', 'sendNotifTwo@sendNotification'] }
      )
    }

    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('reports') != -1) {
      this.joyrideService.startTour(
        { steps: ['filterData@reports', 'download@reports'] }
      )
    }

  }

  hover() {
    $('#Bellbadge').addClass('badgeHover');
  }

  unHover() {
    $('#Bellbadge').removeClass('badgeHover');
  }

  displayNotification() {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  clearNotification(index) {
    this.notificationService.markAsRead(this.displayArray[index].NOTIFY_DET_ID).subscribe((result: any) => {
      this.displayArray.splice(index, 1);
      this.newMessages = this.displayArray.length;
      if (this.newMessages > 99) {
        this.displayMessageCount = '...';
      } else {
        this.displayMessageCount = '' + this.newMessages + '';
      }
    })
  }

  deleteAll() {
    for (let i = 0; i < this.displayArray.length; i++) {
      this.notificationService.markAsRead(this.displayArray[i].NOTIFY_DET_ID).subscribe((result: any) => {
        this.displayArray.splice(0, this.displayArray.length);
        this.newMessages = 0;
        this.displayMessageCount = '0';
      });
    }
  }

  checkIfSaved() {
    this.unsaved = this.appService.unsavedChanges;
    console.log(this.unsaved);
  }

  saveBeforeNavigatingFn(text) {
    console.log(text);
    if (text == 'dashboard') {
      this.navigateTopage = '/corpDashboard';
    }
    else if (text == 'holdResume') {
      this.navigateTopage = '/corpHoldResume';
    }
    else if (text == 'debits') {
      this.navigateTopage = '/debitPayment';
    }
    else if (text == 'reviewpay') {
      this.navigateTopage = '/reviewPayment';
    }
    else if (text == 'reports') {
      this.navigateTopage = '/reports';
    }
    else if (text == 'switch') {
      this.navigateTopage = '/switchAccount';
    }
    else if (text == 'sendnote') {
      this.navigateTopage = '/sendNotification';
    }
    if (!this.appService.unsavedChanges) {
      if (text == 'dashboard') {
        this.router.navigate(['/corpDashboard']);
      }
      else if (text == 'holdResume') {
        this.router.navigate(['/corpHoldResume']);
      }
      else if (text == 'debits') {
        this.router.navigate(['/debitPayment']);
      }
      else if (text == 'reviewpay') {
        this.router.navigate(['/reviewPayment']);
      }
      else if (text == 'reports') {
        this.router.navigate(['/reports']);
      }
      else if (text == 'switch') {
        this.router.navigate(['/switchAccount']);
      }
      else if (text == 'sendnote') {
        this.router.navigate(['/sendNotification']);
      }
    }
    else {
      $("#saveModalbeforeExit").removeClass('hide_block');
      $("#saveModalbeforeExit").addClass('display_block');
      $("#saveModalbeforeExit").show("slow");
      console.log('unsaved');
    }
    console.log(this.appService.unsavedChanges);
  }

  dismissModal(text) {
    if (text == 'No') {
      this.appService.unsavedChanges = false;
      console.log(this.navigateTopage)
      setTimeout(
        function () {
          $("#saveModalbeforeExit").removeClass('display_block');
          $("#saveModalbeforeExit").addClass('hide_block');
          $("#saveModalbeforeExit").show("slow");
          this.router.navigate([this.navigateTopage]);
        }.bind(this),
        1
      );
    }
    if (text == 'Yes') {
      $("#saveModalbeforeExit").removeClass('display_block');
      $("#saveModalbeforeExit").addClass('hide_block');
      $("#saveModalbeforeExit").show("slow");
      this.appService.unsavedChanges = false;
      this.router.navigate([this.navigateTopage]);
    }
  }

  async SasToken(container) {
    this.sasTokenResponse = '';
    await this.dashboardService.getSasToken(container).toPromise().then(async (response: any) => {
      this.sasTokenResponse = response.SasToken.Result;
      console.log('Sas', this.sasTokenResponse);

    }, err => {
      console.log('Error while trying to get Sas Token ::' + err);
    });
  }

  async downloadDealerEnrollmentForm() {
    if (this.appService.dealershipFormName != null && this.appService.dealershipFormName != '') {
      await this.dashboardService.getFileLink('dealer', this.appService.dealershipFormName).toPromise().then(async (response: any) => {
        this.fileDownloadError = false;
        this.enrollService.azureFileDownload(response.File.Result).subscribe((response) => {
          var file = new Blob([response.content._body], { type: response.content._body.type });
          var downloadUrl = URL.createObjectURL(file);
          if (!this.IE) {
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = "" + this.appService.dealershipFormName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            window.navigator.msSaveOrOpenBlob(file, this.appService.dealershipFormName);
          }
        }, err => {
          this.openErrorModal();
        });
      }, err => {
        this.fileDownloadError = true;
      });
    } else {
      this.openErrorModal();
      this.fileDownloadError = true;
    }

  }

  closeErrorModal() {
    $("#errorDealerForm").removeClass('display_block');
    $("#errorDealerForm").addClass('hide_block');
    $("#errorDealerForm").show("slow");
  }

  openErrorModal() {
    $("#errorDealerForm").removeClass('hide_block');
    $("#errorDealerForm").addClass('display_block');
    $("#errorDealerForm").show("slow");
  }

  // logout() {
  //   localStorage.clear();
  //   window.sessionStorage.clear();
  //   this.authService.logout();
  //   window.location.href = 'https://login.microsoftonline.com/common/oauth2/logout';
  // }

  scrollUp() {
    $('html,body').animate({ scrollTop: 0 }, "smooth");
  }

  scrollDown() {
    $('html,body').animate({ scrollTop: document.body.scrollHeight }, "smooth");
  }

  scroll35() {
    $('html,body').animate({ scrollTop: document.body.scrollHeight / 5 }, "smooth");
  }

  //===================================ForgeRock Implementation=======================================
  login() { this.authService.login(); }
  logout() { this.authService.logout(); }
  refresh() { this.authService.refresh(); }
  reload() { window.location.reload(); }
  clearStorage() { sessionStorage.clear(); }

  logoutExternally() {
    window.open(this.authService.logoutUrl);
  }

  get hasValidToken() { return this.authService.hasValidToken(); }
  get accessToken() { return this.authService.accessToken; }
  get refreshToken() { return this.authService.refreshToken; }
  get identityClaims() { return this.authService.identityClaims; }
  get idToken() { return this.authService.idToken; }
}
