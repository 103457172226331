import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { CommonService } from '../service/common.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class ReviewPaymentService {

  public getPaymentRecordUrl = 'getPaymentDetails';
  // getPaymentDetails2SNxELkZ00xEKsaSs9ZYF9ITgjODufNKiEzp02vYuanX7Gt4DHX3uw==
  public approveURL = 'ApprovePayment';
  public holdInsertUrl = 'UpdateOverrideHold';
  public saveURL = 'SaveStatusPayment';
  public adminSearchUrl = 'HoldPaymentAdminSearch';
  public paymentHistoryUrl = 'GetPaymentHistory';
  public manualPaymentUrl = 'InsertManualPayment';
  public getManualPaymentUrl = 'ManualPaymentList';
  public manualIsActiveUrl = 'ManualPaymentIsActive';
  public manualIsActiveLeaseUrl = 'ManualPaymentActiveLease';
  public twentyFourUrl = 'ManualPayment24MonthValidation';
  public twelveUrl = 'ManualPaymentFirstPaymentMonthValidation';
  public maximumpayUrl = 'ManualPaymentMoreThan12PaymentValidation';
  public autoEnrollUrl = 'ManualPaymentAutoEnrollment';
  public manualPaymentDuplicationUrl = 'ManualPaymentDuplicationCheck';
  public dealerCodeValidaionUrl = 'ManualPaymentDealerCodeValidation';
  public freezeUrl = 'ChangeFreezeStatus';
  public checkIfFrozenUrl = 'GetFreezeStatus';
  public paymentListUrl = 'getPaymentListDetails';
  public manualPayMaritzValidationUrl = 'ManualPaymentMartizValidation';
  public manualPayCertFlagValidationUrl = 'ManualPaymentCertFlagValidation';
  public updateRenewalURL = 'UpdateRenewalDate';
  public getPaymentNumberURL = 'DynamicLfsPayCdGeneration';
  public jobCodeUrl = 'ManualPaymentJobCodeValidation';
  public LFSCodeUrl = 'GetLFSCode';
  public firstLFSCodeUrl = 'ManualPaymentLFSPayYear';
  public manualPayFlagUrl = 'ManualPaymentFlag';
  public updateManualPayFlag = 'ManualPaymentFlagUpdate';
  public certificationYearURL = 'CertificationYear';
  public bulkApprovePayURL = 'BulkApproveSavePayment';
  public bulkInsertPayURL = 'BulkApprovalInsertPayment';
  public bulkUpdateStatusPayURL = 'BulkApprovalUpdateStatusNumber';
  public bulkUpdateCheckURL = 'BulkApprovalUpdateIsChecked';
  public bulkUpdateRenewalDateURL = 'BulkUpdateRenewalDate';
  public bulkSaveStatusURL = 'BulkSaveIsChecked';
  public pcURL = 'GetPcDetails';
  public dealercodeCheckURL = 'CheckDealercodeValidation';
  public postToSapTrigURL = 'GetSaveApprovedPayments';

  constructor(private _http: Http, private http: Http, private appService: CommonService) { }
  getManualPayment(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getManualPaymentUrl + this.appService.functionKey;
    return this.http.get(url, options)
      .pipe(map((response: Response) => <any[]>response.json())).catch(this.handleError);
  }
  
  isFrozen(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.checkIfFrozenUrl + this.appService.functionKey;
    return this.http.get(url, options)
      .pipe(map((response: Response) => <any[]>response.json())).catch(this.handleError);
  }

  manualIsActive(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.manualIsActiveUrl + this.appService.functionKey;
    let IsoTodayDate = new Date().toISOString().split('T');
    let time = IsoTodayDate[1].split('Z')[0];
    let paytime = select.paymentDate + ' ' + time;
    let body = {
      'spinId': select.spinId,
      'dealerCode': select.dealerCode,
      'paymentDate': paytime
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getPaymentList(spinId, enrollmentNo) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.paymentListUrl + this.appService.functionKey;
    const body = {
      'spinId': spinId,
      'enrollmentNo': enrollmentNo
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  manualIsActiveLease(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.manualIsActiveLeaseUrl + this.appService.functionKey;
    // let IsoTodayDate = new Date().toISOString().split('T');
    let IsoTodayDate = new Date().toLocaleDateString('en-US');
    let time = IsoTodayDate[1].split('Z')[0];
    let paytime = select.paymentDate;// + ' ' + time;
    let body = {
      'uid': select.spinId.substring(3),
      'paymentDate': paytime
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  twentyfourMonthValidation(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.twentyFourUrl + this.appService.functionKey;
    const months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const pt = months.indexOf(select.PaymentMonth);
    const paymenttime = new Date(select.PaymentYr, pt);
    console.log('TIMEEEE', paymenttime);
    let body = {
      'spinId': select.spinId,
      'enrollmentNo': select.enrollmentNo,
      'paymentTime': paymenttime
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  twelveMonthValidation(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.twelveUrl + this.appService.functionKey;
    const months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const pt = months.indexOf(select.PaymentMonth);
    const paymenttime = new Date(select.PaymentYr, pt);
    let body = {
      'spinId': select.spinId,
      'enrollmentNo': select.enrollmentNo,
      'paymentTime': paymenttime
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  maximumPayments(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.maximumpayUrl + this.appService.functionKey;
    let body = {
      'spinId': select.spinId,
      'enrollmentNo': select.enrollmentNo,
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  autoEnrollment(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.autoEnrollUrl + this.appService.functionKey;
    let body = {
      'spinId': select.spinId,
      'enrollmentNo': select.enrollmentNo,
      'certLevel': select.certlevel,
      'certYear': select.lfsPayYear,
      'updateFlag': select.updateFlag
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  manuaIsPaymentDuplicate(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.manualPaymentDuplicationUrl + this.appService.functionKey;
    const months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    let mon = months.indexOf(select.PaymentMonth) + 1;
    mon = mon + '';
    if (mon.length == 1) {
      mon = '0' + mon;
    }
    select.PaymentNumber = select.PaymentNumber + '';
    if (select.PaymentNumber.length == 1) {
      select.PaymentNumber = '0' + select.PaymentNumber;
    }
    let body = {
      "paymentTime": select.PaymentMonth + '-' + select.PaymentYr,
      "spinId": select.spinId,
      "enrollmentNo": select.enrollmentNo,
      // 'payduecd': select.PaymentYr + '-' + select.PaymentNumber
      'payduecd': select.PaymentYr + '-' + mon
    };
    console.log(body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  dealerCodeValidation(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.dealerCodeValidaionUrl + this.appService.functionKey;
    console.log(select);
    let body = {
      "uid": select.spinId,
      "enrollmentNo": select.enrollmentNo,
      // "dealerCode" : select.dealerCode
    };
    console.log(body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  manualPaymentMartizValidation(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.manualPayMaritzValidationUrl + this.appService.functionKey;
    let IsoTodayDate = new Date().toISOString().split('T');
    let time = IsoTodayDate[1].split('Z')[0];
    let paytime = select.paymentDate + ' ' + time;
    let body = {
      'uid': select.spinId.substring(3),
      'paymentDate': paytime
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  manualPaymentCertFlagValidation(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.manualPayCertFlagValidationUrl + this.appService.functionKey;
    let body = {
      'enrollmentno': select.enrollmentNo,
      'certlevel': select.certlevel
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getEnrolledUserSearch(select,region) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.adminSearchUrl + this.appService.functionKey;
    var searchWord = select.split(' ');
    var searchParam1 = '';
    if (searchWord[1] != null)
      searchParam1 = searchWord[1]
    let body = {
      'searchParam': searchWord[0],
      'searchParam1': searchParam1,
      'region': region
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getPaymentRecord(select,region) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getPaymentRecordUrl + this.appService.functionKey;
    const body = {
      'paymentType': select,
      'region': region
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  GetPcDetails(dealerCode: string) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.pcURL + this.appService.functionKey;
    const body = {
      'dealerCode': dealerCode
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  
  getPaymentHistory(spinId, enrollmentNo) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.paymentHistoryUrl + this.appService.functionKey;
    const body = {
      'spinId': spinId,
      'enrollmentNo': enrollmentNo
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  dealercodeCheck(dealerCode:string){
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.dealercodeCheckURL + this.appService.functionKey;
    const body = {
      'dealerCode': dealerCode
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  approvePayments(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.approveURL + this.appService.functionKey;
    select.paymentnumber = select.paymentnumber + '';
    if (select.paymentnumber.length == 1) {
      select.paymentnumber = '0' + select.paymentnumber;
    }
    var status = '', lfsPayCd = '';
    if (select.paymentType == '1') {
      status = '4'
    } else if (select.paymentType == '2') {
      status = '13'
    } else if (select.paymentType == '3') {
      status = '10'
    }

    if (select.LFSPAYCD != '') {
      lfsPayCd = select.LFSPAYCD.slice(0, 4);
    } else {
      lfsPayCd = '';
    }
    const body = {
      'spinid': select.spinid,
      'LFSPAYCD': lfsPayCd + '-' + select.paymentnumber,
      'OLDLFSPAYCD': select.LFSPAYCD,
      'PAYMENTNUMBER': select.paymentnumber,
      'PAYMENTTIME': select.paymenttime,
      'DEALERCODE': select.dealercode,
      'LST_UPDT_BY': this.appService.loggedInUserName, // associatename ?
      'status': status,
      'EnrollmentNo': select.enrollment_no,
      'PaymentCategory': select.paymentType,
      'comment': select.Comments
    };
    console.log(body);
    if (lfsPayCd != '') {
      return this.http.post(url, body, options)
        .map((response: Response) => <any[]>response.json()).catch(this.handleError);
    }
  }

  bulkApprovePayments(ids) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkApprovePayURL + this.appService.functionKey;
    const body = {
      "payId": ids
    };
    console.log('PayID Service ', body.payId);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  bulkSaveStatusUnCheck(ids) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkSaveStatusURL + this.appService.functionKey;
    const body = {
      "payId": ids
    };
    console.log('PayID Service Checked', body.payId);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  bulkInsertPayments(payId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkInsertPayURL + this.appService.functionKey;
    const body = {
      "payId": payId
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  bulkUpdateStatus(payId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkUpdateStatusPayURL + this.appService.functionKey;
    const body = {
      "payId": payId
    };
    return this.http.post(url, body , options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  bulkUpdateChecked(payId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkUpdateCheckURL + this.appService.functionKey;
    const body = {
      "payId": payId
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  bulkUpdateRenewalDate(payId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkUpdateRenewalDateURL + this.appService.functionKey;
    const body = {
      "payId": payId
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  firstTimePayment(select, renewal) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.updateRenewalURL + this.appService.functionKey;
    select.paymentnumber = select.paymentnumber + '';
    if (select.paymentnumber.length == 1) {
      select.paymentnumber = '0' + select.paymentnumber;
    }
    console.log(select);
    if (renewal) {
      let body = {
        'spinid': select.spinId,
        // 'LFSPAYCD': select.LFSPAYCD,
        //'PAYMENTNUMBER': select.paymentnumber,
        'PAYMENTTIME': select.renewalTime,
        // 'DEALERCODE': select.dealercode,
        'EnrollmentNo': select.enrollmentNo,
      };
      console.log('Renewal ::' + body);
      return this.http.post(url, body, options)
        .map((response: Response) => <any[]>response.json()).catch(this.handleError);
    } else {
      let body = {
        'spinid': select.spinid,
        // 'LFSPAYCD': select.LFSPAYCD,
        //'PAYMENTNUMBER': select.paymentnumber,
        'PAYMENTTIME': select.paymenttime,
        // 'DEALERCODE': select.dealercode,
        'EnrollmentNo': select.enrollment_no,
      };
      console.log(body);
      return this.http.post(url, body, options)
        .map((response: Response) => <any[]>response.json()).catch(this.handleError);
    }
  }

  getPaymentNumber(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getPaymentNumberURL + this.appService.functionKey;
    select.paymentnumber = select.paymentnumber + '';
    if (select.paymentnumber.length == 1) {
      select.paymentnumber = '0' + select.paymentnumber;
    }
    const body = {
      'uid': select.SPIN_ID.substring(3)
    };
    console.log(body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  approvePaymentsHold(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.holdInsertUrl + this.appService.functionKey;
    const months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const selectMonth = select.paymenttime.substring(0, 3).toUpperCase();
    const selectyear = parseInt(select.paymenttime.substring(4, 8));
    const paymenttime = new Date(selectyear, months.indexOf(selectMonth));
    console.log(select.paymenttime);
    const body = {
      'paymentHoldId': parseInt(select.payment_hold_id),
      'startPeriod': select.startPeriodForapproval,
      'endPeriod': select.endPeriodForapproval,
      'paymenttime': paymenttime,
    };
    console.log(body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  savePayments(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.saveURL + this.appService.functionKey;
    console.log(select);
    var ischecked = 0;
    if (select.check == false) {
      ischecked = 0;
    }
    else {
      ischecked = 1;
    }
    const body = {
      'Checked': ischecked,
      'pay_id': select.pay_id,
      'comment': select.Comments
    };
    console.log(body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getLFSCode(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.LFSCodeUrl + this.appService.functionKey;

    const body = {
      'spinId': select.SPINID,
    };
    console.log(body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getFirstLFSCode(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.firstLFSCodeUrl + this.appService.functionKey;
    let IsoTodayDate = new Date().toISOString().split('T');
    let time = IsoTodayDate[1].split('Z')[0];
    let paytime = select.paymentDate + ' ' + time;
    const body = {
      'uid': select.SPINID.substring(3),
      'paymentDate': paytime
    };
    console.log(body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  addManualPayment(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.manualPaymentUrl + this.appService.functionKey;
    const months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    select.PaymentNumber = select.PaymentNumber + '';
    select.LFSPayNo = select.LFSPayNo + '';
    if (select.PaymentNumber.length == 1) {
      select.PaymentNumber = '0' + select.PaymentNumber;
    }
    if (select.PaymentMonth.length == 1) {
      select.PaymentMonth = months[select.PaymentMonth - 1];
    }

    if (select.LFSPayNo.length == 1) {
      select.LFSPayNo = '0' + select.LFSPayNo;
    }

    if (select.vin === null || select.vin === undefined || select.ctype === null || select.ctype === undefined) {
      select.vin = ''; select.ctype = '';
    }

    const body = {
      "paymentnumber": select.LFSPayNo,
      "paymenttime": select.PaymentMonth + '-' + select.PaymentYr,
      "associatename": select.associatename,
      "spinid": select.spinId,
      "dealercode": select.dealerCode,
      "lfsPayCd": select.LFSPayYear + '-' + select.LFSPayNo,
      "comments": select.remarks,
      "enrollment_no": select.enrollmentNo,
      "vin": select.vin,
      "ctype": select.ctype
    };
    console.log(body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  freeze() {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.freezeUrl + this.appService.functionKey;
    const body = {
      'status': '0'
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  manualPaymentjobCodeValidation(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.jobCodeUrl + this.appService.functionKey;
    let body = {
      'spinId': select.spinId,
      'dealerCode': select.dealerCode
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  manualPayFlag(enrollmentNo, certLevel) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.manualPayFlagUrl + this.appService.functionKey;
    const body = {
      'enrollmentNo': enrollmentNo,
      'certLevel': certLevel
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getCertYear(select) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.certificationYearURL + this.appService.functionKey;
    const body = {
      'uid': select.SPINID.substring(3),
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  manualPayFlagUpdate(M_S_SS_Flag, enrollmentNo) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.updateManualPayFlag + this.appService.functionKey;
    const body = {
      'enrollmentNo': enrollmentNo,
      'M_S_SS_Flag': M_S_SS_Flag
    }
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  postToSapTrig(dttm) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.postToSapTrigURL + this.appService.functionKey;
    const body = {
      "dttm": dttm
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Observable.throw(new Error(error.status));
  }
}
