import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enrollmentType'
})
export class EnrollmentTypePipe implements PipeTransform {
  transform(value: string): string {
    let type: string
    if (value == 'ME'){
      type = 'Mainframe';
    }
    if(value =='AE'){
      type = 'Auto';
    }
    if(value == 'IE'){
      type = 'Initial';
    }
    return type;
  }
}