import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../notification/notification.service'
import { CommonService } from '../service/common.service'
import { AppComponent } from '../app.component'
declare var $: any;
import { ReportService } from '../reports/reports.service';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SendNotificationComponent implements OnInit {
  selectedRole: any = '';
  roles = new FormControl('', [Validators.required]);
  getErrorMessage() {
    return this.roles.hasError('required') ? 'You must select a value' : '';
  }
  // checkIfPcChecked() {
  //   if(this.dealerCode.length > 0 && this.disableRoleArray[3]) {
  //     return  'You must select a value';
  //   } else {return ''}

  // }
  sendAs = new FormControl('', [Validators.required]);
  getErrorMessageSendAs() {
    return this.sendAs.hasError('required') ? 'You must select a value' : '';
  }
  dealerForm: FormGroup;
  roleList = ['Principal', 'GM', 'PC', 'Controller', 'Lexus College', 'Lexus Admin', 'IS Admin'];
  numberArray = [6, 5, 3, 4, 1, 2];
  sendAsList: string[] = ['Email', 'Notification'];
  sendTab = true;
  showError = false;
  categoryList = [];
  sendToPreview = "";
  successMessage = "";
  Success = false;
  filteredDealers = [];
  isLoading = true;
  dealerCode = "";
  showSpinner = false;
  dasboardTab = false;
  selectedRegion = '';
  isEditingDraft = false;
  isEditingLater = false;
  notify_id = ""
  lastEditedOn = "";
  sendOn = "";
  updateDate = false;

  // for view notifications
  searchkey: any = '';
  hideNotification: Boolean = true;
  todayNotifications: any = [];
  olderNotifications: any = [];
  yesterdayNotifications: any = [];
  iconShow: Boolean = false;
  displayArray: any = [];
  filteredNotification: any = [];
  notificationSubject = '';
  notificationDetails = '';
  notificationFrom = 'Lexus College';
  isSendLaterPreview = false;
  dealerCodeListfromArea = [];

  notificationArray: any[] = [
    { Notification: "RON GREENE sent you a email,ASSOCIATE ENROLLMENT FORM", action: "preview", status: "unRead", date: "11/22/2018" },
    {
      Notification: "LML maintainance", action: "preview", status: "unRead", date: new Date(),
      notificationContents: "We will be facing a shutdown of the LML application from Feb 30 2019 to Feb 31 2019 due to maintenance activities. You will not be able to use the application during this period. Please be informed."
    },
  ];
  msg: string;
  emailList = [];

  //SendLater Flag
  sendLaterFlag: boolean = false;
  emailChecked: boolean = false;
  NotificationChecked: boolean = true;
  todayDate = new Date();
  selectedSendDate: any = new Date();
  buttonName: any = '';
  SendButtonClickFlag: boolean = false;
  sendConfirmation: string = '';
  exceptionSelectedDealer: boolean;
  sendOption: string = '';
  staffMasterUserListResponse: any = [];
  masterDealerList: any = []

  @ViewChild('picker') picker;
  constructor(private fb: FormBuilder, private router: Router, private appService: CommonService,
    private reportService: ReportService, private notificationService: NotificationService,
    private appComponent: AppComponent, private loginService: LoginService) {
    if (this.searchkey = '') {
      this.hideNotification = true;
    }
    this.buttonName = 'SEND LATER';

    if(this.appService.isAreaAdmin == true){
      this.selectedRegion=this.appService.selectedRegion;
      // console.log('SendNoti Component Check 1 :',this.selectedRegion);
    }
  }

  ngOnInit() {
    console.log('clg admin send notif',this.appService.role);
    if (this.appService.role != 'Lexus College' && this.appService.role != 'Lexus Associate' && this.appService.role != 'Area Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.dealerForm = this.fb.group({
      userInput: null
    });
    this.getAllNotifications();
    if(this.appService.isAreaAdmin == true){this.changeAreaCode(this.appService.selectedRegion);}
    else{this.changeAreaCode('');}
    this.getStaffMasterList();
  }

  getAllNotifications() {
    this.displayArray = [];
    // for (let i = 0; i < this.notificationArray.length; i++) {
    //   this.displayArray.push(this.notificationArray[i]);
    // }
    // console.log(this.displayArray);
    // for (let i = 0; i < this.displayArray.length; i++) {
    // }
    // this.seperateNotifications(this.displayArray);
    this.notificationService.getSentNotification(this.appService.workdayId + "").subscribe((result: any) => {
      console.log(result);
      for (let i = 0; i < result.NotificationDetails.NotificationDetail.length; i++) {
        this.displayArray.push(result.NotificationDetails.NotificationDetail[i]);
      }
      console.log('Notification List=displayArray ', this.displayArray);
      for (let i = 0; i < this.displayArray.length; i++) {
        this.displayArray[i].Notification = this.displayArray[i].MSG_SUMMARY;
        this.displayArray[i].action = this.displayArray[i].MSG_TYPE;
        //this.displayArray[i].status = this.displayArray[i].IS_READ; 
        this.displayArray[i].date = this.displayArray[i].LST_UPDT_DT;
        if (this.displayArray[i].MSG_TYPE == "Sent") {
          this.displayArray[i].action = "View"
        }
        if (this.displayArray[i].MSG_TYPE == "Send Later") {
          this.displayArray[i].action = "View/Delete"
        }
        if (this.displayArray[i].MSG_TYPE == "Draft") {
          this.displayArray[i].action = "Edit/Delete"
        }
      }
      this.seperateNotifications(this.displayArray);
    });
  }

  hideIconFunction() {
    if (this.searchkey == '') {
      this.iconShow = false;
      this.hideNotification = true;
      this.seperateNotifications(this.displayArray);
    }
    else {
      this.iconShow = true;
    }
  }

  closeSpinSearch() {
    this.searchkey = '';
    this.hideNotification = true;
  }

  seperateNotifications(arr) {
    this.todayNotifications = [];
    this.olderNotifications = [];
    this.yesterdayNotifications = [];
    for (let i = 0; i < arr.length; i++) {
      const Ndate = new Date(arr[i].date).setHours(0, 0, 0, 0);
      const tdate = new Date().setHours(0, 0, 0, 0);

      const todaysdate = new Date().getDate();
      const todaysyear = new Date().getFullYear();
      const todaysmonth = new Date().getMonth();

      const Notifydate = new Date(arr[i].date).getDate();
      const Notifyyear = new Date(arr[i].date).getFullYear();
      const Notifymonth = new Date(arr[i].date).getMonth();

      if (todaysyear == Notifyyear) {
        if (todaysmonth == Notifymonth) {
          if (todaysdate - Notifydate == 1) {
            this.yesterdayNotifications.push(arr[i]);
          }
          else if (todaysdate == Notifydate) {
            this.todayNotifications.push(arr[i]);
          }
          else {
            this.olderNotifications.push(arr[i]);
          }
        }
        else {
          this.olderNotifications.push(arr[i]);
        }
      }
      else {
        this.olderNotifications.push(arr[i]);
      }
    }
    this.todayNotifications = this.todayNotifications.sort(function (a, b) {
      var sortA = new Date(a.date);
      var sortB = new Date(b.date);
      var compA: any; var compB: any; compA = sortA; compB = sortB;
      return compB - compA
    });
    this.yesterdayNotifications.sort(function (a, b) {
      var sortA = new Date(a.date);
      var sortB = new Date(b.date);
      var compA: any; var compB: any; compA = sortA; compB = sortB;
      return compB - compA
    });
    this.olderNotifications.sort(function (a, b) {
      var sortA = new Date(a.date);
      var sortB = new Date(b.date);
      var compA: any; var compB: any; compA = sortA; compB = sortB;
      return compB - compA
    });
  }
  // search for specific notification
  notifySearch() {
    this.filteredNotification = [];
    for (let i = 0; i < this.displayArray.length; i++) {
      this.msg = this.displayArray[i].Notification.toLowerCase();
      const a = this.searchkey.toLowerCase();
      const regex1 = RegExp(a);
      if (regex1.test(this.msg)) {
        this.filteredNotification.push(this.displayArray[i]);
      }
    }
    this.seperateNotifications(this.filteredNotification);
    console.log(this.filteredNotification);
  }

  showIconFunction() {
    this.iconShow = true;
  }

  editScheduled() {
    this.sendTab = true;
    this.isEditingLater = true;
    this.buttonName = 'SEND ON ' + this.sendOn;
    setTimeout(function () {
      this.isEditingDraft = true;
    }.bind(this), 5);

  }

  async navigationToPage(notification: any) {
    console.log(notification);
    this.sendToPreview = "";
    this.SendButtonClickFlag = false;
    this.selectedSendDate = "";
    this.isEditingLater = false;
    this.buttonName = 'SEND LATER';
    this.updateDate = false;
    if (notification.action.toLowerCase() == 'view' || notification.action.toLowerCase() == 'view/delete') {
      this.isEditingDraft = false;
      this.notificationSubject = notification.Notification;
      this.notificationDetails = notification.MSG_DETAIL;
      this.notificationFrom = notification.TRIG_FROM;
      this.notify_id = notification.NOTIFY_ID;
      this.lastEditedOn = notification.date;
      this.dealerCode = notification.SEND_TO_DEALER;
      this.isSendLaterPreview = notification.action.toLowerCase() == 'view/delete';
      this.categoryList = [];
      this.sendOn = notification.SEND_ON;
      var localArr = this.sendOn.substring(0, 10).split("-")
      this.selectedSendDate = notification.SEND_ON;
      this.sendOn = localArr[1] + "-" + localArr[2] + "-" + localArr[0];
      if (notification.Trigger_Email == 'E') {
        this.emailChecked = true;
      }
      if (notification.Trigger_Email == 'N') {
        this.NotificationChecked = true;
      }
      if (notification.Trigger_Email == 'B') {
        this.emailChecked = true;
        this.NotificationChecked = true;
      }
      console.log(this.roles);
    }
    if (notification.action.toLowerCase() == 'edit/delete') {
      this.isSendLaterPreview = false;
      this.isEditingDraft = true;
      this.notify_id = notification.NOTIFY_ID;
      this.notificationSubject = notification.Notification;
      this.notificationDetails = notification.MSG_DETAIL;
      this.notificationFrom = notification.TRIG_FROM;
      this.sendTab = true;
      this.lastEditedOn = notification.date;
      this.dealerCode = notification.SEND_TO_DEALER;
      this.selectedRegion = notification.Area;
      this.categoryList = [];
      if (this.selectedRegion == '') {

      }
      if (notification.Trigger_email == 'E') {
        this.emailChecked = true;
      }
      else if (notification.Trigger_email == 'N') {
        this.NotificationChecked = true;
      }
      else if (notification.Trigger_email == 'B') {
        this.emailChecked = true;
        this.NotificationChecked = true;
      }
      else {
        this.emailChecked = false;
        this.NotificationChecked = false;
      }
    }
    var fromArr = [];
    fromArr = notification.SEND_TO_CODE.split('');
    console.log(this.roles);
    this.selectedRole = [];
    for (let i = 0; i < fromArr.length; i++) {
      if (fromArr[i] == "1") {
        this.selectedRole.push("PC")
      }
      if (fromArr[i] == "2") {
        this.selectedRole.push("Principal")
      }
      if (fromArr[i] == "3") {
        this.selectedRole.push("Lexus College")
      }
      if (fromArr[i] == "4") {
        this.selectedRole.push("Lexus Admin")
      }
      if (fromArr[i] == "5") {
        this.selectedRole.push("IS Admin")
      }
      if (fromArr[i] == "6") {
        this.selectedRole.push("GM")
      }
      if (fromArr[i] == "7") {
        this.selectedRole.push("Controller")
      }
      await this.changeCaregory(this.selectedRole[i]);
    }
  }

  changeAreaCode(code) {
    console.log('Code', code);
    this.selectedRegion = code;
    this.dealerCode = "";
    this.filteredDealers = [];
    this.dealerCodeListfromArea = [];
    this.isLoading = true;
    this.reportService.getNotificationDealerCodeSearch(this.dealerCode, this.selectedRegion).subscribe((result: any) => {
      this.filteredDealers = result.DealerCodeSearch.Result;
      console.log(this.filteredDealers);
      for (let i = 0; i < this.filteredDealers.length; i++) {
        this.filteredDealers[i].AccountName = this.filteredDealers[i].Account_Name;
        this.filteredDealers[i].dealerCodeNo = this.filteredDealers[i].dealerCode;
        this.dealerCodeListfromArea.push(this.filteredDealers[i].dealerCodeNo);
      }
      this.isLoading = false;
      // console.log('Search', this.filteredDealers);
    });
  }

  getStaffMasterList() {
    this.loginService.getUserDetailListWithSpinID(this.appService.selectedRegion).subscribe((response: any) => {
      this.staffMasterUserListResponse = response.UserDetails.Result;
      console.log('staffMasterUserListResponse ', this.staffMasterUserListResponse);
    }, err => {
      console.log('Error in retriving Staff Master User Details')
    });
  }

  clearFields() {
    this.notificationSubject = '';
    this.notificationDetails = '';
    this.notificationFrom = 'Lexus College';
    this.emailChecked = false;
    this.NotificationChecked = true;
    this.isEditingDraft = false;
    this.roles = new FormControl('');
    this.selectedRole = [];
    this.dealerCode = "";
    this.selectedRegion = "";
    this.sendToPreview = '';
    this.categoryList = [];
    this.buttonName = 'SEND LATER';
  }

  async changeCaregory(role) {
    var bool = false;
    var spliceIndex = 0;
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].indexOf(role) != -1) {
        bool = true;
        spliceIndex = i;
      }
    }
    if (!bool) {
      if (role == "PC" || role == "Principal" || role == "GM" || role == "Controller") {
        if (this.dealerCode.length > 0) {
          role = role + " (" + this.dealerCode + ")";
        }
        else {
          if (this.selectedRegion == '') {
            role = role + " (All Regions)";
          }
          else {
            role = role + " (Area Code - " + this.selectedRegion + ")";
          }
        }
      }
      this.categoryList.push(role)
    } else {
      this.categoryList.splice(spliceIndex, 1)
    }
    this.sendToPreview = "";
    console.log(this.categoryList);
    for (let i = 0; i < this.categoryList.length; i++) {
      if (i != this.categoryList.length - 1) {
        this.sendToPreview += this.categoryList[i] + ", "
      }
      else {
        this.sendToPreview += this.categoryList[i];
      }
    }

  }

  saveNotification() {
    var localArr = [];
    localArr = this.categorizeRole()
    var localStr = "";
    for (let i = 0; i < localArr.length; i++) {
      localStr += localArr[i];
    }
    var triggerAs = this.triggerAsValue();
    console.log(localStr);
    this.showSpinner = true;

    this.notificationService.insertNotification('Notification has been sent by Lexus Admin', this.notificationFrom,
      localStr, this.dealerCode, this.notificationSubject, this.notificationDetails,
      'Draft', triggerAs, "ActionLink", this.appService.workdayId + "", this.selectedRegion).subscribe((result: any) => {
        this.Success = true;
        this.successMessage = 'Notification has been saved as Draft';
        this.showSpinner = false;
        $('html,body').animate({ scrollTop: 0 }, "smooth");
        this.getAllNotifications();
        this.sendTab = false;
        this.isEditingDraft = false;
      });

    setTimeout(function () {
      this.Success = false;
    }.bind(this), 5000);

  }

  async sendNotificationNow() {
    this.emailList = [];
    var localArr = [];
    localArr = this.categorizeRole()
    var localStr = "";
    for (let i = 0; i < localArr.length; i++) {
      localStr += localArr[i];
    }
    console.log(localStr);
    var triggerAs = this.triggerAsValue();
    this.emailList = [];
    this.showSpinner = true;
    await this.notificationService.insertNotification('Notification has been sent by Lexus Admin', this.notificationFrom,
      localStr, this.dealerCode, this.notificationSubject, this.notificationDetails,
      'Sent', triggerAs, "ActionLink", this.appService.workdayId + "", this.selectedRegion).toPromise().then(async (result: any) => {
        if (result.RowUpdated.rowCount == 1) {
          await this.notificationService.getMaxIdNotification().toPromise().then(async (response: any) => {
            var id = response.Id.Result[0].NOTIFY_ID
            console.log(id);

            if ((localStr.indexOf('1') != -1 || localStr.indexOf('2') != -1 || localStr.indexOf('6') != -1 || localStr.indexOf('7') != -1)
              && this.dealerCode.length == 0 && this.dealerCodeListfromArea.length > 0) {

              let insertQuery = '';
              this.appService.category1 = [];
              this.appService.category2 = [];
              this.appService.category6 = [];
              this.appService.category7 = [];
              console.log('this.staffMasterUserListResponse-> ', this.staffMasterUserListResponse);

              for (let insertForAreaVar = 0; insertForAreaVar < this.dealerCodeListfromArea.length; insertForAreaVar++) {

                this.masterDealerList = [];

                // this.masterDealerList = result.UserDetails.Result;

                var j = 0;
                for (let i1 = 0; i1 < this.staffMasterUserListResponse.length; i1++) {
                  //60402
                  if (this.staffMasterUserListResponse[i1].dealerCode == this.dealerCodeListfromArea[insertForAreaVar]) {
                    this.masterDealerList.push(this.staffMasterUserListResponse[i1]);
                    j++;
                  }
                }
                for (let i1 = 0; i1 < this.masterDealerList.length; i1++) {
                  if (this.masterDealerList[i1].isPc.toUpperCase() == 'TRUE') {
                    this.appService.category1.push(this.masterDealerList[i1]);
                  } else if (this.masterDealerList[i1].jobDescription == 'DEALER PRINCIPAL') {
                    this.appService.category2.push(this.masterDealerList[i1]);
                  } else if (this.masterDealerList[i1].jobDescription == 'GENERAL MANAGER') {
                    this.appService.category6.push(this.masterDealerList[i1]);
                  } else if (this.masterDealerList[i1].jobDescription == 'CONTROLLER') {
                    this.appService.category7.push(this.masterDealerList[i1]);
                  }
                }

              }
              console.log('category1 ', this.appService.category1);
              console.log('category2 ', this.appService.category2);
              console.log('category6 ', this.appService.category6);
              console.log('category7 ', this.appService.category7);


              // // to get the latest date
              // if (this.appService.category6.length > 1) {
              //   this.appService.category6.sort(function (a, b) {
              //     //debugger;
              //     console.log(a);
              //     return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
              //   });
              //   this.appService.category6 = this.appService.category6[0];
              // }

              if (localStr.indexOf('1') != -1) {
                for (let i = 0; i < this.appService.category1.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category1[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category1[i].spinId + "', '"
                      + this.appService.category1[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category1[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category1[i].spinId + "', '"
                      + this.appService.category1[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('2') != -1) {
                for (let i = 0; i < this.appService.category2.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category2[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category2[i].spinId + "', '"
                      + this.appService.category2[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category2[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category2[i].spinId + "', '"
                      + this.appService.category2[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }

              if (localStr.indexOf('3') != -1) {
                for (let i = 0; i < this.appService.category3.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category3[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category3[i].workdayId + "', '"
                      + this.appService.category3[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category3[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category3[i].workdayId + "', '"
                      + this.appService.category3[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('4') != -1) {
                for (let i = 0; i < this.appService.category4.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category4[i].emailId);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category4[i].workdayId + "', '"
                      + this.appService.category4[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category4[i].emailId);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category4[i].workdayId + "', '"
                      + this.appService.category4[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('5') != -1) {
                for (let i = 0; i < this.appService.category5.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category5[i].emailId);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category5[i].workdayId + "', '"
                      + this.appService.category5[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category5[i].emailId);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category5[i].workdayId + "', '"
                      + this.appService.category5[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('6') != -1) {
                for (let i = 0; i < this.appService.category6.length; i++) {
                  // this.emailList.push(this.appService.category6[i].email);
                  // if (!this.emailChecked) {
                  //   await this.notificationService.insertNotificationDetails(id,
                  //     this.appService.category6[i].spinId, this.appService.category6[i].email, this.notificationFrom,
                  //     this.notificationSubject, this.notificationDetails,
                  //     'Sent', 'N', 'N', this.appService.workdayId + "").toPromise().then((final: any) => {
                  //       this.Success = true;
                  //       this.successMessage = 'Notification sent successfully';
                  //       $('html,body').animate({ scrollTop: 0 }, "smooth");
                  //     });
                  // }

                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category6[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category6[i].spinId + "', '"
                      + this.appService.category6[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category6[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category6[i].spinId + "', '"
                      + this.appService.category6[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('7') != -1) {
                for (let i = 0; i < this.appService.category7.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category7[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category7[i].spinId + "', '"
                      + this.appService.category7[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category7[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category7[i].spinId + "', '"
                      + this.appService.category7[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }

              console.log('insert Query', insertQuery);


              if (this.emailChecked && this.NotificationChecked) {
                var emailTo = []
                for (let i = 0; i < this.emailList.length; i++) {
                  if (emailTo.indexOf(this.emailList[i]) == -1 && this.emailList[i] != '') {
                    emailTo.push(this.emailList[i]);
                  }
                }
                console.log(emailTo);
                await this.notificationService.notificationEmailListTrigger(this.notificationFrom, emailTo, this.notificationSubject, this.notificationDetails).toPromise().then((result: any) => {
                  console.log('Notification Inserted');
                  console.log('Email Trigged to ', emailTo);
                  this.successMessage = emailTo.length + ' Mail(s) sent successfully \n';
                });
                insertQuery = insertQuery.slice(0, insertQuery.length - 2);
                await this.notificationService.BulkInsertNotificationDetails(insertQuery).toPromise().then((final: any) => {
                  console.log('final count', final.RowUpdated.rowCount);
                  let count = final.RowUpdated.rowCount;
                  this.Success = true;
                  this.successMessage += count + ' Notification(s) sent successfully';
                  $('html,body').animate({ scrollTop: 0 }, "smooth");
                });

              }
              else if (this.NotificationChecked) {
                insertQuery = insertQuery.slice(0, insertQuery.length - 2);
                await this.notificationService.BulkInsertNotificationDetails(insertQuery).toPromise().then((final: any) => {
                  console.log('final count', final.RowUpdated.rowCount);
                  let count = final.RowUpdated.rowCount;
                  this.Success = true;
                  this.successMessage = count + ' Notification(s) sent successfully';
                  $('html,body').animate({ scrollTop: 0 }, "smooth");
                });
              }
              else if (this.emailChecked) {
                var emailTo = []
                for (let i = 0; i < this.emailList.length; i++) {
                  if (emailTo.indexOf(this.emailList[i]) == -1 && this.emailList[i] != '') {
                    emailTo.push(this.emailList[i]);
                  }
                }
                console.log(emailTo);
                await this.notificationService.notificationEmailListTrigger(this.notificationFrom, emailTo, this.notificationSubject, this.notificationDetails).toPromise().then((result: any) => {
                  console.log('Notification Inserted');
                  console.log('Email Trigged to ', emailTo);
                  this.Success = true;
                  this.successMessage = emailTo.length + ' Mail(s) sent successfully';
                  $('html,body').animate({ scrollTop: 0 }, "smooth");
                });

              }
              else {
                $('html,body').animate({ scrollTop: 0 }, "smooth");
              }

            }

            else {
              let insertQuery = '';
              if (localStr.indexOf('1') != -1) {
                for (let i = 0; i < this.appService.category1.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category1[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category1[i].spinId + "', '"
                      + this.appService.category1[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category1[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category1[i].spinId + "', '"
                      + this.appService.category1[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('2') != -1) {
                for (let i = 0; i < this.appService.category2.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category2[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category2[i].spinId + "', '"
                      + this.appService.category2[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category2[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category2[i].spinId + "', '"
                      + this.appService.category2[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }

              if (localStr.indexOf('3') != -1) {
                for (let i = 0; i < this.appService.category3.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category3[i].emailId);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category3[i].workdayId + "', '"
                      + this.appService.category3[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category3[i].emailId);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category3[i].workdayId + "', '"
                      + this.appService.category3[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('4') != -1) {
                for (let i = 0; i < this.appService.category4.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category4[i].emailId);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category4[i].workdayId + "', '"
                      + this.appService.category4[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category4[i].emailId);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category4[i].workdayId + "', '"
                      + this.appService.category4[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('5') != -1) {
                for (let i = 0; i < this.appService.category5.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category5[i].emailId);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category5[i].workdayId + "', '"
                      + this.appService.category5[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category5[i].emailId);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category5[i].workdayId + "', '"
                      + this.appService.category5[i].emailId + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('6') != -1) {
                for (let i = 0; i < this.appService.category6.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category6[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category6[i].spinId + "', '"
                      + this.appService.category6[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category6[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category6[i].spinId + "', '"
                      + this.appService.category6[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (localStr.indexOf('7') != -1) {
                for (let i = 0; i < this.appService.category7.length; i++) {
                  if (this.emailChecked && this.NotificationChecked) {
                    this.emailList.push(this.appService.category7[i].email);
                    insertQuery += " (" + id + ", '"
                      + this.appService.category7[i].spinId + "', '"
                      + this.appService.category7[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                  else if (this.emailChecked) {
                    this.emailList.push(this.appService.category7[i].email);
                  }
                  else if (this.NotificationChecked) {
                    insertQuery += " (" + id + ", '"
                      + this.appService.category7[i].spinId + "', '"
                      + this.appService.category7[i].email + "', '"
                      + this.notificationSubject + "', '"
                      + this.notificationDetails + "', '"
                      + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                      + this.appService.workdayId + "" + "' , getdate()"
                      + "), ";
                  }
                }
              }
              if (this.emailChecked && this.NotificationChecked) {
                var emailTo = []
                for (let i = 0; i < this.emailList.length; i++) {
                  if (emailTo.indexOf(this.emailList[i]) == -1 && this.emailList[i] != '') {
                    emailTo.push(this.emailList[i]);
                  }
                }
                console.log(emailTo);
                await this.notificationService.notificationEmailListTrigger(this.notificationFrom, emailTo, this.notificationSubject, this.notificationDetails).toPromise().then((result: any) => {
                  console.log('Notification Inserted');
                  console.log('Email Trigged to ', emailTo);
                  this.successMessage = emailTo.length + ' Mail(s) sent successfully \n';
                });
                insertQuery = insertQuery.slice(0, insertQuery.length - 2);
                await this.notificationService.BulkInsertNotificationDetails(insertQuery).toPromise().then((final: any) => {
                  console.log('final count', final.RowUpdated.rowCount);
                  let count = final.RowUpdated.rowCount;
                  this.Success = true;
                  this.successMessage += count + ' Notification(s) sent successfully';
                  $('html,body').animate({ scrollTop: 0 }, "smooth");
                });

              }
              else if (this.NotificationChecked) {
                insertQuery = insertQuery.slice(0, insertQuery.length - 2);
                await this.notificationService.BulkInsertNotificationDetails(insertQuery).toPromise().then((final: any) => {
                  console.log('final count', final.RowUpdated.rowCount);
                  let count = final.RowUpdated.rowCount;
                  this.Success = true;
                  this.successMessage = count + ' Notification(s) sent successfully';
                  $('html,body').animate({ scrollTop: 0 }, "smooth");
                });
              }
              else if (this.emailChecked) {
                var emailTo = []
                for (let i = 0; i < this.emailList.length; i++) {
                  if (emailTo.indexOf(this.emailList[i]) == -1 && this.emailList[i] != '') {
                    emailTo.push(this.emailList[i]);
                  }
                }
                console.log(emailTo);
                await this.notificationService.notificationEmailListTrigger(this.notificationFrom, emailTo, this.notificationSubject, this.notificationDetails).toPromise().then((result: any) => {
                  console.log('Notification Inserted');
                  console.log('Email Trigged to ', emailTo);
                  this.Success = true;
                  this.successMessage = emailTo.length + ' Mail(s) sent successfully';
                  $('html,body').animate({ scrollTop: 0 }, "smooth");
                });

              }
              else {
                $('html,body').animate({ scrollTop: 0 }, "smooth");
              }
            }
            this.appComponent.getUnreadNotifications();
            this.getAllNotifications();
            this.showSpinner = false;
            this.sendTab = false;
          })
        }
      });
    setTimeout(function () {
      this.Success = false;
    }.bind(this), 5000);
  }

  sendNotificationLater() {
    var localArr = [];
    localArr = this.categorizeRole()
    var localStr = "";
    for (let i = 0; i < localArr.length; i++) {
      localStr += localArr[i];
    }
    console.log(localStr);
    var triggerAs = this.triggerAsValue();
    this.showSpinner = true;

    this.notificationService.insertNotificationLater('Notification has been sent by Lexus Admin', this.notificationFrom,
      localStr, this.dealerCode, this.notificationSubject, this.notificationDetails,
      'Send Later', triggerAs, "ActionLink", this.appService.workdayId + "", this.selectedRegion, this.selectedSendDate).subscribe((result: any) => {
        this.Success = true;
        var showDate = this.selectedSendDate + '';
        this.successMessage = 'Notification will be sent on ' + showDate.substring(0, 15);
        $('html,body').animate({ scrollTop: 0 }, "smooth");
        this.sendTab = false;
        this.showSpinner = false;
        this.appComponent.getUnreadNotifications();
        this.getAllNotifications();
      });
    setTimeout(function () {
      this.Success = false;
    }.bind(this), 5000);
  }

  categorizeRole() {
    var localArr = [];
    if (this.dealerCode.length > 0) {
      this.appComponent.getCategoryOneandTwo(this.dealerCode);
    }
    for (let i = 0; i < this.categoryList.length; i++) {

      if (this.categoryList[i].indexOf('PC') != -1) {
        localArr.push(1);
      }
      if (this.categoryList[i].indexOf('Principal') != -1) {
        localArr.push(2);
      }
      if (this.categoryList[i] == 'Lexus College') {
        localArr.push(3);
      }
      if (this.categoryList[i] == 'Lexus Admin') {
        localArr.push(4);
      }
      if (this.categoryList[i] == 'IS Admin') {
        localArr.push(5);
      }
      if (this.categoryList[i].indexOf('GM') != -1) {
        localArr.push(6);
      }
      if (this.categoryList[i].indexOf('Controller') != -1) {
        localArr.push(7);
      }
    }
    localArr.sort(function (a, b) { return a - b });
    console.log(localArr);
    return localArr;
  }

  triggerAsValue() {
    if (this.emailChecked && !this.NotificationChecked) {
      return 'E';
    }
    else if (!this.emailChecked && this.NotificationChecked) {
      return 'N';
    }
    else if (this.emailChecked && this.NotificationChecked) {
      return 'B';
    }
  }

  sendLaterDraftNotificationNow() {
    this.showSpinner = true;
    var localArr = [];
    localArr = this.categorizeRole();
    var localStr = "";
    for (let i = 0; i < localArr.length; i++) {
      localStr += localArr[i];
    }
    console.log(localStr);
    var triggerAs = this.triggerAsValue()

    this.notificationService.insertDraftNotificationLater('Notification has been sent by Lexus Admin', this.notificationFrom,
      localStr, this.dealerCode, this.notificationSubject, this.notificationDetails,
      'Send Later', triggerAs, "ActionLink", this.appService.workdayId + "", this.notify_id, this.selectedRegion, this.selectedSendDate).subscribe((result: any) => {
        this.Success = true;
        var showDate = this.selectedSendDate + '';
        this.successMessage = 'Notification will be sent on ' + showDate.substring(0, 15);
        $('html,body').animate({ scrollTop: 0 }, "smooth");
        this.sendTab = false;
        this.showSpinner = false;
        this.isEditingDraft = false;
        this.appComponent.getUnreadNotifications();
        this.getAllNotifications();
      });
    setTimeout(function () {
      this.Success = false;
    }.bind(this), 5000);
  }

  clearNotificationForm() {
    this.notificationFrom = 'Lexus College';
    this.notificationSubject = '';
    this.notificationDetails = '';
    this.emailChecked = false;
    this.NotificationChecked = true;
    this.categoryList = [];
    this.dealerCode = '';
    this.roles = new FormControl('');
  }

  deleteDraftNotification() {
    // fn to delete draft
    this.showSpinner = true;
    this.notificationService.deleteNotification(this.notify_id).subscribe((result: any) => {
      this.Success = true;
      this.successMessage = 'Notification has been Deleted.';
      $('html,body').animate({ scrollTop: 0 }, "smooth");
      this.isEditingDraft = false;
      this.sendTab = false;
      this.clearFields();
      this.getAllNotifications();
      this.showSpinner = false;
    });
    setTimeout(function () {
      this.Success = false;
    }.bind(this), 5000);
  }

  //to populate dealer code
  filterDealerCode() {
    // this.disableRoleArray[3] = true;
    //   this.disableRoleArray[4] = true;
    this.dealerCode = this.dealerCode.replace(/[^a-zA-Z0-9 ]/g, "");
    if (this.dealerCode.length > 1 && this.dealerCode != undefined) {
      this.filteredDealers = [];
      this.isLoading = true;
      this.reportService.getNotificationDealerCodeSearch(this.dealerCode, this.selectedRegion).subscribe((result: any) => {
        this.filteredDealers = result.DealerCodeSearch.Result;
        console.log(this.filteredDealers);
        for (let i = 0; i < this.filteredDealers.length; i++) {
          this.filteredDealers[i].AccountName = this.filteredDealers[i].Account_Name;
          this.filteredDealers[i].dealerCodeNo = this.filteredDealers[i].dealerCode;
        }
        this.isLoading = false;
        // console.log('Search', this.filteredDealers);
      });
    }
  }
  changeDate() {
    let dateSend = this.selectedSendDate;
    this.buttonName = 'SEND ON ' + (dateSend.getMonth() + 1) + '/' + dateSend.getDate() + '/' + dateSend.getFullYear();
    if (this.sendLaterFlag) {
      this.sendOn = (dateSend.getMonth() + 1) + '/' + dateSend.getDate() + '/' + dateSend.getFullYear();
    }
  }

  selectDealer(dealerCode) {
    console.log('selectdealer', dealerCode)
    // if (dealerCode.length > 0) {
    //   this.disableRoleArray[3] = false;
    //   this.disableRoleArray[4] = false;
    // } else {
    //   this.disableRoleArray[3] = true;
    //   this.disableRoleArray[4] = true;
    // }
  }

  saveDraftNotification() {
    var localArr = [];
    localArr = this.categorizeRole();
    var localStr = "";
    for (let i = 0; i < localArr.length; i++) {
      localStr += localArr[i];
    }
    var triggerAs = this.triggerAsValue();
    console.log(localStr);
    this.showSpinner = true;
    if (!this.isEditingLater) {
      this.notificationService.insertDraftNotification('Notification has been sent by Lexus Admin', this.notificationFrom,
        localStr, this.dealerCode, this.notificationSubject, this.notificationDetails,
        'Draft', triggerAs, "ActionLink", this.appService.workdayId + "", this.notify_id).subscribe((result: any) => {
          this.Success = true;
          this.successMessage = 'Notification has been updated and saved as Draft';
          $('html,body').animate({ scrollTop: 0 }, "smooth");
          this.isEditingDraft = false;
          this.showSpinner = false;
          this.clearFields();
          this.getAllNotifications();
          this.sendTab = false;
        });
    }

    if (this.isEditingLater) {
      this.notificationService.insertDraftNotificationLater('Notification has been sent by Lexus Admin', this.notificationFrom,
        localStr, this.dealerCode, this.notificationSubject, this.notificationDetails,
        'Draft', triggerAs, "ActionLink", this.appService.workdayId + "", this.notify_id, this.selectedRegion, this.selectedSendDate).subscribe((result: any) => {
          this.Success = true;
          this.successMessage = 'Notification has been updated and saved as Draft';
          $('html,body').animate({ scrollTop: 0 }, "smooth");
          this.isEditingDraft = false;
          this.showSpinner = false;
          this.clearFields();
          this.getAllNotifications();
          this.sendTab = false;
        });
    }

    setTimeout(function () {
      this.Success = false;
    }.bind(this), 5000);
  }

  async sendDraftNotificationNow() {
    this.emailList = [];
    var localArr = [];
    localArr = this.categorizeRole();
    var localStr = "";
    for (let i = 0; i < localArr.length; i++) {
      localStr += localArr[i];
    }
    console.log(localStr);
    var triggerAs = this.triggerAsValue();
    this.emailList = [];
    this.showSpinner = true;
    await this.notificationService.insertDraftNotification('Notification has been sent by Lexus Admin', this.notificationFrom,
      localStr, this.dealerCode, this.notificationSubject, this.notificationDetails,
      'Sent', triggerAs, "ActionLink", this.appService.workdayId + "", this.notify_id).toPromise().then(async (result: any) => {
        if (result.RowUpdated.rowCount == 1) {
          console.log('Notification Added');
          if ((localStr.indexOf('1') != -1 || localStr.indexOf('2') != -1) && this.dealerCode.length == 0 && this.dealerCodeListfromArea.length > 0) {
            let insertQuery = '';
            this.appService.category1 = [];
            this.appService.category2 = [];
            this.appService.category6 = [];
            this.appService.category7 = [];
            for (let insertForAreaVar = 0; insertForAreaVar < this.dealerCodeListfromArea.length; insertForAreaVar++) {
              // this.masterDealerList = result.UserDetails.Result;

              console.log(this.staffMasterUserListResponse);
              var j = 0;
              // for (let)
              for (let i1 = 0; i1 < this.staffMasterUserListResponse.length; i1++) {
                if (this.staffMasterUserListResponse[i1].dealerCode == this.dealerCodeListfromArea[insertForAreaVar]) {
                  this.masterDealerList.push(this.staffMasterUserListResponse[i1]);
                  j++;
                }
              }
              for (let i1 = 0; i1 < this.masterDealerList.length; i1++) {
                if (this.masterDealerList[i1].isPc.toUpperCase() == 'TRUE') {
                  this.appService.category1.push(this.masterDealerList[i1]);
                } else if (this.masterDealerList[i1].jobDescription == 'DEALER PRINCIPAL') {
                  this.appService.category2.push(this.masterDealerList[i1]);
                } else if (this.masterDealerList[i1].jobDescription == 'GENERAL MANAGER') {
                  this.appService.category6.push(this.masterDealerList[i1]);
                } else if (this.masterDealerList[i1].jobDescription == 'CONTROLLER') {
                  this.appService.category7.push(this.masterDealerList[i1]);
                }
              }
            }
            // to get the latest date
            // if (this.appService.category6.length > 1) {
            //   this.appService.category6.sort(function (a, b) {
            //     //debugger;
            //     console.log(a);
            //     return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
            //   });
            //   this.appService.category6 = this.appService.category6[0];
            // }

            // console.log('for loop list', this.masterDealerList);
            console.log('category1', this.appService.category1);
            console.log('category2', this.appService.category2);
            console.log('category6', this.appService.category6);
            console.log('category7', this.appService.category7);

            if (localStr.indexOf('1') != -1) {
              for (let i = 0; i < this.appService.category1.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category1[i].email);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category1[i].spinId + "', '"
                    + this.appService.category1[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category1[i].email);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category1[i].spinId + "', '"
                    + this.appService.category1[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('2') != -1) {
              for (let i = 0; i < this.appService.category2.length; i++) {
                // await this.notificationService.insertNotificationDetails(this.notify_id,
                //   this.appService.category2[i].spinId, this.appService.category2[i].email, this.notificationFrom,
                //   this.notificationSubject, this.notificationDetails,
                //   'Sent', 'N', 'N', this.appService.workdayId + "").toPromise().then((final: any) => {
                //     this.Success = true;
                //     this.successMessage = 'Notification sent successfully';
                //     this.isEditingDraft = false;
                //     this.clearFields();
                //     $('html,body').animate({ scrollTop: 0 }, "smooth");
                //   });
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category2[i].email);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category2[i].spinId + "', '"
                    + this.appService.category2[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category2[i].email);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category2[i].spinId + "', '"
                    + this.appService.category2[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('3') != -1) {
              for (let i = 0; i < this.appService.category3.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category3[i].emailId);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category3[i].workdayId + "', '"
                    + this.appService.category3[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category3[i].emailId);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category3[i].workdayId + "', '"
                    + this.appService.category3[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('4') != -1) {
              for (let i = 0; i < this.appService.category4.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category4[i].emailId);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category4[i].workdayId + "', '"
                    + this.appService.category4[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category4[i].emailId);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category4[i].workdayId + "', '"
                    + this.appService.category4[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('5') != -1) {
              for (let i = 0; i < this.appService.category5.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category5[i].emailId);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category5[i].workdayId + "', '"
                    + this.appService.category5[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category5[i].emailId);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category5[i].workdayId + "', '"
                    + this.appService.category5[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('6') != -1) {
              for (let i = 0; i < this.appService.category6.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category6[i].email);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category6[i].spinId + "', '"
                    + this.appService.category6[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category6[i].email);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category6[i].spinId + "', '"
                    + this.appService.category6[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('7') != -1) {
              for (let i = 0; i < this.appService.category7.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category7[i].email);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category7[i].spinId + "', '"
                    + this.appService.category7[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category7[i].email);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category7[i].spinId + "', '"
                    + this.appService.category7[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }

            if (this.emailChecked && this.NotificationChecked) {
              var emailTo = []
              for (let i = 0; i < this.emailList.length; i++) {
                if (emailTo.indexOf(this.emailList[i]) == -1 && this.emailList[i] != '') {
                  emailTo.push(this.emailList[i]);
                }
              }
              console.log(emailTo);
              await this.notificationService.notificationEmailListTrigger(this.notificationFrom, emailTo, this.notificationSubject, this.notificationDetails).toPromise().then((result: any) => {
                console.log('Notification Inserted');
                console.log('Email Trigged to ', emailTo);
                this.successMessage = emailTo.length + ' Mail(s) sent successfully \n';
              });
              insertQuery = insertQuery.slice(0, insertQuery.length - 2);
              await this.notificationService.BulkInsertNotificationDetails(insertQuery).toPromise().then((final: any) => {
                console.log('final count', final.RowUpdated.rowCount);
                let count = final.RowUpdated.rowCount;
                this.Success = true;
                this.successMessage += count + ' Notification(s) sent successfully';
                $('html,body').animate({ scrollTop: 0 }, "smooth");
              });

            }
            else if (this.NotificationChecked) {
              insertQuery = insertQuery.slice(0, insertQuery.length - 2);
              await this.notificationService.BulkInsertNotificationDetails(insertQuery).toPromise().then((final: any) => {
                console.log('final count', final.RowUpdated.rowCount);
                let count = final.RowUpdated.rowCount;
                this.Success = true;
                this.successMessage = count + ' Notification(s) sent successfully';
                $('html,body').animate({ scrollTop: 0 }, "smooth");
              });
            }
            else if (this.emailChecked) {
              var emailTo = []
              for (let i = 0; i < this.emailList.length; i++) {
                if (emailTo.indexOf(this.emailList[i]) == -1 && this.emailList[i] != '') {
                  emailTo.push(this.emailList[i]);
                }
              }
              console.log(emailTo);
              await this.notificationService.notificationEmailListTrigger(this.notificationFrom, emailTo, this.notificationSubject, this.notificationDetails).toPromise().then((result: any) => {
                console.log('Notification Inserted');
                console.log('Email Trigged to ', emailTo);
                this.Success = true;
                this.successMessage = emailTo.length + ' Mail(s) sent successfully';
                $('html,body').animate({ scrollTop: 0 }, "smooth");
              });

            }
            else {
              $('html,body').animate({ scrollTop: 0 }, "smooth");
            }
          }
          else {
            let insertQuery = '';
            if (localStr.indexOf('1') != -1) {
              for (let i = 0; i < this.appService.category1.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category1[i].email);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category1[i].spinId + "', '"
                    + this.appService.category1[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category1[i].email);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category1[i].spinId + "', '"
                    + this.appService.category1[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('2') != -1) {
              for (let i = 0; i < this.appService.category2.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category2[i].email);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category2[i].spinId + "', '"
                    + this.appService.category2[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category2[i].email);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category2[i].spinId + "', '"
                    + this.appService.category2[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('3') != -1) {
              for (let i = 0; i < this.appService.category3.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category3[i].emailId);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category3[i].workdayId + "', '"
                    + this.appService.category3[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category3[i].emailId);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category3[i].workdayId + "', '"
                    + this.appService.category3[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('4') != -1) {
              for (let i = 0; i < this.appService.category4.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category4[i].emailId);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category4[i].workdayId + "', '"
                    + this.appService.category4[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category4[i].emailId);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category4[i].workdayId + "', '"
                    + this.appService.category4[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('5') != -1) {
              for (let i = 0; i < this.appService.category5.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category5[i].emailId);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category5[i].workdayId + "', '"
                    + this.appService.category5[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category5[i].emailId);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category5[i].workdayId + "', '"
                    + this.appService.category5[i].emailId + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('6') != -1) {
              for (let i = 0; i < this.appService.category6.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category6[i].email);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category6[i].spinId + "', '"
                    + this.appService.category6[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category6[i].email);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category6[i].spinId + "', '"
                    + this.appService.category6[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            if (localStr.indexOf('7') != -1) {
              for (let i = 0; i < this.appService.category7.length; i++) {
                if (this.emailChecked && this.NotificationChecked) {
                  this.emailList.push(this.appService.category7[i].email);
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category7[i].spinId + "', '"
                    + this.appService.category7[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
                else if (this.emailChecked) {
                  this.emailList.push(this.appService.category7[i].email);
                }
                else if (this.NotificationChecked) {
                  insertQuery += " (" + this.notify_id + ", '"
                    + this.appService.category7[i].spinId + "', '"
                    + this.appService.category7[i].email + "', '"
                    + this.notificationSubject + "', '"
                    + this.notificationDetails + "', '"
                    + this.notificationFrom + "', 'Sent' , 'N', 'N' , '"
                    + this.appService.workdayId + "" + "' , getdate()"
                    + "), ";
                }
              }
            }
            //debugger;
            if (this.emailChecked && this.NotificationChecked) {
              var emailTo = []
              for (let i = 0; i < this.emailList.length; i++) {
                if (emailTo.indexOf(this.emailList[i]) == -1 && this.emailList[i] != '') {
                  emailTo.push(this.emailList[i]);
                }
              }
              console.log(emailTo);
              await this.notificationService.notificationEmailListTrigger(this.notificationFrom, emailTo, this.notificationSubject, this.notificationDetails).toPromise().then((result: any) => {
                console.log('Notification Inserted');
                console.log('Email Trigged to ', emailTo);
                this.successMessage = emailTo.length + ' Mail(s) sent successfully \n';
              });
              insertQuery = insertQuery.slice(0, insertQuery.length - 2);
              await this.notificationService.BulkInsertNotificationDetails(insertQuery).toPromise().then((final: any) => {
                console.log('final count', final.RowUpdated.rowCount);
                let count = final.RowUpdated.rowCount;
                this.Success = true;
                this.successMessage += count + ' Notification(s) sent successfully';
                $('html,body').animate({ scrollTop: 0 }, "smooth");
              });

            }
            else if (this.NotificationChecked) {
              insertQuery = insertQuery.slice(0, insertQuery.length - 2);
              await this.notificationService.BulkInsertNotificationDetails(insertQuery).toPromise().then((final: any) => {
                console.log('final count', final.RowUpdated.rowCount);
                let count = final.RowUpdated.rowCount;
                this.Success = true;
                this.successMessage = count + ' Notification(s) sent successfully';
                $('html,body').animate({ scrollTop: 0 }, "smooth");
              });
            }
            else if (this.emailChecked) {
              var emailTo = []
              for (let i = 0; i < this.emailList.length; i++) {
                if (emailTo.indexOf(this.emailList[i]) == -1 && this.emailList[i] != '') {
                  emailTo.push(this.emailList[i]);
                }
              }
              console.log(emailTo);
              await this.notificationService.notificationEmailListTrigger(this.notificationFrom, emailTo, this.notificationSubject, this.notificationDetails).toPromise().then((result: any) => {
                console.log('Notification Inserted');
                console.log('Email Trigged to ', emailTo);
                this.Success = true;
                this.successMessage = emailTo.length + ' Mail(s) sent successfully';
                $('html,body').animate({ scrollTop: 0 }, "smooth");
              });

            }
            else {
              $('html,body').animate({ scrollTop: 0 }, "smooth");
            }
          }
          this.appComponent.getUnreadNotifications();
          this.getAllNotifications();
          this.showSpinner = false;
          this.sendTab = false;
        }
      })
    setTimeout(function () {
      this.Success = false;
    }.bind(this), 5000);
  }

  closePreviewModal() {
    if (!this.isEditingLater) {
      //debugger;
      this.buttonName = 'SEND LATER';
    }
    this.sendLaterFlag = false;
    this.selectedSendDate = new Date();
  }

  SendOn() {
    let sendonDate = new Date(this.selectedSendDate);
    this.sendOption = 'Send On';
    if (this.emailChecked && this.NotificationChecked) {
      this.sendConfirmation = 'Do you want to send this email\\notification on ' + (sendonDate.getMonth() + 1) + '/' + sendonDate.getDate() + '/' + sendonDate.getFullYear() + ' ?';
    } else if (!this.emailChecked && this.NotificationChecked) {
      this.sendConfirmation = 'Do you want to send this notification on ' + (sendonDate.getMonth() + 1) + '/' + sendonDate.getDate() + '/' + sendonDate.getFullYear() + ' ?';
    } else if (this.emailChecked && !this.NotificationChecked) {
      this.sendConfirmation = 'Do you want to send this email on ' + (sendonDate.getMonth() + 1) + '/' + sendonDate.getDate() + '/' + sendonDate.getFullYear() + ' ?';
    }
    this.SendButtonClickFlag = true;
    this.sendLaterFlag = false;
  }

  sendNow() {
    this.sendOption = 'Send Now';
    if (this.emailChecked && this.NotificationChecked) {
      this.sendConfirmation = 'Do you want to send this email\\notification now ?';
    } else if (!this.emailChecked && this.NotificationChecked) {
      this.sendConfirmation = 'Do you want to send this notification now ?';
    } else if (this.emailChecked && !this.NotificationChecked) {
      this.sendConfirmation = 'Do you want to send this email now ?';
    }
    this.isSendLaterPreview = false;
    this.exceptionSelectedDealer = false;
    this.SendButtonClickFlag = true;
    this.sendLaterFlag = false;
  }

  ConfirmSend() {
    if (this.sendOption == 'Send Now') {
      //Code for Send Now
      if (this.isEditingDraft) {
        this.sendDraftNotificationNow();
      }
      else {
        this.sendNotificationNow();
      }
    } else if (this.sendOption == 'Send On') {
      //Code for Send Later 
      // selectedSendDate
      if (this.isEditingDraft) {
        this.sendLaterDraftNotificationNow();
      }
      else {
        if (!this.isSendLaterPreview) {
          this.sendNotificationLater();
        }
        else {
          var localArr = [];
          localArr = this.categorizeRole();
          var localStr = "";
          for (let i = 0; i < localArr.length; i++) {
            localStr += localArr[i];
          }
          var triggerAs = this.triggerAsValue();
          console.log(localStr);
          this.showSpinner = true;
          this.notificationService.insertDraftNotificationLater('Notification has been sent by Lexus Admin', this.notificationFrom,
            localStr, this.dealerCode, this.notificationSubject, this.notificationDetails,
            'Send Later', triggerAs, "ActionLink", this.appService.workdayId + "", this.notify_id, this.selectedRegion, this.selectedSendDate).subscribe((result: any) => {
              this.Success = true;
              this.successMessage = 'Notification scheduled date has been Updated';
              $('html,body').animate({ scrollTop: 0 }, "smooth");
              this.isEditingDraft = false;
              this.showSpinner = false;
              this.clearFields();
              this.getAllNotifications();
              this.sendTab = false;
            });
        }

      }
    }
  }
}
