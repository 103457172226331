import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from '../service/common.service';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { SearchAssociateService } from './search-associate.service';
import { Router } from '@angular/router';


export interface AssociateData {
  jobDesc: string,
  employmentStatus: string,
  contact: string,
  emailId: string,
  startDate: string,
  endDate: string
}

@Component({
  selector: 'app-search-associate',
  templateUrl: './search-associate.component.html',
  styleUrls: ['./search-associate.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchAssociateComponent implements OnInit {

  //Search Variables
  loadSpinner: boolean;
  searchAssociateForm: FormGroup;
  isLoading = false;
  spinId: any = "";
  spinIdClicked: Boolean = false;
  user: any = [];
  showAccordion: boolean = false;
  showErrorDiv: boolean = false;
  dasboardTab: boolean = false;

  //Material Table
  associateColumns = ['jobDesc', 'startDate', 'endDate', 'employmentStatus', 'contact', 'emailId'];
  AssociateDataSource: MatTableDataSource<AssociateData>;
  USERDATA: AssociateData[] = [];
  associateTotalData: number = 0
  NoData: boolean = false;
  tableLoading: boolean = false;

  //Server side integration Variables
  associateResponse: any = {};
  associatefirstLevel: any = '';
  associateSecondLevel: any = [];
  loading: boolean = false;
  //------------- Paginator and Sort @ViewChild-----------------
  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder, private appService: CommonService, 
    private serachService: SearchAssociateService, private router: Router) {

  }

  ngOnInit() {
    console.log('admin search assoc',this.appService.role);
    if (this.appService.role != 'Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.searchAssociateForm = this.fb.group({
      userInput: null
    });
  }

  fadeDiv() {
    this.spinIdClicked = true;
  }

  fadeOut() {
    this.spinIdClicked = false;
  }

  closeSpinSearch() {
    this.spinId = "";
    this.spinIdClicked = true;
  }

  // ======================Get the Associate Details Service Call function ==============================
  getUserDetails() {
    this.associateResponse = ''; // initialize empty response
    this.associatefirstLevel = { // Initialize the first level of response
      "spinId": 'N/A',
      "name": 'N/A',
    };
    this.associateSecondLevel = []; // Initialize the second level of response
    var uid = ''; // Initialize the UID
    if (this.spinId.length == 9) { //check if the entered value is a UID with a total length of 9
      uid = this.spinId;
    } else if (this.spinId.length == 12) { //check if the entered value is a spin ID with a total length of 12
      uid = this.spinId.substring(3, ); // remove the 104 from the entered value
    }
    if (uid.length == 9) {
      this.loading = true;

      // STAFF MASTER API service call
      this.serachService.getAssociateDetails(uid).subscribe((response: any) => {
        this.associateResponse = response.message.value[0];
        if (this.associateResponse != {} && this.associateResponse != '' && this.associateResponse != undefined) {
          var fname = '', lname = '', associateName = '';

          if (this.associateResponse.firstName.trim() != '') {
            fname = this.associateResponse.firstName.trim();
          }
          if (this.associateResponse.lastName.trim() != '') {
            lname = this.associateResponse.lastName.trim();
          }
          if (fname != '' && lname != '') {
            associateName = fname + ' ' + lname;
          } else if (fname == '' && lname != '') {
            associateName = fname + ' ' + lname;
          } else if (fname != '' && lname == '') {
            associateName = fname + ' ' + lname;
          } else {
            associateName = '';
          }

          this.associatefirstLevel = { //First level of the associateResponse
            "spinId": this.associateResponse.spinId,
            "name": associateName,
          }

          this.associateSecondLevel = this.associateResponse.orgAssociations;
        } else {
          this.associatefirstLevel = {
            "spinId": 'N/A',
            "name": 'N/A',
          };
          this.associateSecondLevel = [];
        }
        this.loading = false;
        this.showAccordion = true;
        this.showErrorDiv = false;
      }, err => {
        console.log('Error', err);
        console.log('Error while fetching data from Staff Master API');
        this.associateResponse = '';
        this.associatefirstLevel = {
          "spinId": 'N/A',
          "name": 'N/A',
        };
        this.associateSecondLevel = [];
        this.loading = false;
        this.showAccordion = true;
        this.showErrorDiv = false;
      });
    } else {
      this.showErrorDiv = true;
      this.showAccordion = false;
    }
  }

  // Function to captilize the word
  capitalize(s) {
    return s
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word[0].trim().toUpperCase() + word.substr(1);
      })
      .join(' ');
  }

  //Check the entered Associate SPIN ID or UID is of length 9 or 12
  // if yes the fetch the associate details from the Staff Master API
  searchAssociate() {
    this.spinId = this.spinId.replace(/[^a-zA-Z0-9 ]/g, "");
    if (this.spinId.length == 9 || this.spinId.length == 12) {
      this.getUserDetails();
    } else {
      this.showErrorDiv = true;
      this.showAccordion = false;
    }
  }
}
