import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../service/common.service';
import { ExcelService } from '../service/excel.service';
import { CorpHoldResumeService } from 'src/app/corp-hold-resume/corp-hold-resume.service';
import { NotificationService } from '../notification/notification.service'
import { AppComponent } from '../app.component'

declare var $: any;
import { PlatformLocation, Location } from '@angular/common';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";

export interface HoldData {
  name: string;
  dealerCode: string;
  spinId: string;
  enrollmentNo: string;
  startPeriod: string;
  endPeriod: string;
  reason: string;
}

@Component({
  selector: 'app-corp-hold-resume',
  templateUrl: './corp-hold-resume.component.html',
  styleUrls: ['./corp-hold-resume.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CorpHoldResumeComponent implements OnInit {

  // ------Table Source-----------
  corpholdDataSource: MatTableDataSource<any>;
  corpholddataColumns: string[] = ["name", "spinId", "dealerCode", "startPeriod", "endPeriod", "reason"];
  ACTIVEDATA: HoldData[] = [];
  PASTDATA: HoldData[] = [];
  FUTUREDATA: HoldData[] = [];
  tableOverflow: boolean = false;
  activeTableData: number = 0;
  pastTableData: number = 0;
  futureTableData: number = 0;
  activeResponse: any;
  pastResponse: any;
  futureResponse: any;
  tableLoading: boolean;

  //------------- Paginator and Sort @ViewChild-----------------
  @ViewChild(MatSort) sort: MatSort;

  // =========Accordion Panel State==============
  panelOpenState = false;
  holdTable: boolean = true;

  // ==============Search Variable================
  userDetail: any;
  filteredUsers: any[] = [];
  usersForm: FormGroup;
  isLoading = false;
  spinId: any = "";
  userList: any[] = [];
  spinIdClicked: Boolean = false;
  userDetailList: any = [];
  userName: string;
  showTrash: Boolean;

  // ==============Other Variable=================
  resumePayDiv: boolean = true;
  resumePaymentSection: boolean = false;
  holduser: any;
  holdUserList: any = [];
  holdPaymentSection: boolean = false;
  months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  years: any = [];
  reasons: any = ['Medical Leave', 'Not Available', 'On Strike', 'Other'];
  d: any = new Date();
  startMon: string = this.months[this.d.getMonth()];
  startYr: string = this.d.getFullYear();
  endMon: string = this.months[(this.d.getMonth()) + 1];
  endYr: string = this.d.getFullYear();
  endYrResume: string = this.d.getFullYear();
  endMonResume: string = this.months[(this.d.getMonth()) + 1];
  holdreason: string = 'Medical Leave';
  Resumereason: string = 'Medical Leave';
  searchSec: boolean = true;
  succesMessage: string;
  errorMessage: string;
  Success: boolean;
  Error: boolean;
  endSec: boolean = false;
  selectedView: number = 1;
  commentsResume: string = '';
  commentsHold: string = '';
  dasboardTab: boolean = false;
  deleteUser: any = '';
  currentHoldArray: any = {};
  deleteUserName: string;
  holdUserName: string;
  dateValidationError: boolean = false;
  holdAssociate: any = [];
  navigate: boolean = true;
  historyTableLoading: boolean = true;
  extendAssociate: any = {};
  resumeSameDateValidation: boolean = false;
  holdStartnotinHistory: boolean = false;
  holdLoopArray: any = [];
  selectedHoldArray: any = [];
  filterSelected: boolean = false;
  exportData: any = [];
  paymentDate: any = '';
  resumeStartDateGreater: boolean = false;
  holdStartnotinEditHistory: boolean = false;
  emptyHoldComment: boolean = false;
  holdStartgreaterThanPresentDate: boolean = false;
  noCorpdata: boolean;
  holdNavigateTabName: string = 'Active Hold';
  holdLoading: boolean = false;

  //  ================================ CONSTRUCTOR METHOD ==================================
  constructor(private fb: FormBuilder, private appService: CommonService, private excelService: ExcelService, private corpHoldService: CorpHoldResumeService,
    private notificationService: NotificationService, private appComponent: AppComponent, private location: PlatformLocation, private route: ActivatedRoute,
    private router: Router, private _location: Location) {

    //* scroll top on click of this component on side nav bar
    $('html,body').animate({ scrollTop: -90 }, "fast");

    //* location onPopState function is to monitor the click of the browser back button
    location.onPopState(() => {
      var PageUrl = window.location.href;
      if (PageUrl.indexOf('/corpHoldResume') != -1) {
        this.navigateToHoldResume();
      }
    });
    this.holdTable = true;
    this.tableLoading = true;

    //* to autopopulate the year dropdown with next 10 years from the current year
    var currentYear = this.d.getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push(currentYear);
      currentYear++;
    }

    //* this method will get the active hold data
    this.getActiveHold();

    //* to get the past hold data count to populate in the tab
    this.corpHoldService.getHistoryList(this.appService.selectedRegion).subscribe((response: any) => {
      this.pastResponse = response.HoldHistoryList.Result;
      if (this.pastResponse.length != 0) {
        for (let i = 0; i < this.pastResponse.length; i++) {
          this.PASTDATA.push(this.pastResponse[i]);
          this.pastTableData = this.PASTDATA.length;
        }
      } else {
        this.pastTableData = 0;
      }
    }, error => {
      this.PASTDATA = [];
      this.pastTableData = 0;
      console.log('Some error occured while fetching data for Past Hold List');
      console.log('Error', error);
    });

    //* to get the past future data count to populate in the tab
    this.corpHoldService.getFutureList(this.appService.selectedRegion).subscribe((response: any) => {
      this.futureResponse = response.FutureHoldList.Result;
      if (this.futureResponse.length != 0) {
        for (let i = 0; i < this.futureResponse.length; i++) {
          this.FUTUREDATA.push(this.futureResponse[i]);
          this.futureTableData = this.FUTUREDATA.length;
        }
      } else {
        this.futureTableData = 0;
      }
    }, error => {
      this.FUTUREDATA = [];
      this.futureTableData = 0;
      console.log('Some error occured while fetching data for Future Hold List');
      console.log('Error', error);
    });
  }

  // =========================================== ON INIT METHOD ============================================
  ngOnInit() {
    console.log('clg admin hold res',this.appService.role);
    if (this.appService.role != 'Lexus College' && this.appService.role != 'Lexus Associate' && this.appService.role != 'Area Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.appService.setEnrollUserData([]);
    this.usersForm = this.fb.group({
      userInput: null
    });
    this.appService.corpHoldPage = '';
    this.appService.isCorpHoldCreate = false;
  }

  // changeAreaCode(region){
  //   this.appService.selectedRegion = region;
  //   console.log("Hold/Res Component check :",this.appService.selectedRegion);
  //   this.navigateToHoldResume();
  //   this.select(1);
  // }
  //  ============================= HOLD TAB NAVIGATION METHOD ==================================================

  select(index: number) {
    this.selectedView = index;
    this.filterSelected = false;
    this.tableOverflow = false;
    // index = 1 is for selecting the active hold tab
    if (index == 1) {
      this.getActiveHold();
      // change the breadcrumb name
      this.holdNavigateTabName = 'Active Hold';
      this.appService.corpHoldPage = this.holdNavigateTabName;
      this.appService.isCorpHoldCreate = true;

      // index = 2 is for selecting the past hold tab
    } else if (index == 2) {
      this.getPastHold();

      // index = 3 is for selecting the future hold tab
    } else {
      this.getFutureHold();
      // change the breadcrumb name
      this.holdNavigateTabName = 'Future Hold';
      this.appService.corpHoldPage = this.holdNavigateTabName;
      this.appService.isCorpHoldCreate = true;
    }
  }

  //* this method will get the active hold data
  getActiveHold() {
    this.FUTUREDATA = [];
    this.PASTDATA = [];
    this.ACTIVEDATA = [];
    this.tableLoading = true;
    this.noCorpdata = false;
    this.corpHoldService.getActiveList(this.appService.selectedRegion).subscribe((response: any) => {
      this.activeResponse = response.ActiveHoldList.Result
      if (this.activeResponse.length != 0) {
        for (let i = 0; i < this.activeResponse.length; i++) {
          this.ACTIVEDATA.push(this.activeResponse[i]);
          this.activeTableData = this.ACTIVEDATA.length;
        }
      } else {
        this.noCorpdata = true;
        this.activeTableData = 0;
      }
      this.corpholdDataSource = new MatTableDataSource(this.ACTIVEDATA);
      this.corpholdDataSource.sort = this.sort;
      this.tableLoading = false;

      if (this.activeTableData > 10) {
        this.tableOverflow = true;
      } else {
        this.tableOverflow = false;
      }
      this.activeTableData == 0 ? this.noCorpdata = true : this.noCorpdata = false;

    }, err => {
      this.tableLoading = false;
      this.activeTableData = 0;
      this.noCorpdata = true;
      this.ACTIVEDATA = [];
      console.log("Error while fetching Active hold data");
      console.log('Error', err);
    });
  }

  //* this method will get the past hold data
  getPastHold() {
    this.FUTUREDATA = [];
    this.PASTDATA = [];
    this.ACTIVEDATA = []
    this.tableLoading = true;
    this.noCorpdata = false;
    this.corpHoldService.getHistoryList(this.appService.selectedRegion).subscribe((response: any) => {
      this.pastResponse = response.HoldHistoryList.Result
      if (this.pastResponse.length != 0) {
        for (let i = 0; i < this.pastResponse.length; i++) {
          this.PASTDATA.push(this.pastResponse[i]);
          this.pastTableData = this.PASTDATA.length;
        }
      } else {
        this.pastTableData = 0;
      }
      this.corpholdDataSource = new MatTableDataSource(this.PASTDATA);
      this.corpholdDataSource.sort = this.sort;
      this.tableLoading = false;
      if (this.pastTableData > 10) {
        this.tableOverflow = true;
      } else {
        this.tableOverflow = false;
      }
      this.pastTableData == 0 ? this.noCorpdata = true : this.noCorpdata = false;
    }, err => {
      this.tableLoading = false;
      this.pastTableData = 0;
      this.noCorpdata = true
      console.log("Error while fetching Past hold data");
      console.log('Error', err);
    });
  }

  //* this method will get the future hold data
  getFutureHold() {
    this.FUTUREDATA = [];
    this.PASTDATA = [];
    this.ACTIVEDATA = []
    this.tableLoading = true;
    this.noCorpdata = false;
    this.corpHoldService.getFutureList(this.appService.selectedRegion).subscribe((response: any) => {
      this.futureResponse = response.FutureHoldList.Result;
      if (this.futureResponse.length != 0) {
        for (let i = 0; i < this.futureResponse.length; i++) {
          this.FUTUREDATA.push(this.futureResponse[i]);
          this.futureTableData = this.FUTUREDATA.length;
        }
        this.tableLoading = false;
      } else {
        this.tableLoading = false;
        this.futureTableData = 0;
      }
      this.corpholdDataSource = new MatTableDataSource(this.FUTUREDATA);
      this.corpholdDataSource.sort = this.sort;
      if (this.futureTableData > 10) {
        this.tableOverflow = true;
      } else {
        this.tableOverflow = false;
      }

      this.futureTableData == 0 ? this.noCorpdata = true : this.noCorpdata = false;
    }, err => {
      this.tableLoading = false;
      this.futureTableData = 0;
      this.noCorpdata = true
      console.log("Error while fetching future hold data");
      console.log('Error', err);
    });
  }

  //  ================================= TABLE SEARCH FILTER METHOD ======================================
  applyFilter(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.corpholdDataSource.filter = filterValue.trim().toLowerCase();
    this.exportData.push(this.corpholdDataSource.filteredData);
    if (filterValue != '' || this.corpholdDataSource.filteredData.length < 10) {
      $("#holdHistorTable").removeClass('tableOverflow');
    } else {
      $("#holdHistorTable").addClass('tableOverflow');
    }
  }

  //  ================================= VALIDATION FOR HOLD PAYMENT METHOD ======================================

  // ***this method will check whether the selected hold period is present in the history table
  // **holdLoopArray will have the array of hold periods in the history table
  // * selectedHoldArray will have the array of selected hold period
  validateHoldStartEndPeriod() {
    if (this.holdUserList.length != 0) {
      this.holdLoopArray = [];
      this.selectedHoldArray = [];
      var countselected = 0;
      var countHold = 0;
      var endDatePeriodSelected = '';
      var startIndex, endIndex, startLoopIndex, endLoopIndex, diffYr;

      if (!this.endSec) { //* check if the selected hold end period is N/A
        endDatePeriodSelected = 'DEC-2999';
        startIndex = this.months.indexOf(this.startMon);
        endIndex = this.months.indexOf('DEC');
        diffYr = parseInt('2999') - parseInt(this.startYr);
      } else if (this.endSec) { //* check if the selected hold end period is not N/A
        endDatePeriodSelected = this.endMon + '-' + this.endYr;
        startIndex = this.months.indexOf(this.startMon);
        endIndex = this.months.indexOf(this.endMon);
        diffYr = parseInt(this.endYr) - parseInt(this.startYr);
      }

      if (diffYr == 0) { // if the difference between the selected hold start period and end period is 0
        for (let i = startIndex; i < endIndex + 1; i++) {
          var str = this.months[i] + '-' + this.startYr;
          this.selectedHoldArray.push(str);
        }
      } else { // if the difference between the selected hold start period and end period is more than 0
        var index = startIndex - 1;
        var changeYr = parseInt(this.startYr);
        for (let i = 0; i < (diffYr * 15); i++) {
          index += 1;
          countselected += 1;
          if (index == 11) {
            var str = this.months[index] + '-' + changeYr;
            index = -1;
            changeYr += 1;
          } else {
            var str = this.months[index] + '-' + changeYr;
          }
          if (str != endDatePeriodSelected) {
            this.selectedHoldArray.push(str);
          } else {
            this.selectedHoldArray.push(str);
            break;
          }
        }
      }
      console.log('selectedHoldArray', this.selectedHoldArray);

      //* below code is to populate the hold period in the history table in holdLoopArray
      for (let i = 0; i < this.holdUserList.length; i++) {
        this.holdLoopArray = [];
        var endDateLoopPeriod = this.holdUserList[i].endPeriod.toUpperCase();
        startLoopIndex = this.months.indexOf(this.holdUserList[i].startPeriod.slice(0, 3).toUpperCase());
        endLoopIndex = this.months.indexOf(this.holdUserList[i].endPeriod.slice(0, 3).toUpperCase());
        diffYr = parseInt(this.holdUserList[i].endPeriod.slice(4)) - parseInt(this.holdUserList[i].startPeriod.slice(4));
        if (diffYr == 0) {
          for (let j = startLoopIndex; j < endLoopIndex + 1; j++) {
            var str = this.months[j] + '-' + this.holdUserList[i].startPeriod.slice(4);
            this.holdLoopArray.push(str);
          }
        } else {
          var index = startLoopIndex - 1;
          var changeYr = parseInt(this.holdUserList[i].startPeriod.slice(4));
          for (let k = 0; k < (diffYr * 15); k++) {
            index += 1;
            countHold += 1
            if (index == 11) {
              var str = this.months[index] + '-' + changeYr;
              index = -1;
              changeYr += 1;
            } else {
              var str = this.months[index] + '-' + changeYr;
            }
            if (str != endDateLoopPeriod) {
              this.holdLoopArray.push(str);
            } else {
              this.holdLoopArray.push(str);
              break;
            }
          }
        }
        console.log('holdLoopArray', this.holdLoopArray);

        // ***below code is to check if the months present in selectedHoldArray is present in holdLoopArray
        // ** if atleast one month in selectedHoldArray is present in holdLoopArray then the loop will break
        // * else it will check till the last array element and exits the loop with this.holdStartnotinHistory = false;
        for (let l = 0; l < this.selectedHoldArray.length; l++) {
          if (this.holdLoopArray.indexOf(this.selectedHoldArray[l]) > -1) {
            this.holdStartnotinHistory = true;
            break;
          } else {
            this.holdStartnotinHistory = false;
          }
        }
        if (this.holdStartnotinHistory) {
          break;
        } else {
          continue;
        }
      }

    } else {
      this.holdStartnotinHistory = false;
    }
  }

  //  ================================= VALIDATION FOR EDIT PAYMENT METHOD ======================================

  // ***this method will check whether the selected hold end period is present in the history table
  // **holdLoopArray will have the array of hold periods in the history table
  // * selectedHoldArray will have the array of selected hold period

  validateEditPaymentDateValidation() {
    if (this.userDetailList.length > 1) {
      this.selectedHoldArray = [];
      var startIndex, endIndex, startLoopIndex, endLoopIndex, countHold;
      var endDatePeriodSelected = this.endMonResume + '-' + this.endYrResume;
      startIndex = this.months.indexOf(this.currentHoldArray.startPeriod.slice(0, 3).toUpperCase());
      endIndex = this.months.indexOf(this.endMonResume);
      var diffYr = parseInt(this.endYrResume) - parseInt(this.currentHoldArray.startPeriod.slice(4));
      if (diffYr == 0) {
        for (let i = startIndex; i < endIndex + 1; i++) {
          var str = this.months[i] + '-' + this.currentHoldArray.startPeriod.slice(4);
          this.selectedHoldArray.push(str);
        }
      } else {
        var index = startIndex - 1;
        var changeYr = parseInt(this.currentHoldArray.startPeriod.slice(4));
        for (let i = 0; i < (diffYr * 15); i++) {
          index += 1;
          if (index == 11) {
            var str = this.months[index] + '-' + changeYr;
            index = -1;
            changeYr += 1;
          } else {
            var str = this.months[index] + '-' + changeYr;
          }
          if (str != endDatePeriodSelected) {
            this.selectedHoldArray.push(str);
          } else {
            this.selectedHoldArray.push(str);
            break;
          }
        }
      }
      console.log('selectedHoldArray', this.selectedHoldArray);

      //* below code is to populate the hold period in the history table in holdLoopArray
      this.holdLoopArray = [];
      for (let i = 0; i < this.userDetailList.length; i++) {
        if (this.userDetailList[i].paymentHoldId != this.currentHoldArray.paymentHoldId) {
          var endDateLoopPeriod = this.userDetailList[i].endPeriod.toUpperCase();
          startLoopIndex = this.months.indexOf(this.userDetailList[i].startPeriod.slice(0, 3).toUpperCase());
          endLoopIndex = this.months.indexOf(this.userDetailList[i].endPeriod.slice(0, 3).toUpperCase());
          var diffYr = parseInt(this.userDetailList[i].endPeriod.slice(4)) - parseInt(this.userDetailList[i].startPeriod.slice(4));
          if (diffYr == 0) {
            for (let j = startLoopIndex; j < endLoopIndex + 1; j++) {
              var str = this.months[j] + '-' + this.userDetailList[i].startPeriod.slice(4);
              this.holdLoopArray.push(str);
            }
          } else {
            var index = startLoopIndex - 1;
            var changeYr = parseInt(this.userDetailList[i].startPeriod.slice(4));
            for (let k = 0; k < (diffYr * 15); k++) {
              index += 1;
              countHold += 1
              if (index == 11) {
                var str = this.months[index] + '-' + changeYr;
                index = -1;
                changeYr += 1;
              } else {
                var str = this.months[index] + '-' + changeYr;
              }
              if (str != endDateLoopPeriod) {
                this.holdLoopArray.push(str);
              } else {
                this.holdLoopArray.push(str);
                break;
              }
            }
          }
        }
      }
      console.log('holdLoopArray', this.holdLoopArray);

      // ***below code is to check if the months present in selectedHoldArray is present in holdLoopArray
      // ** if atleast one month in selectedHoldArray is present in holdLoopArray then the loop will break
      // * else it will check till the last array element and exits the loop with this.holdStartnotinHistory = false;
      for (let l = 0; l < this.selectedHoldArray.length; l++) {
        if (this.holdLoopArray.indexOf(this.selectedHoldArray[l]) > -1) {
          this.holdStartnotinEditHistory = true;
          break;
        } else {
          this.holdStartnotinEditHistory = false;
        }
      }
    } else {
      this.holdStartnotinEditHistory = false;
    }
    console.log('holdStartnotinEditHistory', this.holdStartnotinEditHistory)
  }

  // * this method will navigate back to main page from EditPayment/HoldPayment
  navigateToHoldResume() {
    this.holdPaymentSection = false;
    this.holdTable = true;
    this.navigate = true;
    this.resumePayDiv = true;
    this.spinId = '';
    this.resumePaymentSection = false;
    this.searchSec = true;
    this.holdStartnotinEditHistory = false;
    this.holdStartnotinHistory = false;
    this._location.go('corpHoldResume');
    this.endSec = false;
    this.appService.corpHoldPage = ''
    this.appService.isCorpHoldCreate = false;
  }

  // ============================== SEARCH AND HOLD THE ASSOCIATE METHODS =====================

  // * this method will blur the table section when clicked on the search field
  fadeDiv() {
    $('#tableSection').fadeTo('slow', .4);
    this.spinIdClicked = true;
  }

  // * this method will remove blur the table section when clicked outside the search field
  fadeOut() {
    $('#tableSection').fadeTo('slow', 1);
    this.spinIdClicked = false;
  }

  // * this method will autopopulate all the user details below the search field according to entered input
  filterId() {
    if (this.spinId != undefined) {this.spinId = this.spinId.replace(/[^a-zA-Z0-9 ]/g, "");}
    if (this.spinId.length > 1 && this.spinId != undefined) {
      this.filteredUsers = [];
      this.isLoading = true;
      this.corpHoldService.getEnrolledUserSearch(this.spinId,this.appService.selectedRegion).subscribe((result: any) => {
        this.filteredUsers = result.HoldPaymentSearch.Result;
        for (let i = 0; i < this.filteredUsers.length; i++) {
          this.filteredUsers[i].firstNm = this.filteredUsers[i].firstName;
          this.filteredUsers[i].lastNm = this.filteredUsers[i].lastName;
          this.filteredUsers[i].spinIdNo = this.filteredUsers[i].spinId;
          this.filteredUsers[i].fullName = this.filteredUsers[i].firstName + ' ' + this.filteredUsers[i].lastName;
          this.filteredUsers[i].enrollNo = this.filteredUsers[i].enrollmentNo;
          this.filteredUsers[i].dealerCodeNo = this.filteredUsers[i].dealerCode;
        }
        this.isLoading = false;
      });
    }
  }

  // * this method will remove blur the table section when clicked on the x mark in the search field
  closeSpinSearch() {
    $('#tableSection').fadeTo('slow', 1);
    this.spinId = "";
    this.spinIdClicked = true;
  }

  // * this method get the details of the selected user and populate in the hold payment page
  selectUser(user) {
    //update the url after navigating to HoldPayment page
    this._location.go('corpHoldResume/HoldPayment');
    this.dateValidationError = false;
    this.emptyHoldComment = false;
    this.holdStartnotinHistory = false;
    this.holdStartgreaterThanPresentDate = false;
    // remove the blur effect for tableSection
    $('#tableSection').fadeTo('slow', 1);
    this.commentsHold = '';
    this.holdUserList = [];
    this.navigate = false;
    var fname = '', lname = '';
    this.holduser = { firstName: user.firstName, lastName: user.lastName, dealerCode: user.dealerCode, spinId: user.spinId, enrollmentNo: user.enrollmentNo };
    if (this.holduser.firstName.trim() != '') {
      fname = this.capitalize(this.holduser.firstName.trim());
    }
    if (this.holduser.lastName.trim() != '') {
      lname = this.capitalize(this.holduser.lastName.trim());
    }
    if (fname != '' && lname != '') {
      this.holdUserName = fname + ' ' + lname;
    } else if (fname == '' && lname != '') {
      this.holdUserName = lname;
    } else if (fname != '' && lname == '') {
      this.holdUserName = fname;
    } else {
      this.holdUserName = '';
    }
    this.historyTableLoading = true;
    // *Get the last payment details of the selected user
    this.corpHoldService.getLastPaymentDate(user.spinId, user.enrollmentNo, user.dealerCode).subscribe((result: any) => {
      if (result.PaymentDate.Result.length == 0) {
        this.paymentDate = 'N/A';
      } else {
        var lastdate = new Date(result.PaymentDate.Result[0].lastPaymentDate);
        var month = lastdate.getMonth() + 1;
        var date = lastdate.getDate();
        var year = lastdate.getFullYear()
        this.paymentDate = month + '/' + date + '/' + year;
      }
    }, err => {
      this.paymentDate = 'N/A';
    });
    // *Get the hold history details of the selected user
    this.corpHoldService.getHoldDetails(user.dealerCode, user.spinId, user.enrollmentNo).subscribe((result: any) => {
      console.log('Payment Hold History Table', result.HoldHistory.Result);
      this.holdUserList = result.HoldHistory.Result;
      for (let i = 0; i < this.holdUserList.length; i++) {
        this.corpHoldService.getHoldHistoryComments(this.holdUserList[i].paymentHoldId).subscribe((response: any) => {
          this.holdUserList[i].comments = response.HoldHistoryComments.Result;
        });
      }
      this.historyTableLoading = false;
    }, err => {
      this.holdUserList = [];
      this.historyTableLoading = false;
    });
    this.resumePayDiv = false; //hide the edit payment section
    this.holdPaymentSection = true; //show the hold payment section
    this.spinIdClicked = false; // to remove the blur effect in the main screen
    this.Success = false; //hide the success msg section 
    this.startMon = this.months[this.d.getMonth()]; // to auto populate the hold start period dropdown section
    this.startYr = this.d.getFullYear();
    // to auto populate the hold end period dropdown section
    if (this.startMon == 'DEC') { // check if the start period is DEC then the end period should JAN month with the next year
      this.endMon = this.months[0];
      this.endYr = (parseInt(this.d.getFullYear()) + 1).toString(); // inorder to get next year value, we adding 1 with current year
    } else {
      this.endMon = this.months[this.d.getMonth() + 1];
      this.endYr = this.d.getFullYear();
    }
    this.appService.corpHoldPage = this.holdNavigateTabName;
    this.appService.isCorpHoldCreate = true;
  }

  // *this method will change the end period according to the click of the checkbox
  checkHoldPeriod() {
    if (!this.endSec) { // if checkbox is unchecked the the end period is 'DEC - 2999'
      this.endMon = 'DEC';
      this.endYr = '2999';
    } else { // if checkbox is checked the the end period is next month from the current month
      if (this.startMon == 'DEC') { // check if the start period is DEC then the end period should JAN month with the next year
        this.endMon = this.months[0];
        this.endYr = (parseInt(this.d.getFullYear()) + 1).toString(); // inorder to get next year value, we adding 1 with current year
      } else {
        this.endMon = this.months[this.d.getMonth() + 1];
        this.endYr = this.d.getFullYear();
      }
    }
    this.validateHoldDetails();
  }

  // * this method get the user selected hold start month in the hold payment section
  getStartMon(mon) {
    var presentmon = this.d.getMonth(); // get the current month in the form of number (eg: current month-'JAN',then presentmon=0 )
    var selectedMon = this.months.indexOf(mon); // get the user selected month in the form of number using index of month array
    if (presentmon > selectedMon) { //selected start month less than current month
      var smonth = this.startMon;
      var syear = this.startYr;
      this.startMon = '';
      this.startYr = this.d.getFullYear() + 1;
      var startDateCheck = Date.parse(mon + " " + this.startYr);
      var endDateCheck = Date.parse(this.endMon + " " + this.endYr);
      if (startDateCheck > endDateCheck) {
        this.startMon = smonth;
        this.startYr = syear;
      } else {
        this.startMon = mon;
        this.startYr = this.d.getFullYear() + 1;
      }
    } else { //selected start month greater or equal to current month the start year has to be current year
      this.startYr = this.d.getFullYear();
    }
    this.validateHoldDetails(); // validate all the input fields in the hold payment section
  }

  // change the selected start year
  getStartYear(yr) {
    this.startYr = '';
    this.startYr = yr;
    this.validateHoldDetails();
  }

  // * this method get the user selected hold end month in the hold payment section
  getendMon(mon) {
    this.endMon = '';
    this.endMon = mon;
    var presentmon = this.d.getMonth(); // get the current month in the form of number (eg: current month-'JAN',then presentmon=0 )
    var selectedMon = this.months.indexOf(mon); // get the user selected month in the form of number using index of month array
    if (presentmon > selectedMon) { //selected end month less than current month the end year has to be next year
      this.endYr = this.d.getFullYear() + 1;
      this.getendYear(this.endYr);
    } else { //selected end month greater or equal to current month the end year has to be current year
      this.endYr = this.d.getFullYear();
      this.getendYear(this.endYr);
    }
    this.validateHoldDetails();
  }

  // * this method get the user selected hold end year in the hold payment section
  getendYear(yr) {
    // remove the disabled options in the dropdown
    for (var i = 0; i < this.years.length; i++) {
      $("#yearStart option[value=" + this.years[i] + "]").removeAttr('disabled');
    }
    var endYear = this.endYr;
    this.endYr = '';
    // if the selected end year is less than start year then change the start year to selected end year
    if (yr < this.startYr) {
      this.startYr = endYear;
    }
    // get the index of the selected end year and disable the year above the selected end yr
    var syear = this.years.indexOf(parseInt(yr)) + 1;
    for (var j = syear; j < this.years.length; j++) {
      $("#yearStart option[value=" + this.years[j] + "]").attr('disabled', true);
    }
    this.endYr = yr;
    this.validateHoldDetails();
  }

  // * this method get the user selected reason in the hold payment section
  getReason(reas) {
    this.holdreason = '';
    this.holdreason = reas;
    this.validateHoldDetails();
  }

  // * this method will validate all the input field in the hold payment section
  validateHoldDetails() {
    this.validateHoldStartEndPeriod(); // * this method will validate the start and end period in the hold payment section
    var endDate = '';
    var validateStart = Date.parse("01 " + this.startMon + " " + this.startYr); // selected start date in the form of Date
    var dates = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31']; // dates array with end date of all the months in the year
    if (this.endSec) { // if the end period checkbox is checked
      if (this.endMon == 'FEB') { // this is to determine the last date of the feb month
        var yr = parseInt(this.endYr);
        if (yr % 4 == 0) { // leap year
          endDate = '29 ' + this.endMon + ' ' + this.endYr; // selected end date of leap year Feb Month
        } else {
          endDate = '28 ' + this.endMon + ' ' + this.endYr; // selected end date of non-leap year Feb Month
        }
      } else {
        var index = this.months.indexOf(this.endMon);
        endDate = dates[index] + ' ' + this.endMon + ' ' + this.endYr; // selected end date 
      }
    } else { // if the end period checkbox is unchecked
      endDate = '31 Dec 2999'; // selected end date 
    }
    var validateEnd = Date.parse(endDate); // selected end date in the form of Date

    if (validateStart > validateEnd) { // if the start date is greater than the end date then its an error
      this.dateValidationError = true;
    } else {
      this.dateValidationError = false;
    }

    var todayDate = Date.parse('01 ' + this.months[this.d.getMonth()] + ' ' + this.d.getFullYear()); // present day date in the form of Date

    if (validateStart >= todayDate) { // if the start date is less than the today date then its an error
      this.holdStartgreaterThanPresentDate = false;
    } else {
      this.holdStartgreaterThanPresentDate = true;
    }

    if (this.commentsHold.trim() == '') { // if the comment is empty then its an error
      this.emptyHoldComment = true;
      $("#holdComment").addClass('warningDiv');
      $('#holdComment').focus();
    } else {
      this.emptyHoldComment = false;
      $("#holdComment").removeClass('warningDiv');
    }

    // if there is an error, add red border to the input fields
    if (this.holdStartgreaterThanPresentDate) {
      $("#holdStartMonth").addClass('warningDiv');
      $("#holdStartYear").addClass('warningDiv');
      $('#holdStartMonth').focus();
    } else {
      $("#holdStartMonth").removeClass('warningDiv');
      $("#holdStartYear").removeClass('warningDiv');
    }
  }

  // ** this method is to do the validation when the hold payment button is clicked
  // ** Conditions to check -->
  // 1. Start Date should be greater or equal to today's date
  // 2. Start Date should not be greater than the end date
  // 3. Comment should not be empty
  // 4. selected hold period should not be present in the hold history table
  holdPaymentDetails() {
    this.validateHoldDetails();
    if ((!this.dateValidationError) && (this.commentsHold.trim() != '') && (!this.holdStartnotinHistory) && (!this.holdStartgreaterThanPresentDate)) {
      $("#holdStartMonth").removeClass('warningDiv');
      $("#holdStartYear").removeClass('warningDiv');
      $("#holdEndMonth").removeClass('warningDiv');
      $("#holdEndYear").removeClass('warningDiv');
      $("#holdComment").removeClass('warningDiv');
      $("#holdPayModal").removeClass('hide_block');
      $("#holdPayModal").addClass('display_block');
      $("#holdPayModal").show("slow");
    } else {
      if (this.dateValidationError || this.holdStartnotinHistory) {
        $("#holdStartMonth").addClass('warningDiv');
        $("#holdStartYear").addClass('warningDiv');
        $("#holdEndMonth").addClass('warningDiv');
        $("#holdEndYear").addClass('warningDiv');
        $('#holdStartMonth').focus();
      }
      if (!this.dateValidationError || !this.holdStartnotinHistory) {
        $("#holdStartMonth").removeClass('warningDiv');
        $("#holdStartYear").removeClass('warningDiv');
        $("#holdEndMonth").removeClass('warningDiv');
        $("#holdEndYear").removeClass('warningDiv');
      }
      if (this.dateValidationError) {
        $("#holdStartMonth").removeClass('warningDiv');
        $("#holdStartYear").removeClass('warningDiv');
        $("#holdEndMonth").addClass('warningDiv');
        $("#holdEndYear").addClass('warningDiv');
        $('#holdEndMonth').focus();
      }
      if (this.holdStartgreaterThanPresentDate) {
        $("#holdStartMonth").addClass('warningDiv');
        $('#holdStartMonth').focus();
      } else {
        $("#holdStartMonth").removeClass('warningDiv');
      }
      if (this.commentsHold.trim() == '') {
        this.emptyHoldComment = true;
        $("#holdComment").addClass('warningDiv');
        $("#holdComment").focus();
      }
      if (this.commentsHold.trim() != '') {
        this.emptyHoldComment = false;
        $("#holdComment").removeClass('warningDiv');
      }
    }
  }

  // Close the modal pop up when cancel button is clicked in the hold payment confirmation modal pop up
  cancelHoldPayment() {
    $("#holdPayModal").removeClass('display_block');
    $("#holdPayModal").addClass('hide_block');
    $("#holdPayModal").hide("slow");
  }

  //  ============================== RESUME THE PAYMENT METHODS =============================

  // * this method will be called when navigated to the edit payment page from the active hold and future hold table
  EditPaymentDetails(user) {
    this.showTrash = false;
    this.corpHoldService.getHoldDetails(user.dealerCode, user.spinId, user.enrollmentNo).subscribe((result: any) => {
      let holdResponse = result.HoldHistory.Result
      for (let i = 0; i < holdResponse.length; i++) {
      let createdDate = new Date (holdResponse[i].createdDate);
      console.log(createdDate, createdDate.getFullYear());
      if ((createdDate.getMonth() == new Date().getMonth()) && (createdDate.getFullYear() == new Date().getFullYear())) {
        this.showTrash = true;
      } 
      }
    })

    if (this.holdNavigateTabName == 'Active Hold') { // if the tab is activehold 
      this._location.go('corpHoldResume/ActiveHold'); // url should be updated
      this.appService.corpHoldPage = this.holdNavigateTabName;
    } else if (this.holdNavigateTabName == 'Future Hold') {
      this._location.go('corpHoldResume/FutureHold'); // url should be updated
      this.appService.corpHoldPage = this.holdNavigateTabName;
    }
    $('#tableSection').fadeTo('slow', 1); // remove the blur effect in the search field
    if (this.selectedView == 1 || this.selectedView == 3) { // active hold is 1 and future hold is 3
      this.userDetail = user; // selected row in the table
      // get the hold history of the selected user
      this.historyTableLoading = true;
      this.corpHoldService.getHoldDetails(user.dealerCode, user.spinId, user.enrollmentNo).subscribe((result: any) => {
        console.log('Selected', result.HoldHistory.Result);
        this.userDetailList = result.HoldHistory.Result;
        for (let i = 0; i < this.userDetailList.length; i++) {
          // get the hold history comments of the selected user
          this.corpHoldService.getHoldHistoryComments(this.userDetailList[i].paymentHoldId).subscribe((response: any) => {
            this.userDetailList[i].comments = response.HoldHistoryComments.Result;
          });
        }
        this.historyTableLoading = false;
        for (let i = 0; i < this.userDetailList.length; i++) {
          if (user.paymentHoldId == this.userDetailList[i].paymentHoldId) {
            this.currentHoldArray = this.userDetailList[i];
            // console.log('currentHoldArray', this.currentHoldArray);
          }
        }

        this.endMonResume = this.currentHoldArray.endPeriod.slice(0, 3).toUpperCase(); //hold end period month
        if (this.currentHoldArray.endPeriod.slice(4) == '2999') { // since 2999 will not be in the take the last year in the this.years array
          this.endYrResume = this.years[(this.years.length) - 1];
        } else {
          this.endYrResume = this.currentHoldArray.endPeriod.slice(4);
        }

        var datstart = '01 ' + this.currentHoldArray.startPeriod.slice(0, 3) + " " + this.currentHoldArray.startPeriod.slice(4); // hold start period 
        var validateStartDate = Date.parse(datstart); // start period in the form of Date
        var dates = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];
        if (this.endMonResume == 'FEB') {
          var yr = parseInt(this.endYrResume);
          if (yr % 4 == 0) {
            var validateEndDate = Date.parse(' 29 Feb ' + yr);
           } 
          //else {
          //   var validateEndDate = Date.parse(' 29 Feb ' + yr);
          // }
        } else {
          var index = this.months.indexOf(this.endMonResume);
          var datend = dates[index] + ' ' + this.endMonResume + ' ' + this.endYrResume;
          var validateEndDate = Date.parse(datend); // end period in the form of Date
        }

        var startMonth = this.currentHoldArray.startPeriod.slice(0, 3).toUpperCase();

        // ** if condition is to do the validate the hold period
        // ** Conditions to check -->
        // 2. Start Date should not be greater than the end date
        // 4. selected hold end period should not be same as the end period already put on hold
        if ((validateStartDate < validateEndDate) || (this.endMonResume == startMonth)) {
          this.resumeStartDateGreater = false;
        } else {
          this.resumeStartDateGreater = true;
        }
      }, err => {
        this.userDetailList = [];
        this.historyTableLoading = false;
      });
      this.searchSec = false;
      this.navigate = false;
      this.userDetailList = [];
      this.resumePaymentSection = true;
      this.holdTable = false;
    }
  }

  //* resume the payment for the associate when trash button is clicked
  delete() {
    this.deleteUser = this.userDetail;
    var fname = '', nameArr = [], fullname = '', name = '';
    name = this.userDetail.name.split(' ');
    for (let i = 0; i < name.length; i++) {
      if (name[i].trim() != '') {
        fname = this.capitalize(name[i].trim());
        nameArr.push(fname);
      }
    }

    for (let i = 0; i < nameArr.length; i++) {
      fullname += nameArr[i] + ' ';
    }

    this.deleteUserName = fullname.trim();
    // console.log('Delete', this.deleteUser);
  }

  // Validate the comments in the edit payment modal : comments should not be empty
  validateComment() {
    if (this.commentsResume.trim() == '') {
      $('#commentResumeInput').addClass('warningDiv');
      $('#HoldCommentSection').addClass('errorInfo');
      $('#commentResumeInput').focus();
    } else {
      $('#commentResumeInput').removeClass('warningDiv');
      $('#HoldCommentSection').removeClass('errorInfo');
    }
  }

  // * this method is to open the confirmation modal pop up and validate to edit the hold end period of the particular associate
  extendHoldPeriodModalPopUp() {
    var fname = '', nameArr = [], fullname = '', name = '';
    name = this.userDetail.name.split(' ');
    for (let i = 0; i < name.length; i++) {
      if (name[i].trim() != '') {
        fname = this.capitalize(name[i].trim());
        nameArr.push(fname);
      }
    }

    for (let i = 0; i < nameArr.length; i++) {
      fullname += nameArr[i] + ' ';
    }

    this.userName = fullname.trim();

    var checkEndPeriod = this.capitalize(this.endMonResume) + '-' + this.endYrResume;
    if (this.currentHoldArray.endPeriod == checkEndPeriod) {
      this.resumeSameDateValidation = true;
    } else {
      this.resumeSameDateValidation = false;
    }

    $("#resumePayModal").removeClass('hide_block');
    $("#resumePayModal").addClass('display_block');
    $("#resumePayModal").show("slow");
  }

  cancelEditPayment() {
    $("#resumePayModal").removeClass('display_block');
    $("#resumePayModal").addClass('hide_block');
    $("#resumePayModal").hide("slow");

    // Remove Comment Section Warning
    $('#commentResumeInput').removeClass('warningDiv');
    $('#HoldCommentSection').removeClass('errorInfo');
  }

  getendMonResume(mon) {
    this.endMonResume = '';
    this.endMonResume = mon;
    var presentmon = this.d.getMonth();
    var selectedMon = this.months.indexOf(mon);
    if (presentmon > selectedMon) {
      var endResumeyr = this.d.getFullYear();
      $("#yearResumeEnd option[value=" + endResumeyr + "]").attr('disabled', 'disabled');
      this.endYrResume = this.d.getFullYear() + 1;
    } else {
      this.endYrResume = this.d.getFullYear();
      $("#yearResumeEnd option[value=" + this.endYrResume + "]").removeAttr('disabled');
    }
    var checkEndPeriod = this.capitalize(this.endMonResume) + '-' + this.endYrResume;
    if (this.currentHoldArray.endPeriod == checkEndPeriod) {
      this.resumeSameDateValidation = true;
    } else {
      this.resumeSameDateValidation = false;
    }

    var datstart = '01 ' + this.currentHoldArray.startPeriod.slice(0, 3) + " " + this.currentHoldArray.startPeriod.slice(4);
    var validateStartDate = Date.parse(datstart);
    var dates = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];
    if (this.endMonResume == 'FEB') {
      var yr = parseInt(this.endYrResume);
      if (yr % 4 == 0) {
        var validateEndDate = Date.parse(' 29 Feb ' + yr);
      } 
      // else {
      //   var validateEndDate = Date.parse(' 29 Feb ' + yr);
      // }
    } else {
      var index = this.months.indexOf(this.endMonResume);
      var datend = dates[index] + ' ' + this.endMonResume + ' ' + this.endYrResume;
      var validateEndDate = Date.parse(datend);
    }

    var startMonth = this.currentHoldArray.startPeriod.slice(0, 3).toUpperCase();
    if ((validateStartDate < validateEndDate) || ((this.endMonResume == startMonth) && (this.endYrResume == this.currentHoldArray.startPeriod.slice(4)))) {
      this.resumeStartDateGreater = false;
    } else {
      this.resumeStartDateGreater = true;
    }

    this.validateEditPaymentDateValidation();
  }

  getendYrResume(yr) {
    this.endYrResume = '';
    this.endYrResume = yr;
    var checkEndPeriod = this.capitalize(this.endMonResume) + '-' + this.endYrResume;
    if (this.currentHoldArray.endPeriod == checkEndPeriod) {
      this.resumeSameDateValidation = true;
    } else {
      this.resumeSameDateValidation = false;
    }

    var datstart = '01 ' + this.currentHoldArray.startPeriod.slice(0, 3) + " " + this.currentHoldArray.startPeriod.slice(4);
    var validateStartDate = Date.parse(datstart);
    var dates = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];
    if (this.endMonResume == 'FEB') {
      var yrs = parseInt(this.endYr);
      if (yrs % 4 == 0) {
        var validateEndDate = Date.parse(' 29 Feb ' + yrs);
      } 
      // else {
      //   var validateEndDate = Date.parse(' 29 Feb ' + yrs);
      // }
    } else {
      var index = this.months.indexOf(this.endMonResume);
      var datend = dates[index] + ' ' + this.endMonResume + ' ' + this.endYrResume;
      var validateEndDate = Date.parse(datend);
    }

    var startMonth = this.currentHoldArray.startPeriod.slice(0, 3).toUpperCase();
    if ((validateStartDate < validateEndDate) || ((this.endMonResume == startMonth) && (this.endYrResume == this.currentHoldArray.startPeriod.slice(4)))) {
      this.resumeStartDateGreater = false;
    } else {
      this.resumeStartDateGreater = true;
    }

    this.validateEditPaymentDateValidation();
  }

  getResumereason(res) {
    this.Resumereason = '';
    this.Resumereason = res;
  }

  // ==============================  COMMON METHOD =============================================================

  capitalize(s) {
    return s
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word[0].trim().toUpperCase() + word.substr(1);
      })
      .join(' ');
  }

  // ============================ HOLD PAYMENT SERVICE CALL METHOD =====================================

  HoldPaymentService() {
    this.holdLoading = true;
    this.appComponent.getCategoryOneandTwo(this.holduser.dealerCode);
    this.cancelHoldPayment();
    var startDate = this.startMon + ' 01 ' + this.startYr + ' 00:00:00';
    var endDate;
    var dates = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];
    if (this.endSec) {
      if (this.endMon == 'FEB') {
        var yr = parseInt(this.endYr);
        if (yr % 4 == 0) {
          endDate = this.endMon + ' 29 ' + this.endYr + ' 00:00:00';
        } else {
          endDate = this.endMon + ' 28 ' + this.endYr + ' 00:00:00';
        }
      } else {
        var index = this.months.indexOf(this.endMon);
        endDate = this.endMon + ' ' + dates[index] + ' ' + this.endYr + ' 00:00:00';
      }
    } else {
      endDate = 'DEC 31 2999 00:00:00';
    }
    this.holdAssociate = {
      "firstName": this.holduser.firstName,
      "lastName": this.holduser.lastName,
      "dealerCode": this.holduser.dealerCode,
      "enrollmentNo": this.holduser.enrollmentNo,
      "spinId": this.holduser.spinId,
      "startPeriod": new Date(startDate).toISOString(),
      "endPeriod": new Date(endDate).toISOString(),
      "holdBy": this.appService.loggedInUserName,
      "reason": this.holdreason,
      "comments": this.commentsHold
    }
    this.corpHoldService.associateHoldPayment(this.holdAssociate).subscribe((response: any) => {
      if (response.RowUpdated.rowCount == 1) {
        console.log('Holded');
        this.Success = true;
        if (this.endSec) {
          this.succesMessage = 'Payments have been put on hold for ' + this.holdUserName + ' from ' + this.capitalize(this.startMon) + ' ' + this.startYr + ' to ' + this.capitalize(this.endMon) + ' ' + this.endYr + '.';
        } else {
          this.succesMessage = 'Payments have been put on hold for ' + this.holdUserName + ' from ' + this.capitalize(this.startMon) + ' ' + this.startYr + ' onwards';
          console.log('Unable to Hold');
        }
        if (parseInt(this.startYr) > this.d.getFullYear()) {
          this.select(3);
        } else {
          this.select(1);
        }
        // Call for notification HOLD
        this.notificationService.insertNotification('Associate has been put on Hold', 'System_HoldPayment',
          '12345', this.holduser.dealerCode, 'Payment on Hold', 'Associate ' + this.holdUserName + ' ' + this.holduser.spinId + ' has been put on Hold from ' + (startDate + "").substring(0, 11) + ' to ' + (endDate + "").substring(0, 11),
          'Info', 'No', "ActionLink", "SYSTEM", '').subscribe((result: any) => {
            if (result.RowUpdated.rowCount == 1) {
              this.notificationService.getMaxIdNotification().subscribe((response: any) => {
                var id = response.Id.Result[0].NOTIFY_ID
                console.log(id);
                for (let i = 0; i < this.appService.category1.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category1[i].spinId, 'lexusmasterlease@lexus.com', 'System_HoldPayments',
                    'Payment on Hold', 'Associate ' + this.holdUserName + ' ' + this.holduser.spinId + ' has been put from Hold from ' + (startDate + "").substring(0, 11) + ' to ' + (endDate + "").substring(0, 11),
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Inserted Notification');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                for (let i = 0; i < this.appService.category2.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category2[i].spinId, 'lexusmasterlease@lexus.com', 'System_HoldPayments',
                    'Payment on Hold', 'Associate ' + this.holdUserName + ' ' + this.holduser.spinId + ' has been put from Hold from ' + (startDate + "").substring(0, 11) + ' to ' + (endDate + "").substring(0, 11),
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Inserted Notification');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                for (let i = 0; i < this.appService.category3.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category3[i].spinId, 'lexusmasterlease@lexus.com', 'System_HoldPayments',
                    'Payment on Hold', 'Associate ' + this.holdUserName + ' ' + this.holduser.spinId + ' has been put from Hold from ' + (startDate + "").substring(0, 11) + ' to ' + (endDate + "").substring(0, 11),
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Inserted Notification');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                for (let i = 0; i < this.appService.category4.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category4[i].workdayId, this.appService.category4[i].emailId, 'System_HoldPayments',
                    'Payment on Hold', 'Associate ' + this.holdUserName + ' ' + this.holduser.spinId + ' has been put from Hold from ' + (startDate + "").substring(0, 11) + ' to ' + (endDate + "").substring(0, 11),
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Inserted Notification');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                for (let i = 0; i < this.appService.category5.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category5[i].workdayId, this.appService.category5[i].emailId, 'System_HoldPayments',
                    'Payment on Hold', 'Associate ' + this.holdUserName + ' ' + this.holduser.spinId + ' has been put from Hold from ' + (startDate + "").substring(0, 11) + ' to ' + (endDate + "").substring(0, 11),
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Inserted Notification');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                this.appComponent.getUnreadNotifications();
              })
            }
          }, err => {
            console.log('Error in insert notification');
          });
        setTimeout(function () {
          this.Success = false;
        }.bind(this), 9000);
      } else {
        this.Error = true;
        this.errorMessage = 'Payments for ' + this.holdUserName + ' has not been put on hold. ';
      }
      if (this.startMon == 'DEC') {
        this.endMon = this.months[0];
        this.endYr = (parseInt(this.d.getFullYear()) + 1).toString();
      } else {
        this.endMon = this.months[this.d.getMonth() + 1];
        this.endYr = this.d.getFullYear();
      }
      this.navigateToHome();
      this.holdLoading = false;
    }, err => {
      console.log('Error while trying to put the associate on hold');
      this.Error = true;
      this.errorMessage = 'Payments for ' + this.holdUserName + ' has not been put on hold. ';
      this.navigateToHome();
      this.holdLoading = false;
    });
  }

  navigateToHome() {
    this.navigate = true;
    this.resumePayDiv = true;
    this.holdPaymentSection = false;
    this.endSec = false;
    this.commentsHold = '';
    this.spinId = '';
    this.holdreason = 'Medical Leave';
    this._location.go('corpHoldResume');
  }


  // ============================== DELETE HOLD AND RESUME PAYMENT ====================================

  deleteHoldService() {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var username = currentUser['preferred_name'];
    this.appComponent.getCategoryOneandTwo(this.userDetail.dealerCode);
    this.corpHoldService.resumeAssociatePayment(this.currentHoldArray.paymentHoldId, username).subscribe((response: any) => {
      if (response.RowUpdated.rowCount == 1) {
        this.Success = true;
        this.succesMessage = 'Payments for ' + this.deleteUserName + ' will be resumed beginning next payment cycle.';
        setTimeout(function () {
          this.Success = false;
        }.bind(this), 9000);

        // Call for notification DELETE
        this.notificationService.insertNotification('Associate has been removed from Hold', 'System_HoldPayment',
          '12345', this.userDetail.dealerCode, 'Payment Resumed', 'Associate ' + this.deleteUserName + ' ' + this.userDetail.spinId + ' has been removed from Hold',
          'Info', 'No', "ActionLink", "SYSTEM", '').subscribe((result: any) => {
            if (result.RowUpdated.rowCount == 1) {
              console.log('Notification Added for payment Resumed');
              this.notificationService.getMaxIdNotification().subscribe((response: any) => {
                var id = response.Id.Result[0].NOTIFY_ID
                console.log(id);
                for (let i = 0; i < this.appService.category1.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category1[i].spinId, 'lexusmasterlease@lexus.com',
                    'Syestem_HoldPayments', 'Payment Resumed', 'Associate ' + this.deleteUserName + ' ' + this.userDetail.spinId + ' has been removed from Hold',
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Notification Inserted');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                for (let i = 0; i < this.appService.category2.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category2[i].spinId, 'lexusmasterlease@lexus.com',
                    'Syestem_HoldPayments', 'Payment Resumed', 'Associate ' + this.deleteUserName + ' ' + this.userDetail.spinId + ' has been removed from Hold',
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Notification Inserted');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                for (let i = 0; i < this.appService.category3.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category3[i].spinId, 'lexusmasterlease@lexus.com',
                    'Syestem_HoldPayments', 'Payment Resumed', this.deleteUserName + ' ' + this.userDetail.spinId + ' has been removed from Hold',
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Notification Inserted');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                for (let i = 0; i < this.appService.category4.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category4[i].workdayId, this.appService.category4[i].emailId,
                    'Syestem_HoldPayments', 'Payment Resumed', this.deleteUserName + ' ' + this.userDetail.spinId + ' has been removed from Hold',
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Notification Inserted');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                for (let i = 0; i < this.appService.category5.length; i++) {
                  this.notificationService.insertNotificationDetails(id,
                    this.appService.category5[i].workdayId, this.appService.category5[i].emailId,
                    'Syestem_HoldPayments', 'Payment Resumed', this.deleteUserName + ' ' + this.userDetail.spinId + ' has been removed from Hold',
                    'Info', 'N', 'N', "SYSTEM").subscribe((final: any) => {
                      console.log('Notification Inserted');
                    }, err => {
                      console.log('Error in Notification Insert');
                    });
                }
                this.appComponent.getUnreadNotifications();
              })
            }
          }, err => {
            console.log('Error in adding notification for resume payment');
          });
      } else {
        this.Error = true;
        this.errorMessage = 'Payments for ' + this.deleteUserName + ' was not resumed';
      }
      this.navigatefromResume();
    }, err => {
      console.log('Error while trying to resume payment for the associate');
      this.Error = true;
      this.errorMessage = 'Payments for ' + this.deleteUserName + ' was not resumed';
      this.navigatefromResume();
    });
  }

  navigatefromResume() {
    this.select(1);
    this.navigate = true;
    this.resumePayDiv = true;
    this.holdPaymentSection = false;
    this.spinId = '';
    this.holdTable = true;
    this.resumePaymentSection = false;
    this.searchSec = true;
    this._location.go('corpHoldResume');
  }


  // ================================= EXTEND THE HOLD END PERIOD SERVICE CALL METHOD ======================

  extendHoldService() {
    if ((this.commentsResume.trim() != '') && (!this.resumeSameDateValidation) && (!this.resumeStartDateGreater) && (!this.holdStartnotinEditHistory)) {
      this.cancelEditPayment();
      this.navigate = true;
      this.resumePayDiv = true;
      this.holdPaymentSection = false;
      this.spinId = '';
      this.holdTable = true;
      this.resumePaymentSection = false;
      this.searchSec = true;

      var endDate;
      var dates = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];
      if (this.endMonResume == 'FEB') {
        var yr = parseInt(this.endYrResume);
        if (yr % 4 == 0) {
          endDate = this.endMonResume + ' 29 ' + this.endYrResume + ' 00:00:00';
        } else {
          endDate = this.endMonResume + ' 28 ' + this.endYrResume + ' 00:00:00';
        }
      } else {
        var index = this.months.indexOf(this.endMonResume);
        endDate = this.endMonResume + ' ' + dates[index] + ' ' + this.endYrResume + ' 00:00:00';
      }
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.extendAssociate = {
        "endPeriod": new Date(endDate).toISOString(),
        "paymentHoldId": this.currentHoldArray.paymentHoldId,
        "holdBy": currentUser['preferred_name'],
        "comments": this.commentsResume
      }
      if (this.endMonResume == 'DEC') {
        var monthName = this.months[0];
        var nextYear = parseInt(this.endYrResume) + 1;
        var year = nextYear.toString();
      } else {
        var nextMonth = this.months.indexOf(this.endMonResume) + 1;
        var monthName = this.months[nextMonth];
        var year = this.endYrResume;
      }
      console.log('Extend', this.extendAssociate);
      this.corpHoldService.extendAssociatePayment(this.extendAssociate).subscribe((response: any) => {
        if (response.RowUpdated.rowCount == 1) {
          this.Success = true;
          this.succesMessage = 'Payments for ' + this.userName + ' will be put on hold till ' + this.capitalize(this.endMonResume) + '-' + this.endYrResume + '.';
          setTimeout(function () {
            this.Success = false;
          }.bind(this), 9000);
          this.select(1);

        } else {
          this.Error = true;
          this.errorMessage = 'Payments for ' + this.userName + ' has not been resumed.';
        }
        this._location.go('corpHoldResume');
        this.endMonResume = this.months[this.d.getMonth() + 1];
        if (this.months.indexOf(this.endMonResume) > 11) {
          this.endYrResume = this.d.getFullYear() + 1;
        } else {
          this.endYrResume = this.d.getFullYear();
        }
        this.commentsResume = '';

      }, err => {
        this.Error = true;
        this.errorMessage = 'Payments for ' + this.userName + ' has not been resumed.';
        this.commentsResume = '';
        this.navigate = true;
        this.resumePayDiv = true;
        this.holdPaymentSection = false;
        this.spinId = '';
        this.holdTable = true;
        this.resumePaymentSection = false;
        this.searchSec = true;
        this._location.go('corpHoldResume');
      });
    } else {
      if (this.commentsResume.trim() == '') {
        $('#commentResumeInput').addClass('warningDiv');
        $('#HoldCommentSection').addClass('errorInfo');
        $('#commentResumeInput').focus();
      } else if (this.commentsResume.trim() != '') {
        $('#commentResumeInput').removeClass('warningDiv');
        $('#HoldCommentSection').removeClass('errorInfo');
      } else {
        $('#extendHoldWarningDiv').focus();
      }
    }
  }


  // ========================== DOWNLOAD THE TABLE DATA IN THE EXCEL ====================================

  exportFile() {
    var name = '';
    if (this.selectedView == 1) {
      name = 'Active_Hold_Summary';
    } else if (this.selectedView == 2) {
      name = 'Past_Hold_Summary';
    } else {
      name = 'Future_Hold_Summary';
    }
    if (this.filterSelected) {
      if (this.exportData[0].length != 0) {
        this.excelService.exportAsExcelFile(this.exportData[0], name);
      } else {
        this.showDownloadErrorModal();
      }
    } else {
      if (this.corpholdDataSource.data.length != 0) {
        this.excelService.exportAsExcelFile(this.corpholdDataSource.data, name);
      } else {
        this.showDownloadErrorModal();
      }
    }
  }

  showDownloadErrorModal() {
    $("#errorExcelModal").removeClass('hide_block');
    $("#errorExcelModal").addClass('display_block');
    $("#errorExcelModal").show("slow");
  }

  closeDownloadErrorModal() {
    $("#errorExcelModal").removeClass('display_block');
    $("#errorExcelModal").addClass('hide_block');
    $("#errorExcelModal").show("slow");
  }
}
