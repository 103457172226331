import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from './login.service';
import { CommonService } from '../service/common.service'

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  userid: string = '';
  userRole: string = '';
  invalidID: boolean = false;
  invalidPassword: boolean = false;
  loginDetailResponse: any;
  optionLoading: boolean = false;
  selectedName: string = '';
  dealerCode: string = '';
  workdayId: string = '';
  invalidUser: boolean = false;
  error: boolean = false;
  isPrincipal: boolean = false;
  isGM: boolean = false;
  controller: boolean = false;
  listLoading: boolean = false;
  userListResponse: any = '';
  staffMasterUserListResponse: any = '';
  userListArray: any = [];
  noUserDetails: boolean = false;
  userListwithSpinArray: any = [];
  pcUser: boolean;
  Password: string = '';
  successUserId: boolean = false;
  successPassword: boolean = false;
  emptyField: boolean = false;
  invalidCredential: boolean;

  constructor(public loginService: LoginService, public appService: CommonService) {
  }

  ngOnInit() {
  }

  @Output() loggedIn: EventEmitter<any> = new EventEmitter();

  blurInput(key) {
    this.invalidCredential = false;
    var input = $('.validate-input .input');
    if (key == 'userID') {
      this.userid = input[0].value;
      if (this.userid.trim() != "") {
        $('input[name="userID"]').addClass('has-val');
      } else {
        $('input[name="userID"]').removeClass('has-val');
      }
    }
    else if (key == 'password') {
      this.Password = input[1].value;
      if (this.Password.trim() != "") {
        $('input[name="password"]').addClass('has-val');
      } else {
        $('input[name="password"]').removeClass('has-val');
      }
    }
  }

  validateUserID() {
    var input = $('.validate-input .input');
    if (this.userid.trim() == '') {
      this.showValidate(input[0]);
      $(input[0]).focus();
      return true;
    } else {
      this.hideValidate(input[0]);
      return false;
    }
  }

  validatePassword() {
    var input = $('.validate-input .input');
    if (this.Password.trim() == '') {
      this.showValidate(input[1]);
      return true;
    } else {
      this.hideValidate(input[1]);
      return false;
    }
  }

  Login() {
    var input = $('.validate-input .input');
    this.userid = input[0].value;
    this.Password = input[1].value;
    this.emptyField = false;
    this.invalidCredential = false;
    var spinID = this.userid;
    var regexPattern = RegExp('^[0-9]*$');
    this.optionLoading = true;
    this.loginDetailResponse = '';
    this.selectedName = '';
    if (this.userid == '' && this.Password == '') {
      this.emptyField = true;
    } else if (this.userid == '' && this.Password != '') {
      this.emptyField = true;
    } else if (this.userid != '' && this.Password == '') {
      this.emptyField = true;
    } else if (this.userid != '' && this.Password != '') {
      if (regexPattern.test(spinID)) {
        if (this.userid.length == 6) {
          this.getWorkDay();
          this.dealerCode = '';
        } else if (this.userid.length == 12) {
          this.selectedName = '';
          this.loginDetailResponse = '';   
          this.getSpinDayId();
        } else {
          this.invalidCredential = true;
        }
      } else {
        this.invalidCredential = true;
      }
    }
  }

  getWorkDay(){
    this.loginService.getUserLoginDetails(this.userid).subscribe((response: any) => {
      // console.log('LoginDetails', response.LoginDetails.Result);
      this.loginDetailResponse = response.LoginDetails.Result[0];
      if (response.LoginDetails.Result.length == 0) {
        this.invalidCredential = true;
        this.optionLoading = false;
      } else {
        this.workdayId = this.loginDetailResponse.workdayId;
        this.selectedName = this.loginDetailResponse.firstName + ' ' + this.loginDetailResponse.lastName;
        if (this.loginDetailResponse.isLexusCollegeAdmin == 'Y') {
          this.userRole = 'Lexus College';
          this.successUserId = true;
          $('#LoginBtn').addClass('login-success-btn-color');
        }
        if (this.loginDetailResponse.isLexusUser == 'Y') {
          this.userRole = 'Lexus Associate';
          this.successUserId = true;
          $('#LoginBtn').addClass('login-success-btn-color');
        }
        if (this.loginDetailResponse.isAdmin == 'Y') {
          this.userRole = 'Admin';
          this.successUserId = true;
          $('#LoginBtn').addClass('login-success-btn-color');
        }
        if (this.loginDetailResponse.isAreaAdmin == 'Y') {
          this.userRole = 'Area Admin';
          this.successUserId = true;
          $('#LoginBtn').addClass('login-success-btn-color');
        }
        
        if (this.Password == 'Toyota@123') {
          this.invalidCredential = false;
        } else {
          this.invalidCredential = true;
        }

        if (this.userRole != '' && this.Password == 'Toyota@123') {
          const loginDetails = {
            'spinId': this.userid,
            'userName': this.selectedName,
            'userRole': this.userRole,
            'dealerCode': this.dealerCode,
            'isPrincipal': this.isPrincipal,
            'isGm':this.isGM,
            'controller': this.controller,
            'PcUser': this.pcUser,
            'workdayId': this.workdayId,
          };
          this.loggedIn.emit(loginDetails);
        }

      }
      this.pcUser = false;
      this.optionLoading = false;
    }, err => {
      this.selectedName = '';
      this.error = true;
    });
  }

  getSpinDayId(){
    this.loginService.getUserDetailUsingSpinId(this.userid,this.appService.dc).subscribe((response: any) => {
      // console.log('LoginDetails', response.LoginDetails.Result);
      //debugger;
      this.loginDetailResponse = response.LoginDetails.Result[0];
      if (response.LoginDetails.Result.length == 0) {
        this.invalidCredential = true;
        this.optionLoading = false;
      } else {
        this.selectedName = this.loginDetailResponse.firstName + ' ' + this.loginDetailResponse.lastName;
        this.dealerCode = this.loginDetailResponse.dealerCode;
        if (this.loginDetailResponse.isPc.toUpperCase() == 'TRUE' || this.loginDetailResponse.jobDescription.toUpperCase() == 'DEALER PRINCIPAL' 
            || this.loginDetailResponse.jobDescription.toUpperCase() == 'GENERAL MANAGER' || this.loginDetailResponse.jobDescription.toUpperCase() == 'CONTROLLER') {
          if (this.loginDetailResponse.jobDescription == 'DEALER PRINCIPAL' ) {
            this.isPrincipal = true;
            this.pcUser = false;
            this.isGM = false;
            this.controller = false;
          } else if (this.loginDetailResponse.jobDescription.toUpperCase() == 'GENERAL MANAGER') {
            this.isPrincipal = false; // since principal has same features as gm
            this.pcUser = false;
            this.isGM = true;
            this.controller = false;
          } else if (this.loginDetailResponse.jobDescription.toUpperCase() == 'CONTROLLER') {
            this.isPrincipal = false; // since CONTROLLER has same features as PC
            this.pcUser = false;
            this.isGM = false;
            this.controller = true;
          } else {
            this.isPrincipal = false;
            this.pcUser = true;
            this.isGM = false;
            this.controller = false;
          }
          this.userRole = this.capitalize(this.loginDetailResponse.jobDescription);

          if (this.Password == 'Toyota@123') {
            this.invalidCredential = false;
          } else {
            this.invalidCredential = true;
          }

          if (this.userRole != '' && this.Password == 'Toyota@123') {
            const loginDetails = {
              'spinId': this.userid,
              'userName': this.selectedName,
              'userRole': this.userRole,
              'dealerCode': this.dealerCode,
              'isPrincipal': this.isPrincipal,
              'isGM': this.isGM,
              'controller': this.controller,
              'PcUser': this.pcUser,
              'workdayId': this.workdayId,
            };
            this.loggedIn.emit(loginDetails);
          }

        } else {
          this.invalidCredential = true;
        }
      }
      this.optionLoading = false;
    }, err => {
      this.error = true;
    });
  }

  hideValidate(input) {
    var thisAlert = $(input).parent();
    $(thisAlert).removeClass('alert-validate');
  }

  showValidate(input) {
    var thisAlert = $(input).parent();
    $(thisAlert).addClass('alert-validate');
  }

  showUserList() {
    this.listLoading = true;
    this.userListResponse = '';
    this.noUserDetails = false;
    this.userListArray = [];
    this.loginService.getUserDetailList().subscribe((response: any) => {
      // console.log('USER LIST', response);
      this.userListResponse = response.UserDetails.Result;
      if (this.userListResponse.length == 0) {
        this.noUserDetails = true;
      } else {
        let userRole;
        for (let i = 0; i < this.userListResponse.length; i++) {
          if (this.userListResponse[i].isAdmin == 'Y') {
            userRole = 'Admin'
          } else if (this.userListResponse[i].isLexusCollegeAdmin == 'Y') {
            userRole = 'Lexus College'
          } else if (this.userListResponse[i].isLexusUser == 'Y') {
            userRole = 'Lexus Associate'
          } else if (this.userListResponse[i].isAreaAdmin == 'Y') {
            userRole = 'Area Admin'
          }
          const userdetailObj = {
            'userName': this.userListResponse[i].firstName + ' ' + this.userListResponse[i].lastName,
            'workdayuser': this.userListResponse[i].workdayId,
            'role': userRole
          }
          this.userListArray.push(userdetailObj);
        }
      }
      this.loginService.getUserDetailListWithSpinID(this.appService.selectedRegion).subscribe((response: any) => {
        this.staffMasterUserListResponse = response.UserDetails.Result;
        let staffUserRole;
        // console.log('UserDetails with spin ID', response.UserDetails.Result);
        for (let i = 0; i < this.staffMasterUserListResponse.length; i++) {
          var PC = this.staffMasterUserListResponse[i].isPc.toUpperCase();
          if (PC == "TRUE" || this.staffMasterUserListResponse[i].jobDescription.toUpperCase() == "DEALER PRINCIPAL" 
          ||this.staffMasterUserListResponse[i].jobDescription.toUpperCase() == "GENERAL MANAGER" || this.staffMasterUserListResponse[i].jobDescription.toUpperCase() == "CONTROLLER") {
            staffUserRole = this.capitalize(this.staffMasterUserListResponse[i].jobDescription);
            const userdetailObj = {
              'userName': this.staffMasterUserListResponse[i].firstName + ' ' + this.staffMasterUserListResponse[i].lastName,
              'staffMasteruser': this.staffMasterUserListResponse[i].spinId,
              'dealerCode': this.staffMasterUserListResponse[i].dealerCode,
              'role': staffUserRole,
            }
            this.userListwithSpinArray.push(userdetailObj);
          }
        }
        this.appService.onLoginSpinList = response.UserDetails.Result;
      }, err => {
        console.log('Error in retriving Staff Master User Details')
      });
      this.listLoading = false;
    }, err => {
      this.listLoading = false;
      this.noUserDetails = true;
    });
    // console.log("USER DETAIL LIST", this.userListArray);
  }

  capitalize(s) {
    return s
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word[0].toUpperCase() + word.substr(1);
      })
      .join(' ');
  }
}
