import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatIconModule } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ExcelService } from '../service/excel.service';
import { LexusJobService } from '../lexus-job/lexus-job.service';
declare var $: any;
function reportValidator(fg: FormGroup) {
  const certJobDesc = fg.get('certJobDesc').value;
  const area = fg.get('area').value;
  const nonCertJobDesc = fg.get('nonCertJobDesc').value;
  const dealer = fg.get('dealer').value;
  const certification = fg.get('certification').value;
  return (certJobDesc || nonCertJobDesc || dealer || certification) && area
    ? null
    : { range: true };
}
@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.css']
})
export class UserSummaryComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('ReportPaginator', { read: MatPaginator }) ReportPaginator: MatPaginator;
  searchForm: FormGroup;
  reportArray: any = [];
  reportDataSource: MatTableDataSource<any>;
  reportDataColumns: string[] = ["area", "areaName", "totalCnt", "primaryRole", "secondaryRole"];
  reportDataDistColumns: string[] = ["area", "areaName", "district", "totalCnt", "primaryRole", "secondaryRole"];
  tableLoading = true;
  showSpinner = false;
  exportData: any[];
  filterSelected: boolean;
  lastUpdatedOn: any;
  showMsg = '';
  certJobDescList = [];
  nonCertJobDescList = [];
  dealerList = [];
  areaList = [{ "name": "National", "value": "All" }, { "name": "Western", "value": "310" }, { "name": "Eastern", "value": "330" }, { "name": "Southern", "value": "340" }, { "name": "Central", "value": "320" }]

  constructor(private fb: FormBuilder, private lexusJobService: LexusJobService, private excelService: ExcelService) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      certJobDesc: '',
      area: 'All',
      nonCertJobDesc : '',
      type: 'jobCodeFetch',
      dealer:'',
      certification: 'All'
    }, { validator: reportValidator });
    this.searchForm.controls['certification'].disable();
    this.lexusJobService.getStaffMasterUserDtl(this.searchForm.value).subscribe((response: any) => {
      console.log(response)
      this.certJobDescList = response.certJobDesc.Result;
      this.nonCertJobDescList = response.nonCertJobDesc.Result1;
      this.dealerList = response.dealer.Result2;
    },err=>{
      this.certJobDescList = [];
      this.nonCertJobDescList = [];
      this.dealerList = [];
    })
  }
  disableJobDesc(type) {
    if (type == 'certJobDesc' && this.searchForm.get('certJobDesc').value !='') {
      this.searchForm.controls['nonCertJobDesc'].disable();
      this.searchForm.controls['certification'].enable();
    }
    else if (type == 'nonCertJobDesc' && this.searchForm.get('nonCertJobDesc').value !='') {
      this.searchForm.controls['certJobDesc'].disable();
    }
    else {
      this.searchForm.get('certJobDesc').setValue('');
      this.searchForm.get('nonCertJobDesc').setValue('');
      this.searchForm.controls['certJobDesc'].enable();
      this.searchForm.controls['nonCertJobDesc'].enable();
      this.searchForm.controls['certification'].disable();
    }
  }
  search() {
    this.showMsg = '';
    this.tableLoading = true;
    this.showSpinner = true;
    this.reportArray = [];
    this.exportData = [];
    this.searchForm.get('type').setValue('');
    this.lexusJobService.getStaffMasterUserDtl(this.searchForm.value).subscribe((response: any) => {
      let responseArr = response.Reports.Result;
      for (let i = 0; i < responseArr.length; i++) {
        this.reportArray.push(responseArr[i]);
        this.exportData.push(responseArr[i])
      }
      this.lastUpdatedOn = this.reportArray.length > 0 ? this.reportArray[0].createdOn : new Date()
      this.tableLoading = false;
      this.showSpinner = false;
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    })
  }
  reset() {
    this.searchForm.reset({
      area: 'All',
      certJobDesc: '',
      nonCertJobDesc: '',
      dealer:'',
      certification:'All'
    });
    this.searchForm.controls['certification'].disable();
    this.searchForm.controls['certJobDesc'].enable();
    this.searchForm.controls['nonCertJobDesc'].enable();
    this.reportArray = [];
    this.exportData = []
    this.tableLoading = true;
    this.showSpinner = false;
    this.reportDataSource.sort = this.sort;
    this.reportDataSource = new MatTableDataSource(this.reportArray);
    this.reportDataSource.paginator = this.ReportPaginator;
  }
  download() {
    let reportExportData = [];
    let fileName = '';
    if (this.searchForm.get('area').value == 'All') {
      this.exportData.forEach(data => {
        let obj = {
          "AREA": data.area,
          "AREA NAME": data.areaName,
          "TOTAL COUNT": data.totalCnt,
          "PRIMARY ROLE COUNT": data.primaryRole,
          "SECONDARY ROLE COUNT": data.secondaryRole
        }
        reportExportData.push(obj);
      });
      fileName = 'National_Job_Report'
    }
    else {
      this.exportData.forEach(data => {
        let obj = {
          "AREA": data.area,
          "AREA NAME": data.areaName,
          "DISTRICT": data.district,
          "TOTAL COUNT": data.totalCnt,
          "PRIMARY ROLE COUNT": data.primaryRole,
          "SECONDARY ROLE COUNT": data.secondaryRole
        }
        reportExportData.push(obj);
      });
      fileName = this.searchForm.get('area').value + '_Job_Report'
    }

    this.excelService.exportAsExcelFile(reportExportData, fileName);
  }
  //  ================================= TABLE SEARCH FILTER METHOD ======================================
  applyFilter(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.reportDataSource.filter = filterValue.trim().toLowerCase();
    this.reportDataSource.filteredData.forEach(element => {
      this.exportData.push(element);
    })
    // if (filterValue != '' || this.reportDataSource.filteredData.length < 10) {
    //   $("#holdHistorTable").removeClass('tableOverflow');
    // } else {
    //   $("#holdHistorTable").addClass('tableOverflow');
    // }
  }
}
