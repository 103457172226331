import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class LoginService {
  public getLoginUserDetailURL = "LoginUsingWorkdayId";
  public getLoginUserDetailWithSpinURL = "LoginUsingSpinId";
  public getUserListURL = "GetAccessCtrlUserList";
  public getUserListSpinIdURL = "LoginStaffMasterList";
  public updateStafMasterURL = "updateStaffMaster";
  public updateAccessControlURL = "updateAccessCtrl";
  public checkPilotUserUrl = 'CheckPilotUser';
  public getTestUserUrl = 'GetTestUser';

  constructor(private http: Http, public appService : CommonService) { }

  getUserLoginDetails(workdayId){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "workDayId": workdayId,
    }
    const url = this.appService.baseUrl + this.getLoginUserDetailURL + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  pilotUserCheck(userId) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      "userId": userId,
    }
    const url = this.appService.baseUrl + this.checkPilotUserUrl + this.appService.functionKey;
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getUserDetailUsingSpinId(spinId,dealerCode){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let uid = spinId.slice(3,);
    
    let body = {
      "spinId": uid,
      "dealerCode":dealerCode
    }
    const url = this.appService.baseUrl + this.getLoginUserDetailWithSpinURL + this.appService.functionKey;
    
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getUserDetailList(){ 
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getUserListURL + this.appService.functionKey;
    
    return this.http.get(url, options)
    .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getUserDetailListWithSpinID(region){ 
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getUserListSpinIdURL + this.appService.functionKey;
    const body = {
      "region":region
    }
    return this.http.post(url, body, options)
    .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  updateLastLoginSM(spinId) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });

    const url = this.appService.baseUrl + this.updateStafMasterURL + this.appService.functionKey;
    const body = {
      'spinId': spinId,
      'lastLogin':new Date()
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  updateLastLoginAC(workdayId) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });

    const url = this.appService.baseUrl + this.updateAccessControlURL + this.appService.functionKey;
    const body = {
      'workdayId': workdayId,
      'lastLogin':new Date()
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  updateShowFeatureWTSM(spinId,showFeatureWalkthrough) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });

    const url = this.appService.baseUrl + this.updateStafMasterURL + this.appService.functionKey;
    const body = {
      'spinId': spinId,
      'showFeatureWalkthrough':showFeatureWalkthrough
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  updateShowFeatureWTAC(workdayId,showFeatureWalkthrough) {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });

    const url = this.appService.baseUrl + this.updateAccessControlURL + this.appService.functionKey;
    const body = {
      'workdayId': workdayId,
      'showFeatureWalkthrough':showFeatureWalkthrough
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getTestUser() {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getTestUserUrl + this.appService.functionKey;
    
    return this.http.get(url, options)
    .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

  private handleError(error: Response | any) {
    return Observable.throw(error.status);
  }
}

