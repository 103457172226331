import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs';
import { CommonService } from '../service/common.service';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { environment } from 'src/environments/environment';


let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'Cache-Control': 'no-cache,no-store,must-revalidate',
  'Pragma': 'no-cache',
  'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
  'authToken': localStorage.getItem('accessToken')
};


let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class EnrollService {
  pdfResult: any;

  public statusUpdateURL = 'UpdateEnrollStatusAfterSign';
  public principalURL = 'getPrincipalDetails';
  public enrollStaffUrl = 'insertEnrollDetailsJS';
  public docuSignURL = 'DocusignSend';
  public deleteEnrollmentUrl = 'deleteEnrollment';
  public draftDocusignUrl = 'DocusignThumbnail';
  public resendUrlAssosiate = 'DocusignResendForAssociate';
  public resendUrlPrincipal = 'DocusignResendForPrincipal';
  public getGMDetailsURL = 'getGMDetails';
  public sentToUpdateURL = 'sentToUpdate';
  public seniorEligibleCheckUrl = 'SeniorEligibleCheck';
  public updateEmailURL = 'UpdateEmailAddress';
  public deleteDocusignEnvelopeURL = 'DeleteDocusignEnvelope';
  public getPastValidEnrollUrl = "GetPastValidEnrolls";
  public updatePastValidEnrollUrl = "UpdatePastEnrollRecord";

  constructor(private _http: Http, private appService: CommonService) { }

  docusignFile(envId): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const options1 = new RequestOptions({ headers: myheaders, responseType: ResponseContentType.Blob });
    var fnName = 'Enrollment_Form?'
    const url = this.appService.baseUrl + fnName + this.appService.functionKey + '&envelopeId=' + envId;
    return this._http.get(url, options1)
      .pipe(map(res => ({ content: res }))).catch(this.handleError);
  }

  azureFile(enrId): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const options1 = new RequestOptions({ headers: myheaders, responseType: ResponseContentType.Blob });

    const azureUrl = environment.azureStorage + enrId;
    return this._http.get(azureUrl, options1)
      .pipe(map(res => ({ content: res })));
  }

  draftDocusign(fullName): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.draftDocusignUrl + this.appService.functionKey;
    const body = {
      'associateFullName': fullName.toUpperCase()
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  docuSignApi(user): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    console.log('user.sentTo',user.sentTo);
    
    const url = this.appService.baseUrl + this.docuSignURL + this.appService.functionKey;
    if (user.sentTo == "Principal") {
      // if (user.principalEmail != '' && user.principalEmail != undefined) { 
      let body = {
        'associateFullName': user.fullName.toUpperCase(),
        'associateEmailId': user.email,
        'associateSpinId': user.spin_id,
        'principalFullName': user.principalFullName.toUpperCase(),
        'principalEmailId': user.principalEmail,
        'principalSpinId': user.principalSpinId,
        'pcFullName': this.appService.loggedInUserName,
        'pcEmailId': user.pcEmail
      };
      console.log(" body :: " + body.principalFullName+', '+ body.principalEmailId+', '+ body.principalSpinId+' ');

      return this._http.post(url, body, options)
        .map(this.extractData).catch(this.handleError);
      // } else {

      // }
    } else {
      let body = {
        'associateFullName': user.fullName.toUpperCase(),
        'associateEmailId': user.email,
        'associateSpinId': user.spin_id,
        'principalFullName': user.GMFullName.toUpperCase(),
        'principalEmailId': user.GMEmail,
        'principalSpinId': user.GMSpinId,
        'pcFullName': this.appService.loggedInUserName,
        'pcEmailId': user.pcEmail
      };
      console.log(" body :: " + body.principalFullName+', '+ body.principalEmailId+', '+ body.principalSpinId+' ');

      return this._http.post(url, body, options)
        .map(this.extractData).catch(this.handleError);
    }

  }

  updateEmailAddress(user, enrollNo): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.updateEmailURL + this.appService.functionKey;

    const body = {
      'assocaiteEmail': user.email,
      'principalEmail': (user.principalEmail == null || user.principalEmail == "null" || user.principalEmail == undefined || user.principalEmail == "undefined" ) ? '' : user.principalEmail,
      'principalName': (user.principalFullName == null || user.principalFullName == "null" || user.principalFullName == undefined || user.principalFullName == "undefined" ) ? '' : user.principalFullName,
      'gmName': (user.GMFullName == null || user.GMFullName == "null" || user.GMFullName == undefined || user.GMFullName == "undefined" ) ? '' : user.GMFullName,
      'associateName': user.fullName,
      'gmEmail': (user.GMEmail == null || user.GMEmail == "null" || user.GMEmail == undefined || user.GMEmail == "undefined" ) ? '' : user.GMEmail,
      'enrollmentNo': enrollNo
    };

    console.log('updateEmailAddress body :: ', body)

    return this._http.post(url, body, options).map(this.extractData).catch(this.handleError);
  }

  resendSignApiAssociate(user, envelopeId): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.resendUrlAssosiate + this.appService.functionKey;
    console.log('user in resendSignApiAssociate', user);

    if (user.sentTo != 'GM') {
      if (user.principalFullName != '' && user.principalFullName != undefined) {
        let body = {
          'associateEmailId': user.email,
          'principalEmailId': user.principalEmail,
          'envelopeId': envelopeId
        }
        console.log(" body :: " + body);
        return this._http.post(url, body, options).map(this.extractData).catch(this.handleError);
      }
    } else {
      if (user.GMFullName != '' && user.GMFullName != undefined) {
        let body = {
          'associateEmailId': user.email,
          'principalEmailId': user.GMEmail,
          'envelopeId': envelopeId
        }
        console.log(" body :: " + body);
        return this._http.post(url, body, options).map(this.extractData).catch(this.handleError);
      }
    }
  }

  seniorEligibleCheck(spinId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.seniorEligibleCheckUrl + this.appService.functionKey;
    const body = {
      'uid': spinId.substring(3)
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  resendSignApiPrincipal(user, envelopeId): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.resendUrlPrincipal + this.appService.functionKey;
    console.log(user);
    if (user.sentTo != 'GM') {
      if (user.principalEmail != '' && user.principalEmail != undefined) {
        const body = {
          'principalEmailId': user.principalEmail,
          'envelopeId': envelopeId,
          'principalSpinId': user.principalSpinId,
          'principalName': user.principalFullName
        };

        console.log(" body :: " + body);

        return this._http.post(url, body, options)
          .map(this.extractData).catch(this.handleError);
      }
    } else {
      const body = {
        'principalEmailId': user.GMEmail,
        'envelopeId': envelopeId,
        'principalSpinId': user.GMSpinId,
        'principalName': user.GMFullName
      };

      console.log(" body :: " + body);

      return this._http.post(url, body, options)
        .map(this.extractData).catch(this.handleError);
    }
  }


  statusUpdate1(enrollmentId, status) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.statusUpdateURL + this.appService.functionKey;
    const myDate = new Date();
    let envlpStatus = '';
    if (status == 'PENDING UPLOAD') {
      envlpStatus = 'PENDING UPLOAD';
    }
    if (status == 'ENROLLED') {
      envlpStatus = 'COMPLETED';
    }
    const body = {
      "enrollmentNo": enrollmentId,
      "enrollmentStatus": status,
      "envelopeStatus": envlpStatus,
      "envelopeDateTime": myDate
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  getPrincipalDetails(dealerCode: string) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.principalURL + this.appService.functionKey;
    const body = {
      'dealerCode': dealerCode
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  getGMDetails(dealerCode: string) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getGMDetailsURL + this.appService.functionKey;
    const body = {
      'dealerCode': dealerCode
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  statusUpdate(enrollmentNO: string, status: string, envelopeId: string, enrollmentStatus: string, statusDateTime: Date, eSign: string, fileName: string) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.statusUpdateURL + this.appService.functionKey;
    const body = {
      'enrollmentNo': enrollmentNO,
      'enrollmentStatus': status,
      'envelopeId': envelopeId,
      'envelopeStatus': enrollmentStatus,
      'envelopeDateTime': statusDateTime,
      'eSign': eSign,
      'fileName': fileName
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  sentToUpdate(enrollmentNO: string, sentTo: string) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.sentToUpdateURL + this.appService.functionKey;
    const body = {
      'enrollmentNo': enrollmentNO,
      'sentTo': sentTo
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

  enrollUser(user) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    console.log(user);
    const url = this.appService.baseUrl + this.enrollStaffUrl + this.appService.functionKey;
    if (user.lfsActivationDate != undefined && user.veh_acquisition_date != undefined) {
      const actD = user.lfsActivationDate.split('-');
      const acqD = user.veh_acquisition_date.split('-');
      if (actD.length === 3) {
        user.lfsActivationDate = actD[1] + '/' + actD[2] + '/' + actD[0];
      } else if (actD.length === 1) {
        user.lfsActivationDate = actD[0];
      }
      if (acqD.length === 3) {
        user.veh_acquisition_date = acqD[1] + '/' + acqD[2] + '/' + acqD[0];
      } else if (acqD.length === 1) {
        user.veh_acquisition_date = acqD[0];
      }
    } else {
      user.dealerName = '';
      user.lfsActivationDate = '01/01/1900';
      user.veh_acquisition_date = '01/01/1900';
      user.lfsNo = '';
      user.vehicleBrand = '';
      user.vin = '';
      user.vehicleModel = '';
    }

    let body = {
      "spin_id": user.spin_id,
      "Enrollment_status": 'DRAFT',
      "dlr_code": this.appService.dc,
      "dlr_associate_fname": user.first_name.toUpperCase(),
      "dlr_associate_Mname": user.middleName.toUpperCase(),
      "dlr_associate_Lname": user.lastName.toUpperCase(),
      "emp_status": user.employee_status,
      "created_by": this.appService.loggedInDetails.firstName.toUpperCase() + ' ' + this.appService.loggedInDetails.lastName.toUpperCase(),
      "job_description": user.job_description,
      "tenure_month": user.tenure.substring(0, 2),
      "dlr_associate_email": user.email,
      "contact_phone_no": user.contact_phone_no,
      // "certification_months": user.certification_months,
      "certification_months": user.tenureMonths,
      "CERTIFIED_YEAR": user.certifiedYear,
      // "cert_level": user.certificationLevel.substring(0, 1),
      "cert_level": user.marCert.substring(0, 1),
      "dlr_name": user.dealerName,
      "lfs_activation_date": user.lfsActivationDate,
      "veh_acquisition_date": user.veh_acquisition_date,
      "lfs_no": user.lfsNo,
      "veh_brand": user.vehicleBrand,
      "veh_vin": user.vin,
      "veh_model": user.vehicleModel,
      "creator_role": this.appService.loggedInDetails.jobDescription,
      "Type": "IE",
      "VEH_Maturity_DATE": user.lfs_maturity_date,
      "Sent_To": 'Principal'
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json());
  }

  pushFileToStorage(file: File, tempRequestId: string): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    // const url = this.appService.baseUrl + this.insertDealerEnrollmentUrl + this.appService.functionKey;
    const formData = new FormData();
    formData.append('multipartFile', file);
    const headers = new Headers({ 'Content-Type': undefined });
    const myoptions = new RequestOptions({ headers: headers });
    const url = ``;
    return this._http.post(url, formData)
      .map(this.extractData)
      .catch(this.handleError);
  }

  deleteEnrollment(enrollmentId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.deleteEnrollmentUrl + this.appService.functionKey;
    let date = new Date();
    let body = {
      "enrollmentNo": enrollmentId,
      "status": "DELETED",
      "lastUpdatedBy": this.appService.loggedInDetails.spinId,
      "deletedDate": date,
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json())
      .catch(this.handleError);
  }

  deleteEnvelope(envelopeId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.deleteDocusignEnvelopeURL + this.appService.functionKey;
    let date = new Date();
    let body = {
      "envelopeId": envelopeId,
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json())
      .catch(this.handleError);
  }
 

  azureFileDownload(url): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const options1 = new RequestOptions({ headers: myheaders, responseType: ResponseContentType.Blob });

    const azureUrl = url;
    return this._http.get(azureUrl, options1)
      .pipe(map(res => ({ content: res })));
  }

    // ===========================PAST VALID ENROLL===================
    getPastValidEnroll(spinId) {
      headerDict.authToken = localStorage.getItem('accessToken');
      myheaders = new Headers(headerDict);
      options = new RequestOptions({ headers: myheaders });
      const url = this.appService.baseUrl + this.getPastValidEnrollUrl + this.appService.functionKey;
      const body = {
        'spin_id': spinId
      };
      return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError)
    }

    updatePastValidEnroll(spinId, dealerCode) {
      headerDict.authToken = localStorage.getItem('accessToken');
      myheaders = new Headers(headerDict);
      options = new RequestOptions({ headers: myheaders });
      const url = this.appService.baseUrl + this.updatePastValidEnrollUrl + this.appService.functionKey;
      const body = {
        'spin_id': spinId,
        'dlr_code': dealerCode
      };
      return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError)
    }

  private handleError(error: Response | any) {
    return Observable.throw(new Error(error.status));
  }
}
