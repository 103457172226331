import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { CommonService } from '../service/common.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class DashboardService {

  public getStaffDetailsUrl = 'getEnrollDetailsJS';
  public getCertificationDetailsUrl = 'getMaritzDetailsJS';
  public getLFSDetailsUrl = 'getLFSDetailsJS';
  public getEnrollmentsummaryUrl = 'DashboardEnrollmentSummary';
  public getTransfersummaryUrl = 'getTransferSummJS';
  public getEligiblesummaryUrl = 'getEligilibilitySummJS';
  public sendMailUrl = 'DealerEnrollmentDocusign';
  public principalSignUrl = 'DocusignEmbeddedSending';
  public dealershipCheckUrl = 'DealerEnrollmentCheck';
  public insertDealerEnrollmentUrl = 'InsertDealerEnrollmentDetails';
  public statusUpdateUrl = 'UpdateDealerEnrollmentStatus';
  public draftDocusignUrl = 'DealerEnrollmentThumbnail';
  public associateDetailsUrl = 'AssociateDetailsFromPrincipalId';
  public getUserDetailsUrl = 'jwtDecode'; //DecodeUserDetails
  public getPaymentSummaryURL = 'PaymentSummary';
  public saveTokenUrl = 'saveToken';
  public sasTokenUrl = 'GetSasToken';
  public fileDownloadUrl = 'FileDownload';
  public getDealerDetailsUrl = 'GetDealerDetails';

  constructor(private _http: Http, private appService: CommonService) { }

  checkDealershipEnrolled(dc) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.dealershipCheckUrl + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ dealerCode: dc }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  getAssociateDetails() {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.associateDetailsUrl + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ dealerCode: this.appService.dc }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  autoSearch(dealerCode, string): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + 'getenrollmentdtlJS' + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ dealer_cd: this.appService.dc, SearchStr: string }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  saveToken(token): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.saveTokenUrl + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ token: token }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  getTransferData(DealerCode): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getTransfersummaryUrl + this.appService.functionKey;
    const body = {
      'DealerCode': DealerCode
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  
  insertDealerEnrollemnt(principalName): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertDealerEnrollmentUrl + this.appService.functionKey;
    const body = {
      'enrollmentStatus': 'DRAFT',
      'dealerCode': this.appService.dc,
      'dealerName': this.appService.dealerName,
      'createdBy': this.appService.loggedInUserName,
      'principalName': principalName,
      'type': 'DE'
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  statusUpdate(eNo, status, envId, name, role): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.statusUpdateUrl + this.appService.functionKey;
    const body = {
      'enrollmentNo': eNo,
      'enrollmentStatus': status,
      'envelopeId': envId,
      'principalOrGm': name,
      'sentTo': role
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getStaffDetails(spinId, dealerCode): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getStaffDetailsUrl + this.appService.functionKey;
    const body = {
      'spinID': spinId,
      'dealerCode': dealerCode
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getCertificationDetails(spinId, dealerCode): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getCertificationDetailsUrl + this.appService.functionKey;
    const body = {
      'spinID': spinId,
      'DealerCode': dealerCode
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getLFSDetails(spinId): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getLFSDetailsUrl + this.appService.functionKey;
    const body = {
      'uid': spinId
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getEnrollmentData(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getEnrollmentsummaryUrl + this.appService.functionKey;
    const body = {
      'dealerCode': this.appService.dc
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getEligibleData(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getEligiblesummaryUrl + this.appService.functionKey;
    const body = {
      'DealerCode': this.appService.dc
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getPayHistoryData(dealerCode): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getPaymentSummaryURL + this.appService.functionKey;
    const body = {
      'dealerCode': dealerCode
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  getDealerDetails(dealerCode): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getDealerDetailsUrl + this.appService.functionKey;
    const body = {
      'dealerCode': dealerCode
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  docusignSendMail(dealerInfo, principaEmail, principalName, principalSpinId, associateFullname, associateEmail) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.sendMailUrl + this.appService.functionKey;
    let body = {};
    // if (this.appService.isPrincipal) {
      console.log(associateFullname);
      body = {
        'dealerInfo': dealerInfo,
        'principalFullName': principalName,
        'principalEmailId': principaEmail,
        'principalSpinId': principalSpinId,
        'pcFullName': associateFullname,
        'pcEmailId': associateEmail
      };
    // }
    // else {
    //   body = {
    //     'dealerInfo': dealerInfo,
    //     'principalFullName': principalName,
    //     'principalEmailId': principaEmail,
    //     'principalSpinId': principalSpinId,
    //     'pcFullName': 'LML USER',
    //     'pcEmailId': 'LexusMasterLease@lexus.com'
    //   };
    // }
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  principalSignMail(dealerInfo, principaEmail, principalName, principalSpinId, associateFullname, associateEmail) {

    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.principalSignUrl + this.appService.functionKey;
    const body = {
      'dealerInfo': dealerInfo,
      'principalFullName': principalName,
      'principalEmailId': principaEmail,
      'principalSpinId': principalSpinId,
      'pcFullName': associateFullname,
      'pcEmailId': associateEmail
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  draftDocusign(fullName): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.draftDocusignUrl + this.appService.functionKey;
    const body = {
      'principalFullName': fullName.toUpperCase()
    };
    return this._http.post(url, body, options)
      .map(this.extractData).catch(this.handleError);
  }

  getUserDetails(token): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getUserDetailsUrl + this.appService.functionKey;
    const body = {
      'jwtToken': token
    };
    return this._http.post(url, body, options)
      .map(this.extractData);
  }

  getSasToken(container): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.sasTokenUrl + this.appService.functionKey;
    const body = {
      'containerName': container
    };
    return this._http.post(url, body, options)
      .map(this.extractData).catch(this.handleError);
  }

  getFileLink(container, name): Observable<any> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.fileDownloadUrl + this.appService.functionKey;
    const body = {
      'containerName': container,
      'blobName': name
    };
    return this._http.post(url, body, options)
      .map(this.extractData).catch(this.handleError);
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }
  private extractDataUrl(res: Response) {
    const body = res;
    return body || {};
  }
  private handleError(error: Response | any) {
    return Observable.throw(new Error(error.status));
  }
}
