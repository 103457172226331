import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class CorpDashboardService {

  public getCurrentMonthPaymentURL = 'DashboardCurrentMonthPayment';
  public getPastPaymentURL = 'DashboardPaymentLastOneYear';
  public getCurrentMonthDebitPaymentURL = 'DashboardCurrentMonthDebits';
  public getPastDebitPaymentURL = 'DashboardDebitsLastOneYear';
  public getRecentURL = 'RecentEnrollmentSummary';
  public getAllURL = 'PastEnrollmentSummary';
  public getAssociateDetailsURL = 'getEnrollDetailsJS';
  public getMaritizDetailsURL = 'getMaritzDetailsJS';
  public getLFSDetailsUrl = 'getLFSDetailsJS';
  public seniorEligibleCheckUrl = 'SeniorEligibleCheck';
  public insertDebitsDeletePaymentURL = 'InsertDebitHistoryDeletePaymentHistory';
  public getPastYearCertRecsUrl = 'GetPastYearCertDetails';

  constructor(private http: Http, public appService : CommonService) {}

  // ============================CURRENT MONTH PAYMENT DATA=========================================
  getCurrentMonthPayment(region){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getCurrentMonthPaymentURL + this.appService.functionKey;
    const body = {
      'region': region,
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================PAST YEAR PAYMENT DATA=========================================
  getPastYearPayment(region){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getPastPaymentURL + this.appService.functionKey;
    const body = {
      'region': region,
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================CURRENT MONTH DEBITED PAYMENT DATA=========================================
  getCurrentMonthDebitPayment(region){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getCurrentMonthDebitPaymentURL + this.appService.functionKey;
    const body = {
      'region': region,
    };
    // console.log("Dashboard payment data",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================PAST YEAR DEBITED PAYMENT DATA=========================================
  getPastYearDebitPayment(region){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getPastDebitPaymentURL + this.appService.functionKey;
    const body = {
      'region': region,
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================RECENT ENROLLMENT DATA=========================================
  getRecentData(region){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getRecentURL + this.appService.functionKey;
    const body = {
      'region': region,
    };
    // console.log("Enrollment Summary",url);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================ALL ENROLLMENT DATA=========================================
  getAllData(region){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getAllURL + this.appService.functionKey;
    const body = {
      'region': region,
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================INSERT DEBIT PAYMENT=========================================
  insertDebitPayment(spinid, lfspaycd, dlrcode, payduecd, comment){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertDebitsDeletePaymentURL + this.appService.functionKey;
    const body = {
      'spinid': spinid,
      'lfspaycd': lfspaycd,
      'dlrcode': dlrcode,
      'payduecd': payduecd,
      'comment': comment
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ======================= ASSOCIATE INFORMATION DETAILS ========================================
  getAssociateDetails(spinId, dealerCode) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getAssociateDetailsURL + this.appService.functionKey;
    const body = {
      'spinID': spinId,
      'dealerCode': dealerCode
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ========================== ASSOCIATE CERTIFICATION DETAILS ====================================
  getCertificateDetails(spinId, dealerCode){
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getMaritizDetailsURL + this.appService.functionKey;
    const body = {
      'spinID': spinId,
      'DealerCode': dealerCode
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ========================== ASSOCIATE LFS DETAILS ================================================
  getLFSDetails(spinId): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getLFSDetailsUrl + this.appService.functionKey;
    const body = {
      'uid': spinId
    };
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ============================= SENIOR ELIGIBLE CHECK =================================
  seniorEligibleCheck(spinId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.seniorEligibleCheckUrl + this.appService.functionKey;
    const body = {
      'uid': spinId.substring(3)
    };
    return this.http.post(url, body, options)
    .map((response: Response) => <any[]>response.json()).catch(this.handleError)
  }

// ============================= PAST YEAR CERT DEETS =================================
  getPastYearCertDetails(spinId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getPastYearCertRecsUrl + this.appService.functionKey;
    const body = {
      'spin_id': spinId
    };
    return this.http.post(url, body, options)
    .map((response: Response) => <any[]>response.json()).catch(this.handleError)
  }

  // ============================EXCEPTION HANDLER=========================================
  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }
}
