import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { CommonService } from '../service/common.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class NotificationService {

  public getAllNotificationsUrl = 'FetchAllNotifications';
  public unreadNotificationsUrl = 'FetchUnreadNotifications';
  public markNotificationAsReadUrl = 'UpdateIsReadNotification';
  public insertNotificationUrl = 'InsertNotificationMaster';
  public getMaxIdUrl = 'GetIdNotificationMaster';
  public insertNotificationDetailsUrl = 'InsertNotificationDetails';
  public getSentNotificationUrl = 'FetchNotificationMaster';
  public insertDraftNotificationUrl = 'UpdateDraftNotification';
  public insertDraftNotificationDetailsUrl = '';
  public deleteNotificationUrl = 'DeleteDraftNotification';
  public notificationEmailUrl = 'NotificationEmailTrigger';
  public notificationEmailListUrl = 'NotificationEmailListTrigger';
  public bulkNotificationInsertUrl = 'BulkInsertNotificationDetails';

  constructor(private _http: Http, private appService: CommonService) { }

  getAllNotificationList(senderId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getAllNotificationsUrl + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ senderId: senderId }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  getUnreadNotifications(senderId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.unreadNotificationsUrl + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ senderId: senderId }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  getSentNotification(senderId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getSentNotificationUrl + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ LST_UPDT_BY: senderId }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  markAsRead(notifId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.markNotificationAsReadUrl + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ NOTIFY_DET_ID: notifId }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  deleteNotification(notifId) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.deleteNotificationUrl + this.appService.functionKey;
    return this._http.post(url, JSON.stringify({ notify_id: notifId }), options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  getMaxIdNotification() {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getMaxIdUrl + this.appService.functionKey;
    return this._http.get(url, options)
      .pipe(map(this.extractData)).catch(this.handleError);
  }

  insertNotification(scenario, trigFrom, sendToCode, dealerCode, msgSummary, msgDetails, msgType, triggerEmail, actionLink, lstUpdt, area): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertNotificationUrl + this.appService.functionKey;
    const body = {
      'scenario': scenario,
      'trigFrom': trigFrom,
      'sendToCode': sendToCode,
      'dealerCode': dealerCode,
      'msgSummary': msgSummary,
      'msgDetails': msgDetails,
      'msgType': msgType,
      'triggerEmail': triggerEmail,
      'actionLink': actionLink,
      'LST_UPDT_BY': lstUpdt,
      'area': area,
      'sendOn': ''
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  insertNotificationLater(scenario, trigFrom, sendToCode, dealerCode, msgSummary, msgDetails, msgType, triggerEmail, actionLink, lstUpdt, area, sendOn): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertNotificationUrl + this.appService.functionKey;
    const body = {
      'scenario': scenario,
      'trigFrom': trigFrom,
      'sendToCode': sendToCode,
      'dealerCode': dealerCode,
      'msgSummary': msgSummary,
      'msgDetails': msgDetails,
      'msgType': msgType,
      'triggerEmail': triggerEmail,
      'actionLink': actionLink,
      'LST_UPDT_BY': lstUpdt,
      'area': area,
      'sendOn': sendOn
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  BulkInsertNotificationDetails(insert): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.bulkNotificationInsertUrl + this.appService.functionKey;
    const body = {
      'insertSummary': insert,
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  insertDraftNotification(scenario, trigFrom, sendToCode, dealerCode, msgSummary, msgDetails, msgType, triggerEmail, actionLink, lstUpdt, notifId): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertDraftNotificationUrl + this.appService.functionKey;
    const body = {
      'scenario': scenario,
      'trigFrom': trigFrom,
      'sendToCode': sendToCode,
      'dealerCode': dealerCode,
      'msgSummary': msgSummary,
      'msgDetails': msgDetails,
      'msgType': msgType,
      'triggerEmail': triggerEmail,
      'actionLink': actionLink,
      'LST_UPDT_BY': lstUpdt,
      'notifId': notifId,
      'LST_UPDT_DT': new Date(),
      'area': '',
      'sendOn': ''
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  insertDraftNotificationLater(scenario, trigFrom, sendToCode, dealerCode, msgSummary, msgDetails, msgType, triggerEmail, actionLink, lstUpdt, notifId, area, sendOn): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertDraftNotificationUrl + this.appService.functionKey;
    const body = {
      'scenario': scenario,
      'trigFrom': trigFrom,
      'sendToCode': sendToCode,
      'dealerCode': dealerCode,
      'msgSummary': msgSummary,
      'msgDetails': msgDetails,
      'msgType': msgType,
      'triggerEmail': triggerEmail,
      'actionLink': actionLink,
      'LST_UPDT_BY': lstUpdt,
      'notifId': notifId,
      'LST_UPDT_DT': new Date(),
      'area': area,
      'sendOn': sendOn
    };
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  insertNotificationDetails(notifyId, senderId, senderEmail, msgFrom, msgSummary, msgDetails, msgType, isRead, isArchive, lstUpdt): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertNotificationDetailsUrl + this.appService.functionKey;
    const body = {
      'notifyId': notifyId,
      'senderId': senderId + "",
      'senderEmail': senderEmail,
      'msgFrom': msgFrom,
      'msgSummary': msgSummary,
      'msgDetails': msgDetails,
      'msgType': msgType,
      'isRead': isRead,
      'isArchive': isArchive,
      'LST_UPDT_BY': lstUpdt,
      'area': '',
      'sendOn': ''
    };
    console.log('inserting into N-Details Table', body);
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  insertDraftNotificationDetails(notifyId, senderId, senderEmail, msgFrom, msgSummary, msgDetails, msgType, isRead, isArchive, lstUpdt): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertDraftNotificationDetailsUrl + this.appService.functionKey;
    const body = {
      'notifyId': notifyId,
      'senderId': senderId + "",
      'senderEmail': senderEmail,
      'msgFrom': msgFrom,
      'msgSummary': msgSummary,
      'msgDetails': msgDetails,
      'msgType': msgType,
      'isRead': isRead,
      'isArchive': isArchive,
      'LST_UPDT_BY': lstUpdt
    };
    console.log('inserting into N-Details Table', body);
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  notificationEmailTrigger(from, to, subject, details): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.notificationEmailUrl + this.appService.functionKey;
    const body = {
      'from': from,
      'toAddress': to + "",
      'subject': subject,
      'details': details
    };
    console.log('inserting into N-Details Table', body);
    return this._http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  notificationEmailListTrigger(from, to, subject, details) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.notificationEmailListUrl + this.appService.functionKey;
    const body = {
      'from': from,
      'toAddress': to + "",
      'subject': subject,
      'details': details,
      // 'mailList':mailList
    };
    console.log('inserting into N-Details Table', body);
    return this._http.post(url, body, options)
      .map((response: Response) => response).catch(this.handleError);
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }
  private handleError(error: Response | any) {
    return Observable.throw(new Error(error.status));
  }

}
