import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from '../service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class PaymentReportsService {

  public getPaymentReportDataURL = "PaymentSummaryReport";
  public createPaymentReportPdfURL = "CreatePaymentReportPdf";

  constructor(public http: Http, private appService: CommonService) {}

  // ==========================PAYMENT REPORT DATA=========================================================
  
  getPayReportData(dealerCode,startdate,enddate){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      'dealerCode': dealerCode,
      'startdate': startdate,
      'enddate': enddate
    }
    let url = this.appService.baseUrl + this.getPaymentReportDataURL + this.appService.functionKey;
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ==========================PDF CREATION METHOD=========================================================
  
  createPaymentReportPdf(dealerCode,dealerName,startdate,enddate){
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    let body = {
      'dealerCode': dealerCode,
      'dealerName': dealerName,
      'startdate': startdate,
      'enddate': enddate
    }
    let url = this.appService.baseUrl + this.createPaymentReportPdfURL + this.appService.functionKey;
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  // ==========================ERROR HANDLING METHOD=========================================================
  private handleError(error: Response | any) {
    if (error.status === 500) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 400) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 409) {
      return Observable.throw(new Error(error.status));
    }
    else if (error.status === 406) {
      return Observable.throw(new Error(error.status));
    }
  }
}
