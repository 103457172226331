import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class AseLexusService {

  public getCertStatusURL = "LMSASECertStatusReportSearch";
  public getRejectionReportURL = "LMSASERejectionReportSearch";
  public getCollisionReportURL = "LMSASECollisionReportSearch";


  constructor(private http: Http, public appService: CommonService) { }

  getCertStatus(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getCertStatusURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getRejectionReport(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getRejectionReportURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getCollisionReport(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getCollisionReportURL + this.appService.functionKey;
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  

  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }
}
