import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { PaymentProcessService } from './payment-process.service'
declare var $: any;
import { ExcelService } from '../service/excel.service';
import { Router } from '@angular/router';
import { Observable, of } from "rxjs";
import { CommonService } from "src/app/service/common.service";


export interface PaymentData {
  enrollmentNumber: string;
  dealerCode: number;
  name: string;
  spinId: number;
  paymentTime: string;
  paymentNumber: string;
  comments: string;
}

@Component({
  selector: 'app-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.css']
})
export class PaymentProcessComponent implements OnInit {

  //Material Table
  @ViewChild('paymentProcesssort', { read: MatSort }) paymentProcesssort: MatSort;
  @ViewChild('paymentProcessPaginator', { read: MatPaginator }) paymentProcessPaginator: MatPaginator;
  PayDataColumns: string[] = ['name', 'spinId', 'dealerCode', 'paymentTime', 'paymentNumber', 'comments'];
  PayDataSource: MatTableDataSource<any>;
  paymentTableLoading: boolean = false;
  paymentNoData: boolean = false;
  PAYMENT_LIST: PaymentData[] = [];

 data:any="Emails sent report";
 downloadNow:boolean=false;
  //Variables
  sendNotification: boolean = false;
  sendEmail: boolean = false;
  noExcelData: boolean = false;
  errorMessage: string;
  successMessage: string;
  Error: boolean = false;
  Success: boolean = false;
  sinsert: boolean = false;
  einsert: boolean = false;
  semail: boolean = false;
  eEmail: boolean = false;
  snotify: boolean = false;
  enotify: boolean = false;
  errorNotificationMessage: string;
  errorEmailMessage: string;
  sucessEmailMessage: string;
  sucessNotificationMessage: string;
  insertRowCount: number = 0;
  updateRowCount: number = 0;
  checkAllDealers: boolean = false;
  checkAllDealersEmail: boolean = false;
  sendNotiTo: any[] = [];
  sendEmailTo: any[] = [];
  pcMailsCount: number = 0;
  controllerMailsCount: number = 0;

  //Modal Variables
  pcChecked: boolean = false;
  principalChecked: boolean = false;
  gmChecked: boolean = false;
  pcCheckedNoti: boolean = false;
  principalCheckedNoti: boolean = false;
  gmCheckedNoti: boolean = false;
  checkCountNoti: number = 0;
  checkCount: number = 0;
  dealerList: any[] = [];
  counterEmail: number = 0;
  successEmail: number = 0;
  counterNoti: number = 0;
  showPaySpinner: boolean = false;

  //Search 
  dealerNotiSearch: string = '';
  dealerEmailSearch: string = '';
  dealerNotifiList: any = [];
  dealerEmailList: any = [];
  emailFilter: any = [];
  notificationFilter: any = [];

  constructor(private paymentService: PaymentProcessService, private excelService: ExcelService, 
    private router: Router, private appService: CommonService) {
    this.getPaymentListDetails(); //get payment details service call
    this.getDealerList(); //get Dealer list to send notification and email
    // checkbox in the modal popup is unchecked initially
    $('#emailCheckBox').prop('checked', false);
    $('#notificationCheckBox').prop('checked', false);
  }

  ngOnInit() {
    console.log('admin pay proc',this.appService.role);
    if (this.appService.role != 'Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
  }

  // =========================== PAYMENT LIST TABLE FUNCTIONALITIES  ===========================
  // get the payment list detail function
  getPaymentListDetails() {
    this.PAYMENT_LIST = [];
    this.paymentTableLoading = true;
    this.paymentService.getPaymentList().subscribe((response: any) => {
      [].push.apply(this.PAYMENT_LIST, response.PaymentList.Result);
      if (this.PAYMENT_LIST.length != 0) {
        // this.allCount = this.PAYMENT_LIST.length;
        this.paymentNoData = false;
        this.PayDataSource = new MatTableDataSource(this.PAYMENT_LIST);
        this.PayDataSource.sort = this.paymentProcesssort;
        this.PayDataSource.paginator = this.paymentProcessPaginator;
      } else {
        this.paymentNoData = true;
      }
      this.paymentTableLoading = false;
    }, err => {
      console.log('Error', err);
      console.log('Error while fetching all enrollment data');
      this.paymentTableLoading = false;
      this.paymentNoData = true;
      this.PAYMENT_LIST = [];
    });
  }

  // Search bar filter in the table
  paymentapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.PayDataSource.filter = filterValue;
    this.PayDataSource.paginator = this.paymentProcessPaginator;
    this.PayDataSource.sort = this.paymentProcesssort;
  }

  // ========================== PAYMENT HISTORY TABLE BUTTON CLICK SERVICE CALL =============================
  // Insert Payment List to payment history table service call
  insertPayment() {
    if (!this.paymentNoData) {
      // this.insertListServiceCall();
      $('html,body').animate({ scrollTop: 0 }, "smooth");
    }
  }

  // ==================================SEND NOTIFICATION/EMAIL FUNCTIONALITIES ==========================

  // =================== GET THE DEALER LIST TO POPULATE IN THE POPUP ==================
  getDealerList() {
    this.paymentService.getDealerList().subscribe((response: any) => {
      if (response.DealerCodeList.Result.length != 0) {
        this.dealerList = response.DealerCodeList.Result;
        this.dealerNotifiList = response.DealerCodeList.Result;
        this.dealerEmailList = response.DealerCodeList.Result;
      } else {
        this.dealerList = [];
      }
    }, err => {
      console.log('Error while trying to get dealer list');
      console.log('Error', err);
      this.dealerList = [];
    });
  }

  // Change detector in the check box
  sendEmailNotification() {
    var email = (<HTMLInputElement>document.getElementById("emailCheckBox")).checked;
    var notification = (<HTMLInputElement>document.getElementById("notificationCheckBox")).checked;
    if (email) {
      this.sendEmail = true;
    } else {
      this.sendEmail = false;
    }
    if (notification) {
      this.sendNotification = true;
    } else {
      this.sendNotification = false;
    }
  }

  // ========================== SEND NOTIFICATION SERVICE CALL =============================
  // notification service call if the send notification checkbox is selected
  async notifyServiceCall(dlrCode) {
    await this.paymentService.NotifyPaymentSucess(dlrCode).toPromise().then(async (response: any) => {
      if (response.RowUpdated.rowCount > 0) {
        this.counterNoti++;
      } else {
        // this.snotify = false;
        // this.errorNotificationMessage = "Unable to send notification";
      }
      if (this.counterNoti == this.sendNotiTo.length) {
        this.snotify = true;
        this.sucessNotificationMessage = "Notification has been sent successfully";
        this.checkAllDealers = true;
        this.checkAll();
        setTimeout(function () {
          this.snotify = false;
        }.bind(this), 15000);
      }
      console.log('this.counterNoti', this.counterNoti);

    }, err => {
      console.log(err);
      console.log('Error while trying to send notification');
      console.log('Email fail', this.counterNoti);
    });
  }

  // ========================== SEND EMAIL SERVICE CALL =============================
  // email service call if the send email checkbox is selected
  async emailServiceCall(dealerDetail) {
    await this.paymentService.emailPaymentSucess(dealerDetail).toPromise().then(async (response: any) => {
      // console.log(response.ShareAccess);
      this.pcMailsCount += response.ShareAccess[0].pcCount;
      this.controllerMailsCount += response.ShareAccess[0].controllerCount;
      if (response.ShareAccess != '' || response.ShareAccess[0].result.indexOf('Mail Sent Successfully') != -1) {
        this.counterEmail++;
        this.successEmail++;
      }
      if (this.counterEmail == this.sendEmailTo.length) {
        this.semail = true;
        this.sucessEmailMessage = "Email has been sent successfully for " + this.successEmail + " dealers";
        this.showPaySpinner = false;
        this.checkAllDealersEmail = true;
        this.checkAllEmail();
        setTimeout(function () {
          this.semail = false;
        }.bind(this), 15000);
      }
      console.log('this.counterEmail' + this.counterEmail + ' - ' + response.ShareAccess[0].result);
      console.log('PC mails : ', response.ShareAccess[0].pcEmail);
      console.log('Controller mails : ', response.ShareAccess[0].controllerEmail);

      this.data= this.data+ "\n\n" +
      "this.counterEmail" + this.counterEmail+ ' - ' +response.ShareAccess[0].result+", \n"+
      "PC mails" +" : " + response.ShareAccess[0].pcEmail+", \n"+

      "Controller mails" + " : " +  response.ShareAccess[0].controllerEmail


      

    }, err => {
      console.log('Error while trying to trigger email notification');
      this.counterEmail++;
      console.log('Email fail' + this.counterEmail + ' Error ' + err);

      this.data = this.data+ "\n"+
      "Error while tryng to trigger email notification\n"+"Email fail"+this.counterEmail+ " Error "+ err

      
    });
  }

  // insert payment service call
  async insertListServiceCall() {
    await this.paymentService.insertPaymentList().toPromise().then(async (response: any) => {
      if (response.RowUpdated.rowCount > 0) {
        this.insertRowCount = response.RowUpdated.rowCount;
        this.sinsert = true;
        this.successMessage = this.insertRowCount + " rows has been successfully inserted in payment history table";
        setTimeout(function () {
          this.sinsert = false;
        }.bind(this), 15000);
        this.getPaymentListDetails();

      } else {
        this.sinsert = false;
        this.errorMessage = "Payments has not been posted to the payment history table";
      }
    }, err => {
      this.errorMessage = "Payments has not been posted to the payment history table";
      this.sinsert = false;
      console.log('Error while trying to post the payments to the payment history table');
      console.log(err);
    });
  }

  // Update the payment after the insert and notification and email
  async updatePaymentServiceCall() {
    await this.paymentService.updatePayment().toPromise().then(async (response: any) => {
      if (response.RowUpdated.rowCount > 0) {
        this.updateRowCount = response.RowUpdated.rowCount;
      } else {
        this.updateRowCount = 0;
      }
    }, err => {
      this.updateRowCount = 0;
      console.log('Error while updating the payment list');
      console.log(err);
    });
  }

  // Download the table data
  exportSummary() {
    this.noExcelData = false;
    if (!this.paymentNoData) {
      this.excelService.exportAsExcelFile(this.PayDataSource.data, 'Payment_List');
    } else {
      this.noExcelData = true;
    }
  }

  //* ======================== NOTIFICATION MODAL POPUP FUNCTIONS =============================================

  //* Select All the checkbox in the modal popup to send notification *//
  checkAll() {
    if ($('#selectAll').is(":checked")) { //all the checkbox not selected
      this.checkAllDealers = true;
      for (let i = 0; i < this.dealerNotifiList.length; i++) { // iterate to check all the checkboxes
        $('#' + this.dealerNotifiList[i].dealerCode).prop('checked', true);
        if (this.notificationFilter.indexOf(this.dealerNotifiList[i].dealerCode) == -1) {
          this.notificationFilter.push(this.dealerNotifiList[i].dealerCode);
        }
      }
      this.checkCountNoti = this.dealerNotifiList.length; //get the count
    }
    else {//all the checkbox are selected
      $('#selectAll').prop('checked', false);
      this.checkAllDealers = false;
      for (let i = 0; i < this.dealerNotifiList.length; i++) { // iterate to uncheck all the checkboxes
        $('#' + this.dealerNotifiList[i].dealerCode).prop('checked', false);
        if (this.notificationFilter.indexOf(this.dealerNotifiList[i].dealerCode) != -1) {
          this.notificationFilter.splice(this.notificationFilter.indexOf(this.dealerNotifiList[i].dealerCode), 1);
        }
      }
      this.checkCountNoti = 0; // count is 0
    }
    console.log(this.notificationFilter);

  }

  //* Get the count of the checkboxes selected in the modal popup to send notification *//
  getCheckedCount(code, row) {
    if (this.notificationFilter.indexOf(code) == -1) { // Check for the dealerCode in the filter list, if not in the list push the code to the list
      this.notificationFilter.push(code);
      $('#' + code).prop('checked', true); // check the checkbox in UI using jQuery
    } else {
      this.notificationFilter.splice(this.notificationFilter.indexOf(code), 1) // If the dealerCode is present in the filter list remove the code using splice method
      $('#' + code).prop('checked', false); // uncheck the checkbox using jQuery
    }
    console.log('this.notificationFilter', this.notificationFilter.sort()); // Print the sorted filtr list
  }

  //* ======================== EMAIL MODAL POPUP FUNCTIONS =============================================

  //* Select All the checkbox in the modal popup to send email *//
  checkAllEmail() {
    if ($('#selectAllEmail').is(":checked")) { //all the checkbox not selected
      this.checkAllDealersEmail = true;
      for (let i = 0; i < this.dealerEmailList.length; i++) { // iterate to check all the checkboxes
        $('#' + this.dealerEmailList[i].dealerCode + '0').prop('checked', true);
        if (this.emailFilter.indexOf(this.dealerEmailList[i].dealerCode) == -1) {
          this.emailFilter.push(this.dealerEmailList[i].dealerCode);
        }
      }
      this.checkCount = this.dealerEmailList.length; //get the count
    } else {
      $('#selectAllEmail').prop('checked', false);
      this.checkAllDealersEmail = false;
      for (let i = 0; i < this.dealerEmailList.length; i++) { // iterate to uncheck all the checkboxes
        $('#' + this.dealerEmailList[i].dealerCode + '0').prop('checked', false);
        if (this.emailFilter.indexOf(this.dealerEmailList[i].dealerCode) != -1) {
          this.emailFilter.splice(this.emailFilter.indexOf(this.dealerEmailList[i].dealerCode), 1);
        }
      }
      this.checkCount = 0; // count is 0
    }
    console.log('this.emailFilter', this.emailFilter);

  }

  //* Get the count of the checkboxes selected in the modal popup to send email *//
  getEmailChecked(code, row) {
    if (this.emailFilter.indexOf(code) == -1) { // check for the dealerCode in the filter list, if no then push the dealerCode to the filter list
      this.emailFilter.push(code);
      $('#' + code + '0').prop('checked', true); //check the checkbox in UI  using jQuery
    } else {
      this.emailFilter.splice(this.emailFilter.indexOf(code), 1) //If code is present in the filter list then remove the code from the list using splice method
      $('#' + code + '0').prop('checked', false); //uncheck the checkbox in UI  using jQuery
    }
    console.log('this.emailFilter', this.emailFilter.sort()); // Print the sorted filtr list
  }

  // =================== SEND NOTIFICATION TO THE SELECTED DEALERS ==================
  //* Submit in Send Notification Popup *//
  async submitNotification() {
    for (let i = 0; i < this.dealerList.length; i++) {
      // $('#' + i).is(":checked")
      if (this.notificationFilter.indexOf(this.dealerList[i].dealerCode) != -1  && this.sendNotiTo.indexOf(this.dealerList[i].dealerCode) == -1) { // check if the checkbox is checked
        this.sendNotiTo.push(this.dealerList[i].dealerCode);
      }
    }
    console.log('submit Noti', this.sendNotiTo);
    for (let j = 0; j < this.sendNotiTo.length; j++) {
      await this.notifyServiceCall(this.sendNotiTo[j]);
    }
    this.notificationFilter = [];
  }

  // =================== SEND EMAIL TO THE SELECTED DEALERS ==================
  //* Submit in send Email Popup *//
  async submitEmail() {
    let selectedDealers = [];
    for (let i = 0; i < this.dealerList.length; i++) {
      // $('#0' + i).is(":checked")
      if (this.emailFilter.indexOf(this.dealerList[i].dealerCode) != -1 && selectedDealers.indexOf(this.dealerList[i].dealerCode) == -1) { // check if the checkbox is checked
        this.emailFilter.splice(this.emailFilter.indexOf(this.dealerList[i].dealerCode),1)
        selectedDealers.push(this.dealerList[i].dealerCode);
        let dealerDetail = {
          "dealerCode": this.dealerList[i].dealerCode,
          "dealerName": this.dealerList[i].dealerName
        }
        this.sendEmailTo.push(dealerDetail);
      }
    }
    console.log('submit Email', this.sendEmailTo);
    let count = 0;
    this.showPaySpinner = true;
    this.pcMailsCount = 0;
    this.controllerMailsCount = 0;
    this.counterEmail = 0;
    for (let j = 0; j < this.sendEmailTo.length; j++) {
      await this.emailServiceCall(this.sendEmailTo[j]);
      count++;
      console.log(count + ' - ' + this.sendEmailTo[j].dealerCode);
    }
    console.log("Total PC mails : ", this.pcMailsCount);
    console.log("Total Controller mails : ", this.controllerMailsCount);
    

    this.data= this.data +"\n\n"+ "Total PC mails : "+ this.pcMailsCount+"\n"+"Total Controller mails : "+this.controllerMailsCount + "\n\n" + 
    "-------------------------------------------------------------------------------------------";
    this.sendEmailTo = [];
    this.successEmail =0; 

    this.downloadNow=true;
  }

  // =================== SEND EMAIL POP UP SEARCH FILTER ==================
  applyEmailFilter(filterValue) {
    this.dealerEmailList = [...this.dealerList];
    this.dealerEmailList = this.dealerEmailList.filter(elt => {
      return elt.dealerCode.indexOf(filterValue) != -1
    });
    if (this.emailFilter.length != 0) {
      for (let code of this.emailFilter) {
        if ($('#' + code + '0')) {
          $('#' + code + '0').prop('checked', true);
        }
      }
    }
  }

  // =================== SEND NOTIFICATION POP UP SEARCH FILTER ==================
  applyNotiFilter(filterValue) {
    this.dealerNotifiList = [...this.dealerList];
    this.dealerNotifiList = this.dealerNotifiList.filter(elt => {
      return elt.dealerCode.indexOf(filterValue) != -1
    });
    if (this.notificationFilter.length != 0) {
      for (let code of this.notificationFilter) {
        if ($('#' + code)) {
          $('#' + code).prop('checked', true);
        }
      }
    }
  }

  // =================== SEND EMAIL POP UP CANCEL BUTTON FUNCTIONALITY ==================
  resetDealerEmail() {
    this.dealerEmailList = [...this.dealerList];
    this.checkAllDealersEmail = true;
    this.emailFilter = [];
    this.checkAllEmail();
    this.dealerEmailSearch = '';
    this.emailFilter = [];
  }

  // =================== SEND NOTIFICATION POP UP CANCEL BUTTON FUNCTIONALITY ==================
  resetDealerNotification() {
    this.dealerNotifiList = [...this.dealerList];
    this.checkAllDealers = true;
    this.notificationFilter = [];
    this.checkAll();
    this.dealerNotiSearch = '';
    this.notificationFilter = [];
  }

  // =========================Downloading console report=====================================

  // private setting = {
  //   element: {
  //     dynamicDownload: null as HTMLElement
  //   }
  // }

  // dynamicDownloadTxt() {
  
  //     this.DownloadByHtmlTag({
  //       fileName: 'Email Report',
  //       text: this.data
  //     });
  

  // }

  // private DownloadByHtmlTag(arg: {
  //   fileName: string,
  //   text: string
  // }) {
  //   if (!this.setting.element.dynamicDownload) {
  //     this.setting.element.dynamicDownload = document.createElement('a');
  //   }
  //   const element = this.setting.element.dynamicDownload;
  //   const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
  //   element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
  //   element.setAttribute('download', arg.fileName);

  //   var event = new MouseEvent("click");
  //   element.dispatchEvent(event);

  //   this.downloadNow=false;
  // }
}
