import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AppComponent } from '../app.component'
import { CommonService } from '../service/common.service'


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
  noFilteredNotification: boolean;
  noNotifications: boolean;
  noMatch: boolean;
  notificationArray: any[] = [];
  displayArray: any = [];
  todayNotifications: any = [];
  olderNotifications: any = [];
  yesterdayNotifications: any = [];
  filteredNotification: any = [];
  searchkey: any = '';
  notifyTime: string;
    msg: string;
  iconShow: Boolean = false;
  hideNotification: Boolean = true;
  showfilteredNotification: Boolean = false;

  // for scrolling
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  constructor(public notificationService: NotificationService, private router: Router, 
    private dashboardComponent: DashboardComponent, private appcComponent: AppComponent,
    public appService: CommonService) {
    if (this.searchkey = '') {
      this.hideNotification = true;
      this.showfilteredNotification = false;
    }
  }

  ngOnInit() {
    this.getAllNotifications();
  }

  // function to get all the notifications
  getAllNotifications() {
    this.displayArray = [];
    var senderid = this.appService.loginId;
    console.log('test to see code changes');
    this.notificationService.getAllNotificationList(senderid).subscribe((result:any) => {
      console.log(result);
      for (let i = 0; i < result.NotificationDetails.NotificationDetail.length; i++) {
        if(result.NotificationDetails.NotificationDetail[i].IS_ARCHIEVE == "N"){
          this.displayArray.push(result.NotificationDetails.NotificationDetail[i]);
        }
      }
      console.log('Notification List', this.displayArray);
      for (let i = 0; i < this.displayArray.length; i++) {
        this.displayArray[i].Notification = this.displayArray[i].MSG_SUMMARY;
        this.displayArray[i].action = this.displayArray[i].MSG_TYPE;
        this.displayArray[i].status = this.displayArray[i].IS_READ;
        this.displayArray[i].date = this.displayArray[i].LST_UPDT_DT;
        if (this.displayArray[i].MSG_TYPE == "Info" || this.displayArray[i].MSG_TYPE == "Sent" ) {
          this.displayArray[i].action = "View"
        }
      }
      this.appcComponent.deleteAll();
      // this.seperateNotifications(this.displayArray);
    });
  }

  // function to seperate notifications based on date (today / yesterday / older)
  seperateNotifications(arr) {
    this.todayNotifications = [];
    this.olderNotifications = [];
    this.yesterdayNotifications = [];
    for (let i = 0; i < arr.length; i++) {
      const Ndate = new Date(arr[i].date).setHours(0, 0, 0, 0);
      const tdate = new Date().setHours(0, 0, 0, 0);

      const todaysdate = new Date().getDate();
      const todaysyear = new Date().getFullYear();
      const todaysmonth = new Date().getMonth();

      const Notifydate = new Date(arr[i].date).getDate();
      const Notifyyear = new Date(arr[i].date).getFullYear();
      const Notifymonth = new Date(arr[i].date).getMonth();
      
      if (todaysyear == Notifyyear) {
        if (todaysmonth == Notifymonth) {
          if (todaysdate - Notifydate == 1) {
            this.yesterdayNotifications.push(arr[i]);
          }
          else if (todaysdate == Notifydate) {
            this.todayNotifications.push(arr[i]);
          }
          else {
            this.olderNotifications.push(arr[i]);
          }
        }
        else {
          this.olderNotifications.push(arr[i]);
        }
      }
      else {
        this.olderNotifications.push(arr[i]);
      }
    }

    this.todayNotifications = this.todayNotifications.sort(function(a, b) {
      var sortA = new Date(a.date);
      var sortB = new Date(b.date);
      var compA: any; var compB: any; compA = sortA;compB = sortB;
      return compB - compA
    });
    this.yesterdayNotifications.sort(function(a, b) {
      var sortA = new Date(a.date);
      var sortB = new Date(b.date);
      var compA: any; var compB: any; compA = sortA;compB = sortB;
      return compB - compA
    });
    this.olderNotifications.sort(function(a, b) {
      var sortA = new Date(a.date);
      var sortB = new Date(b.date);
      var compA: any; var compB: any; compA = sortA;compB = sortB;
      return compB - compA
    });
  }

  // function to search for specific notification
  notifySearch() {
      this.filteredNotification = [];
      for (let i = 0; i < this.displayArray.length; i++) {
        this.msg = this.displayArray[i].Notification.toLowerCase();
        const a = this.searchkey.toLowerCase();
        const regex1 = RegExp(a);
        if (regex1.test(this.msg )) {
          this.filteredNotification.push(this.displayArray[i]);
        }
      }
      // this.seperateNotifications(this.filteredNotification);
      console.log(this.filteredNotification);
  }

  // function to show the close icon in the search area on focus 
  showIconFunction() {
    this.iconShow = true;
  }

  // function to show the hide icon in the search area on focusout 
  hideIconFunction() {
    if (this.searchkey == '') {
      this.iconShow = false;
      this.hideNotification = true;
      this.showfilteredNotification = false;
      this.noFilteredNotification = false;
      // this.seperateNotifications(this.displayArray);
    }
    else {
      this.iconShow = true;
    }
  }

  // function to clear the search field when close is clicked in search area
  closeSpinSearch() {
    this.searchkey = '';
    this.hideNotification = true;
    this.showfilteredNotification = false;
    this.noFilteredNotification = false;
  }

  // function to navigate to a specific page based on the notification when 'View' is clicked
  navigationToPage(notification: any) {
    if (notification.Notification.toLowerCase().indexOf('dealership')!=-1) {
      this.router.navigateByUrl('/dealershipEnrollment');
      this.appService.dealershipEnrolledDealerCode = notification.MSG_DETAILS.split(' ')[3] + '';
    }
    if (notification.Notification.toLowerCase().indexOf('associate')!=-1) {
      // this.router.navigateByUrl('/dashboard');
    //  this.dashboardComponent.notificationViewForm('104090187451');
      var sId = notification.MSG_DETAILS.split(' ')[4] + '';
    this.dashboardComponent.notificationViewForm(sId);
      // this.router.navigateByUrl('/dashboard');
    }
    if (notification.Notification.toLowerCase().indexOf('payment on hold')!=-1 || 
    notification.Notification.toLowerCase().indexOf('payment resumed')!=-1) {
      if(this.appService.corpUser) {
        this.router.navigateByUrl('/corpHoldResume');
      } else {
        this.router.navigateByUrl('/holdResumePayments');
      }
      
    }
    if (notification.Notification.toLowerCase().indexOf('new reports available')!=-1) {
      this.router.navigateByUrl('/reports');
    }
    if (notification.Notification.toLowerCase().indexOf('payment paid and approved')!=-1) {
      this.router.navigateByUrl('/reviewPayment');
    }
  }

  onScrollDown (ev) {
    this.getAllNotifications();
    console.log('scrolled down!!', ev);
      }
  
  onUp(ev) {
    console.log('scrolled up!', ev);
  }

}
