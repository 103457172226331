import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatIconModule } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AseLexusService } from '../ase-lexus/ase-lexus.service';
import { ExcelService } from '../service/excel.service';
declare var $: any;
function reportValidator(fg: FormGroup) {
  const spinId = fg.get('spinId').value;
  const firstName = fg.get('firstName').value;
  const ase = fg.get('ase').value;
  const lastName = fg.get('lastName').value;
  const dealerNumber = fg.get('dealerNumber').value;
  const testTakenDate = fg.get('testTakenDate').value;
  const expirationDate = fg.get('expirationDate').value;
  return spinId || firstName || ase || lastName || dealerNumber || testTakenDate || expirationDate
    ? null
    : { range: true };
}
@Component({
  selector: 'app-ase-certification-status',
  templateUrl: './ase-certification-status.component.html',
  styleUrls: ['./ase-certification-status.component.css']
})

export class AseCertificationStatusComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('ReportPaginator', { read: MatPaginator }) ReportPaginator: MatPaginator;
  searchForm: FormGroup;
  reportArray: any = [];
  reportDataSource: MatTableDataSource<any>;
  reportDataColumns: string[] = ["name", "spinId", "dealer", "testId", "certExpStatus", "expirationDate", "testTakenDate", "aseId", "candidateId"];
  tableLoading = true;
  showSpinner = false;
  exportData: any[];
  filterSelected: boolean;
  lastUpdatedOn: any;
  showMsg = '';
  constructor(private fb: FormBuilder, private aseLexusService: AseLexusService, private excelService: ExcelService) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      spinId: '',
      dealerNumber: '',
      firstName: '',
      lastName: '',
      ase: '',
      testTakenDate: '',
      expirationDate: '',
      download: 'no'
    }, { validator: reportValidator });
  }
  search() {
    this.showMsg = '';
    this.tableLoading = true;
    this.showSpinner = true;
    this.reportArray = [];
    this.exportData = [];
    this.aseLexusService.getCertStatus(this.searchForm.value).subscribe((response: any) => {
      let responseArr = response.Reports.Result;
      for (let i = 0; i < responseArr.length; i++) {
        this.reportArray.push(responseArr[i]);
        this.exportData.push(responseArr[i])
      }
      this.lastUpdatedOn = this.reportArray.length > 0 ? this.reportArray[0].createdOn : new Date()
      if (this.reportArray.length == 1 && this.reportArray[0].testTakenDate == '' && this.reportArray[0].expirationDate == '' && this.reportArray[0].aseId == '') {
        if (this.reportArray[0].jobRole == 'LEXUS-DEALERSHIP-SERVICE-012-SERVICE TECHNICIAN' ||
          this.reportArray[0].jobRole == 'LEXUS-DEALERSHIP-SERVICE-013-TECHNICIAN APPRENTICE' ||
          this.reportArray[0].jobRole == 'LEXUS-DEALERSHIP-SERVICE-015-DIAGNOSTIC SPECIALIST' ||
          this.reportArray[0].jobRole == 'LEXUS-DEALERSHIP-SERVICE-016-TEAM LEADER' ||
          this.reportArray[0].jobRole == 'LEXUS-REGIONAL-SERVICE-074-SERV TRAINING SPECIALIST' ||
          this.reportArray[0].jobRole == 'LEXUS-DEALERSHIP-SERVICE-020-SERVICE CONSULTANT' ||
          this.reportArray[0].jobRole == 'LEXUS-DEALERSHIP-SERVICE-073-FIELD TECH SPECIALIST' ||
          this.reportArray[0].jobRole == 'LEXUS-REGIONAL-SERVICE-075-DIST SERV MANAGER' ||
          this.reportArray[0].jobRole == 'LEXUS-DEALERSHIP-SERVICE-014-SHOP F||EMAN' ||
          this.reportArray[0].jobRole == 'LEXUS-DEALERSHIP-SERVICE-079-FIELD PRODUCT ENGINNER') {
          this.showMsg ='Associate does not have any certification. Please check in ASE Rejection Report.'
          $('#noStatus').modal('show');
          this.reportArray = [];
        }
        else {
          this.showMsg ='Associate does not have any certification/valid job role.'
          $('#noStatus').modal('show');
          this.reportArray = [];
        }

      }
      this.tableLoading = false;
      this.showSpinner = false;
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    })
  }
  reset() {
    this.searchForm.reset({
      spinId: '',
      dealerNumber: '',
      firstName: '',
      lastName: '',
      ase: '',
      testTakenDate: '',
      expirationDate: '',
      download: 'no'
    });
    this.reportArray = [];
    this.exportData = []
    this.tableLoading = true;
    this.showSpinner = false;
    this.reportDataSource.sort = this.sort;
    this.reportDataSource = new MatTableDataSource(this.reportArray);
    this.reportDataSource.paginator = this.ReportPaginator;
  }
  download(type) {
    if (type == 'full') {
      this.searchForm.reset({
        spinId: '',
        dealerNumber: '',
        firstName: '',
        lastName: '',
        ase: '',
        testTakenDate: '',
        expirationDate: '',
        download: 'yes'
      });
      this.reportArray = [];
      this.exportData = []
      // this.searchForm.get('download').setValue('yes');
      this.aseLexusService.getCertStatus(this.searchForm.value).subscribe((response: any) => {
        let responseArr = response.Reports.Result;
        responseArr.forEach(element => {
          this.exportData.push(element)
        });
        this.lastUpdatedOn = this.reportArray.length > 0 ? this.reportArray[0].createdOn : new Date()
      })
    }
    let reportExportData = [];
    this.exportData.forEach(data => {
      let obj = {
        "NAME": data.name,
        "SPIN ID": data.spinId,
        "COURSE": data.testId,
        "DEALER CODE": data.dealer,
        "CERTIFICATION STATUS": data.certExpStatus,
        "EXPIRATION DATE": data.expirationDate,
        "TEST TAKEN DATE": data.testTakenDate,
        "ASE ID": data.aseId,
        "CANDIDATE ID": data.candidateId
      }
      reportExportData.push(obj);
    });
    this.excelService.exportAsExcelFile(reportExportData, 'ASE_Certifcation_Status_Report');
  }
  //  ================================= TABLE SEARCH FILTER METHOD ======================================
  applyFilter(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.reportDataSource.filter = filterValue.trim().toLowerCase();
    this.reportDataSource.filteredData.forEach(element => {
      this.exportData.push(element);
    })
    // if (filterValue != '' || this.reportDataSource.filteredData.length < 10) {
    //   $("#holdHistorTable").removeClass('tableOverflow');
    // } else {
    //   $("#holdHistorTable").addClass('tableOverflow');
    // }
  }
}
