import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminService } from "./admin.service";
import { ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService } from '../service/common.service';

export interface JobLoggerData {
  JOB_PROCESS_DT: string;
  JOB_DESC: string;
  USER: string;
  RESULT: string;
}

export interface OtherJobLoggerData {
  startDate: string;
  endDate: string;
  rowAffected: string;
}

@Component({
  selector: 'app-admin-component',
  templateUrl: './admin-component.component.html',
  styleUrls: ['./admin-component.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AdminComponentComponent implements OnInit {
  successMsg: string = '';
  errorMsg: string = '';
  showErrorMsg: boolean = false;
  showSuccessMsg: boolean = false;
  expandState: boolean = true;
  openAccordian: boolean = true;

  paymentProcessColumns: string[] = ['JOB_PROCESS_DT', 'JOB_DESC', 'USER', 'RESULT'];
  paymentProcessdataSource = new MatTableDataSource<JobLoggerData>();
  SendLaterNotiColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  SendLaterNotiDataSource = new MatTableDataSource<OtherJobLoggerData>();
  ISReportColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  ISReportDataSource: any = new MatTableDataSource<OtherJobLoggerData>();;
  OutboundFileColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  OutboundFileDataSource: any = new MatTableDataSource<OtherJobLoggerData>();;
  ArchiveNotiColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  ArchiveNotiDataSource: any = new MatTableDataSource<OtherJobLoggerData>();;
  DeleteEnrollColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  DeleteEnrollDataSource: any = new MatTableDataSource<OtherJobLoggerData>();;
  DeleteNotiDataColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  DeleteNotiDataSource: any = new MatTableDataSource<OtherJobLoggerData>();;
  SnapshotColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  SnapshotDataSource: any = new MatTableDataSource<OtherJobLoggerData>();;
  MaritizColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  MaritizDataSource: any = new MatTableDataSource<OtherJobLoggerData>();;
  AccountMasterColumns: string[] = ['startDate', 'endDate', 'rowAffected'];
  AccountMasterDataSource: any = new MatTableDataSource<OtherJobLoggerData>();;
  @ViewChild('PaymentProcessPaginator', { read: MatPaginator }) PaymentProcessPaginator: MatPaginator;
  @ViewChild('PaymentProcessSort', { read: MatSort }) PaymentProcessSort: MatSort;
  @ViewChild('SnapshotSort', { read: MatSort }) SnapshotSort: MatSort;
  @ViewChild('SnapshotPaginator', { read: MatPaginator }) SnapshotPaginator: MatPaginator;
  @ViewChild('MaritizSort', { read: MatSort }) MaritizSort: MatSort;
  @ViewChild('MaritizPaginator', { read: MatPaginator }) MaritizPaginator: MatPaginator;
  @ViewChild('AccountMasterSort', { read: MatSort }) AccountMasterSort: MatSort;
  @ViewChild('AccountMasterPaginator', { read: MatPaginator }) AccountMasterPaginator: MatPaginator;
  @ViewChild('SendLaterNotiSort', { read: MatSort }) SendLaterNotiSort: MatSort;
  @ViewChild('SendLaterNotiPaginator', { read: MatPaginator }) SendLaterNotiPaginator: MatPaginator;
  @ViewChild('DeleteNotiSort', { read: MatSort }) DeleteNotiSort: MatSort;
  @ViewChild('DeleteNotiPaginator', { read: MatPaginator }) DeleteNotiPaginator: MatPaginator;
  @ViewChild('ArchiveNotiSort', { read: MatSort }) ArchiveNotiSort: MatSort;
  @ViewChild('ArchiveNotiPaginator', { read: MatPaginator }) ArchiveNotiPaginator: MatPaginator;
  @ViewChild('ISReportSort', { read: MatSort }) ISReportSort: MatSort;
  @ViewChild('ISReportPaginator', { read: MatPaginator }) ISReportPaginator: MatPaginator;
  @ViewChild('DeleteEnrollSort', { read: MatSort }) DeleteEnrollSort: MatSort;
  @ViewChild('DeleteEnrollPaginator', { read: MatPaginator }) DeleteEnrollPaginator: MatPaginator;
  @ViewChild('OutboundFileSort', { read: MatSort }) OutboundFileSort: MatSort;
  @ViewChild('OutboundFilePaginator', { read: MatPaginator }) OutboundFilePaginator: MatPaginator;


  //PaymentProcess Variable
  jobLogResponse: any = '';
  loadSpinner: boolean = false;
  jobLogLoading: boolean = false;
  paymentProcessJobLog: any = [];
  noPPData: boolean = false;

  //OtherBatchJob
  otherBatchResponse: any = '';
  otherbatchLogLoading: boolean = false;
  noOtherBatchJob: boolean = false;
  SendLaterNoti5: any = [];
  ISReport8: any = [];
  OutboundFile10: any = [];
  ArchiveNoti7: any = [];
  DeleteEnroll9: any = [];
  DeleteNoti6: any = [];
  Snapshot2: any = [];
  martiz3: any = [];
  accountMaster4: any = [];
  jobDesc1: any = '';
  jobDesc2: any = '';
  jobDesc3: any = '';
  jobDesc4: any = '';
  jobDesc5: any = '';
  jobDesc6: any = '';
  jobDesc7: any = '';
  jobDesc8: any = '';
  jobDesc9: any = '';
  jobDesc10: any = '';
  jobName1: any = '';
  jobName2: any = '';
  jobName3: any = '';
  jobName4: any = '';
  jobName5: any = '';
  jobName6: any = '';
  jobName7: any = '';
  jobName8: any = '';
  jobName9: any = '';
  jobName10: any = '';
  schedule1: any = '';
  schedule2: any = '';
  schedule3: any = '';
  schedule4: any = '';
  schedule5: any = '';
  schedule6: any = '';
  schedule7: any = '';
  schedule8: any = '';
  schedule9: any = '';
  schedule10: any = '';
  jobSchedule1: any = '';
  jobSchedule2: any = '';
  jobSchedule3: any = '';
  jobSchedule4: any = '';
  jobSchedule5: any = '';
  jobSchedule6: any = '';
  jobSchedule7: any = '';
  jobSchedule8: any = '';
  jobSchedule9: any = '';
  jobSchedule10: any = '';
  adfname1: any = '';
  adfname2: any = '';
  adfname3: any = '';
  adfname4: any = '';
  adfname5: any = '';
  adfname6: any = '';
  adfname7: any = '';
  adfname8: any = '';
  adfname9: any = '';
  adfname10: any = '';

  constructor(private AdminService: AdminService, private appService: CommonService, private router: Router) {
    this.loadSpinner = true;
    this.PPJobLogger(); //  get the payment processing job log details
    this.OtherBatchLog(); //  get the other job log details
  }

  ngOnInit() {
    console.log('admin',this.appService.role);
    if (this.appService.role != 'Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
   }

  // ===========================CHECK THE PAYMENT PROCESS JOB LOG AND OTHER JOB LOG LOADING FUNCTION==============
  checkLoading() {
    if (!this.jobLogLoading && !this.otherbatchLogLoading) {
      this.loadSpinner = false;
    }
  }

  // ============================PAYMENT PROCESSING JOB LOG DETAIL FUNCTION========================================
  PPJobLogger() {
    this.jobLogLoading = true;
    //get the job logger details
    this.AdminService.getJobLoggerDetails().subscribe((response: any) => {
      this.jobLogResponse = response.JobLogger.Result;
      if (this.jobLogResponse.length != 0) {
        this.noPPData = false; //payment processing job logger response is not empty
        for (let i = 0; i < this.jobLogResponse.length; i++) {
          let obj = {
            'JOB_PROCESS_DT': this.jobLogResponse[i].JOB_PROCESS_DT,
            'JOB_DESC': this.jobLogResponse[i].JOB_DESC,
            'USER': this.jobLogResponse[i].LST_UPDT_BY,
            'RESULT': this.jobLogResponse[i].RESULT,
          }
          if (this.jobLogResponse[i].BATCH_JOB_ID == 1) { // if the batch job id is 1, then it is payment processing job
            this.paymentProcessJobLog.push(obj);
            this.jobDesc1 = this.jobLogResponse[i].JOB_DESC; // job description
            this.jobName1 = this.jobLogResponse[i].JOB_PROCESS_DT; // job name
            this.jobSchedule1 = this.jobLogResponse[i].JOB_TS; // job schedule time
            this.adfname1 = this.jobLogResponse[i].adfname; // ADF name
            this.schedule1 = this.checkTime(this.jobSchedule1); // next schedule time
          }
        }
      } else {
        //payment processing job logger response is empty
        this.noPPData = true;
        this.jobDesc1 = 'Daily job to trigger the Payments processing work';
        this.jobName1 = 'N/A';
        this.jobSchedule1 = 'N/A';
        this.schedule1 = 'N/A';
        this.adfname1 = 'N/A';
      }
      if (this.paymentProcessJobLog.length == 0) { //payment processing job logger response is not empty
        this.jobDesc1 = 'Daily job to trigger the Payments processing work';
        this.jobName1 = 'N/A';
        this.jobSchedule1 = 'N/A';
        this.schedule1 = 'N/A';
        this.adfname1 = 'N/A';
      }
      // initialise the datasource for payment process table
      this.paymentProcessdataSource = new MatTableDataSource(this.paymentProcessJobLog);
      this.paymentProcessdataSource.paginator = this.PaymentProcessPaginator;
      this.paymentProcessdataSource.sort = this.PaymentProcessSort;
      this.jobLogLoading = false;
      this.checkLoading();
    }, err => {
      console.log('Error', err);
      console.log('Error while fetching job logger details');
      this.noPPData = true;
      this.paymentProcessJobLog = [];
      this.jobLogLoading = false;
      this.checkLoading();
      this.jobLogResponse = '';
      this.jobDesc1 = 'Daily job to trigger the Payments processing work';
      this.jobName1 = 'N/A';
      this.jobSchedule1 = 'N/A';
      this.schedule1 = 'N/A';
      this.adfname1 = 'N/A';
    });
  }

  // ============================NEXT BATCH RUN TIME CALCULATION FUNCTION========================================
  checkTime(batchtime) {
    var batchTime = '';
    if (batchtime != null && batchtime != '' && batchtime != 'NOT SCHEDULED') {
      if (batchtime.indexOf('CST')==-1) {
        var batchtimeFormat = new Date(batchtime);
        batchTime = batchtimeFormat.toLocaleTimeString();
      }else{
        batchTime = batchtime;
      } 
        var today = new Date();  //Get today's date
        var hours = today.getHours(); //Get the hour from the today's date
        var splitbatchTime = batchTime.split(' '); // split the batchtime with space
        var meridian = splitbatchTime[1]; // meridian is to know whether batchtime is AM or PM
        var givenTime = batchTime.split(':');  // given time is to get the hour from the batchtime
        if (meridian.toUpperCase() == 'PM') {
          var scheduleTime = parseInt(givenTime[0]) + 12; // If meridian is PM the add the batchtime hour with 12
        } else {
          var scheduleTime = parseInt(givenTime[0]);
        }
        if (scheduleTime > hours) {
          let date = this.dateFormatter(today); // today's date if the batchtime is greater than current hour
          return date + ' ' + splitbatchTime[0] + ' ' + splitbatchTime[1];
        } else {
          let tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000)); // get tomorrow date
          let date = this.dateFormatter(tomorrow); //tomorrow's date if the batchtime is less than current hour
          return date + ' ' + splitbatchTime[0] + ' ' + splitbatchTime[1];
        }
    } else {
      return batchtime
    }
  }

  // ============================DATE FORMAT FUNCTION========================================
  dateFormatter(date) {
    let givenDate = new Date(date);
    let month = givenDate.getMonth() + 1;
    let dat = givenDate.getDate();
    let yr = givenDate.getFullYear();
    return month + '/' + dat + '/' + yr;
  }

  
  // ============================GET THE OTHER JOB LOG FILES========================================
  OtherBatchLog() {
    this.otherbatchLogLoading = true;
    //get the other job logger details
    this.AdminService.getAllJobLoggerDetails().subscribe((response: any) => {
      this.otherBatchResponse = response.OtherBatchJobLogger.Result;
      if (this.otherBatchResponse.length != 0) {
        this.noOtherBatchJob = false;
        for (let i = 0; i < this.otherBatchResponse.length; i++) {
          let obj = {
            'startDate': this.otherBatchResponse[i].startDate,
            'endDate': this.otherBatchResponse[i].endDate,
            'rowAffected': this.otherBatchResponse[i].rowAffected
          }
          if (this.otherBatchResponse[i].batchJobId == 2) {// batch job id 2 is for snapshot job
            this.Snapshot2.push(obj);
            this.jobDesc2 = this.otherBatchResponse[i].jobDescription;
            this.jobName2 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule2 = this.otherBatchResponse[i].scheduledTime;
            this.adfname2 = this.otherBatchResponse[i].adfname;
            this.schedule2 = this.checkTime(this.jobSchedule2);
          } else if (this.otherBatchResponse[i].batchJobId == 3) { // batch job id 3 is for maritz  job
            this.martiz3.push(obj);
            this.jobDesc3 = this.otherBatchResponse[i].jobDescription;
            this.jobName3 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule3 = this.otherBatchResponse[i].scheduledTime;
            this.schedule3 = this.checkTime(this.jobSchedule3);
            this.adfname3 = this.otherBatchResponse[i].adfname;
          } else if (this.otherBatchResponse[i].batchJobId == 4) { // batch job id 4 is for account master job 
            this.accountMaster4.push(obj);
            this.jobDesc4 = this.otherBatchResponse[i].jobDescription;
            this.jobName4 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule4 = this.otherBatchResponse[i].scheduledTime;
            this.schedule4 = this.checkTime(this.jobSchedule4);
            this.adfname4 = this.otherBatchResponse[i].adfname;

          } else if (this.otherBatchResponse[i].batchJobId == 5) { // batch job id 5 is for send later notification job 
            this.SendLaterNoti5.push(obj);
            this.jobDesc5 = this.otherBatchResponse[i].jobDescription;
            this.jobName5 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule5 = this.otherBatchResponse[i].scheduledTime;
            this.schedule5 = this.checkTime(this.jobSchedule5);
            this.adfname5 = this.otherBatchResponse[i].adfname;

          } else if (this.otherBatchResponse[i].batchJobId == 6) { // batch job id 6 is for delete notification job 
            this.DeleteNoti6.push(obj);
            this.jobDesc6 = this.otherBatchResponse[i].jobDescription;
            this.jobName6 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule6 = this.otherBatchResponse[i].scheduledTime;
            this.schedule6 = this.checkTime(this.jobSchedule6);
            this.adfname6 = this.otherBatchResponse[i].adfname;

          } else if (this.otherBatchResponse[i].batchJobId == 7) { // batch job id 7 is for archieve notification job 
            this.ArchiveNoti7.push(obj);
            this.jobDesc7 = this.otherBatchResponse[i].jobDescription;
            this.jobName7 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule7 = this.otherBatchResponse[i].scheduledTime;
            this.schedule7 = this.checkTime(this.jobSchedule7);
            this.adfname7 = this.otherBatchResponse[i].adfname;

          } else if (this.otherBatchResponse[i].batchJobId == 8) { // batch job id 8 is for IS report job 
            this.ISReport8.push(obj);
            this.jobDesc8 = this.otherBatchResponse[i].jobDescription;
            this.jobName8 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule8 = this.otherBatchResponse[i].scheduledTime;
            this.schedule8 = this.checkTime(this.jobSchedule8);
            this.adfname8 = this.otherBatchResponse[i].adfname;

          } else if (this.otherBatchResponse[i].batchJobId == 9) { // batch job id 9 is for delete enrollment job 
            this.DeleteEnroll9.push(obj);
            this.jobDesc9 = this.otherBatchResponse[i].jobDescription;
            this.jobName9 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule9 = this.otherBatchResponse[i].scheduledTime;
            this.schedule9 = this.checkTime(this.jobSchedule9);
            this.adfname9 = this.otherBatchResponse[i].adfname;

          } else if (this.otherBatchResponse[i].batchJobId == 10) { // batch job id 10 is for outbound job 
            this.OutboundFile10.push(obj);
            this.jobDesc10 = this.otherBatchResponse[i].jobDescription;
            this.jobName10 = this.otherBatchResponse[i].procedureName;
            this.jobSchedule10 = this.otherBatchResponse[i].scheduledTime;
            this.schedule10 = this.checkTime(this.jobSchedule10);
            this.adfname10 = this.otherBatchResponse[i].adfname;

          }
        }
      } else {
        this.noOtherBatchJob = true;
      }
      if (this.Snapshot2.length == 0) {
        this.jobDesc2 = 'Snapshot of data in summary table';
        this.jobName2 = 'N/A';
        this.jobSchedule2 = 'N/A';
        this.schedule2 = 'N/A';
        this.adfname2 = 'N/A';
      } if (this.martiz3.length == 0) {
        this.jobDesc3 = 'Maritz Inbound data';
        this.jobName3 = 'N/A';
        this.jobSchedule3 = 'N/A';
        this.schedule3 = 'N/A';
        this.adfname3 = 'N/A';
      } if (this.accountMaster4.length == 0) {
        this.jobDesc4 = 'Account Master Inbound data';
        this.jobName4 = 'N/A';
        this.jobSchedule4 = 'N/A';
        this.schedule4 = 'N/A';
        this.adfname4 = 'N/A';
      } if (this.SendLaterNoti5.length == 0) {
        this.jobDesc5 = 'Scheduler for send later Notification';
        this.jobName5 = 'N/A';
        this.jobSchedule5 = 'N/A';
        this.schedule5 = 'N/A';
        this.adfname5 = 'N/A';
      } if (this.DeleteNoti6.length == 0) {
        this.jobDesc6 = 'Delete notifications older than 60 days';
        this.jobName6 = 'N/A';
        this.jobSchedule6 = 'N/A';
        this.schedule6 = 'N/A';
        this.adfname6 = 'N/A';
      } if (this.ArchiveNoti7.length == 0) {
        this.jobDesc7 = 'Archive Notifications older than 30 days';
        this.jobName7 = 'N/A';
        this.jobSchedule7 = 'N/A';
        this.schedule7 = 'N/A';
        this.adfname7 = 'N/A';
      } if (this.ISReport8.length == 0) {
        this.jobDesc8 = 'Daily IS report';
        this.jobName8 = 'N/A';
        this.jobSchedule8 = 'N/A';
        this.schedule8 = 'N/A';
        this.adfname8 = 'N/A';
      } if (this.DeleteEnroll9.length == 0) {
        this.jobDesc9 = 'Delete Enrollment';
        this.jobName9 = 'N/A';
        this.jobSchedule9 = 'N/A';
        this.schedule9 = 'N/A';
        this.adfname9 = 'N/A';
      } if (this.OutboundFile10.length == 0) {
        this.jobDesc10 = 'After Outbound file updation';
        this.jobName10 = 'N/A';
        this.jobSchedule10 = 'N/A';
        this.schedule10 = 'N/A';
        this.adfname10 = 'N/A';
      }

      //Initialise the mat table
      this.SnapshotDataSource = new MatTableDataSource(this.Snapshot2);
      this.SnapshotDataSource.sort = this.SnapshotSort;
      this.SnapshotDataSource.paginator = this.SnapshotPaginator;
      this.MaritizDataSource = new MatTableDataSource(this.martiz3);
      this.MaritizDataSource.sort = this.MaritizSort;
      this.MaritizDataSource.paginator = this.MaritizPaginator;
      this.AccountMasterDataSource = new MatTableDataSource(this.accountMaster4);
      this.AccountMasterDataSource.sort = this.AccountMasterSort;
      this.AccountMasterDataSource.paginator = this.AccountMasterPaginator;
      this.SendLaterNotiDataSource = new MatTableDataSource(this.SendLaterNoti5);
      this.SendLaterNotiDataSource.sort = this.SendLaterNotiSort;
      this.SendLaterNotiDataSource.paginator = this.SendLaterNotiPaginator;
      this.DeleteNotiDataSource = new MatTableDataSource(this.DeleteNoti6);
      this.DeleteNotiDataSource.sort = this.DeleteNotiSort;
      this.DeleteNotiDataSource.paginator = this.DeleteNotiPaginator;
      this.ArchiveNotiDataSource = new MatTableDataSource(this.ArchiveNoti7);
      this.ArchiveNotiDataSource.sort = this.ArchiveNotiSort;
      this.ArchiveNotiDataSource.paginator = this.ArchiveNotiPaginator;
      this.ISReportDataSource = new MatTableDataSource(this.ISReport8);
      this.ISReportDataSource.sort = this.ISReportSort;
      this.ISReportDataSource.paginator = this.ISReportPaginator;
      this.DeleteEnrollDataSource = new MatTableDataSource(this.DeleteEnroll9);
      this.DeleteEnrollDataSource.sort = this.DeleteEnrollSort;
      this.DeleteEnrollDataSource.paginator = this.DeleteEnrollPaginator;
      this.OutboundFileDataSource = new MatTableDataSource(this.OutboundFile10);
      this.OutboundFileDataSource.sort = this.OutboundFileSort;
      this.OutboundFileDataSource.paginator = this.OutboundFilePaginator;

      this.otherbatchLogLoading = false;
      this.checkLoading();
    }, err => {
      console.log('Error', err);
      console.log('Error while fetching Other job logger details');
      this.otherBatchResponse = '';
      this.otherbatchLogLoading = false;
      this.checkLoading();
      this.noOtherBatchJob = true;
      this.SendLaterNoti5 = [];
      this.ISReport8 = [];
      this.OutboundFile10 = [];
      this.ArchiveNoti7 = [];
      this.DeleteEnroll9 = [];
      this.DeleteNoti6 = [];
      this.Snapshot2 = [];
      this.martiz3 = [];
      this.accountMaster4 = [];
      this.jobDesc2 = 'Snapshot of data in summary table';
      this.jobDesc3 = 'Maritz Inbound data';
      this.jobDesc4 = 'Account Master Inbound data';
      this.jobDesc5 = 'Scheduler for send later Notification';
      this.jobDesc6 = 'Delete notifications older than 60 days';
      this.jobDesc7 = 'Archive Notifications older than 30 days';
      this.jobDesc8 = 'Daily IS report';
      this.jobDesc9 = 'Delete Enrollment';
      this.jobDesc10 = 'After Outbound file updation';
      this.jobName2 = 'N/A';
      this.jobName3 = 'N/A';
      this.jobName4 = 'N/A';
      this.jobName5 = 'N/A';
      this.jobName6 = 'N/A';
      this.jobName7 = 'N/A';
      this.jobName8 = 'N/A';
      this.jobName9 = 'N/A';
      this.jobName10 = 'N/A';
      this.jobSchedule2 = 'N/A';
      this.jobSchedule3 = 'N/A';
      this.jobSchedule4 = 'N/A';
      this.jobSchedule5 = 'N/A';
      this.jobSchedule6 = 'N/A';
      this.jobSchedule7 = 'N/A';
      this.jobSchedule8 = 'N/A';
      this.jobSchedule9 = 'N/A';
      this.jobSchedule10 = 'N/A';
      this.schedule2 = 'N/A';
      this.schedule3 = 'N/A';
      this.schedule4 = 'N/A';
      this.schedule5 = 'N/A';
      this.schedule6 = 'N/A';
      this.schedule7 = 'N/A';
      this.schedule8 = 'N/A';
      this.schedule9 = 'N/A';
      this.schedule10 = 'N/A';
      this.adfname2 = 'N/A';
      this.adfname3 = 'N/A';
      this.adfname4 = 'N/A';
      this.adfname5 = 'N/A';
      this.adfname6 = 'N/A';
      this.adfname7 = 'N/A';
      this.adfname8 = 'N/A';
      this.adfname9 = 'N/A';
      this.adfname10 = 'N/A';
    });
  }

  // ============================MANUAL BATCH TRIGGER========================================
  batchTrigger() {
    this.AdminService.triggerBatch().subscribe((response: any) => {
      console.log(response.ManualPaymentJob.ManualJobDetail[0].SuccessMsg);
      this.successMsg = response.ManualPaymentJob.ManualJobDetail[0].SuccessMsg;
      if (this.successMsg != '') {
        this.showSuccessMsg = true;
        setTimeout(function () {
          this.showSuccessMsg = false;
          this.successMsg = '';
        }.bind(this), 5000);
      }
    }, err => {
      console.log('Error', err);
      console.log("Error while triggering the batch process");
      this.showErrorMsg = true;
      this.errorMsg = 'Manual batch trigger failed';
      setTimeout(function () {
        this.showErrorMsg = false;
        this.errorMsg = '';
      }.bind(this), 5000);
    })
  }

  // ============================DOWNLOAD LOG FILES========================================
  downloadLog(rowData) {
    this.AdminService.downloadLogFiles().subscribe((res: any) => {
    })
  }
}

