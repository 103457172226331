import { Component, OnInit } from '@angular/core';
import { MatTableDataSource,MatSelectModule } from '@angular/material';
import {FormControl,FormsModule} from '@angular/forms';
import { CommonService } from "src/app/service/common.service";
import { Router } from '@angular/router';

declare var $: any;
import { AccessControlService } from './accesscontrol.service';

@Component({
  selector: 'app-accesscontrol',
  templateUrl: './accesscontrol.component.html',
  styleUrls: ['./accesscontrol.component.css']
})

export class AccesscontrolComponent implements OnInit {
  loggedId: string;
  loggedUserWorkdayId: any;
  canBeDeleted: boolean;
  emailErrorMsg: boolean = false;
  workIdErrorMsg: boolean = false;
  workDayIdIsValid: boolean = false;
  emailIsValid: boolean = false;
  lastNameIsValid: boolean = false;
  firstNameIsValid: boolean = false;
  collegeAdminValue: string;
  lexusUserValue: string;
  isAdminValue: string;
  areaAdminValue: string;
  updateValue: string;
  arrayLength: number;
  indexToRemove: any;
  insertError: boolean;
  isLexusCollegeAdmin: string;
  isLexusUser: string;
  isAreaAdmin: string;
  isIsAdmin: string;
  region: string;
  workDayId: any;
  firstName: any;
  lastName: any;
  emailAddress: any;
  DeleteId: any;
  duplicateIdWarning: boolean;
  duplicateId: number;
  workIdCheck: boolean;
  workDayIdisNotNull: boolean = false;
  checkboxValue: number;
  checkBoxCheck: boolean;
  name: any;
  workdayid: number;
  username: any;
  disbaleaddRow: boolean;
  Iderror: boolean;
  disableSave: boolean = false;;
  number: number = 1;
  usersArray: any = [];
  deleteError = false;
  tableLoading:boolean;
  fnameErrorMsg: boolean = false;
  lnameErrorMsg: boolean = false;
  selectedRegion = [];
  regionCodeList = [  {name:'NATIONAL LEVEL',code:'000'},
                  {name:'AREA WEST (310)',code:'310'},
                  {name:'CENTRAL AREA (320)',code:'320'},
                  {name:'AREA EAST (330)',code:'330'},
                  {name:'AREA SOUTH (340)',code:'340'} ]

  constructor(private accessService: AccessControlService, public appService: CommonService, private router: Router) {
  }
  
  ngOnInit() {
    console.log('admin access ctrl',this.appService.role);
    if (this.appService.role != 'Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.tableLoading = true; //boolean to enable the spinner appear till the table is loaded
    // Service call to get the user list
    this.accessService.getUserList().subscribe((result: any) => {
      this.usersArray = result.UserDetails.Result; //Array of users in the access control table
      this.arrayLength = this.usersArray.length; //length of the usersArray
      // For loop to load the userArray with the radio button check
      for (let i = 0; i < this.usersArray.length; i++) {
        this.usersArray[i].FIRSTNAME = this.usersArray[i].firstName.toUpperCase();
        this.usersArray[i].LASTNAME = this.usersArray[i].lastName.toUpperCase();
        this.usersArray[i].WORKDAYID = this.usersArray[i].workdayId;
        this.usersArray[i].EMAIL = this.usersArray[i].emailId;
        this.usersArray[i].REGION = this.usersArray[i].region; 
        this.selectedRegion[i] = this.usersArray[i].region;
        if (this.usersArray[i].isAdmin == 'Y') {
          setTimeout(function () {
            (<HTMLInputElement>document.getElementById("IsAdmin" + i)).checked = true;
          }.bind(this), 30);
        }
        else if (this.usersArray[i].isAreaAdmin == 'Y') {
          setTimeout(function () {
            (<HTMLInputElement>document.getElementById("areaAdmin" + i)).checked = true;
          }.bind(this), 30);
        }
        else if (this.usersArray[i].isLexusCollegeAdmin == 'Y') {
          setTimeout(function () {
            (<HTMLInputElement>document.getElementById("collegeAdmin" + i)).checked = true;
          }.bind(this), 30);
        }

        else if (this.usersArray[i].isLexusUser == 'Y') {
          setTimeout(function () {
            (<HTMLInputElement>document.getElementById("lexusUser" + i)).checked = true;
          }.bind(this), 30);
        }

        setTimeout(function () {
          for (let i = 0; i < this.usersArray.length; i++) {
            $("input[name=firstName" + i + "]").attr('readonly', 'readonly');
            $("input[name=lastName" + i + "]").attr('readonly', 'readonly');
            $("input[name=workId" + i + "]").prop('readonly', true);
            $("input[name=email" + i + "]").prop('readonly', true);
            $("input[name=firstName" + i + "]").addClass('removeBorder');
            $("input[name=lastName" + i + "]").addClass('removeBorder');
            $("input[name=workId" + i + "]").addClass('removeBorder');
            $("input[name=email" + i + "]").addClass('removeBorder');
            $("#collegeAdmin" + i).removeAttr("disabled");
            $("#lexusUser" + i).removeAttr("disabled");
            $("#IsAdmin" + i).removeAttr("disabled");
            $("#areaAdmin" + i).removeAttr("disabled");
          }
          this.tableLoading = false;
        }.bind(this), 30);
      }
    });
  }

  //workday validation to check the duplicates and length of the workday id is 6
  // if the above condition is satisfied, enable the radio button
  workIdValidation(index) {
    var readOnlyWorkId = $("input[name=workId" + index + "]").prop('readonly');
    if (!readOnlyWorkId) {
      $("#collegeAdmin" + index).prop("checked", false);
      $("#lexusUser" + index).prop("checked", false);
      $("#IsAdmin" + index).prop("checked", false);
      $("#areaAdmin" + index).prop("checked", false);
      this.duplicateId = 0;
      var workDayIDValue = $("input[name=workId" + index + "]").val();
      var id = workDayIDValue.toString()

      for (var i = 0; i < this.arrayLength; i++) {
        if (this.usersArray[i].WORKDAYID == workDayIDValue) {
          this.duplicateId += 1;
        }
      }
      if (this.duplicateId != 0) {
        this.workDayIdIsValid = false;
      }
      else {
        this.workDayIdIsValid = true;
      }
      if (workDayIDValue != null && id.length == 6 && this.workDayIdIsValid) {
        this.workDayIdisNotNull = true;
        this.workIdErrorMsg = false
        $("input[name=workId" + index + "]").removeClass('warningBox')
        this.usersArray[index].WORKDAYID = workDayIDValue;
        this.workDayId = workDayIDValue;
      }
      else {
        this.workIdErrorMsg = true
        this.workDayIdisNotNull = false;
        $("input[name=workId" + index + "]").addClass('warningBox')

      }
    }
    this.enableRadioBtn(index);    
  }

  // first name validation to check if it is alphabets and not empty string
  // if the above condition is satisfied, enable the radio button
  firsNameValidation(index) {
    let firstNameValue = $("input[name=firstName" + index + "]").val();
    var regexPattern = RegExp('^[a-zA-Z]*$');
    this.usersArray[index].FIRSTNAME = firstNameValue;
    if (regexPattern.test(firstNameValue) && firstNameValue!=null && firstNameValue!='') {
      this.firstNameIsValid = true;
      this.firstName = firstNameValue;
      this.fnameErrorMsg = false;
    }
    else {
      this.firstNameIsValid = false;
      this.fnameErrorMsg = true;
    }
    this.enableRadioBtn(index);    
  }

  // last name validation to check if it is alphabets and not empty string
  // if the above condition is satisfied, enable the radio button
  lastNameValidation(index) {
    let lastNameValue = $("input[name=lastName" + index + "]").val();
    var regexPattern = RegExp('^[a-zA-Z]*$');
    this.usersArray[index].LASTNAME = lastNameValue;
    if (regexPattern.test(lastNameValue) && lastNameValue!=null && lastNameValue!='') {
      this.lastNameIsValid = true;
      this.lnameErrorMsg = false;
      this.lastName = lastNameValue;
    }
    else {
      this.lastNameIsValid = false;
      this.lnameErrorMsg = true;
    }
    this.enableRadioBtn(index);    
  }

  // Email ID validation to check if it is alphabets and not empty string
  // if the above condition is satisfied, enable the radio button
  emailValidation(index) {
    var readOnlyemail = $("input[name=email" + index + "]").prop('readonly');
    if (!readOnlyemail) {
      $("#collegeAdmin" + index).prop("checked", false);
      $("#lexusUser" + index).prop("checked", false);
      $("#IsAdmin" + index).prop("checked", false);
      $("#areaAdmin" + index).prop("checked", false);

      var emailEntered = $("input[name=email" + index + "]").val();
      var emailSplit = emailEntered.toLowerCase().split('@');

      if (emailSplit[0].length >= 3 && ((emailSplit[1]).endsWith('toyota.com') || (emailSplit[1]).endsWith('lexus.com'))) {
        console.log("email is valid")
        this.emailIsValid = true;
        this.emailErrorMsg = false
        $("input[name=email" + index + "]").removeClass('warningBox');
        this.emailAddress = emailEntered;
      }
      else {
        this.emailErrorMsg = true;
        console.log("email is invalid")
        this.emailIsValid = false;
        $("input[name=email" + index + "]").addClass('warningBox')

      }
    }
    this.enableRadioBtn(index);
  }

  // remove the readonly property of the radio button only if the workdayId, email address, firstname, lastname  are not null
  // and if there is no error in the workday id and email address
  enableRadioBtn(index) {
    if (this.workDayId != null && this.emailAddress != null && this.firstName != null && this.lastName != null && !this.workIdErrorMsg && !this.emailErrorMsg) {
      $("#collegeAdmin" + index).removeAttr("disabled");
      $("#lexusUser" + index).removeAttr("disabled");
      $("#IsAdmin" + index).removeAttr("disabled");
      $("#areaAdmin" + index).removeAttr("disabled");
    }else{
      $("#collegeAdmin" + index).attr( "disabled", "disabled"); 
      $("#lexusUser" + index).attr( "disabled", "disabled" );
      $("#IsAdmin" + index).attr( "disabled", "disabled" );
      $("#areaAdmin" + index).attr( "disabled", "disabled" ); 
    }
  }

  // Add a new array with empty firstname, lastname, workdayid, email and region in the userArray
  addNewUser() {
    var lastIndex = this.usersArray.length;
    this.usersArray.push({ FIRSTNAME: '', LASTNAME: '', WORKDAYID: '', EMAIL: '', REGION: '' });
  }

  // insert the new added row in the userArray
  saveData(index, userId) {
    var indexOfDataToBeSaved = this.usersArray.length - 1 // get the last row of the userArray
    // var regionList = $("mat-select[aria-label=regionSelect" + index + "]:selected").val();
    // console.log(this.selectedRegion[index]);
    this.region=this.selectedRegion[index].toString();
    if (index < this.arrayLength) {
      var radioValue = $("input[name=userCheckBox" + index + "]:checked").val();

      if ((radioValue.toString()) == "collegeAdmin") {
        this.isAdminValue = 'N'
        this.areaAdminValue = 'N' 
        this.lexusUserValue = 'N'
        this.collegeAdminValue = 'Y'
      }
      else if ((radioValue.toString()) == 'lexusUser') {
        this.isAdminValue = 'N'
        this.areaAdminValue = 'N'
        this.lexusUserValue = 'Y'
        this.collegeAdminValue = 'N'
      }
      else if ((radioValue.toString()) == 'IsAdmin') {
        this.isAdminValue = 'Y'
        this.areaAdminValue = 'N'
        this.lexusUserValue = 'N'
        this.collegeAdminValue = 'N'
      }
      else if ((radioValue.toString()) == 'areaAdmin') {
        this.isAdminValue = 'N'
        this.areaAdminValue = 'Y'
        this.lexusUserValue = 'N'
        this.collegeAdminValue = 'N'
      }
      
      // Service call to update the user role if the user is already in the table 
      this.accessService.updateUser(userId, this.isAdminValue,this.areaAdminValue,this.lexusUserValue, 
        this.collegeAdminValue, this.region).subscribe((response: any) => {
        console.log('updated the user role');
      },err=>{
        console.log('Unable to update the user role');
      });
    }
    else {
      var radioValue = $("input[name=userCheckBox" + index + "]:checked").val();

      if ((radioValue.toString()) == "collegeAdmin") {
        this.isLexusCollegeAdmin = 'Y';
        this.isLexusUser = 'N';
        this.isIsAdmin = 'N';
        this.isAreaAdmin = 'N';
      }
      else if ((radioValue.toString()) == 'lexusUser') {
        this.isLexusCollegeAdmin = 'N';
        this.isLexusUser = 'Y';
        this.isIsAdmin = 'N';
        this.isAreaAdmin = 'N';
      }
      else if ((radioValue.toString()) == 'IsAdmin') {
        this.isLexusCollegeAdmin = 'N';
        this.isLexusUser = 'N';
        this.isIsAdmin = 'Y';
        this.isAreaAdmin = 'N';
      }
      else if ((radioValue.toString()) == 'areaAdmin') {
        this.isLexusCollegeAdmin = 'N';
        this.isLexusUser = 'N';
        this.isIsAdmin = 'N';
        this.isAreaAdmin = 'Y';
      }

      if (this.workDayIdIsValid && this.workDayIdisNotNull) {
        if (this.emailIsValid) {
          var userDetail = {
            "workDayId": this.workDayId.trim(),
            "firstName": (this.firstName.trim()),
            "lastName": (this.lastName.trim()),
            "emailAddress": this.emailAddress.trim(),
            "isLexusUser": this.isLexusUser,
            "isLexusCollegeAdmin": this.isLexusCollegeAdmin,
            "isAdmin": this.isIsAdmin,
            "isAreaAdmin": this.isAreaAdmin,
            "region": this.region
          }
          $("input[name=firstName" + index + "]").attr('readonly', 'readonly');
          $("input[name=lastName" + index + "]").attr('readonly', 'readonly');
          $("input[name=workId" + index + "]").attr('readonly', 'readonly');
          $("input[name=email" + index + "]").attr('readonly', 'readonly');

          $("input[name=workId" + index + "]").removeClass('warningBox');
          $("input[name=email" + index + "]").removeClass('warningBox');

          $("input[name=firstName" + index + "]").addClass('removeBorder');
          $("input[name=lastName" + index + "]").addClass('removeBorder');
          $("input[name=workId" + index + "]").addClass('removeBorder');
          $("input[name=email" + index + "]").addClass('removeBorder');

          // Service call to insert the user details if the user is not already in the table.
          this.accessService.addUser(userDetail).subscribe((response: any) => {
            if (response.RowUpdated.rowCount == 1) {
              console.log("data successfully inserted");
            }
            else {
              console.log("Unable to add user")
            }
          },err=>{
            console.log("Error while to adding user");
          }); 
        }
        else {
          $("input[name=email" + index + "]").addClass('warningBox')
        }
      }
      else {
        $("input[name=workId" + index + "]").addClass('warningBox')
      }
    }
  }

  // Check the user to be deleted is not user who has logged in
  sendIdToDelete(id, index) {
    this.DeleteId = id;
    this.indexToRemove = index;
    this.loggedUserWorkdayId = (this.usersArray[index].WORKDAYID).toString()
    this.loggedId = this.appService.getIdOfLoggedUser();

    if (this.loggedId != this.loggedUserWorkdayId) {
      this.canBeDeleted = true;
    }
    else {
      this.canBeDeleted = false;
    }
  }

  // Delete the user with the delete ID
  deleteRow() {
    this.deleteError = false;

    this.accessService.deleteUser(this.DeleteId).subscribe((response: any) => {
      if (response.RowUpdated.rowCount == 1) {
        console.log('Successfully deleted the user');
        this.deleteError = false;
        this.usersArray.splice(this.indexToRemove, 1);
      }
      else {
        this.deleteError = true;
        console.log('Unable to delete the user');
      }
    },err=>{
      console.log('Error while deleting the user');
    })

  }

}   
