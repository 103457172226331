import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service'

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(public appService: CommonService) { }

  ngOnInit() {
  }

}
