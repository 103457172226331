import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { CommonService } from 'src/app/service/common.service';

let headerDict = {
  'Content-Type': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  'authToken': localStorage.getItem('accessToken')
};
let myheaders = new Headers(headerDict);
let options = new RequestOptions({ headers: myheaders });

@Injectable()
export class BillingService {

  public getBillingTrendYearUrl = "LMSBillingDealersTrendYear";
  public coursesTrendYearUrl = "LMSBillingCoursesTrendYear";
  public twelveMonthBillingUrl = "LMSBillingAmntPerYear";
  public dealersAttendanceUrl = "LMSBillingAttendanceDealers";
  public coursesAttendanceUrl = "LMSBillingAttendanceCourses";
  public coursesDealersTrendUrl = "LMSBillingCoursesDealersMonTrend";
  public coursesListUrl = "LMSBillingReportCoursesList";
  public dealersListUrl = "LMSBillingReportDealersList";
  public getReportsUrl = "LMSBillingReportSearch"
  public dealersAttendanceCountUrl = "LMSBillingDealersAttendanceCnt";
  public billingDealersTrendMonthUrl = "LMSBillingDealersTrendMonth";
  public dealersOverallAttendanceUrl = "LMSBillingDealersOverallAttendanceCnt";
  public coursesAttendanceCountUrl = "LMSBillingCoursesAttendanceCnt";
  public getExceptionPayUrl = "LMSBillingExceptionPayFetch";
  public insertExceptionPayUrl = "LMSBillingExceptionPayInsert";
  public areaTrendUrl = "LMSBillingAreaTrend";
  public areaDealersTrendUrl = "LMSBillingAreaDealersTrend";
  public areaTrendYearUrl = "LMSBillingAreaTrendYear";
  public areaDealerTrendYearUrl = "LMSBillingAreaDealersTrendYear";

  constructor(private http: Http, public appService: CommonService) { }

  billingTrendYear(year,type): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getBillingTrendYearUrl + this.appService.functionKey;
    const body = {
      'year': year,
      'yearType': type
    };
    return this.http.post(url,body ,options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  coursesTrendYear(year,type): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.coursesTrendYearUrl + this.appService.functionKey;
    const body = {
      'year': year,
      'yearType': type
    };
    return this.http.post(url,body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  twelveMonthBilling(month,year,type): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.twelveMonthBillingUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'year': year,
      'yearType': type
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  dealersAttendance(month,type,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.dealersAttendanceUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'filterType': type,
      'year': year
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  usersAttendance(month,type,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.coursesAttendanceUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'filterType': type,
      'year': year,
      'type': 'chart'
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  coursesTable(month,type,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.coursesAttendanceUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'filterType': type,
      'year': year,
      'type': 'table'
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  coursesDealersTrend(month, course,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.coursesDealersTrendUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'course': course,
      'year': year
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  coursesList(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.coursesListUrl + this.appService.functionKey;
    return this.http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  dealersList(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.dealersListUrl + this.appService.functionKey;
    return this.http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getReports(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getReportsUrl + this.appService.functionKey;
    return this.http.post(url,body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  dealersAttendanceCount(month,type,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.dealersAttendanceCountUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'filterType': type,
      'year': year
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  billingDealersTrendMonth(month,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.billingDealersTrendMonthUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'year': year
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  dealersOverallAttendance(month,type,area,year,download): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.dealersOverallAttendanceUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'filterType': type,
      'area': area,
      'year':year,
      'type': download
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  coursesAttendanceCount(month,type,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.coursesAttendanceCountUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'filterType': type,
      'year': year
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  getExceptionPay(): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.getExceptionPayUrl + this.appService.functionKey;
    return this.http.get(url, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }
  insertExceptionPay(body): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');   
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.insertExceptionPayUrl + this.appService.functionKey;
    return this.http.post(url,body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  areaTrend(month,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.areaTrendUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'year': year
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  areaDealersTrend(month, area,year): Observable<any[]> {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.areaDealersTrendUrl + this.appService.functionKey;
    const body = {
      'month': month,
      'area': area,
      'year': year
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  areaTrendYear(year, yearType) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.areaTrendYearUrl + this.appService.functionKey;
    const body = {
      'year': year,
      'yearType': yearType
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  areaDealerTrendYear(year, yearType) {
    headerDict.authToken = localStorage.getItem('accessToken');
    myheaders = new Headers(headerDict);
    options = new RequestOptions({ headers: myheaders });
    const url = this.appService.baseUrl + this.areaDealerTrendYearUrl + this.appService.functionKey;
    const body = {
      'year': year,
      'yearType': yearType
    };
    //console.log('body', body);
    return this.http.post(url, body, options)
      .map((response: Response) => <any[]>response.json()).catch(this.handleError);
  }

  private handleError(error: Response | any) {
      return Observable.throw(new Error(error.status));
  }
}
