import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ReviewPaymentComponent } from '../review-payment/review-payment.component';
import 'rxjs/add/operator/map'
import 'rxjs/Rx';

@Injectable()
export class CommonService {
	loggedUserId: any;
	user: any[];
	viewEnrollment = false;
	filterName: string;
	filterContent: any[] = [];
	navigationFromDashboard: Boolean = false;
	selectedRows: any[] = [];
	unsavedChanges = false;
	_reviewPayment: ReviewPaymentComponent;
	dealershipEnrollment = true;
	dealershipStatus = 'UNENROLLED'; // PENDING WITH PRINCIPAL // DRAFT // UNENROLLED
	dc: string = '';
	isPrincipal = false;
	loggedInPrincipalEmail: any = '';
	loggedInPrincipalFullName: any = '';
	loggedInPrincipalspinId: any = '';
	isLexusCollegeAdmin: boolean = false;
	isAreaAdmin: boolean = false;
	isDebitAllowed: boolean = false;
	isASE: boolean = false;
	isJobUser: boolean = false;
	userRole = '';
	baseUrl = '';
	functionKey = '';
	hideNavbar = true;
	unauthorizedErrorMsg = ''
	defaultPage = '';
	staffMasterDealerDetails = [];
	category1 = []; // PC
	category2 = []; // Principal
	category3 = [];
	category4 = [];
	category5 = [];
	category6 = []; // GM
	category7 = []; // CONTROLLER
	accessControlDetails = [];
	isCategoryFunctionCalled: boolean = false;
	onLoginSpinList = [];
	sendMail: Boolean;
	pcHoldPage = '';
	corpHoldPage = '';
	isPcHoldCreate = false;
	isCorpHoldCreate = false;
	enrollmentCurrentStatus;
	statusDetails = '';
	authToken = '';
	dealershipEnrolledDealerCode = '';
	showSpinner = true;
	userEmail = '';
	dealershipEnrollmentNo = '';
	dealershipFormName = '';
	dealerName = '';
	certification = '';
	dashtoenrollEnrollmentForm: any = '';
	loggedInUserName: string = '';
	loggedInDetails: any = '';
	selectedRegion: string = '';

	// AuthService Variables
	showSpinnerAuth: boolean;
	corpUser: boolean;
	adminAccount: boolean;
	billing: boolean;
	ASE: boolean;
	JobUser: boolean;
	unauthorizedUser: boolean;
	lastLoginTime: any = '';
	dealerCode: any = '';
	hideSideFull: boolean;
	mainContent: boolean;
	LoggedInUser: any = '';
	workdayId: any = '';
	userType: any;
	spinId: any;
	loginId: any;
	role: string;
	showHelp: any;
	loggedIn: string;
	idleState: string;
	timedOut: boolean;
	idleStart: boolean;
	regionList = [];

	// JOB CODE LIST
	jobCodeList = [
		// Lexus Certification List
		'038', '040', '043', '006', '035', '032', '065', '066', '053', '055', '027', '007', '136', '028', '162', '031', '054', '060', '005', '017', '029', '039', '041', '020', '012', '015',
		// Mainframe Payment
		'002', '003', '004', '005', '007', '008', '010', '011', '017', '023', '024', '028', '035', '037', '039', '040', '043', '044', '045', '050', '051', '066', '068', '069', '099', '049'
	];

	constructor(private _http: Http) { }

	setEnrollUserData(value) {
		this.user = value;
		this.viewEnrollment = false;
	}

	setViewlUserData(value) {
		this.user = value;
		this.viewEnrollment = true;
	}

	getEnrollUserData(): any[] {
		return this.user;
	}

	getViewEnrollment(): any {
		return this.viewEnrollment;
	}
	// default date - 1900-01-01
	// getSeniorEligibileCheck(array): String {
	// 	for (let i = 0; i < array.length; i++) {
	// 		var { jobCode, seniorEligibleDate1, seniorEligibleDate2, seniorEligibleDate3 } = array[i];
	// 		if (jobCode == '012' || jobCode == '015') {
	// 			if (seniorEligibleDate1 != undefined && seniorEligibleDate1 != null && seniorEligibleDate1 != '') {
	// 				if (seniorEligibleDate2 != undefined && seniorEligibleDate2 != null && seniorEligibleDate2 != '') {
	// 					if (seniorEligibleDate3 != undefined && seniorEligibleDate3 != null && seniorEligibleDate3 != '') {
	// 						var currentDate = new Date();
	// 						if (currentDate > new Date(seniorEligibleDate1 + '') && currentDate <= new Date(seniorEligibleDate3 + '')) {
	// 							return 'Senior Eligible';
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 		else {
	// 			return 'Senior';
	// 		}
	// 	}
	// 	return 'Senior';
	// }
	async getSeniorEligibileCheck(array) {
		return new Promise((resolve, reject) => {
		  for (let i = 0; i < array.length; i++) {
				var { jobCode, seniorEligibleDate1, seniorEligibleDate2, seniorEligibleDate3 } = array[i];
				if (jobCode == '012' || jobCode == '015') {
					if (seniorEligibleDate1 != undefined && seniorEligibleDate1 != null && seniorEligibleDate1 != '') {
						if (seniorEligibleDate2 != undefined && seniorEligibleDate2 != null && seniorEligibleDate2 != '') {
							if (seniorEligibleDate3 != undefined && seniorEligibleDate3 != null && seniorEligibleDate3 != '') {
								var currentDate = new Date();
								if (currentDate > new Date(seniorEligibleDate1 + '') && currentDate <= new Date(seniorEligibleDate3 + '')) {
									resolve('Senior Eligible');
								}
							}
						}
					}
				}
				else {
					resolve('Senior');
				}
			}
			resolve('Senior');
		});
	  }

	filterSeniorEligibleDashboard(object): Boolean {
		//console.log(object);
		var { jobCode, seniorEligibleDate1, seniorEligibleDate2, seniorEligibleDate3 } = object;
		if (jobCode == '012' || jobCode == '015') {
			if (seniorEligibleDate1 != undefined && seniorEligibleDate1 != null && seniorEligibleDate1 != '') {
				if (seniorEligibleDate2 != undefined && seniorEligibleDate2 != null && seniorEligibleDate2 != '') {
					if (seniorEligibleDate3 != undefined && seniorEligibleDate3 != null && seniorEligibleDate3 != '') {
						var currentDate = new Date();
						if (currentDate > new Date(seniorEligibleDate1 + '') && currentDate <= new Date(seniorEligibleDate3 + '')) {
							return true;
						}
					}
				}
			}
		}
		else {
			return false;
		}
	}

	dateSplitter(dates) {
		if (dates != undefined && dates != null && dates != '') {
			let dategn = dates.split('T');
			let dateparts = dategn[0].split('-');
			return dateparts[1] + '/' + dateparts[2] + '/' + dateparts[0];
		}
	}

	dateWithTimeSplitter(dates) {
		let date = this.dateSplitter(dates)
		let time = new Date(dates).toLocaleString().split(', ')[1];
		return date + ' ' + time;
	}

	setFilter(filterName, key) {
		if (key == 'add') {
			this.filterContent.push(filterName);
		} else {
			this.filterContent = this.filterContent.filter(array => array.name !== filterName);
		}

	}

	getFilterContent(): any[] {
		return this.filterContent;
	}

	setCheckedRow(rows) {
		this.selectedRows = rows;
	}

	getCheckedRows(): any {
		return this.selectedRows;
	}

	setThisRequestPayment(reviewPayment): void {
		this._reviewPayment = reviewPayment;
	}

	getThisRequestPayment() {
		return this._reviewPayment;
	}

	setLexusCollegeAdminUserFromLogin() {
		this.isLexusCollegeAdmin = true;
	}

	setAreaAdminUserFromLogin() {
		this.isAreaAdmin = true;
	}

	setAreaCode(region) {
		this.selectedRegion = region;
	}

	// idOfLoggedUser
	idOfLoggedUser(id) {
		this.loggedUserId = id
		console.log("form service file code is", this.loggedUserId)
	}
	getIdOfLoggedUser() {
		return this.loggedUserId
	}
}
