import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { CommonService } from '../service/common.service';
declare var $: any;
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-view-dealership-enrollment',
  templateUrl: './view-dealership-enrollment.component.html',
  styleUrls: ['./view-dealership-enrollment.component.css']
})
export class ViewDealershipEnrollmentComponent implements OnInit {

  constructor(private dashboardService:DashboardService, private appService: CommonService ) { }

  public dealershipEnvelopeId = '';

  ngOnInit() {

    this.dashboardService.checkDealershipEnrolled(this.appService.dealershipEnrolledDealerCode).subscribe ((result: any) => {
      this.dealershipEnvelopeId = result.DealerEnrollmentCheck.Result[0].envelopeId;
      var url = 'Enrollment_Form?'
      if (environment.baseUrl.indexOf('funclml')!=-1) {
        url = 'Enrollment_Form'
      }
      $('#expandImage').attr('src',
      environment.baseUrl + url + environment.functionKey + '&envelopeId='
      + this.dealershipEnvelopeId + '' + '#zoom=100');
    })
  }

  ngAfterViewInit() {
    setTimeout(function () {
    }.bind(this), 1000);
    console.log('After view',this.dealershipEnvelopeId);
  }

}
