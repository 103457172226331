import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UnenrollService } from '../unenroll-assosiate/unenroll.service';
import { CommonService } from '../service/common.service';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { AppComponent } from '../app.component';
import { PaymentHistoryService } from './payment-history.service';
import { CorpHoldResumeService } from '../corp-hold-resume/corp-hold-resume.service';
import { ExcelService } from '../service/excel.service';
import { Router } from '@angular/router';

export interface PaymentData {
  payduecode: string;
  lfspaycode: string;
  paymentPeriod: string;
  paymentDate: string;
}

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentHistoryComponent implements OnInit {
  //Material Table
  paymentDataSource: MatTableDataSource<PaymentData>;
  paymentColumns = [ 'lfspaycode', 'paymentPeriod', 'paymentDate'];

  //------------- Paginator and Sort @ViewChild-----------------
  @ViewChild(MatSort) sort: MatSort;

  //BreadCrumbs Variables
  dasboardTab: boolean = false;

  //Search Variables
  loadSpinner: boolean;
  filteredUsers: any[] = [];
  PayHistoryForm: FormGroup;
  isLoading = false;
  spinId: any = "";
  spinIdClicked: Boolean = false;
  selectedUser: any = {};

  //Table Variables
  tableDisplay: boolean = false;
  paymentTableLoading: boolean = false;
  paymentNoData: boolean = false;
  paymentHistoryResponse: any = '';
  PAYMAINDATA: any = [];
  PAYMENTDATA: PaymentData[] = [];
  paymentTotalData: number = 0;
  noExcelData: boolean = false;

  //Info Variable
  dealername: string;
  spinnos: string;
  dealercode: string;
  nameAssociate: string;

  constructor(private fb: FormBuilder, private unenrollService: UnenrollService,
    private appService: CommonService, private payHistoryService: PaymentHistoryService,
    private appComponent: AppComponent, private corpHoldService: CorpHoldResumeService, 
    private excelService: ExcelService, private router: Router) {

  }

  ngOnInit() {
    console.log('pc pay hist',this.appService.role);
    if (this.appService.role == 'Lexus College' || this.appService.role == 'Lexus Associate' || 
    this.appService.role == 'Area Admin' || this.appService.role == 'Billing' || this.appService.role == 'Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
        this.router.navigate(['unauthorized']);
    }
    this.PayHistoryForm = this.fb.group({
      userInput: null
    });
  }

  // ======================================DEALER CODE SEARCH==========================================

  fadeDiv() {
    this.spinIdClicked = true;
  }

  fadeOut() {
    this.spinIdClicked = false;
  }

  closeSpinSearch() {
    this.spinId = "";
    this.spinIdClicked = true;
  }

  filterUser() {
    if (this.spinId != undefined) {this.spinId = this.spinId.replace(/[^a-zA-Z0-9 ]/g, "");}
    if (this.spinId != undefined && this.spinId.length > 1) {
      this.filteredUsers = [];
      this.isLoading = true;
      this.unenrollService.getEnrolledUserSearch(this.appService.dc, this.spinId).subscribe((result: any) => {
        this.filteredUsers = result.HoldPaymentSearch.Result;
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.filteredUsers = [];
        console.log('Error', err);
        console.log('Error while fetching the user list');
      });
    }
  }

  selectUser(user) {
    this.selectedUser = user;
    this.tableDisplay = true;
    this.nameAssociate= user.firstName+' '+user.lastName;
    this.getPaymentHistoryDetail(user.spinId, this.appService.dc);
  }

  // =========================================TABLE FILTER=============================================
  getPaymentHistoryDetail(spinId, dlrcode) {
    this.paymentTableLoading = true;
    this.PAYMAINDATA = [];
    this.paymentHistoryResponse = '';
    this.PAYMENTDATA = [];
    this.payHistoryService.getPayHistoryData(spinId, dlrcode).subscribe((response: any) => {
      this.paymentHistoryResponse = response.PaymentHistory.Result;
      [].push.apply(this.PAYMAINDATA, this.paymentHistoryResponse);
      if (this.PAYMAINDATA.length != 0) {
        let obj, month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        for (let i = 0; i < this.PAYMAINDATA.length; i++) {
          this.dealercode =  this.PAYMAINDATA[i].dealerCode;
          this.dealername = this.PAYMAINDATA[i].dealerName;
          this.spinnos = '104' + this.PAYMAINDATA[i].uid;
          let paydueDate = this.PAYMAINDATA[i].payDueCd;
          obj = {
           // 'payduecode': this.PAYMAINDATA[i].payDueCd ,
            'lfspaycode': this.PAYMAINDATA[i].lfsPayCd,
            'paymentPeriod': this.PAYMAINDATA[i].payDueCd,
            'paymentDate': this.PAYMAINDATA[i].lfsRunDtp
          }
          this.PAYMENTDATA.push(obj);
        };
        console.log('After For', this.PAYMENTDATA);
        this.paymentTotalData = this.PAYMENTDATA.length;
      } else {
        this.paymentNoData = true;
        this.paymentTotalData = 0;
      };
      this.paymentDataSource = new MatTableDataSource(this.PAYMENTDATA);
      this.paymentDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'paymentDate': return new Date(item.paymentDate);
          default: return item[property];
        }
      };
      this.paymentDataSource.sort = this.sort;
      this.paymentTableLoading = false;
      this.spinId = this.nameAssociate;
    }, error => {
      this.paymentNoData = true;
      this.paymentTotalData = 0;
      this.paymentTableLoading = false;
      this.spinId = this.nameAssociate;
      console.log('Error', error);
      console.log('Error While getting data for the payment History Table');
    });
  };

  paymentapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.paymentDataSource.filter = filterValue;
    this.paymentDataSource.sort = this.sort;
  }

  // ===========================================EXPORT EXCEL FILE================================================
  exportFile() {
    if (this.paymentTotalData != 0) {
      this.excelService.exportAsExcelFile(this.paymentDataSource.data, this.spinId + '_Payment_History');
    } else {
      this.noExcelData = true;
    }
  }

}
